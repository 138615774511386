import clsx from "clsx";
import { isMobile } from "helpers/const";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import MyListingsOutline from "../../../../../assets/media/icons/my-listings-outline.svg";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";
import EyeSVG from "../../../../../assets/media/icons/other_icons/Eye.svg";
import ReportsOutline from "../../../../../assets/media/icons/reports-outline.svg";
import { LayoutContext } from "../../../../../components/core/LayoutProvider";
import DropDownField from "../../../../../components/formComponent/DropDownField";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";

const ActionButton = ({
  sIndex,
  list,
  SVGIconName,
  handleViewForm,
  matchedView,
}: any) => {
  return (
    <button
      className={clsx(
        SVGIconName === "edit"
          ? `group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7`
          : `group my-button flex items-center justify-center p-1 md:p-1 w-full md:w-10`,
        matchedView && "pointer-events-none"
      )}
      type="button"
      id={`record-view-tooltip${sIndex}`}
      onClick={() => {
        handleViewForm(list, sIndex);
      }}
    >
      <Tooltip
        anchorId={`record-view-tooltip${sIndex}`}
        content={
          matchedView ? "Close" : SVGIconName === "edit" ? "Edit" : "View"
        }
        place={`${sIndex === 0 ? "left" : "top"}`}
        variant="light"
        className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
          sIndex === 0 ? "mt-0" : "mt-1.5"
        }`}
      />
      <IKTSVG
        className={
          SVGIconName === "edit"
            ? `flex items-center justify-center fill-white group-hover:fill-white transition`
            : `flex items-center justify-center  group-hover:fill-indigo-500 ${
                matchedView ? "fill-indigo-500" : "fill-violet-500"
              }`
        }
        svgClassName="w-4 h-[.6875rem]"
        path={SVGIconName === "edit" ? EDITSVG : EyeSVG}
      />
    </button>
  );
};

const ButtonComponent = ({
  sIndex,
  list,
  SVGPath,
  handleClick,
  matchedView,
  tooltipContent,
  id,
  disabled = false,
}: {
  sIndex: any;
  list: any;
  SVGPath: any;
  handleClick: any;
  matchedView: any;
  tooltipContent: any;
  id: any;
  disabled: any;
}) => {
  return (
    <button
      className={clsx(
        `group inline-flex group items-center justify-center text-white hover:text-white ${
          disabled ? "bg-gray-400 pointer-events-none" : "bg-violet-500"
        } hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7`,
        matchedView && "pointer-events-none"
      )}
      type="button"
      data-tooltip-id={`record-view-tooltip${id}${sIndex}`}
      onClick={() => {
        handleClick(list, sIndex);
      }}
      disabled={disabled}
    >
      <Tooltip
        id={`record-view-tooltip${id}${sIndex}`}
        content={matchedView ? "Close" : tooltipContent}
        place={`${sIndex === 0 ? "left" : "top"}`}
        variant="light"
        className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
          sIndex === 0 ? "mt-0" : "mt-1.5"
        }`}
      />
      <IKTSVG
        className={`flex items-center justify-center group-hover:fill-white transition ${
          matchedView && "!fill-indigo-500"
        } `}
        svgClassName={`w-4 h-[1rem]`}
        path={SVGPath}
      />
    </button>
  );
};
const TableActionButtons = ({
  list,
  sIndex,
  matchedView,
  handleViewForm,
  handleOnChangeStatus,
  SVGIconName = "edit",
  mainOptions,
  shadowRequired,
  tab = "",
}: any) => {
  const findOption = (options: any, value: any) => {
    return options.find((option: any) => option?.name === value) || null;
  };
  const globalLayout = useContext(LayoutContext);
  const currentStatusValue = list?.status?.name;
  const selectedOption = findOption(mainOptions, currentStatusValue);
  const navigate = useNavigate();

  const handleInventoryRedirect = (list: any, sIndex: any) => {
    globalLayout?.setGlobalInventory({
      team_id: list?.id,
      team_name: list?.name,
    });
    navigate("/my-listings");
  };
  const handleReportsRedirect = (list: any, sIndex: any) => {
    globalLayout?.setGlobalReports({
      team_id: list?.id,
      team_name: list?.name,
    });
    navigate("/reports");
  };

  return (
    <>
      <td
        className={`md:sticky relative right-0 ml-auto p-0 icon_td  ${
          tab === "subscriber_detail"
            ? "md:w-[10.75rem] md:max-w-[10.75rem] md:min-w-[15.25rem] w-[13rem] max-w-[13rem] min-w-[13rem]"
            : " md:!w-[10.75rem] md:!max-w-[10.75rem] md:!min-w-[10.75rem] !w-[5.6rem] !max-w-[5.6rem] !min-w-[5.6rem]"
        }`}
      >
        <div
          className={` bg-opacity-[7%] ${
            matchedView && "bg-indigo-500 bg-opacity-[7%]"
          }`}
        >
          <div
            className={clsx(
              "flex relative w-full h-full border-l left-shad",
              shadowRequired && "left-shad"
            )}
            id="left-shad-list"
          >
            <div
              id={sIndex}
              className="w-[8.1875rem] max-w-[8.1875rem] min-w-[8.1875rem] border-r subscription_drop p-[.625rem]  py-2 "
            >
              <DropDownField
                options={mainOptions}
                placeholder={selectedOption ? "" : "Action"}
                name={`Status`}
                getOptionLabel="label"
                value={selectedOption}
                isMenuPortalTarget={true}
                isClearable={false}
                getOptionValue="name"
                handleOnChange={(data: any) => {
                  handleOnChangeStatus(data, list, sIndex);
                }}
                isSearchable={false}
              />
            </div>
            {tab === "subscriber_detail" ? (
              <div className="flex items-center justify-center gap-1.5 p-1 md:p-1 w-8  md:flex-auto flex-1 md:border-r">
                <>
                  <ButtonComponent
                    sIndex={sIndex}
                    list={list}
                    SVGPath={MyListingsOutline}
                    handleClick={handleInventoryRedirect}
                    matchedView={matchedView}
                    tooltipContent="My listing"
                    id={"viewInventory"}
                    disabled={
                      list?.status?.id !== "Active" || list?.tx_trade_user
                    }
                  />
                  <ButtonComponent
                    sIndex={sIndex}
                    list={list}
                    SVGPath={ReportsOutline}
                    handleClick={handleReportsRedirect}
                    matchedView={matchedView}
                    tooltipContent="Reports"
                    id={"viewReports"}
                    disabled={
                      list?.status?.id !== "Active" || list?.tx_trade_user
                    }
                  />
                  {!isMobile && (
                    <ActionButton
                      sIndex={sIndex}
                      list={list}
                      SVGIconName={SVGIconName}
                      handleViewForm={handleViewForm}
                      matchedView={matchedView}
                    />
                  )}
                </>
              </div>
            ) : (
              <>
                {!isMobile && (
                  <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
                    <ActionButton
                      sIndex={sIndex}
                      list={list}
                      SVGIconName={SVGIconName}
                      handleViewForm={handleViewForm}
                      matchedView={matchedView}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </td>
      {globalLayout?.isMobile && (
        <td
          className={`sticky right-0 ml-auto p-0 icon_td w-[2.5rem] max-w-[2.5rem] min-w-[2.5rem]`}
        >
          <div
            className={` bg-opacity-[7%] ${
              matchedView && "bg-indigo-500 bg-opacity-[7%]"
            }`}
          >
            <div
              className={clsx(
                "flex relative w-full h-full border-l",
                shadowRequired && "left-shad"
              )}
              id="left-shad-list"
            >
              <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
                <ActionButton
                  sIndex={sIndex}
                  list={list}
                  SVGIconName={SVGIconName}
                  handleViewForm={handleViewForm}
                  matchedView={matchedView}
                />
              </div>
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default TableActionButtons;
