import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../../helpers/AssetHelpers";
import {
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_FILTER,
  TX_ACCOUNT_OPTIONS_STATE,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
  WALLET_COLUMNS,
} from "../../../../helpers/const";
import { tabConditions } from "../../../tx_account/core/functions";

export interface LayoutContextModel {
  // GENERAL
  isTabActive: any;
  setTabActive: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  customLoader: boolean;
  setCustomLoader: (data: any) => void;

  filterOptions: any;
  setFilterOptions: (data: any) => void;

  currencyBalance: any;
  setCurrencyBalance: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  porterState: any;
  setPorterState: (data: any) => void;

  // SELLER WALLETS
  sellerWalletsFilter: any;
  setSellerWalletsFilter: (data: any) => void;

  sellerWalletsList: any;
  setSellerWalletsList: (data: any) => void;

  sellerWalletsMeta: any;
  setSellerWalletsMeta: (data: any) => void;

  // Bank accounts
  bankAccountFilter: any;
  setBankAccountFilter: (data: any) => void;

  bankAccountList: any;
  setBankAccountList: (data: any) => void;

  bankAccountMeta: any;
  setBankAccountMeta: (data: any) => void;

  // WITHDRAW REQUEST
  withdrawRequestFilter: any;
  setWithdrawRequestFilter: (data: any) => void;

  withdrawRequestList: any;
  setWithdrawRequestList: (data: any) => void;

  withdrawRequestMeta: any;
  setWithdrawRequestMeta: (data: any) => void;

  isSelectAll: any;
  setIsSelectAll: (data: any) => void;

  commonFilter: any;
  setCommonFilter: (data: any) => void;
}

const WalletAndBankDetailsContext = createContext<LayoutContextModel>({
  // GENERAL
  isTabActive: {},
  setTabActive: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  customLoader: false,
  setCustomLoader: (data: any) => {},

  filterOptions: [],
  setFilterOptions: (data: any) => {},

  currencyBalance: [],
  setCurrencyBalance: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  porterState: [],
  setPorterState: (data: any) => {},

  // SELLER WALLETS
  sellerWalletsFilter: {},
  setSellerWalletsFilter: (data: any) => {},

  sellerWalletsList: [],
  setSellerWalletsList: (data: any) => {},

  sellerWalletsMeta: [],
  setSellerWalletsMeta: (data: any) => {},

  // BANK ACCOUNT
  bankAccountFilter: {},
  setBankAccountFilter: (data: any) => {},

  bankAccountList: [],
  setBankAccountList: (data: any) => {},

  bankAccountMeta: [],
  setBankAccountMeta: (data: any) => {},

  // WITHDRAW REQUEST
  withdrawRequestFilter: {},
  setWithdrawRequestFilter: (data: any) => {},

  withdrawRequestList: [],
  setWithdrawRequestList: (data: any) => {},

  withdrawRequestMeta: [],
  setWithdrawRequestMeta: (data: any) => {},

  isSelectAll: [],
  setIsSelectAll: (data: any) => {},

  commonFilter: [],
  setCommonFilter: (data: any) => {},
});

const WalletAndBankDetailsProvider = ({ children }: PropsWithChildren) => {
  const globalLayout = useContext(LayoutContext);

  // GENERAL
  const [isTabActive, setTabActive] = useState<string>("Seller wallets");
  const [porterState, setPorterState] = useState<any>();

  const tabCondition = tabConditions(isTabActive, "filter");

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[
      `wallet_bank_details_filter_${tabCondition}`
    ];

  let item = getHeaderFilter(
    `wallet_bank_details_filter_${tabCondition}`,
    isFilterExistInDB,
    TX_ACCOUNT_FILTER
  );

  item = item?.filter((obj: any) => {
    return obj?.tab.includes(tabCondition);
  });

  const [filterItems, setFilterItems] = useState<any>(() => {
    return item;
  });
  const [draggedItems, setDraggedItems] = useState(filterItems);
  const [customLoader, setCustomLoader] = useState<boolean>(false);
  const [filterOptions, setFilterOptions] = useState<any>(
    TX_ACCOUNT_OPTIONS_STATE
  );
  const [draggedColumns, setDraggedColumns] = useState([]);

  const tabConditionColumn = tabConditions(isTabActive, "column");

  // FILTER - CONDITION
  let isColumnExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.[
      `wallet_bank_details_column_${tabConditionColumn}`
    ];

  let itemColumn = getHeaderFilter(
    `wallet_bank_details_column_${tabConditionColumn}`,
    isColumnExistInDB,
    WALLET_COLUMNS
  );

  const [columnItems, setColumnItems] = useState(() => {
    return itemColumn;
  });
  const [currencyBalance, setCurrencyBalance] = useState({
    overview: undefined,
  });

  // SELLER WALLETS
  const [sellerWalletsFilter, setSellerWalletsFilter] = useState<any>(
    TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER
  );
  const [sellerWalletsList, setSellerWalletsList] = useState<any>([]);
  const [sellerWalletsMeta, setSellerWalletsMeta] = useState<any>();

  // BANK ACCOUNT
  const [bankAccountFilter, setBankAccountFilter] = useState<any>(
    TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER
  );
  const [bankAccountList, setBankAccountList] = useState<any>();
  const [bankAccountMeta, setBankAccountMeta] = useState<any>();

  // WITHDRAW REQUEST
  const [withdrawRequestFilter, setWithdrawRequestFilter] = useState<any>(
    TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
  );
  const [withdrawRequestList, setWithdrawRequestList] = useState<any>();
  const [withdrawRequestMeta, setWithdrawRequestMeta] = useState<any>();
  const [isSelectAll, setIsSelectAll] = useState<any>({
    status: "",
  });

  const [commonFilter, setCommonFilter] = useState<any>({
    sellerWalletsFilterCopy: TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
    bankAccountFilterCopy: TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
    withdrawRequestFilterCopy: TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
  });

  // CONTEXT VALUE
  const value: LayoutContextModel = {
    isTabActive,
    setTabActive,
    filterItems,
    setFilterItems,
    draggedItems,
    setDraggedItems,
    customLoader,
    setCustomLoader,
    filterOptions,
    setFilterOptions,
    draggedColumns,
    setDraggedColumns,
    columnItems,
    setColumnItems,
    currencyBalance,
    setCurrencyBalance,
    porterState,
    setPorterState,

    // SELLER WALLET
    sellerWalletsFilter,
    setSellerWalletsFilter,
    sellerWalletsList,
    setSellerWalletsList,
    sellerWalletsMeta,
    setSellerWalletsMeta,

    // BANK ACCOUNT
    bankAccountFilter,
    setBankAccountFilter,
    bankAccountList,
    setBankAccountList,
    bankAccountMeta,
    setBankAccountMeta,

    // WITHDRAW REQUEST
    withdrawRequestFilter,
    setWithdrawRequestFilter,
    withdrawRequestList,
    setWithdrawRequestList,
    withdrawRequestMeta,
    setWithdrawRequestMeta,
    isSelectAll,
    setIsSelectAll,
    commonFilter,
    setCommonFilter,
  };

  return (
    <WalletAndBankDetailsContext.Provider value={value}>
      {children}
    </WalletAndBankDetailsContext.Provider>
  );
};
export { WalletAndBankDetailsContext, WalletAndBankDetailsProvider };
