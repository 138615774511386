export const formattedKeyValues: any = {
  face_value: "face_value_currency",
  sell_price: "sell_price_currency",
  ticket_price: "ticket_currency",
  listing_price: "listing_currency",
  fine_amount: "fine_currency",
  order_total: "ticket_currency",
};

export const CHANGE_LOGS_KEY: any = {
  id: "order_ID",
  event_venue: "venue",
  ticket_quantity: "qty",
  event_description: "event",
  order_id: "marketplace_ID",
  customer_id: "customer_ID",
  sell_price: "proceed_price",
  quantity_available: "quantity",
  quantity_display: "max_display_quantity",
  customer_national_id: "customer_national_ID",
  date_shipped: "date_of_ship",
  ppe_prices: "PPE_prices",
};

export const INITIAL_KEY = Object.fromEntries(
  Object.entries(CHANGE_LOGS_KEY).map(([key, value]) => [value, key])
);

export const DATE_TIME_KEYS = ["event_date_time", "order_date_time"];

export const UTC_DATE_TIME_KEYS = ["updated_at", "created_at"];

export const EXCEPTION_KEYS = {
  LISTING_QUALITY: "listing_quality",
  TICKET_TYPE: "ticket_type",
  STATUS: "status",
};

export const TICKET_TYPE_LABEL: any = {
  mobile: "External Transfer",
  "mobile-link": "Mobile Link/PKPASS",
  "season-cards": "Member/Season Card",
  paper: "Paper",
};

export const UPDATED_STATUS: any = {
  Publish: "Published",
  Unpublish: "Unpublished",
};

export const FORMAT_EXCEPTION_KEYS = {
  [EXCEPTION_KEYS.LISTING_QUALITY]: (value: any) => {
    return String(value) === "0" ? "Low" : `Level ${value}`;
  },
  [EXCEPTION_KEYS.TICKET_TYPE]: (value: any) => {
    return TICKET_TYPE_LABEL[value] ?? value;
  },
  [EXCEPTION_KEYS.STATUS]: (value: any) => {
    return UPDATED_STATUS[value] ?? value;
  },
};

export const LOG_TAB_KEYS = { ORDER: "order_id", INVENTORY: "listing_id" };

export const LOG_TABS = [
  { title: "Order Logs", key: LOG_TAB_KEYS.ORDER },
  { title: "Inventory Logs", key: LOG_TAB_KEYS.INVENTORY },
];
