/* eslint-disable array-callback-return */
import _, { size } from "lodash";
import moment from "moment";
import React, { useContext } from "react";
import {
  getBenefitsValue,
  getCategoryValue,
  getRestrictionValue,
  getSectionValue,
  getTicketType,
} from "../../../../../helpers/AssetHelpers";
import { deliveryTypeOrders, SYMBOLS } from "../../../../../helpers/const";
import {
  crc32,
  decimalNumber,
  firstLetterCapital,
} from "../../../../../helpers/Functions";
import MobileViewListingBlock from "../../../../add_inventory/table/components/MobileViewListingBlock";
import { InventoryContext } from "../../../core/InventoryProvider";
import { ICellBlockClasses } from "../../../core/models";
import TicketTypeExist from "../../nonJSXComponent/TicketTypeExist";

type TMobileRenderList = {
  columns: any;
  event: any;
  eventRecord: any;
};

const RenderList = ({ columns, event, eventRecord }: TMobileRenderList) => {
  const layout = useContext(InventoryContext);
  const { selectedDeliveryTypes, paperTypeExist } = TicketTypeExist(event);
  const ticketTypeValue =
    _.size(eventRecord) > 0 && getTicketType(eventRecord, layout);

  const isPaper =
    eventRecord?.ticket_type === "paper" ||
    eventRecord?.ticket_type?.id === "paper";

  let splitCondition: string = "";
  if (
    (typeof eventRecord?.split_type === "string" &&
      eventRecord?.split_type !== "MULTIPLES") ||
    (typeof eventRecord?.split_type === "object" &&
      eventRecord?.split_type?.id !== "MULTIPLES")
  ) {
    splitCondition = "text-gray-400";
  } else {
    splitCondition = "";
  }

  const categoryValue =
    _.size(eventRecord) > 0 &&
    _.size(event) > 0 &&
    getCategoryValue(eventRecord, event);
  const sectionValue =
    _.size(eventRecord) > 0 &&
    _.size(event) > 0 &&
    getSectionValue(eventRecord, event);
  const benefitsValue =
    _.size(eventRecord) > 0 && getBenefitsValue(eventRecord, layout);
  const restrictionValue =
    _.size(eventRecord) > 0 && getRestrictionValue(eventRecord, layout);

  const faceValue =
    typeof eventRecord?.face_value === "object"
      ? decimalNumber(eventRecord?.face_value?.value)
      : decimalNumber(eventRecord?.face_value);
  const proccedValue =
    typeof eventRecord?.sell_price === "object"
      ? decimalNumber(eventRecord?.sell_price?.value)
      : decimalNumber(eventRecord?.sell_price);

  let splitTypeValue: any =
    typeof eventRecord?.split_type === "object"
      ? eventRecord?.split_type
      : Array.isArray(layout.splitType) &&
        layout.splitType.find(
          (split: any) => split.id === eventRecord?.split_type
        );

  return columns?.map((columnItem: any, index: number) => {
    const uniqueKey = `${event?.id}-${columnItem.id}-${index}`;

    // CLASSES
    const cellBlockClasses: ICellBlockClasses = {
      "Listing ID": "disable second-col truncate",
      "Team name": "p-1.5 min-w-[7.625rem] max-w-[7.625rem] ellips-text",
      "User name": "p-1.5 min-w-[7.625rem] max-w-[7.625rem] ellips-text",
      "Ticket type": "third-col",
      "Delivery type": `delivery-type disable four-col ${
        !isPaper ? "text-gray-400" : "ena"
      }`,
      Quantity: `eight-col ${
        layout.tableDataEditChanging?.includes("quantity_available") && "edited"
      }`,
      Sold: "nine-col",
      "Split type": "ellips-text ten-col inline",
      "Sell in multiple": `eleven-col ${splitCondition}`,
      "Max display": "twelve-col",
      Category: "thirteen-col",
      "Section/block": "fourteen-col",
      Row: "fifteen-col",
      "First seat": "sixteen-col",
      "Face value": "seventeen-col",
      "Proceed Price": "eighteen-col",
      Benefits: `twentyone-col ${
        benefitsValue?.length ? "en" : "text-gray-500"
      }`,
      Restrictions: `twentytwo-col ${
        restrictionValue?.length ? "en" : "text-gray-500"
      }`,
      ticketInHand: `inhand ${
        eventRecord?.ticketsInHands ? "hand" : "nohand"
      } ${
        layout.tableDataEditChanging?.includes("ticketsInHands") &&
        eventRecord?.selected &&
        "edited-new"
      } nineteen-col`,
      DateToShip: `twenty-col ${
        eventRecord?.ticketsInHands && "text-gray-400"
      }`,
    };

    switch (columnItem.id) {
      case 0:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-listing-ID`}
            className={cellBlockClasses["Listing ID"]}
            value={eventRecord?.seller_listing_id ? eventRecord?.seller_listing_id :  crc32(eventRecord?.id)}
            placeholder="Listing ID"
          />
        );
      case 1:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-team-member`}
            className={cellBlockClasses["Team name"]}
            value={
              eventRecord?.team_name &&
              firstLetterCapital(eventRecord?.team_name)
            }
            placeholder="Team name"
          />
        );
      case 2:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-team-user`}
            className={cellBlockClasses["User name"]}
            value={
              eventRecord?.user_name &&
              firstLetterCapital(eventRecord?.user_name)
            }
            placeholder="User name"
          />
        );
      case 3:
        return (
          <React.Fragment key={`${uniqueKey}-mobile-ticket-wrapper`}>
            <MobileViewListingBlock
              key={`${uniqueKey}-mobile-ticket-type`}
              className={cellBlockClasses["Ticket type"]}
              value={ticketTypeValue?.name}
              placeholder=""
            />

            {paperTypeExist && (
              <MobileViewListingBlock
                key={`${uniqueKey}-mobile-delivery-type`}
                className={cellBlockClasses["Delivery type"]}
                value={
                  size(eventRecord?.delivery_options)
                    ? `${size(eventRecord?.delivery_options)} selected`
                    : " Delivery type"
                }
                placeholder="Delivery type"
              />
            )}

            {paperTypeExist &&
              selectedDeliveryTypes?.length > 0 &&
              paperTypeExist &&
              Array.isArray(selectedDeliveryTypes) &&
              selectedDeliveryTypes.length > 0 &&
              _.orderBy(selectedDeliveryTypes, deliveryTypeOrders, ["asc"]).map(
                (selected: any) => {
                  const typeTemp = layout.deliveryType.find(
                    (item: any) => item.type === selected
                  );
                  const delivery = eventRecord?.delivery_options?.find(
                    (option: any) => option.id === typeTemp?.id
                  );

                  const content =
                    delivery && eventRecord?.[delivery.id] !== undefined
                      ? eventRecord[delivery.id]
                      : delivery?.price
                      ? Number(delivery.price)
                      : selected;

                  const className = delivery
                    ? "p-1.5 min-w-[7.625rem] max-w-[7.625rem] five-col"
                    : "p-1.5 min-w-[7.625rem] max-w-[7.625rem] disable five-col";

                  return (
                    <MobileViewListingBlock
                      key={`${uniqueKey}-mobile-delivery-type-options`}
                      className={className}
                      value={content}
                      placeholder="-"
                    />
                  );
                }
              )}
          </React.Fragment>
        );

      case 5:
        return (
          <React.Fragment key={`${uniqueKey}-quantity-field-wrapper`}>
            <MobileViewListingBlock
              key={`${uniqueKey}-mobile-quantity-available`}
              className={cellBlockClasses["Quantity"]}
              value={eventRecord?.quantity_available}
              placeholder="Quantity"
            />

            <MobileViewListingBlock
              key={`${uniqueKey}-mobile-sold`}
              className={cellBlockClasses["Sold"]}
              value={eventRecord?.quantity_sold}
              placeholder="0"
            />
          </React.Fragment>
        );
      case 6:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-split-type`}
            className={cellBlockClasses["Split type"]}
            value={
              splitTypeValue?.name === "No Preference"
                ? "No preference"
                : splitTypeValue?.name
            }
            placeholder="Split type"
          />
        );
      case 7:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-split-qty`}
            className={cellBlockClasses["Sell in multiple"]}
            value={eventRecord?.split_quantity}
            placeholder="Sell in multiple"
          />
        );
      case 8:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-qty-disp`}
            className={cellBlockClasses["Max display"]}
            value={eventRecord?.quantity_display}
            placeholder="Max display ..."
          />
        );

      case 9:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-category`}
            className={cellBlockClasses["Category"]}
            value={categoryValue?.name}
            placeholder="Category"
          />
        );

      case 10:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-section`}
            className={cellBlockClasses["Section/block"]}
            value={sectionValue?.name}
            placeholder="Section/block"
          />
        );

      case 11:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-row`}
            className={cellBlockClasses["Row"]}
            value={eventRecord?.row}
            placeholder="Row"
          />
        );
      case 12:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-row`}
            className={cellBlockClasses["First seat"]}
            value={eventRecord?.first_seat}
            placeholder="First seat"
          />
        );

      case 13:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-face-value`}
            className={cellBlockClasses["Face value"]}
            data-sybmol-attribute={
              eventRecord?.face_value_currency
                ? SYMBOLS[eventRecord?.face_value_currency]
                : SYMBOLS[eventRecord?.sell_price?.symbol]
            }
            placeholder="Face value"
            value={faceValue}
          />
        );
      case 14:
        return (
          !layout.proceedPriceToggle && (
            <MobileViewListingBlock
              key={`${uniqueKey}-mobile-proceed-price`}
              className={cellBlockClasses["Proceed Price"]}
              data-sybmol-attribute={
                eventRecord?.sell_price_currency
                  ? SYMBOLS[eventRecord?.sell_price_currency]
                  : SYMBOLS[eventRecord?.sell_price?.symbol]
              }
              placeholder="Proceed Price"
              value={proccedValue}
            />
          )
        );

      case 15:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-benefits`}
            className={cellBlockClasses["Benefits"]}
            placeholder="Benefits"
            value={
              benefitsValue?.length
                ? benefitsValue?.length + " selected"
                : "Benefits"
            }
          />
        );
      case 16:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-restrictions`}
            className={cellBlockClasses["Restrictions"]}
            placeholder="Restrictions"
            value={
              restrictionValue?.length
                ? restrictionValue?.length + " selected"
                : "Restrictions"
            }
          />
        );

      case 17:
        return <div className={cellBlockClasses["ticketInHand"]}></div>;
      case 18:
        return (
          <MobileViewListingBlock
            key={`${uniqueKey}-mobile-date-to-ship`}
            className={cellBlockClasses["DateToShip"]}
            placeholder="-"
            value={
              eventRecord?.date_to_ship
                ? moment(eventRecord?.date_to_ship).format("DD/MM/YYYY")
                : "-"
            }
          />
        );
    }
  });
};

export default RenderList;
