import { getVenuePerformerEventConfig } from "helpers/Functions";
import _ from "lodash";
import moment from "moment";
import axios from "../../../axois/SetupAxios";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";
const algoliasearch = require("algoliasearch");

// REPORTS OPTIONS
const getReportsOptions = (): Promise<any> => {
  return axios
    .post(`reports/reports-options`)
    .then((response) => response.data);
};

// REPORTS LISTING
const getReportsListing = (data: any): Promise<any> => {
  let marketplace_id: any = [];
  let category_ids: any = _.map(data?.filters?.category_ids, "id");
  let performer_ids: any = _.map(data?.filters?.performer_ids, "id");
  let buyer_team_id: any = _.map(data?.filters?.buyer_team_id, "id");
  let team_member = data?.filters?.specific
    ? [data?.filters?.specific]
    : _.map(data?.filters?.team_member, "id");
  let event_id = data?.filters?.event_id?.id
    ? [data?.filters?.event_id?.id]
    : _.map(data?.filters?.event_id, "id");
  let venue = data?.filters?.venue?.id;
  let time_to_event = data?.filters?.time_to_event?.value;
  let transaction_date = data?.filters?.transaction_date;
  let order_statuses = data?.filters?.order_statuses?.id
    ? [data?.filters?.order_statuses?.id]
    : _.map(data?.filters?.order_statuses, "id");
  // let event_date_time = data?.filters?.event_date_time
  // let order_date_time = data?.filters?.order_date_time
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let order_by = data?.sorting?.order_by;
  let sort_order = data?.sorting?.sort_order;
  let export_to_csv = data?.export_to_csv;
  let order_start_date;
  let order_end_date;
  let event_start_date;
  let event_end_date;
  let dashboardPage = data?.page;
  let internal_order_statuses;
  let store_id;

  if (data?.filters?.orderDateRange) {
    order_start_date = data?.filters?.orderDateRange?.startDate;
    order_end_date = data?.filters?.orderDateRange?.endDate;
  }
  if (data?.filters?.eventDateRange) {
    event_start_date = data?.filters?.eventDateRange?.startDate;
    event_end_date = data?.filters?.eventDateRange?.endDate;
  }
  if (_.map(data?.filters?.order_exchanges, "id")) {
    marketplace_id = _.map(data?.filters?.order_exchanges, "id");
  }

  if (_.map(data?.filters?.internal_order_statuses, "id")) {
    internal_order_statuses = _.map(
      data?.filters?.internal_order_statuses,
      "id"
    );
  }

  store_id = data?.filters?.store_id;

  const teamValue = data?.team_id
    ? _.map(data?.team_id, "id")
    : _.map(data?.filters?.team_id, "id");

  let payload = {
    ...data.pagination,
    ...(data?.tabValue && { order_status_list: data?.tabValue }),
    ...(data?.debouncedSearch && { keywords: data?.debouncedSearch }),
    ...(data?.filters?.query &&
      data?.export_to_csv && { keywords: data?.filters?.query }),
    ...(event_id && event_id?.length > 0 && { event_id }),
    ...(team_member && team_member.length > 0 && Array.isArray(team_member)
      ? { team_member }
      : { team_member: "all" }),
    // ...(team_member && team_member.length > 0 &&  { team_member }),
    ...(venue && { venues_id: venue }),
    ...(time_to_event && { time_to_event }),
    ...(category_ids && category_ids.length > 0 && { category_ids }),
    ...(performer_ids && performer_ids.length > 0 && { performer_ids }),
    ...(buyer_team_id && buyer_team_id.length > 0 && { buyer_team_id }),
    ...(transaction_date && {
      transaction_date: moment(transaction_date).format("YYYY-MM-DD HH:mm:ss"),
    }),
    ...(order_statuses && order_statuses?.length > 0 && { order_statuses }),
    // ...(event_date_time && { event_date_time: moment(event_date_time).format('YYYY-MM-DD') }),
    // ...(order_date_time && { order_date_time: moment(order_date_time).format('YYYY-MM-DD') }),
    ...(order_start_date && {
      order_start_date: moment(order_start_date).format("YYYY-MM-DD"),
    }),
    ...(order_end_date && {
      order_end_date: moment(order_end_date).format("YYYY-MM-DD"),
    }),
    ...(event_start_date && {
      event_start_date: moment(event_start_date).format("YYYY-MM-DD"),
    }),
    ...(event_end_date && {
      event_end_date: moment(event_end_date).format("YYYY-MM-DD"),
    }),
    ...(page && { page }),
    ...(dashboardPage && { page: dashboardPage }),
    ...(per_page && { per_page }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(marketplace_id &&
      marketplace_id?.length > 0 && { marketplace_id: marketplace_id }),
    ...(export_to_csv && { export_to_csv: 1 }),

    ...(_.size(data?.filters?.payment_status) > 0 && {
      is_paid: _.map(data?.filters?.payment_status, "value"),
    }),
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),

    ...(internal_order_statuses &&
      internal_order_statuses?.length > 0 && {
        internal_order_statuses: internal_order_statuses,
      }),

    ...(store_id && {
      store_id: store_id,
    }),
  };
  return axios
    .post(`reports/listing`, payload)
    .then((response) => response.data);
};

// REPORTS LISTING
const getReportsheaderOptions = (data: any): Promise<any> => {
  let marketplace_id: any = [];
  let category_ids: any = _.map(data?.filters?.category_ids, "id");
  let performer_ids: any = _.map(data?.filters?.performer_ids, "id");
  let buyer_team_id: any = _.map(data?.filters?.buyer_team_id, "id");
  let team_member = data?.filters?.specific
    ? [data?.filters?.specific]
    : _.map(data?.filters?.team_member, "id");
  let event_id = data?.filters?.event_id?.id
    ? [data?.filters?.event_id?.id]
    : _.map(data?.filters?.event_id, "id");
  let venue = data?.filters?.venue?.id;
  let time_to_event = data?.filters?.time_to_event?.value;
  let transaction_date = data?.filters?.transaction_date;
  let order_statuses = data?.filters?.order_statuses?.id
    ? [data?.filters?.order_statuses?.id]
    : _.map(data?.filters?.order_statuses, "id");
  // let event_date_time = data?.filters?.event_date_time
  // let order_date_time = data?.filters?.order_date_time
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let order_by = data?.sorting?.order_by;
  let sort_order = data?.sorting?.sort_order;
  let export_to_csv = data?.export_to_csv;
  let order_start_date;
  let order_end_date;
  let event_start_date;
  let event_end_date;
  let dashboardPage = data?.page;
  let internal_order_statuses;
  let store_id;

  if (data?.filters?.orderDateRange) {
    order_start_date = data?.filters?.orderDateRange?.startDate;
    order_end_date = data?.filters?.orderDateRange?.endDate;
  }
  if (data?.filters?.eventDateRange) {
    event_start_date = data?.filters?.eventDateRange?.startDate;
    event_end_date = data?.filters?.eventDateRange?.endDate;
  }
  if (_.map(data?.filters?.order_exchanges, "id")) {
    marketplace_id = _.map(data?.filters?.order_exchanges, "id");
  }

  if (_.map(data?.filters?.internal_order_statuses, "id")) {
    internal_order_statuses = _.map(
      data?.filters?.internal_order_statuses,
      "id"
    );
  }

  store_id = data?.filters?.store_id;

  const teamValue = data?.team_id
    ? _.map(data?.team_id, "id")
    : _.map(data?.filters?.team_id, "id");

  let payload = {
    ...data.pagination,
    ...(data?.tabValue && { order_status_list: data?.tabValue }),
    ...(data?.debouncedSearch && { keywords: data?.debouncedSearch }),
    ...(data?.filters?.query &&
      data?.export_to_csv && { keywords: data?.filters?.query }),
    ...(event_id && event_id?.length > 0 && { event_id }),
    ...(team_member && team_member.length > 0 && Array.isArray(team_member)
      ? { team_member }
      : { team_member: "all" }),
    ...(venue && { venues_id: venue }),
    ...(time_to_event && { time_to_event }),
    ...(transaction_date && {
      transaction_date: moment(transaction_date).format("YYYY-MM-DD HH:mm:ss"),
    }),
    ...(order_statuses && order_statuses?.length > 0 && { order_statuses }),
    // ...(event_date_time && { event_date_time: moment(event_date_time).format('YYYY-MM-DD') }),
    // ...(order_date_time && { order_date_time: moment(order_date_time).format('YYYY-MM-DD') }),
    ...(order_start_date && {
      order_start_date: moment(order_start_date).format("YYYY-MM-DD"),
    }),
    ...(order_end_date && {
      order_end_date: moment(order_end_date).format("YYYY-MM-DD"),
    }),
    ...(event_start_date && {
      event_start_date: moment(event_start_date).format("YYYY-MM-DD"),
    }),
    ...(event_end_date && {
      event_end_date: moment(event_end_date).format("YYYY-MM-DD"),
    }),
    ...(page && { page }),
    ...(dashboardPage && { page: dashboardPage }),
    ...(per_page && { per_page }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(marketplace_id &&
      marketplace_id?.length > 0 && { marketplace_id: marketplace_id }),
    ...(export_to_csv && { export_to_csv: 1 }),

    ...(_.size(data?.filters?.payment_status) > 0 && {
      is_paid: _.map(data?.filters?.payment_status, "value"),
    }),
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),

    ...(internal_order_statuses &&
      internal_order_statuses?.length > 0 && {
        internal_order_statuses: internal_order_statuses,
      }),

    ...(store_id && {
      store_id: store_id,
    }),

    ...(category_ids && category_ids.length > 0 && { category_ids }),
    ...(performer_ids && performer_ids.length > 0 && { performer_ids }),
    ...(buyer_team_id && buyer_team_id.length > 0 && { buyer_team_id }),
  };
  return axios
    .post(`reports/counts`, payload)
    .then((response) => response.data);
};

//report/details
const reportDetailsView = (data: any): Promise<any> => {
  let payload = {
    ...(data?.order_id && { order_id: data?.order_id }),
  };
  return axios
    .post(`reports/details`, payload)
    .then((response) => response.data);
};

// ALGOLIA
const algoliaSearch = (data: any): Promise<any> => {
  const venuePerformerConfig: any = getVenuePerformerEventConfig(
    data?.coreLayout
  );
  let module: any = data?.salesFilter?.module;
  let tab: any = data?.salesFilter?.tab;
  let query = data?.inputChange;
  let payload = {
    ...(data?.salesFilter?.algoliaPage && {
      page: data?.salesFilter?.algoliaPage,
    }),
  };
  let searchIndex = data?.searchIndex ? data?.searchIndex : "events";
  let str = "";
  let exchangeCondition = "";
  let isSourceTixstock: any = "";
  let coreLayout = data?.coreLayout?.allowedAccess;
  let checkExchange =
    coreLayout?.is_connected_to_preferred_partners === 0
      ? coreLayout?.connected_exchanges &&
        Array.isArray(coreLayout?.connected_exchanges) &&
        _.size(coreLayout?.connected_exchanges) > 0
        ? coreLayout?.connected_exchanges
        : ""
      : "";
  let algoliaConfiguration = data?.algoliaConfiguration;

  let isVenueConfig = "";
  let isPerformerNamesConfig = "";

  // VENUE CONFIG SETUP
  if (venuePerformerConfig?.venue_id) {
    isVenueConfig = `AND venue_id:${venuePerformerConfig?.venue_id} `;
  }

  // Performers CONFIG SETUP
  if (venuePerformerConfig?.performers) {
    isPerformerNamesConfig = `AND performer_names:"${venuePerformerConfig?.performers}"`;
  }

  // IF LOGGEDIN USERD IS NOT CONNECTED WITH TIXSTOCK THEN PASS CONNECTED MARKETPLACE IDS
  checkExchange &&
    checkExchange.map((ex: number) => (str += "exchanges=" + ex + " OR "));
  if (checkExchange) {
    exchangeCondition = `${str.slice(0, -3)}`;
  }

  // IF LOGGED USER IS CONNECTED WITH TIXSTOCK THEN PASS BELOW FILTER
  if (!checkExchange) {
    isSourceTixstock = ` is_source_tixstock=1 `;
  }

  // Get today's date and format it as YYYY-MM-DD
  const today = new Date().toISOString().slice(0, 10);
  // Create Algolia filters to retrieve data only for today and future dates
  let dateFilters = `AND event_date_local >= ${Date.parse(today) / 1000}`;

  if (
    (module === "sales" &&
      ["fulfilled", "completed", "cancelled"].includes(tab)) ||
    module === "report"
  ) {
    dateFilters = "";
  }

  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );

  // SEARCH WITH EVENTS
  const index = client.initIndex(searchIndex);

  const filters =
    searchIndex === "performers"
      ? `events_exists=1`
      : `${exchangeCondition} ${isSourceTixstock} ${dateFilters} ${isVenueConfig} ${isPerformerNamesConfig}`;
  // EXECUTE THE API WITH PAYLOAD
  return index
    .search(query, {
      ...payload,
      filters: filters,
      ...(algoliaConfiguration && algoliaConfiguration),
    })
    .then((response: any) => {
      return response;
    });
};

export {
  algoliaSearch,
  getReportsheaderOptions,
  getReportsListing,
  getReportsOptions,
  reportDetailsView,
};
