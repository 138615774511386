/* eslint-disable react-hooks/exhaustive-deps */
import { getAuthDataFromStorage } from "helpers/AssetHelpers";
import { isArray } from "lodash";
import Pusher from "pusher-js";
import { useCallback, useContext, useEffect } from "react";
import {
  AUTHDATA,
  CHANNEL_INVENTORY_LISTING_CREATE_EVENT_ID,
  CHANNEL_INVENTORY_LISTING_UPDATE_EVENT_ID,
  CHANNEL_LISTING_PUBLISHED_MARKETPLACE_EVENT_ID,
} from "../../../helpers/const";
import { AddInventoryContext } from "../core/AddInventoryProvider";

const PusherSetup = ({ setList }: any) => {
  const layout = useContext(AddInventoryContext);
  let broadcastId = AUTHDATA?.broadcast_id;

  const getEventRecordStatus = (marketplaces: any) => {
    let exchangeStatus: any = Array.from(
      new Set(marketplaces.map((market: any) => market.status))
    );
    let status: string = "";
    if (exchangeStatus.length === 0) {
      status = "pending";
    } else if (exchangeStatus.length === 1) {
      status = exchangeStatus[0];
    } else if (exchangeStatus.length > 1) {
      if (exchangeStatus.includes("published")) {
        status = "partPublished";
      } else {
        status = "failed";
      }
    }
    return status;
  };

  const handleListingPublished = useCallback((data: any) => {
    setList((events: any) => {
      return events.map((event: any) => {
        if (event.id === data?.event_id) {
          let tempEventRecords = event["eventRecords"].map(
            (eventRecord: any) => {
              let tempMarkeplaces =
                eventRecord?.marketplaces &&
                Object.keys(eventRecord?.marketplaces).length > 0
                  ? eventRecord?.marketplaces.map((marketplace: any) => {
                      if (marketplace?.exchange_id === data?.exchange_id) {
                        return {
                          ...marketplace,
                          status:
                            data?.success === true ? "published" : "failed",
                          error: data?.errors,
                        };
                      } else {
                        return marketplace;
                      }
                    })
                  : [];

              if (
                eventRecord?.listingId === data?.list_id ||
                eventRecord?.card == data?.card
              ) {
                return {
                  ...eventRecord,
                  marketplaces: tempMarkeplaces,
                  status: getEventRecordStatus(tempMarkeplaces),
                };
              } else {
                return eventRecord;
              }
            }
          );

          return {
            ...event,
            eventRecords: tempEventRecords,
            loader: setEventLoader(tempEventRecords),
          };
        } else {
          return event;
        }
      });
    });

    setList((currentEvent: any) => {
      return currentEvent;
    });
  }, []);

  const setEventLoader = (eventRecords: any) => {
    return eventRecords.filter(
      (eventRecord: any) =>
        eventRecord?.status === "pending" || eventRecord?.status === undefined
    ).length > 0
      ? true
      : false;
  };

  const handleCreatingListing = useCallback(async (data: any) => {
    setList((events: any) => {
      return events.map((event: any) => {
        let tempEvevntRecords = event["eventRecords"].map(
          (eventRecord: any) => {
            if (data?.card === eventRecord.card) {
              isArray(data?.errors) &&
                data?.errors.length === 0 &&
                layout.setIsPublish(true);
              return {
                ...eventRecord,
                listingId: data?.list_id,
              };
            } else if (
              data?.event_reference_id === eventRecord?.card?.toString()
            ) {
              isArray(data?.errors) &&
                data?.errors.length === 0 &&
                layout.setIsPublish(true);
              return {
                ...eventRecord,
                errors: { commonErrors: data?.errors },
                status: "failed",
              };
            } else if (
              data?.event_reference_id === eventRecord?.card?.toString() &&
              data?.errors.length === 0 &&
              eventRecord?.marketplaces?.length === 0
            ) {
              return {
                ...eventRecord,
                errors: [],
                status: "published",
              };
            } else {
              return eventRecord;
            }
          }
        );
        return {
          ...event,
          eventRecords: tempEvevntRecords,
          loader: setEventLoader(tempEvevntRecords),
        };
      });
    });
  }, []);

  const handleUpdatingListing = async (data: any) => {
    // setList((events: any) => {
    //     return events.map((event: any) => {
    //         let tempEvevntRecords = event["eventRecords"].map((eventRecord: any) => {
    //             if (data?.list_id === eventRecord?.listingId && data?.errors && data?.errors.length > 0) {
    //                 return {
    //                     ...eventRecord,
    //                     errors: { commonErrors: data?.errors },
    //                     status: 'failed'
    //                 }
    //             } else if (data?.list_id === eventRecord?.listingId && data?.errors.length === 0) {
    //                 return {
    //                     ...eventRecord,
    //                     errors: [],
    //                     status: 'published'
    //                 }
    //             } else {
    //                 return eventRecord
    //             }
    //         })
    //         return {
    //             ...event,
    //             eventRecords: tempEvevntRecords,
    //             loader: setEventLoader(tempEvevntRecords)
    //         };
    //     });
    // })
  };

  useEffect(() => {
    let getAuthData: any = getAuthDataFromStorage();
    //  Cookies.get("authData") as string;
    let allAutData = getAuthData ? JSON.parse(getAuthData) : {};
    broadcastId = allAutData?.broadcast_id;

    if (broadcastId) {
      const pusher = new Pusher(
        process.env.REACT_APP_PUSHER_APP_KEY as string,
        {
          cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER as string,
        }
      );

      pusher.connection.bind("error", function (err: any) {
        if (err?.error?.data?.code === 4004) {
          console.count("Over limit!");
        }
      });

      const channel = pusher.subscribe(broadcastId);

      // channel.bind(channel_on_hold_event_id, function (data: any) {
      // });

      // channel.bind(channel_released_event_id, function (data: any) {
      // });

      channel.bind(
        CHANNEL_INVENTORY_LISTING_CREATE_EVENT_ID,
        function (data: any) {
          handleCreatingListing(data?.data);
        }
      );

      channel.bind(
        CHANNEL_INVENTORY_LISTING_UPDATE_EVENT_ID,
        function (data: any) {
          console.count("update-pushers");
          handleUpdatingListing(data?.data);
        }
      );

      channel.bind(
        CHANNEL_LISTING_PUBLISHED_MARKETPLACE_EVENT_ID,
        function (data: any) {
          setTimeout(() => {
            console.count("publish-pushers");
            handleListingPublished(data?.data);
          }, 2000);
        }
      );

      channel.bind_global(function (event: any, data: any) {
        console.log(event, data);
      });

      return () => {
        pusher.disconnect();
        pusher.unsubscribe(broadcastId);
        channel.unbind();
      };
    }
  }, []);

  return <></>;
};

export default PusherSetup;
