import { useContext } from "react";
import downAngleSVG from "../../../../../assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { WalletAndBankDetailsContext } from "../../core/WalletAndBankDetailsProvider";

const HeaderCell = ({ title, width, arrow, postKey, padding }: any) => {
  const layout = useContext(WalletAndBankDetailsContext);
  const sellerWalletsFilter = layout.sellerWalletsFilter;
  const bankAccountFilter = layout.bankAccountFilter;
  const withdrawRequestFilter = layout.withdrawRequestFilter;

  const tab = layout.isTabActive;
  const order =
    tab === "Seller wallets"
      ? sellerWalletsFilter
      : tab === "Bank accounts"
      ? bankAccountFilter
      : tab === "Withdraw requests"
      ? withdrawRequestFilter
      : "";

  const handleSort = (sortedKey: any) => {
    const setFilter = (filterSetter: Function) => {
      filterSetter((preValue: any) => {
        const isNewKey = preValue?.order_by !== sortedKey;
        const nextSortOrder = isNewKey
          ? "desc"
          : preValue?.sort_order === "desc"
          ? "asc"
          : preValue?.sort_order === "asc"
          ? undefined
          : "desc";
        const updatedData = {
          ...preValue,
          order_by: sortedKey,
          sort_order: nextSortOrder,
          page: 1,
          per_page: 20,
        };
        delete updatedData?.id;

        layout.setCommonFilter((prev: any) => {
          const sortPayload = {
            order_by: sortedKey,
            sort_order: nextSortOrder,
            page: 1,
            per_page: 20,
          };

          let updatedFilter = {};

          if (tab === "Seller wallets") {
            updatedFilter = {
              sellerWalletsFilterCopy: {
                ...prev.sellerWalletsFilterCopy,
                ...sortPayload,
              },
            };
          } else if (tab === "Bank accounts") {
            updatedFilter = {
              bankAccountFilterCopy: {
                ...prev.bankAccountFilterCopy,
                ...sortPayload,
              },
            };
          } else {
            updatedFilter = {
              withdrawRequestFilterCopy: {
                ...prev.withdrawRequestFilterCopy,
                ...sortPayload,
              },
            };
          }

          return {
            ...prev,
            ...updatedFilter,
          };
        });

        return updatedData;
        // let final = {
        //   ...currentVal,
        //   order_by: sortedKey,
        //   sort_order:
        //     currentVal &&
        //     Object.keys(currentVal).length > 0 &&
        //     currentVal.order_by !== sortedKey
        //       ? "desc"
        //       : currentVal.sort_order === "desc"
        //       ? "asc"
        //       : currentVal.sort_order === "asc"
        //       ? undefined
        //       : "desc",
        //   page: 1,
        //   per_page: 20,
        // };
        // delete final?.id;
        // return final;
      });
    };

    switch (tab) {
      case "Seller wallets":
        setFilter(layout.setSellerWalletsFilter);
        break;
      case "Bank accounts":
        setFilter(layout.setBankAccountFilter);
        break;
      case "Withdraw requests":
        setFilter(layout.setWithdrawRequestFilter);
        break;
      default:
        break;
    }
  };

  return (
    <th
      className={`px-1.5 py-3 font-medium whitespace-nowrap ${width} ${padding}`}
    >
      {arrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button type="button" onClick={() => handleSort(postKey)}>
            <IKTSVG
              path={downAngleSVG}
              // className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4
              //     `}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                order?.order_by === postKey && order?.sort_order === "desc"
                  ? "rotate-180 !fill-violet-500"
                  : order?.order_by === postKey && order?.sort_order === "asc"
                  ? " !fill-violet-500"
                  : "fill-gray-400"
              }
                  `}
            />
          </button>
        </div>
      ) : (
        title
      )}
    </th>
  );
};

export default HeaderCell;
