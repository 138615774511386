import LockIcon from "assets/media/icons/other_icons/LockIcon";
import UnLockIcon from "assets/media/icons/other_icons/UnLockIcon";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import PriceFieldWithComma from "components/formComponent/PriceFieldWithComma";
import TextInput from "components/formComponent/TextInput";
import NoJSXComponent from "components/noJSX/NoJSXComponent";
import { SYMBOLS } from "helpers/const";
import {
  decimalNumber,
  getCurrencySymbol,
  penaltyBreakdownInOrder,
} from "helpers/Functions";
import Messages from "helpers/Messages";
import { size } from "lodash";
import moment from "moment";
import { DetailInfo } from "pages/Sales/widget/order_details_components/OrderPenalty";

const OrderPenalty = ({
  orderDetails,
  isPenaltyLock,
  setIsPenaltyLock,
  penaltyDate,
  formik,
  DisabledRadioBtn,
}: any) => {
  const { isGodAdmin, penaltyBreakdownOptions, fixedOrderOfPenaltyOptions } =
    NoJSXComponent();

  if (orderDetails?.fine_amount > 0 || isGodAdmin) {
    const lockIconClasses: string = `group-hover:fill-indigo-500 h-[.9375rem] ${
      isPenaltyLock
        ? "w-3 fill-violet-500"
        : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
    }`;

    // HANDLE PENALTY DROPDOWN
    const handlePenaltyOptions = (event: any, options: any) => {
      const sortedValue = event?.target?.value
        ?.map((item: any) => ({
          ...item,
          ...(item?.value ? {} : { value: "0.00" }),
        }))
        .sort(
          (a: any, b: any) =>
            fixedOrderOfPenaltyOptions.indexOf(a.id) -
            fixedOrderOfPenaltyOptions.indexOf(b.id)
        );

      formik.handleChange({
        target: {
          ...event?.target,
          value: sortedValue,
        },
      });
    };

    // HANDLE PENALTY BREAKDOWN
    const handlePenaltyBreakdown = (e: any, index: number) => {
      const { value } = e?.target;
      formik.setFieldValue(`penaltyType[${index}]`, {
        ...formik.values?.penaltyType[index],
        value: value?.value,
      });
    };

    return (
      <div
        className={`flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0 ${
          isGodAdmin && isPenaltyLock === false && "shadow-lt1"
        }`}
      >
        <div
          className={`title_block w-full ${
            isGodAdmin
              ? "border-b flex justify-between min-h-[2.5rem]"
              : " pb-0"
          } `}
        >
          <h5
            className={`text-sm13 flex items-center flex-1 font-semibold md:px-3.5 px-2.5 py-[0.6563rem] ${
              !isGodAdmin && "pt-3.5 pb-0 leading-4"
            }`}
          >
            Order penalty
          </h5>
          {isGodAdmin && !(orderDetails?.order_paid === 1) && (
            <button
              type="button"
              className="w-10 flex items-center justify-center group border-l"
              onClick={() => setIsPenaltyLock(!isPenaltyLock)}
            >
              {isPenaltyLock ? (
                <LockIcon className={lockIconClasses} />
              ) : (
                <UnLockIcon className={lockIconClasses} />
              )}
            </button>
          )}
        </div>

        <div className="py-3.5 px-3.5 justify-start w-full">
          <div className="note-block false">
            <div className="mt-5 first-of-type:mt-0 ">
              {penaltyDate ? (
                <span className="text-sm12 font-medium mb-1 block text-gray-400 ">
                  Penalty{" "}
                  {penaltyDate &&
                    `- ${moment(penaltyDate).format("DD/MM/YYYY")}`}{" "}
                </span>
              ) : null}

              {/* LOCK - STATE */}
              {isPenaltyLock ? (
                <>
                  <div className="px-2 py-1 bg-gray-100 flex justify-between rounded whitespace-pre-line ">
                    <p className="text-sm12 break-words overflow-hidden">
                      {orderDetails?.fine_amount
                        ? `${
                            orderDetails?.fine_currency
                              ? SYMBOLS[orderDetails?.fine_currency]
                              : ""
                          }${decimalNumber(orderDetails?.fine_amount)}`
                        : "None"}
                    </p>
                  </div>

                  {/* PENALTY BREAKDOWNS */}
                  {size(
                    penaltyBreakdownInOrder(
                      fixedOrderOfPenaltyOptions,
                      formik.values.penaltyType
                    )
                  ) > 0 ? (
                    <div className="inner-part py-3.5 flex flex-wrap gap-x-3.5 gap-y-[0.9375rem]">
                      {penaltyBreakdownInOrder(
                        fixedOrderOfPenaltyOptions,
                        formik.values.penaltyType
                      )?.map((option: any, index: number) => {
                        const item = formik.values.penaltyType?.find(
                          (p: any) => p.id === option
                        );
                        return (
                          <DetailInfo
                            key={`readable-penalty-${index}`}
                            header={item?.name}
                            value={`${getCurrencySymbol(
                              orderDetails?.fine_currency
                            )}${item?.value}`}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </>
              ) : (
                <div className="flex flex-col gap-y-5">
                  <div className="flex-1 relative">
                    <PriceFieldWithComma
                      currenncySymbol={orderDetails?.fine_currency}
                      name="fine_amount"
                      type="text"
                      value={formik?.values?.fine_amount}
                      required={false}
                      errorClass={
                        formik.touched.fine_amount &&
                        formik.errors.fine_amount &&
                        "border-rose-500"
                      }
                      labelErrorClass={
                        formik.touched.fine_amount &&
                        formik.errors.fine_amount &&
                        "text-rose-500 "
                      }
                      label="Penalty amount"
                      id="amount"
                      handleOnChange={(data: any) =>
                        formik.setFieldValue(
                          data?.target?.name,
                          parseFloat(data?.target?.value?.value).toFixed(2)
                        )
                      }
                      handleClearValue={() =>
                        formik.setFieldValue("fine_amount", "")
                      }
                      errorEventIndex={"fine_amount"}
                      errorMessage={formik.errors.fine_amount}
                    />
                    {formik.touched.fine_amount &&
                      formik.errors.fine_amount && (
                        <div className="text-xs text-rose-500 error-msg z-[1]">
                          {formik.errors.fine_amount as string}
                        </div>
                      )}
                  </div>

                  {/* BREAKDOWN */}
                  <div className="text-sm12 border-violet-900 relative w-full md:w-1/2 md:pr-[.4375rem]">
                    <MultiDropDownField
                      options={penaltyBreakdownOptions}
                      className={`!h-10`}
                      name="penaltyType"
                      placeholder={`Penalty type`}
                      classNamePrefix={"form_multiDropDown"}
                      value={formik.values?.penaltyType}
                      isClearable={true}
                      handleOnChange={(e: any) =>
                        handlePenaltyOptions(e, penaltyBreakdownOptions)
                      }
                      isCapital={true}
                      isValueCapital={true}
                    />
                  </div>

                  {/* PENALTIES */}
                  {formik.values?.penaltyType &&
                  size(formik.values?.penaltyType) > 0 ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-y-5 gap-x-3.5 w-full ">
                      {penaltyBreakdownInOrder(
                        fixedOrderOfPenaltyOptions,
                        formik.values.penaltyType
                      )?.map((option: any, index: number) => {
                        const item = formik.values.penaltyType?.find(
                          (p: any) => p.id === option
                        );

                        return (
                          <div
                            className="relative"
                            key={`penalty-fee-${index}`}
                          >
                            <PriceFieldWithComma
                              currenncySymbol={orderDetails?.fine_currency}
                              name={option}
                              type="text"
                              value={item?.value}
                              required={false}
                              errorClass={
                                (formik.touched?.penaltyType?.[index] as any)
                                  ?.value &&
                                (formik.errors.penaltyType?.[index] as any)
                                  ?.value &&
                                "border-rose-500"
                              }
                              labelErrorClass={
                                (formik.touched.penaltyType?.[index] as any)
                                  ?.value &&
                                (formik.errors.penaltyType?.[index] as any)
                                  ?.value &&
                                "text-rose-500 "
                              }
                              label={item?.name}
                              id={`sales-order-detail-${option}`}
                              handleOnChange={(data: any) =>
                                handlePenaltyBreakdown(data, index)
                              }
                              handleClearValue={() =>
                                formik.setFieldValue(
                                  `penaltyType[${index}].value`,
                                  ""
                                )
                              }
                              errorEventIndex={option}
                              errorMessage={
                                (formik.errors.penaltyType?.[index] as any)
                                  ?.value
                              }
                            />

                            {(formik.touched.penaltyType?.[index] as any)
                              ?.value &&
                            (formik.errors.penaltyType?.[index] as any)
                              ?.value ? (
                              <div className="text-xs text-rose-500 error-msg z-[1]">
                                {
                                  (formik.errors.penaltyType?.[index] as any)
                                    ?.value
                                }
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  ) : null}

                  {orderDetails?.order_status === "Cancelled" && (
                    <div className="flex gap-5 items-center">
                      <p className="text-sm13 font-medium">
                        Wallet balance:{" "}
                        <span>
                          {getCurrencySymbol(orderDetails?.fine_currency)}
                          {/* {orderDetails?.seller_wallet_balances} */}
                          {decimalNumber(orderDetails?.seller_wallet_balances)}
                        </span>
                      </p>
                      <div className="flex gap-3 items-center">
                        <p className="text-sm13 font-medium">
                          Do you want to deduct from the wallet?
                        </p>
                        <div>
                          <label
                            className={`relative inline-flex items-center cursor-pointer ${
                              DisabledRadioBtn && `disabled`
                            } [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none ${
                              DisabledRadioBtn && "pointer-events-none"
                            }`}
                          >
                            <input
                              type="checkbox"
                              name="wallet_deduction"
                              value=""
                              className="sr-only peer"
                              checked={formik.values.wallet_deduction}
                              disabled={DisabledRadioBtn}
                              onChange={(data: any) => {
                                formik.setFieldValue(
                                  data?.target?.name,
                                  data?.target?.checked
                                );
                              }}
                            />
                            <div
                              className={`${
                                // DisabledRadioBtn
                                //   ? "pointer-events-none bg-gray-100 peer-checked:after:bg-gray-400 after:bg-gray-400"
                                "bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                              } w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8`}
                            ></div>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex-1 relative">
                    <TextInput
                      name={`note_penalty`}
                      type="text"
                      value={formik?.values?.note_penalty}
                      required={false}
                      placeholder={Messages?.penaltyNotePlaceholder}
                      className="!h-[1.875rem] bg-gray-100/50"
                      handleClearValue={() =>
                        formik.setFieldValue("note_penalty", "")
                      }
                      handleOnChange={formik.handleChange}
                      errorClass={
                        formik.touched.note_penalty &&
                        formik.errors.note_penalty &&
                        "!border-rose-500"
                      }
                    />
                    {formik.touched.note_penalty &&
                      formik.errors.note_penalty && (
                        <div className="text-xs text-rose-500 error-msg ">
                          {formik.errors.note_penalty as string}
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default OrderPenalty;
