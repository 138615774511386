import { LayoutContext } from "components/core/LayoutProvider";
import ReferralEarningsCard from "components/ui/ReferralEarningsCard";
import {
  decimalNumber,
  getCurrencySymbol,
  getValueByKey,
} from "helpers/Functions";
import { lowerCase } from "lodash";
import { useContext, useMemo } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import ReferralSVG from "../../../../../assets/media/icons/referrals_nocolor.svg";

const ReferralBoxWrapper = () => {
  const { allowedAccess, setUserInfo } = useContext(LayoutContext);
  const { is_god_admin: isGodAdmin = false, base_currency = "" } =
    allowedAccess?.account_info || false;
  const {
    referral_code: referralCode = "",
    seller_level = "",
    referral_earning = "",
  } = allowedAccess?.team_data || {};
  const { tx_trade_user } = allowedAccess;

  // GET MARGIN INFO
  const referralData = useMemo(() => {
    const referralMargin = getValueByKey(
      seller_level?.config,
      "referral_margin"
    );
    const referralDurationOption = getValueByKey(
      seller_level?.config,
      "referral_expiry_duration_options"
    );
    const referralExpiryDuration = parseFloat(
      getValueByKey(seller_level?.config, "referral_expiry_duration")
    );

    return {
      referralMargin,
      referralDurationOption:
        referralExpiryDuration <= 1
          ? referralDurationOption.replace(/s$/, "")
          : referralDurationOption,
      referralExpiryDuration,
    };
  }, [seller_level?.config]);

  if (!isGodAdmin && tx_trade_user === 0)
    return (
      <div className="border-t border-t-gray-200 p-5 pt-[1.0625rem] w-full">
        <div className="flex items-center justify-between w-full mb-[0.9375rem]">
          <div className="flex items-center">
            <SVG
              src={ReferralSVG}
              className={`w-[1.5rem] h-[1.5rem] stroke-violet-800 mr-[0.375rem]`}
            />
            <h4 className="text-sm15 font-medium leading-[1.1875rem] text-violet-800">
              Referrals
            </h4>
          </div>
          <Link
            to="/settings"
            className="h-6 text-sm13 leading-4 px-2.5 bg-violet-500 hover:bg-indigo-500 rounded text-white py-1"
            state={{ data: "My account" }}
            onClick={() => setUserInfo(false)}
          >
            My referrals
          </Link>
        </div>
        <p className="w-full text-sm12 leading-[1.0625rem] font-normal text-gray-500 pb-5">
          Know someone who sells event tickets? Share your Tixstock referral
          link and we'll give you {referralData?.referralMargin}% of their sales
          commission for {referralData?.referralExpiryDuration}{" "}
          {lowerCase(referralData?.referralDurationOption)}.{" "}
          <Link
            target="_blank"
            to={
              "https://help.tixstock.com/en/articles/10342523-referral-scheme-earn-rewards-for-referrals"
            }
            className="font-medium text-violet-800 hover:text-indigo-500"
          >
            Learn more
          </Link>
        </p>

        {/* REFERRAL LINK - COMPONENT */}
        <ReferralEarningsCard
          earnings={`${getCurrencySymbol(base_currency)}${decimalNumber(
            referral_earning ?? "0"
          )}`}
          referralLink={referralCode}
        />

        {/* VIEW POPUP -  */}
        {/* <button
        type="button"
        className="flex items-center h-6 ps-[0.5062rem] pe-2.5 pt-[0.2188rem] pb-[0.2813rem] rounded text-sm13 leading-4 font-medium bg-gray-100 text-violet-800 group hover:bg-indigo-500 hover:text-white"
        onClick={() => setReferralPopup(true)}
      >
        <SVG
          src={messageSvg}
          className={`w-[0.8594rem] h-[0.6875rem] fill-violet-500 group-hover:fill-white mr-[0.5062rem]`}
        />
        Email an invite
      </button> */}
      </div>
    );
  else return null;
};

export default ReferralBoxWrapper;
