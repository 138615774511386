import { IKTSVG } from "components/ui/IKTSVG";
import { size } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { Tooltip } from "react-tooltip";
import arrowDown from "../../../assets/media/icons/standard_icons/chevron-down.svg";
import { LayoutContext } from "../../core/LayoutProvider";

const AsideItemList = ({ children, props, handleOnClick }: any) => {
  const layout = useContext(LayoutContext);
  const {
    state,
    title,
    svgName,
    path,
    width,
    height,
    pathname,
    showSubmenu,
    subMenuActive,
    separator = false,
    setsubMenuActive,
    setOpen,
    sellerInfoOpen,
  } = props;

  const isSamePath = (path: any, pathname: any) => {
    if (typeof path === "string") {
      return path === pathname;
    } else if (Array.isArray(path)) {
      return path.includes(pathname);
    } else {
      return false;
    }
  };

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref?.current && !ref.current.contains(event.target)) {
      setsubMenuActive && setsubMenuActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [subMenu, setsubMenu] = useState(false);

  function generateId(title: string) {
    return title.toLowerCase().replace(/\s+/g, "-");
  }
  const AsideItem = () => {
    return (
      <div
        className={`w-full p-2 flex items-center gap-x-3 md:hover:bg-violet-400/50 rounded cursor-pointer ${
          !sellerInfoOpen &&
          !layout?.notificationId &&
          (isSamePath(path, pathname) ||
            showSubmenu?.some((item: any) =>
              isSamePath(item?.path, pathname)
            )) &&
          "bg-violet-400 hover:!bg-violet-400"
        }`}
        onClick={() => {
          // if (state === false) {
          //   setOpen(true);
          //   setsubMenu(true);
          // } else {
          //   setsubMenu(!subMenu);
          // }
          setsubMenu(!subMenu);
        }}
      >
        {!state && (
          <Tooltip
            anchorId={generateId(title)}
            content={title}
            place="right"
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
            positionStrategy="fixed"
          />
        )}
        <div className="flex justify-center min-w-[1.5rem]">
          <SVG
            src={svgName}
            onClick={() => layout.setNotificationId(undefined)}
            className={`${width} ${height}`}
          />
        </div>
        <span
          className={`${
            !state && "hidden"
          } origin-left duration-400 text-white font-medium leading-4 text-truncate`}
        >
          {title}
        </span>
        {!state && size(showSubmenu) === 0 && (
          <Tooltip
            anchorId={title}
            content={title}
            place="right"
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
            positionStrategy="fixed"
          />
        )}
        {state && showSubmenu && (
          <span
            className={`123 relative top-0 -right-1 flex items-center justify-center ml-auto flex-none w-6 h-6 pointer-events-none ${
              showSubmenu && "transition-all duration-500"
            } ${
              !state && "hidden"
            }  origin-left duration-400 text-white font-medium leading-4 text-truncate`}
          >
            <IKTSVG
              className={`stroke-white`}
              path={arrowDown}
              svgClassName={`w-[.7813rem] h-[.6563rem] ${
                subMenu && "-scale-y-100"
              }`}
            />
          </span>
        )}
      </div>
    );
  };
  return (
    <React.Fragment key={title}>
      <li
        className={`group relative parent rounded mb-[0.0625rem] min-h-[2.5rem] ${
          showSubmenu ? "cursor-default" : "cursor-pointer flex "
        }  hover:bg-light-white text-gray-300 transition text-sm13 items-center gap-x-3 positon-relative`}
        title={""}
        id={`${path}-${title}`}
        onClick={() => {
          handleOnClick();
        }}
        onBlur={() => {
          setsubMenuActive(false);
        }}
        ref={ref}
      >
        {(!showSubmenu && React.isValidElement(children)) || !state ? (
          React.cloneElement(children, {}, <AsideItem />)
        ) : (
          <AsideItem />
        )}

        {/* {state && showSubmenu && (
           <span
             className={`absolute top-2 right-1 flex items-center justify-center ml-auto flex-none w-6 h-6 pointer-events-none ${
               showSubmenu && "transition-all duration-500"
             }`}
           >
             <IKTSVG
               className={`stroke-white`}
               path={arrowDown}
               svgClassName={`w-[.7813rem] h-[.6563rem] ${
                 subMenu && "-scale-y-100"
               }`}
             />
           </span>
         )} */}
        {state && showSubmenu && size(showSubmenu) > 0 && (
          <ul className={`submenu ${subMenu ? "block" : "hidden"}`}>
            {/* <ul
            className={`h-auto transition-all duration-300 ease-in-out overflow-hidden ${
              subMenu
                ? "max-h-64 opacity-100 visible"
                : "max-h-0 opacity-0 invisible"
            }`}
          > */}
            {showSubmenu?.map((item: any, index: any) => (
              <li
                key={index}
                className={`group/submenu text-sm13 rounded relative cursor-pointer mt-px text-white font-medium leading-[1.0625rem] 
              before:absolute before:top-1/2 before:left-3.5 before:bg-violet-500 ${
                pathname === item?.path &&
                !layout?.notificationId &&
                "before:bg-violet-400 "
              } before:-translate-y-1/2 before:border-[0.0938rem] before:rounded-sm before:border-white before:w-2.5 before:h-2.5 before:z-20 before:pointer-events-none
              after:absolute after:top-0 after:left-[1.1875rem] after:bg-white after:w-px after:z-10 after:h-[calc(100%_+_.0625rem)] last:after:h-1/2 after:pointer-events-none`}
              >
                <a
                  href={item?.path}
                  className={`relative flex justify-between items-center p-[.4375rem] pl-[2.75rem] rounded hover:bg-violet-400/50 transition-all ${
                    !sellerInfoOpen &&
                    !layout?.notificationId &&
                    pathname === item?.path &&
                    "bg-violet-400 "
                  }`}
                  title={item.name}
                >
                  <span className="flex-auto truncate mr-1.5">{item.name}</span>
                  {item?.count?.show ? (
                    <div className="flex-none text-white min-w-[1.125rem] h-[1.125rem] text-xxs font-semibold leading-[0.875rem] px-[.3125rem] bg-violet-400 flex justify-center items-center rounded-[0.25rem]">
                      {item?.count?.value}
                    </div>
                  ) : null}
                </a>
              </li>
            ))}
          </ul>
        )}
        {!state && size(showSubmenu) === 0 && (
          <Tooltip
            anchorId={title}
            content={title}
            place="right"
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
            positionStrategy="fixed"
          />
        )}
      </li>
      {separator && (
        <hr className="-mx-2.5 my-2.5 h-px bg-white opacity-[15%]"></hr>
      )}
    </React.Fragment>
  );
};

export default AsideItemList;
