import { getVenuePerformerEventConfig } from "helpers/Functions";
import { LISTINGS_UTILS } from "helpers/const";
import Cookies from "js-cookie";
import { SALES_NEST_ROUTES } from "pages/Sales/core/const";
import { CustomizeWrapper } from "pages/customize/CustomizeWrapper";
import { SUBSCRIBE_ROUTE } from "pages/subscribe/personal_details/core/const";
import SellerLevelsWrapper from "pages/subscribe/seller_levels/SellerLevelsWrapper";
import ConfigurationTab from "pages/subscribe/seller_levels/component/ConfigurationTab";
import MovementTab from "pages/subscribe/seller_levels/component/MovementTab";
import { TX_PAY_TAB_ROUTES_WITH_PERMISSION } from "pages/tx_pay/core/const";
import VenueDetailsWrapper from "pages/venue_details/VenueDetailsWrapper";
import { FC, lazy, Suspense, useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LayoutContext } from "../components/core/LayoutProvider";
import ForgetPassword from "../components/layout/auth/ForgetPassword";
import LoadingPage from "../components/layout/auth/LoadingPage";
import Login from "../components/layout/auth/Login";
import Logout from "../components/layout/auth/Logout";
import ResetPassword from "../components/layout/auth/ResetPassword";
import MasterLayout from "../components/layout/masterLayout/MasterLayout";
import PublicLayout from "../components/layout/publicLayout/PublicLayout";
import ConnectorWrapper from "../pages/API_connector/ConnectorWrapper";
import ReportsWrapper from "../pages/Reports/ReportsWrapper";
import SalesWrapper from "../pages/Sales/SalesWrapper";
import AddInventoryWrapper from "../pages/add_inventory/AddInventoryWrapper";
import DashboardWrapper from "../pages/dashboard/DashboardWrapper";
import InstructionLetterWrapper from "../pages/instruction_letter/InstructionLetterWrapper";
import InventoryWrapper from "../pages/inventory/InventoryWrapper";
import NotificationsWrapper from "../pages/notifications/NotificationsWrapper";
import PersonalDetailsWrapper from "../pages/subscribe/personal_details/PersonalDetailsWrapper";
import WalletAndBankDetailsWrapper from "../pages/subscribe/wallet-bank_Details/WalletAndBankDetailsWrapper";
import TxAccountWrapper from "../pages/tx_account/TxAccountWrapper";
import TXWrapper from "../pages/tx_pay/TXWrapper";

const TxpayStripeComponent = lazy(
  () => import("../pages/settings/SettingsWrapper")
);
const TxtradeStripeComponent = lazy(
  () => import("../pages/tx_trade/TxTradeWrapper")
);
const SingupStripeComponent = lazy(
  () => import("../components/layout/auth/SignUp")
);

const AppRoutes: FC = () => {
  const basePath = process.env.PUBLIC_URL;
  const layout = useContext(LayoutContext);
  const isLogged = layout.isLogged;
  const access = layout.allowedAccess?.allowed_access;
  let cookieTtoken = Cookies.get("authTokens") as string;
  const isGodAdmin = layout.allowedAccess?.account_info?.is_god_admin;

  if (cookieTtoken) {
    cookieTtoken = JSON.parse(cookieTtoken);
  }

  // CHECK IF ROUTE ACCESSISBLE
  const reportsItem = access?.some(
    (item: any) => item.id === "reports" && item.status === true
  );
  const addInventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );
  const inventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );
  const sales = access?.some(
    (item: any) => (item.id === "shipping" && item.status === true) || false
  );
  const txTrade = access?.some(
    (item: any) => (item.id === "txtrade" && item.status === true) || false
  );
  const settings = access?.some(
    (item: any) => (item.id === "settings" && item.status === true) || false
  );
  const txAccounts = access?.some(
    (item: any) => (item.id === "txaccounts" && item.status === true) || false
  );
  const subscribers = access?.some(
    (item: any) => (item.id === "subscribers" && item.status === true) || false
  );
  const apiConnector = access?.some(
    (item: any) =>
      (item.id === "api-connector" && item.status === true) || false
  );

  const instruction_letters = access?.some(
    (item: any) =>
      (item.id === "instruction_letters" && item.status === true) || false
  );

  const dashboardPath =
    layout.isTxTradeUser === 1 && layout.userDetailShow === false
      ? "/"
      : "/dashboard";

  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route
          element={
            isLogged || cookieTtoken ? <MasterLayout /> : <PublicLayout />
          }
        >
          {isLogged || cookieTtoken ? (
            <>
              {getVenuePerformerEventConfig(layout) ? 
                <Route path="/" element={
                  <Navigate to={
                    inventory === undefined ? (
                            "my-listings"
                          ) : inventory ? (
                            "my-listings"
                          ) : (
                            "notifications"
                          )
                  } replace />} />
              : <Route
                path={layout.isTxTradeUser === 1 ? dashboardPath : "/"}
                element={<DashboardWrapper />}
              />}
              <Route path="*" element={<Navigate to="/" replace />} />
              <>
                <Route
                  path={LISTINGS_UTILS?.addListings?.url}
                  element={
                    !isGodAdmin && addInventory === undefined ? (
                      <AddInventoryWrapper />
                    ) : !isGodAdmin && addInventory ? (
                      <AddInventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path={LISTINGS_UTILS?.myListings.url}
                  element={
                    inventory === undefined ? (
                      <InventoryWrapper />
                    ) : inventory ? (
                      <InventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/sales"
                  element={
                    sales === undefined ? (
                      <SalesWrapper />
                    ) : sales ? (
                      <SalesWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/reports"
                  element={
                    reportsItem === undefined ? (
                      <ReportsWrapper />
                    ) : reportsItem ? (
                      <ReportsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/notifications"
                  element={<NotificationsWrapper />}
                />

                <Route
                  path={LISTINGS_UTILS?.addListings?.url}
                  element={
                    addInventory === undefined ? (
                      <AddInventoryWrapper />
                    ) : addInventory ? (
                      <AddInventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path={LISTINGS_UTILS?.myListings.url}
                  element={
                    inventory === undefined ? (
                      <InventoryWrapper />
                    ) : inventory ? (
                      <InventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                {Object.values(SALES_NEST_ROUTES).map((route) => {
                  return (
                    (route.allow === "godAdmin"
                      ? isGodAdmin
                        ? true
                        : false
                      : true) && (
                      <Route
                        key={route?.path}
                        path={`/sales/${route?.path}`}
                        element={
                          sales === undefined ? (
                            <SalesWrapper />
                          ) : sales ? (
                            <SalesWrapper />
                          ) : (
                            <Navigate to="/" replace />
                          )
                        }
                      />
                    )
                  );
                })}

                {/* TX-TRADE  */}
                <Route
                  path={layout.isTxTradeUser === 1 ? "/" : "/tx-trade"}
                  element={
                    <Suspense fallback={<></>}>
                      {txTrade === undefined ? (
                        <TxtradeStripeComponent />
                      ) : txTrade ? (
                        <TxtradeStripeComponent />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />

                <Route
                  path={"/tx-trade-customise"}
                  element={
                    <Suspense fallback={<></>}>
                      {layout?.isTxTradeCustomize &&
                      isGodAdmin &&
                      (txTrade === undefined || txTrade) ? (
                        <CustomizeWrapper />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />

                {isGodAdmin && txAccounts && (
                  <Route path="/tx-account" element={<TxAccountWrapper />} />
                )}
                {/* REPORTS  */}
                <Route
                  path="/reports"
                  element={
                    reportsItem === undefined ? (
                      <ReportsWrapper />
                    ) : reportsItem ? (
                      <ReportsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                {/* SUBSCRIBE */}
                <Route
                  path={`/subscribe${SUBSCRIBE_ROUTE.subscribeDetails}`}
                  element={
                    subscribers === undefined ? (
                      <PersonalDetailsWrapper />
                    ) : subscribers ? (
                      <PersonalDetailsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                {/* REFERRAL */}
                <Route
                  path={`/subscribe${SUBSCRIBE_ROUTE.referral}`}
                  element={
                    subscribers === undefined ? (
                      <PersonalDetailsWrapper />
                    ) : subscribers ? (
                      <PersonalDetailsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route
                  path="/wallet-&-bank-details"
                  element={
                    subscribers === undefined ? (
                      <WalletAndBankDetailsWrapper />
                    ) : subscribers ? (
                      <WalletAndBankDetailsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route
                  path="/seller-levels/"
                  element={
                    subscribers === undefined ? (
                      <SellerLevelsWrapper />
                    ) : subscribers ? (
                      <SellerLevelsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                >
                  <Route index element={<Navigate to="configuration" />} />
                  <Route
                    path="configuration"
                    index
                    element={<ConfigurationTab />}
                  />
                  <Route path="movement" element={<MovementTab />} />
                  <Route path="*" element={<Navigate to={"configuration"} />} />
                </Route>

                <Route
                  path="/notifications"
                  element={<NotificationsWrapper />}
                />

                {/* settings */}
                <Route
                  path="/settings"
                  element={
                    <Suspense fallback={<></>}>
                      {!settings === undefined ? (
                        <TxpayStripeComponent />
                      ) : settings ? (
                        <TxpayStripeComponent />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />
                {TX_PAY_TAB_ROUTES_WITH_PERMISSION.map((item) => (
                  <Route
                    key={item?.route}
                    path={`/tx-pay${item?.route}`}
                    element={
                      item.hasPermission(access) === undefined ? (
                        <TXWrapper />
                      ) : item.hasPermission(access) ? (
                        <TXWrapper />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                ))}

                {/* INSTRUCTION LETTER */}
                <Route
                  path="/instruction-letter"
                  element={
                    isGodAdmin && instruction_letters ? (
                      <InstructionLetterWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                {/* API CONNECTOR */}
                <Route
                  path={`${LISTINGS_UTILS?.APIConnector.url}${LISTINGS_UTILS?.APIConnector.url}`}
                  element={
                    apiConnector ? (
                      <ConnectorWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                {/* VENUE DETAILS */}
                <Route
                  path={`${LISTINGS_UTILS?.APIConnector.url}${LISTINGS_UTILS?.venueDetails.url}`}
                  element={<VenueDetailsWrapper />}
                />
              </>

              {/* <Route path="/typography" element={<Typography />} /> */}
              <Route path="/settings" element={<TxpayStripeComponent />} />
              <Route path="/logout" element={<Logout />} />
            </>
          ) : (
            <Route path="/" element={<Navigate to="/login" />} />
          )}
        </Route>
        <Route element={<PublicLayout />}>
          {!isLogged && !cookieTtoken && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              {/* SIGN UP ROUTES */}
              <Route
                path="/sign-up/:referral?"
                element={<SingupStripeComponent />}
              />
            </>
          )}
          <Route path="/loadingpage" element={<LoadingPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
