/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import { toNumber } from "helpers/Functions";
import Cookies from "js-cookie";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getAccountInfo } from "../../../pages/settings/core/_request";
import { LayoutContext } from "../../core/LayoutProvider";
import PasswordInput from "../../formComponent/PasswordInput";
import TextInput from "../../formComponent/TextInput";
import { KTSVG } from "../../ui/KTSVG";
import { signIn } from "./core/_request";

// VALIDATION SCHEMA
const loginSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  password: Yup.string().required("Password is required"),
  apiError: Yup.string(),
});

// INITIAl CREDENTIALS
const initialValues = {
  username: "",
  password: "",
  apiError: "",
};

const Login = () => {
  const layout = useContext(LayoutContext);
  const [loader, setLoader] = useState<boolean>(false);
  const location = useLocation();
  const redirected = location.search === "?r=lp";
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const navigate = useNavigate();
  const REACT_APP_TXTRADE_DOMAIN: any =
    process.env.REACT_APP_TXTRADE_DOMAIN || null;
  // SUBMIT LOGIN API
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      setLoader(true);
      signIn(values).then((response: any) => {
        if (response?.data?.status) {
          let authDataVar = response?.data;
          delete authDataVar?.tx_pay_info_data;
          delete authDataVar?.seller_level;
          const redirectUrl = REACT_APP_TXTRADE_DOMAIN
            ? new URL(`${REACT_APP_TXTRADE_DOMAIN}`)
            : null;
          if (
            response?.data?.tx_trade_user === 1 &&
            redirectUrl &&
            toNumber(response?.data?.available_amount) <= 0
          ) {
            const authTokens = JSON.stringify(response?.data?.token);
            const authData = response?.data;
            const serializedData: any = Object.entries(authData)
              .map(
                ([key, value]: any) =>
                  `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
              )
              .join("&");

            document.cookie = `session=${serializedData}; path=/; domain=.tixstock.com; SameSite=None; Secure`;
            document.cookie = `redirect=${authTokens}; path=/; domain=.tixstock.com; SameSite=None; Secure`;

            // redirectUrl.searchParams.set("user-redirect", "true");
            setTimeout(() => {
              window.location.replace(redirectUrl.toString());
            }, 50);
          } else {
            Cookies.set("authTokens", JSON.stringify(response?.data?.token));
            // Cookies.set("authData", JSON.stringify(authDataVar));
            localStorage.setItem("authData", JSON.stringify(authDataVar));
            layout.setUserDetailShow({
              txPayStatus: response?.data?.tx_pay_info_status,
              txPayData: response?.data?.tx_pay_info_data,
            });
            localStorageClear(response?.data?.id);

            localStorage.setItem(
              "txPayStatus",
              JSON.stringify({
                txPayStatus: response?.data?.tx_pay_info_status,
                txPayData: response?.data?.tx_pay_info_data,
              })
            );
            localStorage.setItem(
              "isTxTradeUser",
              response?.data?.tx_trade_user
            );

            layout.setIsTxTradeUser(response?.data?.tx_trade_user);
            getAccountInfo().then((response: any) => {
              layout.SetAllowedAccess(response?.data);
              localStorage.setItem("access", JSON.stringify(response?.data));
              // let body: any = document.querySelector("body");
              // body?.classList.remove('overflow-hidden');
              // layout.setIsNotificationPopupActive(false);
            });
            setTimeout(() => {
              layout.setIsLogged(true);
              layout.setUserDetailShow({
                txPayStatus: response?.data?.tx_pay_info_status,
                txPayData: response?.data?.tx_pay_info_data,
              });
              setLoader(false);
              localStorage.setItem(
                "userKey",
                JSON.stringify(response?.data?.id)
              );
            }, 1000);
            layout.setUsername(response?.data?.name);
          }
          localStorage.removeItem("agrred");
        } else {
          formik.setFieldError("apiError", response?.errors?.title);
          setLoader(false);
        }
      });
    },
  });

  function localStorageClear(id: any) {
    const storedData: any = localStorage.getItem("userKey");
    const parsedData = JSON.parse(storedData);

    if (id !== parsedData) {
      localStorage.removeItem("saveSalesFilter");
      localStorage.removeItem("saveInventoryFilter");
      localStorage.removeItem("saveDraggableFilterData");
      localStorage.removeItem("saveTxPayFilters");
      localStorage.removeItem("reportTableColumn");
      localStorage.removeItem("salesTableColumn");
      localStorage.removeItem("saveInventoryTableColumn");
      localStorage.removeItem("saveDataNotifications");
    }
  }

  // RENDERING
  useEffect(() => {
    const checkValue = (data: any) => {
      if (!formik.values.username || !formik.values.password) {
      }
    };

    const renderCall = () => {
      let userName = document.querySelector('input[name="username"]');
      let password = document.querySelector('input[name="password"]');

      if (!userName) {
        setTimeout(renderCall, 100); // Retry after 100 milliseconds
      } else {
        checkValue({ userName, password });
      }
    };

    // localStorage.clear();

    renderCall();

    return () => {
      checkValue("");
    };
  }, []);

  useLayoutEffect(() => {
    localStorage.clear();
    layout.SetAllowedAccess({});
  }, []);

  // useEffect(() => {
  //   const inputElement = passwordRef.current;
  //   inputElement.addEventListener("blur", () => {
  //     setIsBlur(true);
  //   });
  //   inputElement.addEventListener("focus", () => {
  //     setIsBlur(false);
  //   });

  //   return () => {
  //     inputElement.removeEventListener("blur", () => {});
  //     inputElement.removeEventListener("focus", () => {});
  //   };
  // }, []);

  const handleClick = () => {
    navigate("../", { replace: true });
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="login_page_main relative flex items-start md:items-center justify-center min-h-screen bg-violet-600 bg-no-repeat bg-tixcontactpattern bg-cover bg-center  md:pt-[7.5rem] md:pb-[7.5rem] py-24">
          <div
            className="tix_logo absolute left-0 right-0 m-auto top-7 md:top-12"
            onClick={handleClick}
          >
            <KTSVG
              className="min-w-[1.125rem] flex items-center justify-center fill-white transition"
              path="tix_logo.svg"
              svgClassName="max-w-[12.75rem] md:max-w-[13.5rem] mx-auto"
            />
          </div>
          <div
            className={`login_inner ${
              redirected ? "max-w-[36.87rem]" : `max-w-[73.75rem]`
            } flex flex-wrap w-full mx-5 rounded-xl bg-indigo-500 bg-opacity-[25%]`}
          >
            <div
              className={`login_panel ${
                !redirected ? `md:w-1/2` : ``
              } w-full bg-white px-5 md:py-12 py-7 text-center rounded-xl shadow-op3 md:min-h-[30.125rem] flex items-center`}
            >
              <div className="max-w-[23.75rem] m-auto w-full">
                <h2 className="md:text-3xl text-2xl font-artex_reg md:mb-6 mb-4 leading-[1.3]">
                  {redirected ? `Continue Sign up` : `Login`}
                </h2>
                <p
                  className={` ${
                    !redirected && `md:text-sm15`
                  } text-sm14 md:leading-6 leading-5  mb-7 font-basier`}
                >
                  {redirected
                    ? `Login using your existing Tixstock credentials to continue.`
                    : `Connecting global inventory suppliers with a unique distribution network.`}
                </p>
                {/* LOGIN FORM */}
                <div className="form_login new-inp">
                  <div className="w-full mb-5 relative">
                    <TextInput
                      name="username"
                      autoComplete="off"
                      type="text"
                      value={formik.values.username}
                      required={false}
                      errorClass={
                        ((formik.touched.username && formik.errors.username) ||
                          formik.errors?.apiError) &&
                        "border-rose-500"
                      }
                      labelErrorClass={
                        formik.touched.username &&
                        formik.errors.username &&
                        "text-rose-500 "
                      }
                      apiError={formik.errors?.apiError && "!text-rose-500"}
                      label="Username"
                      id="username"
                      handleOnChange={(data: any) =>
                        formik.setFieldValue(
                          data?.target?.name,
                          data?.target?.value
                        )
                      }
                      focusProp={isFocused}
                      handleClearValue={() =>
                        formik.setFieldValue("username", "")
                      }
                    />
                    {formik.touched.username && formik.errors?.username && (
                      <div className="text-xxs text-rose-500 error-msg left-3">
                        {formik.errors?.username as string}
                      </div>
                    )}
                  </div>
                  <div className="w-full mb-5 relative">
                    {/* <div
                      className={`relative w-full  ${
                        isFocused && "dp-focused "
                      } `}
                    >
                      <input
                        type="password"
                        id="password"
                        ref={passwordRef}
                        name="password"
                        autoComplete="new-password"
                        className={`${
                          formik.values?.password &&
                          !isFocused &&
                          "!bg-white border-indigo-500"
                        } ${
                          ((formik.touched.password &&
                            formik.errors.password) ||
                            formik.errors?.apiError) &&
                          "border-rose-500"
                        }  placeholder:truncate block px-2.5 font-medium w-full h-10 text-xs transition bg-gray-100 border border-gray-300 appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer undefined peer-focus:bg-white autofill:bg-indigo-500`}
                        placeholder=" "
                        value={formik.values?.password}
                        onFocus={(data: any) => {
                          setIsFocused(true);
                        }}
                        onBlur={(data: any) => {
                          data?.preventDefault();
                          // setIsFocused(false);
                        }}
                        onChange={(data) => {
                          formik.setFieldValue("password", data.target.value);
                        }}
                      />

                      {formik.values?.password && isFocused ? (
                        <button
                          tabIndex={-1}
                          type="button"
                          className={` w-5 h-5 absolute top-1/2 -translate-y-1/2 right-2.5 flex items-center justify-center fill-violet-500 group-hover:fill-white bg-white rounded-full   hover:fill-white cursor-pointer hover:bg-violet-500  transition-all`}
                          onMouseDown={(e: any) => {
                            e.preventDefault();
                            formik.setFieldValue("password", "");
                          }}
                        >
                          <SVG src={CrossSVG} className={"w-2 h-2"} />
                        </button>
                      ) : (
                        isFocused &&
                        isBlur &&
                        formik.values?.password &&
                        !(
                          (formik.touched.password && formik.errors.password) ||
                          formik.errors?.apiError
                        ) &&
                        "border-rose-500" && (
                          <IKTSVG
                            className="flex items-center justify-center transition absolute  w-5 h-5  top-1/2 -translate-y-1/2 right-2.5"
                            path={PurpleCheckSVG}
                            svgClassName="w-[1.125rem]"
                          />
                        )
                      )}

                      <label
                        htmlFor="password"
                        className={`${
                          formik.values?.password && "!bg-white text-indigo-500"
                        } ${
                          formik.touched.password &&
                          formik.errors.password &&
                          "text-rose-500"
                        } ${
                          formik.errors?.apiError && "!text-rose-500"
                        }   absolute text-xxs rounded-full text-gray-400 duration-300 transform -translate-y-4 font-medium top-2 left-1 z-10 origin-[0] bg-gray-100 peer-focus:bg-white px-2 peer-focus:px-2 peer-focus:text-indigo-500 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4`}
                      >
                        Password
                      </label>
                    </div> */}
                    {/* <input
                      type="password"
                      id="password"
                      value={formik.values?.password}
                      className={`${
                        formik.values?.password &&
                        !isFocused &&
                        "!bg-white border-indigo-500"
                      }   placeholder:truncate block px-2.5 font-medium w-full h-10 text-xs transition bg-gray-100 border border-gray-300 appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer undefined peer-focus:bg-white autofill:bg-indigo-500`}
                      onChange={(data) => {
                        formik.setFieldValue("password", data?.target?.value);
                      }}
                    /> */}
                    <PasswordInput
                      name="password"
                      type="password"
                      autoComplete="new-password"
                      label="Password"
                      required={false}
                      value={formik.values.password}
                      errorClass={
                        ((formik.touched.password && formik.errors.password) ||
                          formik.errors?.apiError) &&
                        "border-rose-500"
                      }
                      labelErrorClass={
                        formik.touched.password &&
                        formik.errors.password &&
                        "text-rose-500"
                      }
                      apiError={formik.errors?.apiError && "!text-rose-500"}
                      id="Password"
                      handleOnChange={(data: any) =>
                        formik.setFieldValue(
                          data?.target?.name,
                          data?.target?.value
                        )
                      }
                      handleClearValue={() =>
                        formik.setFieldValue("password", "")
                      }
                    />
                    {formik.touched.password && formik.errors?.password && (
                      <div className="text-xxs text-rose-500 error-msg left-3">
                        {formik.errors?.password as string}
                      </div>
                    )}
                  </div>
                  {formik.errors?.apiError && (
                    <div className="text-sm text-rose-500 mb-4">
                      {formik.errors?.apiError as string}
                    </div>
                  )}
                  <button
                    type="submit"
                    className={`${
                      loader && "pointer-events-none loading"
                    } loader-stripe text-sm18 font-artex_reg px-3 py-2.5 w-full bg-indigo-500 border-b-4 border-b-violet-500/20 hover:border-b-green-600 rounded-lg hover:bg-green-600 text-white transition mt-1`}
                  >
                    {loader ? "Loading" : "Login"}
                  </button>
                </div>
                <span className="mt-5 block leading-5">
                  <Link
                    to={"/forgot-password"}
                    className="text-indigo-500 font-basier hover:underline text-sm15"
                  >
                    Forgot password
                  </Link>
                </span>
              </div>
            </div>
            {!redirected && (
              <div className="create_account md:w-1/2 w-full flex items-center justify-center px-5 md:py-[3.75rem] py-7 ">
                <div className="inner_create max-w-[23.75rem] m-auto text-center text-white">
                  <h5 className="md:text-3xl text-2xl font-artex_reg md:mb-6 mb-4 leading-[1.3]">
                    Don’t have an account?
                  </h5>
                  <p className="md:text-sm15 text-sm14 md:leading-6 leading-5 font-basier mb-9">
                    Buyer or seller, Tixstock empowers you to offer access to
                    the world’s most in-demand events.
                  </p>
                  <button
                    type="button"
                    className=" font-artex_reg  rounded-lg px-5 py-2.5 leading-7 hover:bg-indigo-500 transition w-full text-sm18  outline-1 outline-white outline hover:outline-indigo-500"
                    onClick={() => (window.location.href = "/sign-up")}
                  >
                    Sign up now
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
