import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import { SearchIcon } from "assets/media/icons/standard_icons/SearchIcon";
import { useEffect, useState } from "react";

type Props = {
  query: string;
  loading: boolean;
  page: number;
  searchID: string;
  searchPlaceholder: string;
  setState: any;
};

const SimpleSearch: React.FC<Props> = ({
  query,
  loading,
  page,
  searchID = "simple-search",
  searchPlaceholder = "",
  setState,
}) => {
  const [searchString, setSearchString] = useState<string>("");

  useEffect(() => {
    if (query === "") {
      setSearchString("");
    }
  }, [query]);

  return (
    <div
      className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
        loading && page === 1 && "shimmer-effect"
      } `}
    >
      <>
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
          <button
            type="button"
            onClick={() => {
              if (searchString) setSearchString("");
            }}
          >
            {searchString ? (
              <CrossIcon className="w-3.5" />
            ) : (
              <SearchIcon className="h-3.5 w-3.5" />
            )}
          </button>
        </div>
        <input
          value={searchString}
          type="text"
          name="query"
          id={searchID}
          placeholder={searchPlaceholder}
          className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
          onChange={(e: any) => setSearchString(e?.target?.value)}
          onKeyDown={(event: any) => {
            if (event?.target?.value && event.key === "Enter") {
              event.preventDefault();
              setState((pre: any) => {
                return {
                  ...pre,
                  query: event?.target?.value,
                  page: 1,
                };
              });
            }
          }}
        />
      </>
    </div>
  );
};

export default SimpleSearch;
