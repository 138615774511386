import SimpleSearch from "components/ui/SimpleSearch";
import { useContext } from "react";
import { VenueDetailsContext } from "../core/VenueDetailsProvider";

const Filters = ({ loading }: any) => {
  const { venueFilter, setVenueFilter } = useContext(VenueDetailsContext);
  const { page } = venueFilter;

  return (
    <div
      className="flex flex-wrap border-b gap-y-2.5 py-4 px-5 bg-white"
      id={`filters_block`}
    >
      <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%] px-[.3125rem]`}>
        {/* SEARCH BAR */}
        <SimpleSearch
          page={page}
          query={venueFilter?.query}
          loading={loading}
          searchID={"simple-search-venue-details"}
          searchPlaceholder={"Search reseller"}
          setState={setVenueFilter}
        />
      </div>
    </div>
  );
};

export default Filters;
