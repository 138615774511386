import { KTSVG } from "../../../../components/ui/KTSVG";

const EventHeader = ({ className, title, Imgpath, svgClassName }: any) => {
  return (
    <>
      <div className={className}>
        <KTSVG
          className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
          path={Imgpath}
          svgClassName={svgClassName}
        />
        <span className="truncate">{title}</span>
      </div>
    </>
  );
};

export default EventHeader;
