import {
  INITIAL_PERSONAL_DETAILS,
  INITIAL_REFERRAL_DETAILS,
} from "helpers/const";
import { useContext } from "react";
import { SUBSCRIBE_ROUTE, SUBSCRIBE_TAB } from "../core/const";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import SubscribeTab from "./SubscribeTab";

const TabWrapper = () => {
  const { activeTab, setActiveTab, setCommonFilter } = useContext(
    PersonalDetailsContext
  );

  const handleOnTab = (data: any) => {
    setCommonFilter({
      subscriberFilter: INITIAL_PERSONAL_DETAILS,
      referralFilter: INITIAL_REFERRAL_DETAILS,
    });
  };

  return (
    <>
      {Object.entries(SUBSCRIBE_TAB).map(([key, labelText]) => {
        const route =
          labelText === SUBSCRIBE_TAB.subscribeDetails
            ? `/subscribe${SUBSCRIBE_ROUTE?.subscribeDetails}`
            : `/subscribe${SUBSCRIBE_ROUTE?.referral}`;
        return (
          <SubscribeTab
            key={key}
            labelText={labelText}
            setActiveTab={setActiveTab}
            route={route}
            tab={activeTab}
            handleOnTab={handleOnTab}
          />
        );
      })}
    </>
  );
};

export default TabWrapper;
