import { IKTSVG } from "components/ui/IKTSVG";
import { VENUE_DETAILS_INITIAL_FILTER } from "helpers/const";
import { addCommaInNumber } from "helpers/Functions";
import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import refreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { VenueDetailsContext } from "../core/VenueDetailsProvider";

const FilterChip = ({ paginateData, loading }: any) => {
  const { venueFilter, setVenueFilter } = useContext(VenueDetailsContext);
  const { query, page } = venueFilter;

  const handleRefresh = () => {
    setVenueFilter(VENUE_DETAILS_INITIAL_FILTER);
  };

  return (
    <div
      className="flex flex-wrap customStyle relative bg-white"
      id="report_filter"
    >
      <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center">
        {loading && page === 1 ? (
          <div className="shimmer-effect inline-block ">
            <span className="opacity-0">
              {paginateData?.total > 0
                ? `${paginateData?.total} results`
                : "0 results"}
            </span>
          </div>
        ) : (
          <>
            {`${addCommaInNumber(paginateData?.total || 0)} result${
              paginateData?.total > 1 ? "s" : ""
            }`}
          </>
        )}
      </div>
      {(loading || query) && page === 1 && (
        <div className="flex items-center justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full min-h-[3.375rem] max-sm:border-t">
          {loading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  loading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {query && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    loading && "shimmer-effect"
                  } 
        `}
                  onClick={handleRefresh}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem] w-3 h-3"
                  />
                </button>
              )}

              {/* QUERY */}
              {query && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {query}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={handleRefresh}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterChip;
