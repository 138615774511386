import { useContext, useMemo, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import DeleteSVG from "../../../../assets/media/icons/other_icons/Delete.svg";
import EYESVG from "../../../../assets/media/icons/other_icons/Eye.svg";
import UploadSVG from "../../../../assets/media/icons/other_icons/Upload.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { MobileInsturction } from "../../../../helpers/AssetHelpers";
import { pdfViewer } from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import { generateUniqueId } from "../../core/_functions";
import UploadFileInstruction from "../UploadFileInstruction";
import PDFViewer from "./PDFViewer";

//Styled Component for Styling the Assigned List
const List = styled.li`
  background-color: ${(props) =>
    // @ts-ignore
    props?.checkedState || props?.assigned ? "rgba(0, 229, 0, 0.1)" : "white"};
`;

const ETicketLeftPanelWrapper = ({ files, setFiles, isOpen }: any) => {
  const globalLayout = useContext(LayoutContext);
  let { status: PDFViewStatus } = globalLayout?.filePreviewState;
  const [checkedState, setCheckedState] = useState(false);

  //   DELETE PDF
  const deletePdf = (file: any, index: number) => {
    setFiles((currentPDF: any) =>
      currentPDF.filter(
        (item: any, filterIndex: number) => index !== filterIndex
      )
    );
  };

  let allTicketType = ["eticket", "pdf", "mobile-qr", "mobile-link"];
  //   CHECK TICKET TYPE
  const checkTicketType = useMemo(() => {
    let ticketType = isOpen?.event?.[0]?.eventRecords?.[0]?.ticketType?.id;
    if (ticketType === "mobile-link") {
      ticketType = "Mobile Link/PKPASS";
    } else if (ticketType === "mobile-qr") {
      ticketType = "Mobile Screenshot";
    } else if (allTicketType.includes(ticketType)) {
      ticketType = "E-ticket";
    }
    return {
      heading: `${ticketType} upload instructions`,
      listing:
        ticketType === "E-ticket" || "Mobile Screenshot"
          ? [
              "Use this window to upload individual tickets for each e-ticket order (PDF format)",
              "Drag and drop each file - ensuring any designated seat matches the ticket",
              "Confirm upload by clicking the green 'confirm' button",
            ]
          : MobileInsturction,
      type: ticketType === "E-ticket" ? "E-Ticket" : "Mobile",
      extra:
        ticketType === "E-ticket"
          ? "Making changes to uploaded tickets is limited to unpublished listings. Upon publishing the listing, tickets will be secured for delivery and sell faster on the Tixstock Network"
          : "",
      ticketType,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen?.event]);

  //   FILE DROPZONE
  const { getRootProps, getInputProps, open } = useDropzone({
    maxSize: 5244899, // 5MB in bytes,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }
      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "vnd.apple.pkpass": [".pkpass", "pkpass"],
    },
  });

  return PDFViewStatus ? (
    <PDFViewer />
  ) : (
    <div
      className={`lg2:border-r w-full lg2:w-1/2 p-4 md:p-5 flex justify-start  flex-col ${
        files && files.length > 0 && "!justify-start"
      }`}
    >
      <div
        {...getRootProps({ className: "dropzone" })}
        className={`flex flex-col items-center justify-center text-center ${
          files && files.length > 0 ? "h-20" : "h-20"
        } w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs`}
      >
        <input {...getInputProps()} />
        <div className="mb-2.5">Upload or drop files here</div>
        <button
          type="button"
          onClick={open}
          className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
        >
          <IKTSVG
            className="fill-white mr-2"
            path={UploadSVG}
            svgClassName="w-[.8125rem] h-[.6875rem]"
          />
          Upload
        </button>
      </div>
      {!(files && files.length > 0) && (
        <UploadFileInstruction
          data={{
            heading: checkTicketType?.heading,
            listing: checkTicketType?.listing,
            type: checkTicketType?.type,
            extra: checkTicketType?.extra,
          }}
        />
      )}
      {files && files.length > 0 && (
        <aside className="w-full mt-5">
          <div className="flex flex-wrap gap-1 mb-5">
            <h4 className="text-sm flex-auto">
              {files.length} file uploaded{" "}
              {files.filter((file: any) => file.assigned).length} assigned
            </h4>
            <div className="flex items-center gap-x-2">
              <span className="text-xs">Show assigned</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={checkedState}
                  onChange={() => setCheckedState(!checkedState)}
                />
                <div className="w-7 h-3 bg-gray-200 peer-checked:bg-green-600/40 rounded-full transition-all peer peer-checked:after:translate-x-full peer-checked:after:bg-green-600 after:content-[''] after:absolute after:-top-0.5 after:-left-0.5 after:bg-gray-400 after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100"></div>
              </label>
            </div>
          </div>
          {/* LEFT INNER DRAG  */}
          <Droppable
            droppableId="eventTicketUploadedFiles"
            key="eventTicketUploadedFiles"
          >
            {(provided: any, snapshot: any) => (
              <ul ref={provided.innerRef}>
                {files.map((file: any, index: number) => {
                  return (
                    (file?.assigned !== 1 || checkedState) && (
                      <Draggable
                        key={index}
                        // draggableId={file.path}
                        draggableId={`${file.path}${index}`}
                        index={index}
                        isDragDisabled={file.assigned ? true : false}
                      >
                        {(provided: any, snapshot: any) => (
                          // @ts-ignore
                          <List checkedState={checkedState}>
                            <span
                              key={index}
                              className={`${
                                file.assigned === 1
                                  ? "flex   hover:bg-green-600/10 border border-green-600 hover:border-green-600 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                  : "flex bg-white hover:bg-green-600/10 border hover:border-green-600 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                              }`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              isdragging={snapshot.isdragging}
                            >
                              <span
                                className="text-xs flex-auto truncate"
                                title={file.path}
                              >
                                {" "}
                                {file.path}{" "}
                              </span>
                              <span className="flex s-center gap-x-2">
                                {file?.type && file?.type !== "application/vnd.apple.pkpass" && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      pdfViewer(
                                        globalLayout.setFilePreviewState,
                                        file
                                      )
                                    }
                                    id={`View-${index}`}
                                  >
                                    <Tooltip
                                      anchorId={`View-${index}`}
                                      content="View"
                                      place="top"
                                      variant="light"
                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                    />
                                    <IKTSVG
                                      className="fill-violet-500 hover:fill-indigo-500 transition"
                                      svgClassName="w-4 h-[.6875rem]"
                                      path={EYESVG}
                                    />
                                  </button>
                                )}

                                <button
                                  type="button"
                                  onClick={() => deletePdf(file, index)}
                                  id={`delete-${index}`}
                                >
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={DeleteSVG}
                                    svgClassName="w-2.5 h-[.8125rem]"
                                  />
                                </button>
                                <Tooltip
                                  anchorId={`delete-${index}`}
                                  content="Delete"
                                  place="top"
                                  variant="light"
                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                />
                              </span>
                            </span>
                          </List>
                        )}
                      </Draggable>
                    )
                  );
                })}
              </ul>
            )}
          </Droppable>
        </aside>
      )}
    </div>
  );
};

export default ETicketLeftPanelWrapper;
