/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  checkWeightageOfEvent,
  uploadAdditionalFileAWS,
  uploadFileWithPreAssignedURL,
} from "helpers/AssetHelpers";
import Messages from "helpers/Messages";
import _, { isArray } from "lodash";
import moment from "moment";
import { getDynamicHTML } from "pages/Sales/core/_functions";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import backToTOp from "../../../assets/media/icons/other_icons/backtotop.svg";
import ListingLinesSVG from "../../../assets/media/icons/standard_icons/listing_lines.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import AddInventoryButton from "../../../components/ui/buttons/AddInventoryButton";
import CollapseButton from "../../../components/ui/buttons/CollapseButton";
import PublishButton from "../../../components/ui/buttons/PublishButton";
import {
  addCommaInNumber,
  priceCalculationWithNumber,
  removeCommaFromNumber,
} from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  initialKeyboardField,
  REACT_APP_AWS_TICKET_FOLDER_NAME,
} from "../../../helpers/const";
import TablePopup from "../../inventory/components/TablePopup";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import {
  checkAccordionCollapse,
  countExpandedAccordions,
  footer_dynamic,
  generateUniqueId,
  TicketError,
  visibilityMeterCalculationForKeyBoard,
  visiblityCountForPayload,
} from "../core/_functions";
import { Event, EventFields } from "../core/_models";
import { inventoryBlukAdd } from "../core/_requsets";

const Footer = React.memo(({ list, setList, virtuosoRef }: any) => {
  const layout = useContext(AddInventoryContext);
  const globalLayout = useContext(LayoutContext);
  const sellerData = globalLayout?.allowedAccess?.team_data?.seller_level;
  const user_base_currency = globalLayout.currencyLabel;
  const [tableAlert, setTableAlert] = useState(false);

  const [tableAlertMsg, setTableAlertMsg] = useState("");
  const [tableAlertConfirm, setTableAlertConfirm] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const events: any = list;
  const btnRef: any = useRef(null);
  const [flag, setFlag] = useState(false);
  const [inventoryLoader, setInventoryLoader] = useState(false);
  const [publishLoader, setPublishLoader] = useState(false);
  const [confirmAddInventory, ConfirmAddInventory] = useState(false);
  const [saveAsBtnText, setSaveAsBtnText] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [eventLength, setEventLength] = useState<boolean>(false);
  const [publishedAlertMsg, setPublishedAlertMsg] = useState<any>();
  const [listingCount, setListingCount] = useState<any>();

  const priceFactor = Number(
    globalLayout?.allowedAccess?.price_suggestion_factor
  );

  let bulkTableChangedFields = _.uniq(_.flatten(layout.tableDataEditChanging));
  const deliveryIds = _.map(layout?.deliveryType, "id");
  bulkTableChangedFields = _.compact(
    _.difference(bulkTableChangedFields, deliveryIds)
  );

  // REMOVE SELECTED IF EVENT IS COLLAPSE
  useLayoutEffect(() => {
    setList((pre: any) => {
      return pre?.map((event: any, eIndex: number) => {
        let eventAccordion: any = document.getElementById(
          `EventHeading${event?.id}`
        );
        const isAccordionExpanded =
          eventAccordion.getAttribute("aria-expanded") === "true";

        if (eventAccordion && !isAccordionExpanded) {
          return {
            ...event,
            eventRecords: event?.eventRecords?.map((record: any) => {
              return {
                ...record,
                selected: false,
              };
            }),
          };
        } else {
          return event;
        }
      });
    });
  }, [countExpandedAccordions("add-inventory-collapse")]);

  // DISABLED FOOTER
  useEffect(() => {
    if (events && events.length > 0) {
      let isRecord = events?.some(
        (item: any) => item?.eventRecords?.length > 0
      );
      let checkCloneNewEvent = events?.some((event: any) => event?.id == "");

      setEventLength(
        checkCloneNewEvent || !checkAccordionCollapse("add-inventory-collapse")
          ? false
          : isRecord
      );
    } else {
      setEventLength(false);
    }
  }, [events, eventLength]);

  // EVENT SELECTED
  const eventCountSelected = list?.filter(
    (obj: any) =>
      obj?.eventRecords &&
      obj?.eventRecords?.some((item: any) => item?.selected)
  );

  // CHECK IF ANY EVENTRECORD EXIST OR NOT
  useEffect(() => {
    let tempRecords = events?.filter(
      (event: any) => !_.isEmpty(event?.eventRecords)
    );
    tempRecords && _.size(tempRecords) === 0 && setSelectAll(false);

    const selectedCount = events.reduce((count: any, obj: any) => {
      // Use some to check if any item in the inner array is selected
      if (obj?.eventRecords?.some((item: any) => item?.selected)) {
        // If at least one item is selected, increment the count
        return count + 1;
      } else {
        return count;
      }
    }, 0);
    if (selectedCount === 0) {
      setSelectAll(false);
    }
  }, [events]);

  // CHECK IF TABLE IS EDITABLE OR NOT
  const isTableEditable = layout.isTableEditable;
  let eventRecordLength =
    list &&
    list?.reduce(
      (count: any, event: any) =>
        count + ((event["eventRecords"] && event["eventRecords"]?.length) || 0),
      0
    );
  let eventsLength = list && list?.length;

  const getCheckboxCount = ({ action }: any) => {
    const events: Array<Event> = list;
    let rowSelected = 0;
    let eventSelected: any = [];
    events.map((event) => {
      return Array.isArray(event["eventRecords"])
        ? event["eventRecords"].map((eventRecord: EventFields) => {
            if (eventRecord?.selected === true) {
              rowSelected++;

              if (action === "edit") {
                let tempcountSelectedEditEvents: any = [];
                if (Array.isArray(layout.countSelectedEdit.events)) {
                  tempcountSelectedEditEvents = [
                    ...layout.countSelectedEdit.events,
                  ];
                }
                if (eventSelected.indexOf(event.id) === -1) {
                  eventSelected.push(event.id);
                }
              }
            }
          })
        : [];
    });
    if (action === "edit") {
      layout.setCountSelectedEdit({
        ...layout.countSelectedEdit,
        rows: rowSelected,
        events: eventSelected.length,
      });
    }
    return rowSelected;
  };

  // useEffect(() => {
  //   if (layout.getselectedCount) {
  //     getCheckboxCount({ action: "edit" });
  //     const handler = setTimeout(() => {
  //       layout.setGetSelectedCount(false);
  //     }, 50);
  //     return () => {
  //       clearTimeout(handler);
  //     };
  //   }
  // }, [layout.getselectedCount]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter" && btnRef && btnRef.current !== null) {
        btnRef.current.click();
        setTableAlert(false);
        setFlag(true);
      }
    };
    if (flag === true) {
      setFlag(false);
    }
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [flag]);

  // Delete events
  const handleEventFieldsDelete = () => {
    const events: Array<Event> = list;

    let countSelected = 0;
    events.map((event) => {
      return Array.isArray(event["eventRecords"])
        ? event["eventRecords"].map((eventRecord: EventFields) => {
            if (
              eventRecord?.selected === true &&
              eventRecord?.status !== "published"
            ) {
              countSelected++;
            }
          })
        : [];
    });
    if (countSelected > 0) {
      layout.SetIsDeleteActive(true);
      setTableAlert(true);
      setTableAlertMsg(
        `Are you sure you want to delete ${countSelected} selected listing${
          countSelected > 1 ? "s" : ""
        }?`
      );

      setTableAlertConfirm(true);
    } else {
      setTableAlert(true);
      setTableAlertMsg("Select a row to Delete");
      setTableAlertConfirm(false);
    }
  };

  const confirmEventFieldsDelete = () => {
    // const tempEvents = (events: Array<Event>) => {
    //   return list.map((event: any) => {
    //     return {
    //       ...event,
    //       eventRecords: Array.isArray(event["eventRecords"])
    //         ? event["eventRecords"].filter(
    //             (eventRecord: EventFields) =>
    //               eventRecord?.selected === false ||
    //               eventRecord?.status === "published"
    //           )
    //         : [],
    //     };
    //   });
    // };

    // //need to check the all events must have the event records
    // // const reCheckTempEvents = (events: Array<Event>) => {
    // //   return events.filter((event) => {
    // //     return (
    // //       Array.isArray(event["eventRecords"]) &&
    // //       event["eventRecords"].length > 0
    // //     );
    // //   });
    // // };
    // const reCheckTempEvents = (events: Array<Event>) => {
    //   return events.filter((event: any) => {
    //     return event;
    //     // event?.["hideError"] &&
    //     // Array.isArray(event["eventRecords"]) &&
    //     // event["eventRecords"].length > 0
    //   });
    // };

    // const tempReCheckTempEvents = tempEvents([...events]);
    // layout.setAddAllEvents(false);

    // // ------------------------ GANDA CODE (PATCH) --------------------------------
    // // IF USER IS DELETING THE ALL EVENTS THEN RESET ALL
    // setList((current: any) => {
    //   let final: any = tempReCheckTempEvents
    //     ?.map((event: any, eIndex: any) => {
    //       if (eIndex === 0) {
    //         return {
    //           ...event,
    //           selected: true,
    //         };
    //       } else {
    //         return {
    //           ...event,
    //           selected: false,
    //         };
    //       }
    //     })
    //     .filter(
    //       (item: any) =>
    //         !(
    //           item.hasOwnProperty("hideError") &&
    //           item?.eventRecords?.length === 0
    //         )
    //     )
    //     ?.map((item: any) => {
    //       return {
    //         ...item,
    //         ...(item?.eventRecords?.length > 0 && { hideError: true }),
    //       };
    //     });
    //   if (_.size(final) === 1) {
    //     // console.log(final);
    //     if (final?.[0]?.hideError) {
    //       return final;
    //     } else {
    //       return [];
    //     }
    //     // return [];
    //   } else {
    //     let result: any;
    //     result =
    //       current &&
    //       current
    //         .map((currentItem: any, index: any) => {
    //           if (
    //             currentItem?.id === final[index]?.id &&
    //             currentItem?.eventRecords?.length > 0 &&
    //             final[index]?.eventRecords?.length === 0
    //           ) {
    //             // If the conditions are met, return an empty array
    //             return null;
    //           } else {
    //             // Otherwise, return the current item as is
    //             return currentItem;
    //           }
    //         })
    //         ?.filter((item: any) => item !== null);

    //     if (_.size(result) === 0) {
    //       return result;
    //     } else if (_.size(final) === 0) {
    //       return final;
    //     } else if (_.size(final) > _.size(result)) {
    //       return result;
    //     } else {
    //       return final;
    //     }
    //   }
    // });
    layout.setAddAllEvents(false);
    setList((prevList: any) => {
      return prevList
        ?.map((event: any) => {
          const updatedEventRecords = event?.eventRecords?.filter(
            (record: any) => !record?.selected
          );

          return {
            ...event,
            eventRecords: updatedEventRecords,
          };
        })
        ?.filter((event: any) => {
          return event?.isPerformer || event?.eventRecords?.length > 0;
        });
    });

    setTimeout(() => {
      setList((item: any) => {
        if (item.length === 0) {
          layout.setSearchFieldValue("");
          layout.setKeyboardField(initialKeyboardField);
          layout.setTriggered(false);
        } else {
          layout.setSearchFieldValue((current: any) => {
            return {
              ...item?.[0],
              eventRecords: [...initialKeyboardField?.eventRecords],
            };
          });

          layout.setKeyboardField((current: any) => {
            let visibility: any = visibilityMeterCalculationForKeyBoard(
              item?.[0],
              { ...current?.eventRecords?.[0], category: null, section: null }
            );

            return {
              ...item?.[0],
              // eventRecords: [...current?.eventRecords, visibility],
              eventRecords: current?.eventRecords?.map((record: any) => {
                return { ...record, category: null, section: null, visibility };
              }),
            };
          });
        }
        return item;
      });
    }, 200);
  };

  // Inside In-table clone
  const handleEventFieldsClone = () => {
    if (getCheckboxCount({}) > 0) {
      setList((currentEvents: any) => {
        return currentEvents.map((event: any) => {
          return {
            ...event,
            eventRecords: event["eventRecords"] && [
              ...event["eventRecords"]
                .filter((eventRecord: any) => eventRecord?.selected === true)
                .map((finalResult: any) => {
                  const isAllFilesUploaded =
                    finalResult?.ticketFiles &&
                    Object.keys(finalResult?.ticketFiles).length &&
                    Object.keys(
                      finalResult?.ticketFiles?.filter(
                        (obj: any) => JSON.stringify(obj) !== "{}"
                      )
                    ).length >=
                      (finalResult?.quantity
                        ? Number(finalResult?.quantity)
                        : 0) &&
                    Object.keys(
                      finalResult?.ticketFiles?.filter(
                        (obj: any) => JSON.stringify(obj) !== "{}"
                      )
                    ).length > 0
                      ? true
                      : false;
                  let ticketFiles: any = [];
                  for (let i = 0; i < finalResult?.quantity; i++) {
                    ticketFiles.push({});
                  }
                  let visibility: any = visibilityMeterCalculationForKeyBoard(
                    event,
                    { ...finalResult, ticketFiles }
                  );

                  return {
                    ...finalResult,
                    selected: false,
                    newRow: true,
                    card: generateUniqueId(),
                    ticketFiles: ticketFiles,
                    additionalFile: null,
                    template: null,
                    inputs: null,
                    templateKeys: null,
                    oldVisibility:
                      finalResult?.visibility === visibility
                        ? finalResult?.oldVisibility
                        : finalResult?.visibility,
                    visibility: visibility,
                    ticketsInHands: isAllFilesUploaded
                      ? false
                      : finalResult?.ticketsInHands,
                  };
                }),
              ..._.cloneDeep(
                event["eventRecords"].map((eventRecord: any, index: any) => {
                  return {
                    ...eventRecord,
                    selected: false,
                  };
                })
              ),
            ],
          };
        });
      });
    } else {
      setTableAlert(true);
      setTableAlertMsg("Select a row to clone");
      setTableAlertConfirm(false);
    }
  };

  // CLONE TO NEW TABLE
  const handleEventFieldsCloneToNew = () => {
    if (events) {
      let isEventId = events?.every((event: any) => event?.id);

      if (getCheckboxCount({}) > 0 && isEventId) {
        //do the clone
        let cloneEvent: any = [
          {
            id: "",
            selected: true,
            eventRecords: [],
          },
        ];

        events?.map((event: any) => {
          return [
            Array.isArray(event["eventRecords"])
              ? event["eventRecords"].map((eventRecord: EventFields) => {
                  if (eventRecord?.selected === true) {
                    cloneEvent[0]["eventRecords"].push({
                      ...eventRecord,
                      selected: false,
                      card: generateUniqueId(),
                      dateToShip: null,
                    });
                  }
                })
              : [],
          ];
        });

        // MAKE ALL SELECTED -> FALSE
        const updatedEvents = _.cloneDeep(events).map((event: any) => ({
          ...event,
          selected: false,
          eventRecords: event?.eventRecords?.map((record: any) => ({
            ...record,
            selected: false,
          })),
        }));

        layout.setSearchFieldValue("");
        layout.setAddAllEvents(false);
        // UPDATE THE STATE
        setList([..._.cloneDeep(cloneEvent), ...updatedEvents]);
        scrollToTop();
      } else {
        let isEventId = events?.every((event: any) => event?.id);

        setTableAlert(true);
        setTableAlertMsg(
          !isEventId ? "Select an event" : "Select a row to clone"
        );
        setTableAlertConfirm(false);
      }
    }
  };

  // SELECT ALL EVENTS
  const handleSelectAll = (data: any, deselect: boolean) => {
    setSelectAll(data.target.checked);
    const tempEvents = (events: Array<Event>) => {
      return events.map((event) => {
        return {
          ...event,
          eventRecords: Array.isArray(event["eventRecords"])
            ? event["eventRecords"].map((eventRecord: EventFields) => {
                //       if(eventRecord?.status !== 'published'){
                return {
                  ...eventRecord,
                  selected:
                    deselect === true
                      ? false
                      : data.target.checked
                      ? true
                      : false,
                };
              })
            : [],
        };
      });
    };
    setList(tempEvents([...events]));
  };

  // EDIT SELECTED EVENTS
  const handleEditEvent = (data: any, deselect: boolean) => {
    if (getCheckboxCount({ action: "edit" }) > 0 === false) {
      setTableAlert(true);
      setTableAlertMsg("Select at least one row to edit");
      setTableAlertConfirm(false);
    } else {
      layout.setIsTableEditable(_.cloneDeep(list));
      // setList((events: any) => {
      //   return events.map((event: any, eventKey: number) => {
      //     return {
      //       ...event,
      //       eventRecords: event["eventRecords"].map(
      //         (eventRecord: any, eventRecordKey: number) => {
      //           return {
      //             ...eventRecord,
      //             ppeIsOpen: false,
      //           };
      //         }
      //       ),
      //     };
      //   });
      // });
    }
  };

  const [tempIsPublish, TempIsPublish] = useState(false);

  /**
   *  Upload ETicket Fiels to AWS
   * */
  const uploadETickets = async (eticketFiles: any) => {
    return await Promise.all(
      eticketFiles.map(async (eticketFile: any) => {
        //CHECK ARLEADY UPLOADED OR NOT
        if (!eticketFile?.awsFile) {
          try {
            // const res = await s3Upload.uploadFile(eticketFile);
            const res = await uploadFileWithPreAssignedURL(
              eticketFile,
              REACT_APP_AWS_TICKET_FOLDER_NAME ?? ""
            );
            return {
              ...eticketFile,
              awsFile: res?.key,
            };
          } catch (exception) {
            /* handle the exception */
            // console.warn(exception);
            return eticketFile;
          }
        } else {
          return eticketFile;
        }
      })
    );
  };

  /**
   *  Get REQUEST JSON
   * */
  const getRequestJSONData = async () => {
    return new Promise(async (resolve) => {
      const inventoryData: any = [];

      //SET CARD ID TO EVENT RECORDS
      const events = list.map((event: any) => {
        return {
          ...event,
          eventRecords: event["eventRecords"].map((eventRecord: any) => {
            return {
              ...eventRecord,
              card: generateUniqueId(),
              errors: {},
            };
          }),
        };
      });

      await Promise.all(
        events.map(async (event: any, eIndex: number) => {
          await Promise.all(
            event["eventRecords"].map(
              async (eventRecord: any, rIndex: number) => {
                // console.log(eventRecord, "eventRecord");
                let ppeExchanges: any = [];
                const ticketTypeId = eventRecord?.ticketType?.id;
                let price_per_exchange = eventRecord?.ppe === true ? 1 : 0;
                var ticket_exchange_prices: any = [];
                if (price_per_exchange === 1) {
                  //if price per exchange true then converting data to format of backend side
                  ticket_exchange_prices = eventRecord?.ppePartners.map(
                    (partner: any) => {
                      return {
                        exchange_id: partner.exchange_id,
                        sell_price_currency: user_base_currency,
                        sell_price:
                          typeof partner?.value?.value === "string"
                            ? partner?.value?.value?.replace(",", "")
                            : partner?.value?.value,
                        reseller_id: partner?.id,
                        reseller_name: partner?.name,
                      };
                    }
                  );
                } else {
                  //if price per exchange false then making all data similar to proceedvalue
                  ticket_exchange_prices = eventRecord?.ppePartners.map(
                    (partner: any) => {
                      return {
                        exchange_id: partner.exchange_id,
                        sell_price_currency: user_base_currency,
                        sell_price:
                          typeof eventRecord?.proceedValue?.value?.value ===
                          "string"
                            ? eventRecord?.proceedValue?.value?.value?.replace(
                                ",",
                                ""
                              )
                            : eventRecord?.proceedValue?.value?.value,
                        reseller_id: partner?.id,
                        reseller_name: partner?.name,
                      };
                    }
                  );
                }

                const ticket_type =
                  ticketTypeId === "pdf" || ticketTypeId === "mobile-qr"
                    ? "eticket"
                    : ticketTypeId;

                const listing_quality = visiblityCountForPayload(
                  eventRecord?.visibility
                );
                const price_quality = priceCalculationWithNumber(
                  eventRecord?.proceedValue?.value,
                  eventRecord?.avg_price,
                  priceFactor
                );

                eventRecord?.ppeExchanges &&
                  Object.keys(eventRecord?.ppeExchanges).length > 0 &&
                  eventRecord?.ppeExchanges.map(
                    (ppeExchange: any, key: number) => {
                      if (ppeExchange) {
                        ppeExchanges.push({
                          exchange_id: key,
                          sell_price_currency: user_base_currency,
                          sell_price: Number(ppeExchange?.proceed_price),
                        });
                      }
                    }
                  );

                //upload ticketfiles to AWS
                let uploadedTickets: any = eventRecord?.ticketFiles?.filter(
                  (obj: any) => JSON.stringify(obj) !== "{}"
                );

                if (
                  isArray(uploadedTickets) &&
                  uploadedTickets.length > 0 &&
                  eventRecord?.ticketType?.id !== "mobile-link"
                ) {
                  uploadedTickets = await uploadETickets(
                    eventRecord?.ticketFiles
                  );
                  events[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                    uploadedTickets;
                }

                const mobile_links = _.map(
                  eventRecord?.ticketFiles,
                  "mobileLink"
                )?.filter((link) => link !== undefined);

                const uploadedTicketsUpdate = uploadedTickets?.filter(
                  (obj: any) => !obj?.mobileLink
                );

                let additional_File: any = [];
                let additional_file_html: any;
                if (eventRecord?.additionalFile) {
                  try {
                    const res = await uploadAdditionalFileAWS(
                      eventRecord?.additionalFile
                    );
                    additional_File.push({ file: res });
                  } catch (error) {
                    console.log(error);
                  }
                }
                if (
                  eventRecord?.template &&
                  eventRecord?.template?.value != 0
                ) {
                  additional_file_html = getDynamicHTML(
                    eventRecord?.template,
                    eventRecord?.inputs
                  );
                }

                inventoryData.push({
                  delivery_options: eventRecord?.deliveryType
                    .map((delivery: any) => {
                      return (
                        eventRecord?.[delivery?.id] !== "" && {
                          id: delivery?.id,
                          price: eventRecord?.[delivery?.id],
                        }
                      );
                    })
                    .filter((delivery: any) => delivery?.price),
                  name: event?.name,
                  card: eventRecord?.card,
                  event_reference_id: eventRecord?.card,
                  event_id: event?.id,
                  exchanges: _.map(eventRecord?.marketplaces, "exchange_id"),
                  ticket_type: ticket_type,
                  sub_ticket_type:
                    ticket_type === "eticket"
                      ? eventRecord?.ticketType?.id
                      : undefined,
                  // ticket_type: eventRecord?.ticketType?.id,
                  // sub_ticket_type:
                  //   eventRecord?.ticketType?.id === "eticket"
                  //     ? eventRecord?.sub_ticket_type?.id
                  //     : undefined,

                  sell_price_currency: user_base_currency,
                  sell_price: eventRecord?.proceedValue?.value
                    ? removeCommaFromNumber(eventRecord?.proceedValue?.value)
                    : "",
                  price_per_exchange: price_per_exchange,
                  ticket_exchange_prices: ticket_exchange_prices,
                  face_value_currency: user_base_currency,
                  face_value: removeCommaFromNumber(
                    eventRecord?.faceValue?.value
                  ),
                  quantity_available: eventRecord?.quantity,
                  quantity_display: eventRecord?.maxDisplayQuantity,
                  category:
                    event?.categories &&
                    Object.keys(event?.categories).length > 0
                      ? eventRecord?.category?.id
                      : undefined,
                  section:
                    eventRecord?.category?.sections &&
                    Object.keys(eventRecord?.category?.sections).length > 0
                      ? eventRecord?.section?.id
                      : undefined,
                  row: eventRecord?.row,
                  first_seat: eventRecord?.firstSeat,
                  split_type: eventRecord?.splitType?.id
                    ? eventRecord?.splitType?.id
                    : "",
                  split_quantity:
                    eventRecord?.splitType?.id &&
                    eventRecord?.splitType?.id === "MULTIPLES"
                      ? parseInt(eventRecord?.MULTIPLES)
                      : 0,
                  restrictions: _.map(eventRecord?.restrictions, "id"),
                  benefits: _.map(eventRecord?.benefits, "id"),
                  in_hand: eventRecord?.ticketsInHands ? "1" : "0",
                  date_to_ship: eventRecord?.dateToShip
                    ? moment(eventRecord?.dateToShip).format("DD.MM.YYYY")
                    : "",
                  ticketFiles: eventRecord?.ticketFiles,

                  ticket_files: _.map(uploadedTicketsUpdate, "awsFile"),
                  mobile_links: mobile_links,
                  listing_quality: listing_quality,
                  price_quality: price_quality,
                  ...(additional_File?.length > 0 && {
                    additional_file: additional_File,
                  }),
                  ...(additional_file_html && {
                    additional_file_html: additional_file_html,
                  }),
                });
                return inventoryData;
              }
            )
          );
        })
      );

      setList([...events]);
      resolve(inventoryData);
    });
  };

  const instantLocalValidation = useCallback(
    (event: any, eventRecord: any, isPublish: any) => {
      let fieldErrors: any = {};
      const CHECKQTYFILEUPLOAD =
        // isPublish
        //   ? eventRecord?.quantity &&
        //     Number(eventRecord?.quantity) !==
        //       eventRecord?.ticketFiles?.filter(
        //         (obj: any) => JSON.stringify(obj) !== "{}"
        //       )?.length
        isPublish
          ? null
          : eventRecord?.quantity &&
            eventRecord?.ticketFiles?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            )?.length > 0 &&
            Number(eventRecord?.quantity) !==
              eventRecord?.ticketFiles?.filter(
                (obj: any) => JSON.stringify(obj) !== "{}"
              )?.length;

      !eventRecord?.ticketType?.id &&
        (fieldErrors = {
          ...fieldErrors,
          ticket_type: "Ticket type field is required",
        });

      eventRecord?.ticketType?.id === "paper" &&
        eventRecord?.deliveryType?.length > 0 &&
        eventRecord?.deliveryType?.map((singleDelivery: any) => {
          (!eventRecord?.[singleDelivery?.id] ||
            Number(eventRecord?.[singleDelivery?.id]) <= 0) &&
            (fieldErrors = {
              ...fieldErrors,
              [singleDelivery?.id]:
                singleDelivery?.type + " field is required more than zero",
            });
        });

      (!eventRecord?.quantity || CHECKQTYFILEUPLOAD) &&
        (fieldErrors = {
          ...fieldErrors,
          quantity_available: CHECKQTYFILEUPLOAD
            ? TicketError
            : "Quantity field is required",
        });
      !eventRecord?.category?.id &&
        event?.categories &&
        Object.keys(event?.categories).length > 0 &&
        (fieldErrors = {
          ...fieldErrors,
          category: "Category value field is required",
        });

      !eventRecord?.faceValue?.value &&
        (fieldErrors = {
          ...fieldErrors,
          face_value: "Face value field is required",
        });

      //new more than zero
      eventRecord?.faceValue?.value &&
        Number(eventRecord?.faceValue?.value) <= 0 &&
        (fieldErrors = {
          ...fieldErrors,
          face_value: "Face value field is required more than zero",
        });

      !eventRecord?.proceedValue?.value &&
        !eventRecord?.ppe &&
        (fieldErrors = {
          ...fieldErrors,
          sell_price: "Proceed value field is required",
        });

      //new more than zero
      eventRecord?.proceedValue?.value &&
        Number(eventRecord?.proceedValue?.value) <= 0 &&
        (fieldErrors = {
          ...fieldErrors,
          sell_price: "Proceed value field is required more than zero",
        });

      // !eventRecord?.section?.id &&
      //   eventRecord?.category?.sections &&
      //   Object.keys(eventRecord?.category?.sections).length > 0 &&
      //   (fieldErrors = {
      //     ...fieldErrors,
      //     section: "Section value field is required",
      //   });
      !eventRecord?.splitType?.id &&
        (fieldErrors = {
          ...fieldErrors,
          splitType: "Split Type value field is required",
        });

      (!eventRecord?.MULTIPLES ||
        eventRecord?.MULTIPLES === 0 ||
        eventRecord?.MULTIPLES === "0") &&
        eventRecord?.splitType.id === "MULTIPLES" &&
        (fieldErrors = {
          ...fieldErrors,
          MULTIPLES: "Sell in Multiple Field is required",
        });

      !eventRecord?.sub_ticket_type?.id &&
        eventRecord?.ticketType?.id === "eticket" &&
        (fieldErrors = {
          ...fieldErrors,
          sub_ticket_type: "Sub Ticket type Field is required",
        });
      eventRecord?.dateToShip &&
        moment(event?.date).valueOf() <
          moment(
            moment(eventRecord?.dateToShip).format("DD-MMM-YYYY")
          ).valueOf() &&
        (fieldErrors = {
          ...fieldErrors,
          date_to_ship:
            "Date of ship should not be greater than the event date",
        });

      if (eventRecord?.ppe) {
        if (Array.isArray(eventRecord?.ppePartners)) {
          eventRecord?.ppePartners?.forEach((partner: any) => {
            if (
              partner?.name &&
              (partner?.value?.value === "0.00" ||
                partner?.value?.value === "0" ||
                partner?.value?.value === 0) &&
              !partner.is_hidden
            ) {
              fieldErrors["PPP"] = `PPP fields are required more than zero`;
              fieldErrors[
                partner?.name
              ] = `${partner?.name} value field required more than zero`;
            }
          });
        }
      }
      return fieldErrors;
    },
    []
  );

  /**
   * SET LOCAL VALDATION EVENT RECORDS
   */
  const setLocalValidationToEventRecords = async (isPublish: any) => {
    let success = true;
    let fieldErrors: any;
    let firstTimeScroll = false;
    setList((events: any) => {
      return events.map((event: any, eventKey: number) => {
        return {
          ...event,
          eventRecords: event["eventRecords"].map(
            (eventRecord: any, eventRecordKey: number) => {
              fieldErrors = instantLocalValidation(
                event,
                eventRecord,
                isPublish
              );
              success = Object.keys(fieldErrors).length > 0 ? false : true;
              if (!success && !firstTimeScroll) {
                setInventoryLoader(true);
                //scroll to error field
                firstTimeScroll = true;
                const [firstKey] = Object.keys(fieldErrors);
                var inputField: any = document.getElementById(
                  firstKey + "-" + eventKey + "-" + eventRecordKey
                );
                const mainCollapseBtn: any = document.getElementById(
                  `collapseIcon-${event.id}`
                );
                if (
                  mainCollapseBtn &&
                  mainCollapseBtn.getAttribute("aria-expanded") === "false"
                ) {
                  mainCollapseBtn?.click();
                }
                if (!inputField) {
                  layout.setVirtuosoRecordIndex({
                    virtuosoId: eventRecordKey,
                    eventId: event.id,
                  });
                }
                setTimeout(() => {
                  let inputFields: any = document.getElementById(
                    firstKey + "-" + eventKey + "-" + eventRecordKey
                  );
                  inputFields?.scrollIntoView({
                    behavior: "auto",
                    inline: "center",
                    block: `center`,
                  });
                  showAlert("Please fix the errors on the listings", true);
                  setInventoryLoader(false);
                }, 2000);
              }
              if (fieldErrors?.["quantity_available"] === TicketError) {
                showAlert(TicketError, true, 3000);
              }
              return {
                ...eventRecord,
                errors: fieldErrors,
              };
            }
          ),
        };
      });
    });
    // if (
    //   fieldErrors?.["quantity_available"] ===
    //   "Mismatch with no. of file upload and quantity"
    // ) {
    //   showAlert(
    //     "Listing quantity must match with the number of tickets uploaded to processd",
    //     true,
    //     3000
    //   );
    // }

    return success;
  };

  /**
   * SET VALDATION EVENT RECORDS
   */
  const setValidationToEventRecords = (errors: any) => {
    setList((events: any) => {
      return events.map((event: any) => {
        return {
          ...event,
          eventRecords: event["eventRecords"].map((eventRecord: any) => {
            let fieldErrors: any = errors?.[eventRecord?.card]
              ? errors?.[eventRecord?.card]
              : eventRecord?.errors;

            return {
              ...eventRecord,
              errors: fieldErrors,
              bulkLoader: false,
            };
          }),
        };
      });
    });
  };

  const handleAllLocalValidation = (isPublish: any) => {
    let status: boolean = true;
    events.map((event: any) => {
      event["eventRecords"].map((eventRecord: any) => {
        let fieldErrors: any = instantLocalValidation(
          event,
          eventRecord,
          isPublish
        );
        if (Object.keys(fieldErrors).length > 0) {
          status = false;
        }
      });
    });
    if (!status) {
      setLocalValidationToEventRecords(isPublish);
      return false;
    } else {
      return status;
    }
  };

  /**
   * Handle Bulk Add Inventory
   */
  const handleBulkAddInventory = async () => {
    try {
      const selectedEventsIDS = list?.map((obj: any) => {
        return {
          id: obj?.id,
        };
      });

      const { checkWeightage, level, filterEvenets } =
        await checkWeightageOfEvent(
          selectedEventsIDS,
          globalLayout,
          sellerData
        );

      if (!(await handleAllLocalValidation(true))) {
        return true;
      } else if (checkWeightage) {
        const message = `
            Seller Level [${level}] is not eligible to create listings for this ${
          filterEvenets?.length > 1 ? "events" : "event"
        }.
            <br/>
            <ul class="list-disc list-inside pt-2.5">
              ${filterEvenets
                ?.map(
                  (obj: any) =>
                    `<li class="w-[17.1875rem] truncate" title="${obj.name}">${obj.name}</li>`
                )
                .join("")}
            </ul>
          `;
        showAlert(message, 2);
        return 0;
      } else {
        setLocalValidationToEventRecords(true);
        setInventoryLoader(true);
        const inventoryData: any = await getRequestJSONData();
        const addInventoryFinalData = {
          publish: "0",
          listings: inventoryData,
        };

        setList((pre: any) => {
          return pre?.map((event: any) => {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map((record: any) => {
                return {
                  ...record,
                  bulkLoader: true,
                };
              }),
            };
          });
        });

        // setInventoryLoader(false);
        const inventoryAdd = await inventoryBlukAdd(addInventoryFinalData)
          .then((res) => {
            return res?.data;
          })
          .finally(() => {
            setInventoryLoader(false);
          });
        layout.setIsPublish(false);

        if (inventoryAdd?.data && Object.keys(inventoryAdd?.data).length > 0) {
          // ADD INVENTORY DONE
          ConfirmAddInventory(false);
          showAlert(inventoryAdd?.message, false);
          setList([]);
        } else {
          // ERROR DURING ADDING INVENTORY
          setValidationToEventRecords(inventoryAdd?.errors);
          showAlert(
            inventoryAdd?.errors?.title
              ? inventoryAdd?.errors?.title
              : Messages.solveErrorTry,
            true
          );
        }
      }
    } catch {
    } finally {
    }

    //FIRE LOCAL VALIDATION
  };

  function chunkArray(array: any[], size: number) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }

  /** HANDLE PUBLISH */
  const handlePublish = async () => {
    try {
      const selectedEventsIDS = list?.map((obj: any) => {
        return {
          id: obj?.id,
        };
      });

      const { checkWeightage, level, filterEvenets } =
        await checkWeightageOfEvent(
          selectedEventsIDS,
          globalLayout,
          sellerData
        );

      //fire local validation
      if (!(await handleAllLocalValidation(false))) {
        setPublishLoader(false);
        return true;
      } else if (checkWeightage) {
        const message = `
          Seller Level [${level}] is not eligible to create listings for this ${
          filterEvenets?.length > 1 ? "events" : "event"
        }.
          <br/>
          <ul class="list-disc list-inside pt-2.5">
            ${filterEvenets
              ?.map(
                (obj: any) =>
                  `<li class="w-[17.1875rem] truncate" title="${obj.name}">${obj.name}</li>`
              )
              .join("")}
          </ul>
        `;
        showAlert(message, 2);
        setPublishLoader(false);
        return 0;
      } else {
        setLocalValidationToEventRecords(false);
        const inventoryData: any = await getRequestJSONData();
        // const publishInventoryFinalData = {
        //   publish: "1",
        //   listings: inventoryData,
        // };

        // setList((pre: any) => {
        //   return pre?.map((event: any) => {
        //     return {
        //       ...event,
        //       eventRecords: event?.eventRecords?.map((record: any) => {
        //         return {
        //           ...record,
        //           bulkLoader: true,
        //         };
        //       }),
        //     };
        //   });
        // });

        // console.log(publishInventoryFinalData, "**************");

        // const inventoryAdd = await inventoryBlukAdd(publishInventoryFinalData)
        //   .then((res) => {
        //     return res?.data;
        //   })
        //   .finally(() => {
        //     setTimeout(() => {
        //       setList((pre: any) => {
        //         return pre?.map((event: any) => {
        //           return {
        //             ...event,
        //             eventRecords: event?.eventRecords?.map((record: any) => {
        //               if (record?.bulkLoader) {
        //                 return {
        //                   ...record,
        //                   bulkLoader: false,
        //                 };
        //               } else {
        //                 return record;
        //               }
        //             }),
        //           };
        //         });
        //       });
        //     }, 3000);
        //     setPublishLoader(false);
        //   });

        // if (inventoryAdd?.data && Object.keys(inventoryAdd?.data).length > 0) {
        //   // ADD INVENTORY DONE
        //   assignListingIdToEventRecords(inventoryAdd?.data);
        //   layout.setIsPublish(true);
        //   //REMOVE THE LOCAL STORAGE OF EVENTS
        //   localStorage.removeItem("events");
        // } else {
        //   // ERROR DURING ADDING INVENTORY
        //   setValidationToEventRecords(inventoryAdd?.errors);
        //   showAlert(Messages.solveErrorTry, true);
        // }

        // const chunks = chunkArray(inventoryData, 20);

        // setList((pre: any) => {
        //   return pre?.map((event: any) => {
        //     return {
        //       ...event,
        //       eventRecords: event?.eventRecords?.map((record: any) => {
        //         return {
        //           ...record,
        //           bulkLoader: true,
        //         };
        //       }),
        //     };
        //   });
        // });

        // // Function to handle each chunk
        // const processChunk = async (chunk: any[]) => {
        //   const publishInventoryFinalData = {
        //     publish: "1",
        //     listings: chunk,
        //   };

        //   return inventoryBlukAdd(publishInventoryFinalData)
        //     .then((res) => res?.data)
        //     .catch((err) => {
        //       console.error(err);
        //       return null;
        //     });
        // };

        // // Process all chunks in parallel
        // const results = await Promise.all(
        //   chunks.map((chunk) => processChunk(chunk))
        // );

        // // Handle the results
        // results.forEach((inventoryAdd) => {
        //   if (inventoryAdd?.data && Object.keys(inventoryAdd?.data).length > 0) {
        //     // ADD INVENTORY DONE
        //     assignListingIdToEventRecords(inventoryAdd?.data);
        //     layout.setIsPublish(true);
        //     // REMOVE THE LOCAL STORAGE OF EVENTS
        //     localStorage.removeItem("events");
        //   } else {
        //     // ERROR DURING ADDING INVENTORY
        //     setValidationToEventRecords(inventoryAdd?.errors);
        //     showAlert(Messages.solveErrorTry, true);
        //   }
        // });

        // // Reset the bulkLoader flags
        // setTimeout(() => {
        //   setList((pre: any) => {
        //     return pre?.map((event: any) => {
        //       return {
        //         ...event,
        //         eventRecords: event?.eventRecords?.map((record: any) => {
        //           if (record?.bulkLoader) {
        //             return {
        //               ...record,
        //               bulkLoader: false,
        //             };
        //           } else {
        //             return record;
        //           }
        //         }),
        //       };
        //     });
        //   });
        // }, 3000);

        // setPublishLoader(false);

        const chunks = chunkArray(inventoryData, 20);

        let errors = [];
        // console.log(list, "list")

        setList((pre: any) => {
          // console.log(pre, "pre list")
          return pre?.map((event: any) => {
            return {
              ...event,
              eventRecords: event?.eventRecords?.map((record: any) => {
                return {
                  ...record,
                  bulkLoader: true,
                };
              }),
            };
          });
        });

        // Function to handle each chunk
        const processChunk = async (chunk: any[]) => {
          const publishInventoryFinalData = {
            publish: "1",
            listings: chunk,
          };

          return inventoryBlukAdd(publishInventoryFinalData)
            .then((res) => res?.data)
            .catch((err) => {
              console.error(err);
              return null;
            });
        };

        // Process all chunks sequentially
        for (const chunk of chunks) {
          const inventoryAdd = await processChunk(chunk);

          // Handle the result for the current chunk
          if (
            inventoryAdd?.data &&
            Object.keys(inventoryAdd?.data).length > 0
          ) {
            // ADD INVENTORY DONE
            await assignListingIdToEventRecords(inventoryAdd?.data);
            layout.setIsPublish(true);
            // REMOVE THE LOCAL STORAGE OF EVENTS
            localStorage.removeItem("events");
          } else {
            // ERROR DURING ADDING INVENTORY
            setValidationToEventRecords(inventoryAdd?.errors);
            errors.push(inventoryAdd?.errors);
          }
        }

        // Reset the bulkLoader flags
        setTimeout(() => {
          setList((pre: any) => {
            return pre?.map((event: any) => {
              return {
                ...event,
                eventRecords: event?.eventRecords?.map((record: any) => {
                  if (record?.bulkLoader) {
                    return {
                      ...record,
                      bulkLoader: false,
                    };
                  } else {
                    return record;
                  }
                }),
              };
            });
          });
        }, 3000);

        if (errors?.length > 0) {
          showAlert(Messages.solveErrorTry, true);
        }

        setPublishLoader(false);
      }
    } catch {
    } finally {
    }
  };

  /**
   * Assign DB Listing IDS to Event Records
   */
  const assignListingIdToEventRecords = async (data: Array<any>) => {
    setList((events: any) => {
      return events.map((event: any) => {
        return {
          ...event,
          eventRecords: event["eventRecords"].map((eventRecord: any) => {
            return {
              ...eventRecord,
              ...(!eventRecord?.listingId && {
                listingId: data.find(
                  (object) => object?.card === eventRecord?.card.toString()
                )?.listing_id,
              }),
            };
          }),
        };
      });
    });
  };

  useEffect(() => {
    if (layout.isPublish === false) {
      TempIsPublish(false);
    }
  }, [layout.isPublish]);

  /**
   * SAVE EVENT TABLE DATA TO LOCAL STORAGE
   */
  const saveEventTableAsDraft = () => {
    setSaveLoading(true);
    // setSaveAsBtnText(true);
    localStorage.setItem("events", JSON.stringify(list));

    setTimeout(() => {
      setSaveLoading(false);
      setSaveAsBtnText(true);
    }, 3000);
    setTimeout(() => {
      setSaveAsBtnText(false);
    }, 6000);
  };

  useEffect(() => {
    if (list) {
      const events: Array<Event> = list;

      let countSelected = 0;

      events.map((event) => {
        return Array.isArray(event["eventRecords"])
          ? event["eventRecords"].map((eventRecord: EventFields) => {
              if (eventRecord?.selected === true) {
                countSelected++;
              }
            })
          : [];
      });

      // REMOVE ALERT IF USER SELECT THE LISING
      if (countSelected === 0 && tableAlertConfirm) {
        setTableAlert(false);
        setTableAlertConfirm(false);
      }

      if (countSelected > 0 && tableAlertConfirm === false) {
        setTableAlert(false);
      }
      footer_dynamic();
      setListingCount(countSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;
    // Show the button when the user scrolls down 400px
    if (scrollPosition > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const hasUnpublishedEvents = events.some((event: any) =>
    event.eventRecords.some((record: any) => record.status !== "published")
  );
  return (
    <>
      {/* {tableAlert && (
        <TablePopup
          btnRef={btnRef}
          isConfirm={tableAlertConfirm}
          message={tableAlertMsg}
          cancelBtn={(data: any) => setTableAlert(data)}
          confirmBtn={() => {
            layout.setConfirmRowDelete(true);
            setTimeout(() => {
              confirmEventFieldsDelete();
              layout.setConfirmRowDelete(false);
            }, 500);
          }}
        />
      )} */}

      {publishLoader && layout.isPublish && (
        <ProgressBardLoader
          LoadingText={
            "We're processing your request, please do not close this window"
          }
          secondCounter={5}
        />
      )}
      <footer
        className={`page_footer sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white shadow-2xl shadow-black z-[1] min-h-[3.125rem] ${
          globalLayout?.asideMenuOpen && "menu_open"
        }`}
      >
        <button
          type="button"
          className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed bottom-[4.0625rem] right-0 transition duration-300 ${
            isVisible ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
          id="backtotop"
          onClick={scrollToTop}
        >
          <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
        </button>
        <div
          className={`flex overflow-auto whitespace-nowrap gap-2.5 ${
            tempIsPublish
              ? "justify-end"
              : confirmAddInventory
              ? "justify-end"
              : !tempIsPublish && !confirmAddInventory
              ? "justify-between"
              : null
          } w-full`}
        >
          <div
            className={`flex  items-center gap-2 my-2 ${
              layout.isPublish || tableAlert ? "w-full overflow-auto" : ""
            } ${confirmAddInventory && "w-full "} ${tableAlert && "w-full"} ${
              confirmAddInventory &&
              isTableEditable.length === 0 &&
              "justify-end"
            } `}
          >
            {/* SHOW ALERT - POPUP */}
            {tableAlert ? (
              <div className="w-full">
                <TablePopup
                  btnRef={btnRef}
                  isConfirm={tableAlertConfirm}
                  message={tableAlertMsg}
                  cancelBtn={(data: any) => {
                    layout.SetIsDeleteActive(false);

                    setTableAlert(data);
                  }}
                  confirmBtn={() => {
                    layout.setConfirmRowDelete(true);
                    setTimeout(() => {
                      confirmEventFieldsDelete();
                      layout.setConfirmRowDelete(false);
                    }, 1);
                  }}
                />
              </div>
            ) : (
              <>
                {isTableEditable.length > 0 || tempIsPublish ? (
                  <>
                    {/* ASK CONFIRM TO PUBLISH */}
                    {tempIsPublish &&
                    isTableEditable.length === 0 &&
                    !layout.isPublish ? (
                      <>
                        <span className="text-sm13 font-medium">
                          Are you sure you want to publish{" "}
                          {eventsLength > 1
                            ? `${eventsLength} events `
                            : `${eventsLength} event `}
                          with{" "}
                          {eventRecordLength > 1
                            ? `${eventRecordLength} listings?`
                            : `${eventRecordLength} listing?`}
                        </span>
                        <button
                          type="button"
                          className={`border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                    bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5
                    ${publishLoader && `disabled pointer-events-none`}`}
                          onClick={() => TempIsPublish(false)}
                        >
                          Cancel
                        </button>
                        <PublishButton
                          name="publish"
                          eventLength={!eventLength}
                          btnType="success"
                          handleOnClick={() => {
                            setPublishLoader(true);
                            setTimeout(() => {
                              handlePublish();
                            }, 500);
                          }}
                          isLoading={publishLoader}
                          isActive={true}
                        />
                      </>
                    ) : !tempIsPublish && !layout.isPublish ? (
                      // EDITING
                      <>
                        <button
                          type="button"
                          className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                    bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                          onClick={() => {
                            setList(layout.isTableEditable);
                            layout.setIsTableEditable([]);
                            layout.setTableDataEditChanging([]);
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="inline-flex items-center text-white bg-green-500 hover:bg-indigo-500 transition
                      font-medium rounded text-sm13 px-2.5 py-0.5"
                          onClick={() => {
                            layout.setIsTableEditable([]);
                            layout.setTableDataEditChanging([]);
                            setList((events: any) => {
                              return events?.map((event: any) => ({
                                ...event,
                                eventRecords: event?.eventRecords?.map(
                                  (record: any) => ({
                                    ...record,
                                    selected: false,
                                  })
                                ),
                              }));
                            });
                          }}
                        >
                          <KTSVG
                            className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-white"
                            path="other_icons/edit-square.svg"
                          />
                          Confirm
                        </button>

                        <span className="text-sm13 font-medium pl-3 leading-4">
                          Editing{" "}
                          {layout.countSelectedEdit?.events > 1 &&
                            `${layout.countSelectedEdit?.events} events with`}{" "}
                          {layout.countSelectedEdit?.rows} listing
                          {layout.countSelectedEdit?.rows > 1 && `s`}
                          <span className="pl-2 ">
                            {bulkTableChangedFields?.map(
                              (item: any, index: number) => {
                                let formattedString =
                                  item === "ppe"
                                    ? "Price per exchange"
                                    : _.capitalize(
                                        item.replace(/([a-z])([A-Z])/g, "$1 $2")
                                      );
                                return (
                                  <span
                                    className="text-xs text-gray-500 font-medium"
                                    key={index}
                                  >
                                    &nbsp;
                                    {index ===
                                      bulkTableChangedFields?.length - 1 &&
                                    formattedString
                                      ? formattedString
                                      : formattedString + ", "}
                                  </span>
                                );
                              }
                            )}
                          </span>
                        </span>
                      </>
                    ) : (
                      // PUBLISHED
                      <>
                        <div className="flex justify-between w-full overflow-auto gap-x-2.5">
                          {
                            // events.some((item: any) => item.hideError) ===
                            // true &&
                            hasUnpublishedEvents && !publishLoader && (
                              <div className="flex items-center gap-2 my-0.5 false">
                                {/* SELECT */}
                                <div className="inline-flex items-center text-violet-800transition font-medium rounded px-2.5 ">
                                  <input
                                    id="default-checkbox"
                                    type="checkbox"
                                    checked={selectAll}
                                    className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 ${
                                      !eventLength &&
                                      "pointer-events-none border-gray-300 hover:!border-gray-300 "
                                    }`}
                                    onChange={(data) =>
                                      handleSelectAll(data, false)
                                    }
                                  />
                                  <label
                                    htmlFor="default-checkbox"
                                    className={`ml-2 text-sm13 font-medium  dark:text-gray-300 ${
                                      !eventLength
                                        ? "opacity-50"
                                        : "text-gray-900"
                                    }`}
                                  >
                                    Select all
                                  </label>
                                </div>
                                {/* DE-select */}

                                <button
                                  type="button"
                                  className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                                    !eventLength &&
                                    "opacity-80 pointer-events-none text-gray-500"
                                  }`}
                                  onClick={(data) =>
                                    handleSelectAll(data, true)
                                  }
                                >
                                  <KTSVG
                                    className={`${
                                      !eventLength
                                        ? "fill-gray-500"
                                        : "fill-violet-500 "
                                    } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                                    path="other_icons/close_square.svg"
                                  />
                                  Deselect
                                </button>

                                {/* Delete */}

                                <button
                                  type="button"
                                  className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                                    !eventLength &&
                                    "opacity-80 pointer-events-none text-gray-500"
                                  }`}
                                  onClick={() =>
                                    flag === false && handleEventFieldsDelete()
                                  }
                                >
                                  <KTSVG
                                    className={`${
                                      !eventLength
                                        ? "fill-gray-500"
                                        : "fill-violet-500 "
                                    } min-w-[0.625rem] max-w-[0.63rem]  mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                                    path="other_icons/Delete.svg"
                                  />
                                  Delete
                                </button>
                              </div>
                            )
                          }
                          <div
                            className={`${
                              events.some((item: any) => item.hideError) ===
                              false
                                ? "flex flex-wrap gap-2  justify-end w-full"
                                : "flex flex-wrap gap-2 my-2"
                            }`}
                          >
                            <CollapseButton
                              eventLength={eventLength}
                              list={list}
                            />
                            {}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : isTableEditable.length > 0 || confirmAddInventory ? (
                  <>
                    {confirmAddInventory && isTableEditable.length === 0 ? (
                      <>
                        <span className="text-sm13 font-medium">
                          Are you sure you want to add{" "}
                          {eventsLength > 1
                            ? `${eventsLength} events `
                            : `${eventsLength} event `}
                          with{" "}
                          {eventRecordLength > 1
                            ? `${eventRecordLength} listings?`
                            : `${eventRecordLength} listing?`}
                        </span>
                        <button
                          type="button"
                          className={`border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
              bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                inventoryLoader && `pointer-events-none`
              }`}
                          onClick={() => ConfirmAddInventory(false)}
                        >
                          Cancel
                        </button>
                        <AddInventoryButton
                          disabled
                          eventLength={!eventLength}
                          handleOnClick={handleBulkAddInventory}
                          classNames={inventoryLoader && `pointer-events-none`}
                          isActive={true}
                        />
                      </>
                    ) : (
                      <>
                        <div className="inline-flex items-center text-violet-800transition font-medium rounded px-2.5 py-0.5">
                          <input
                            id="default-checkbox"
                            type="checkbox"
                            checked={selectAll}
                            className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 ${
                              !eventLength && "pointer-events-none"
                            }`}
                            onChange={(data) => handleSelectAll(data, false)}
                          />
                          <label
                            htmlFor="default-checkbox"
                            className={`ml-2 text-sm13 font-medium  dark:text-gray-300 ${
                              !eventLength ? "opacity-50" : "text-gray-900"
                            }`}
                          >
                            Select all
                          </label>
                        </div>
                        <button
                          type="button"
                          className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                            !eventLength &&
                            "opacity-80 pointer-events-none text-gray-500"
                          }`}
                          onClick={(data) => handleSelectAll(data, true)}
                        >
                          <KTSVG
                            className={`${
                              !eventLength
                                ? "fill-gray-500"
                                : "fill-violet-500 "
                            } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                            path="other_icons/close_square.svg"
                          />
                          Deselect
                        </button>
                        <button
                          type="button"
                          className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                            !eventLength &&
                            "opacity-80 pointer-events-none text-gray-500"
                          }`}
                          onClick={() => handleEventFieldsClone()}
                        >
                          <KTSVG
                            className={`${
                              !eventLength
                                ? "fill-gray-500"
                                : "fill-violet-500 "
                            } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                            path="other_icons/Clone.svg"
                          />
                          Clone
                        </button>
                        <button
                          type="button"
                          className={` inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 
                hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                  !eventLength && "opacity-80 pointer-events-none text-gray-500"
                }  `}
                          onClick={() => handleEventFieldsCloneToNew()}
                        >
                          <KTSVG
                            className={`${
                              !eventLength
                                ? "fill-gray-500"
                                : "fill-violet-500 "
                            } min-w-[0.625rem] max-w-[0.625rem] mr-2 flex items-center justify-center  group-hover:fill-white 
                  transition`}
                            path="standard_icons/calendar.svg"
                          />
                          Clone to new
                        </button>
                        <button
                          type="button"
                          className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                            !eventLength &&
                            "opacity-80 pointer-events-none text-gray-500 max-md:hidden"
                          }`}
                          onClick={(data: any) => handleEditEvent(data, true)}
                        >
                          <KTSVG
                            className={`${
                              !eventLength
                                ? "fill-gray-500"
                                : "fill-violet-500 "
                            } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                            path="other_icons/edit-square.svg"
                          />
                          Edit
                        </button>
                        <button
                          type="button"
                          className={`inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                            !eventLength &&
                            "opacity-80 pointer-events-none text-gray-500"
                          }`}
                          onClick={() =>
                            flag === false && handleEventFieldsDelete()
                          }
                        >
                          <KTSVG
                            className={`${
                              !eventLength
                                ? "fill-gray-500"
                                : "fill-violet-500 "
                            } min-w-[0.625rem] max-w-[0.63rem]  mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                            path="other_icons/Delete.svg"
                          />
                          Delete
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="inline-flex items-center text-violet-800transition font-medium rounded px-2.5 py-0.5 123">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={selectAll}
                        className={`w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 ${
                          (!eventLength || tableAlert) &&
                          "pointer-events-none border-gray-300 hover:!border-gray-300"
                        }`}
                        onChange={(data) => handleSelectAll(data, false)}
                      />
                      <label
                        htmlFor="default-checkbox"
                        className={`ml-2 text-sm13 font-medium  dark:text-gray-300 ${
                          !eventLength || tableAlert
                            ? "text-gray-400 pointer-events-none"
                            : "text-gray-900"
                        }`}
                      >
                        Select all
                      </label>
                    </div>
                    <button
                      type="button"
                      className={`inline-flex group items-center  hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                        !eventLength || tableAlert
                          ? "pointer-events-none text-gray-400"
                          : "text-violet-800"
                      }`}
                      onClick={(data) => handleSelectAll(data, true)}
                    >
                      <KTSVG
                        className={`${
                          !eventLength || tableAlert
                            ? "fill-gray-400"
                            : "fill-violet-500 "
                        } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                        path="other_icons/close_square.svg"
                      />
                      Deselect all
                    </button>
                    <button
                      type="button"
                      className={`inline-flex group items-center hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                        !eventLength || tableAlert
                          ? "pointer-events-none text-gray-400"
                          : "text-violet-800"
                      }`}
                      onClick={() => handleEventFieldsClone()}
                    >
                      <KTSVG
                        className={`${
                          !eventLength || tableAlert
                            ? "fill-gray-400"
                            : "fill-violet-500 "
                        } min-w-[.8125rem] max-w-[.8125rem] mr-2 flex items-center justify-center group-hover:fill-white transition`}
                        path="other_icons/Clone.svg"
                        svgClassName="w-[.7813rem] h-[.6563rem]"
                      />
                      Clone
                    </button>
                    <button
                      type="button"
                      className={`inline-flex group items-center hover:text-white bg-gray-100 
            hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
              !eventLength || tableAlert
                ? "pointer-events-none text-gray-400"
                : "text-violet-800"
            }`}
                      onClick={() => handleEventFieldsCloneToNew()}
                    >
                      <KTSVG
                        className={`${
                          !eventLength || tableAlert
                            ? "fill-gray-400"
                            : "fill-violet-500 "
                        } min-w-[.6875rem] max-w-[.6875rem] mr-2 flex items-center justify-center  group-hover:fill-white 
              transition`}
                        path="standard_icons/calendar.svg"
                        svgClassName="w-[.6875rem] h-[.7813rem]"
                      />
                      Clone to new
                    </button>
                    <button
                      type="button"
                      className={`inline-flex group items-center hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                        !eventLength || tableAlert
                          ? "pointer-events-none text-gray-400"
                          : "text-violet-800"
                      } max-md:hidden`}
                      onClick={(data: any) => handleEditEvent(data, true)}
                    >
                      <KTSVG
                        className={`${
                          !eventLength || tableAlert
                            ? "fill-gray-400"
                            : "fill-violet-500 "
                        } min-w-[.75rem] max-w-[.75rem] mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                        path="other_icons/edit-square.svg"
                        svgClassName="w-3 h-3"
                      />
                      Edit
                    </button>
                    <button
                      type="button"
                      className={`inline-flex group items-center hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                        !eventLength || tableAlert
                          ? "pointer-events-none text-gray-400"
                          : "text-violet-800"
                      }`}
                      onClick={() =>
                        flag === false && handleEventFieldsDelete()
                      }
                    >
                      <KTSVG
                        className={`${
                          !eventLength || tableAlert
                            ? "fill-gray-400"
                            : "fill-violet-500 "
                        } min-w-[0.625rem] max-w-[0.63rem]  mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                        path="other_icons/Delete.svg"
                        svgClassName="w-2.5 h-[.7813rem]"
                      />
                      Delete
                    </button>
                    {listingCount > 0 && (
                      <div>
                        <p className="font-medium text-sm13 ml-2.5 flex items-center">
                          <IKTSVG
                            svgClassName="w-3 h-2.5 mr-2"
                            path={ListingLinesSVG}
                          ></IKTSVG>
                          {addCommaInNumber(listingCount)} selected in{" "}
                          {_.size(eventCountSelected) === 1
                            ? `${addCommaInNumber(_.size(eventCountSelected))} event`
                            : `${addCommaInNumber(_.size(eventCountSelected))} events`}
                        </p>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          {!tableAlert &&
            isTableEditable.length === 0 &&
            tempIsPublish === false &&
            confirmAddInventory === false && (
              <div className="flex gap-2 my-2">
                {/* <CollapseButton
                  eventLength={!eventLength || tableAlert}
                  list={list}
                /> */}
                {/* SAVE AS DRAFT */}
                <button
                  type="button"
                  className={`${saveLoading && "pointer-events-none"} ${
                    saveAsBtnText &&
                    "bg-green-500 pointer-events-none text-white"
                  } inline-flex group items-center hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
                    !eventLength || tableAlert
                      ? "pointer-events-none text-gray-400"
                      : "text-violet-800"
                  }`}
                  onClick={() => saveEventTableAsDraft()}
                >
                  {saveLoading ? (
                    <KTSVG
                      className="flex items-center justify-center fill-violet-500 transition animate-spin"
                      path="other_icons/process.svg"
                    />
                  ) : (
                    <KTSVG
                      className={`${
                        saveAsBtnText
                          ? "fill-white"
                          : !eventLength || tableAlert
                          ? "fill-gray-400"
                          : "fill-violet-500 "
                      } min-w-[.75rem] max-w-[.75rem]  mr-2 flex items-center justify-center  group-hover:fill-white transition`}
                      path="other_icons/save.svg"
                      svgClassName="w-3 h-3"
                    />
                  )}

                  {saveLoading
                    ? "Saving"
                    : saveAsBtnText
                    ? "Saved"
                    : "Save draft"}
                </button>

                <AddInventoryButton
                  eventLength={!eventLength || tableAlert}
                  handleOnClick={(data: any) => ConfirmAddInventory(data)}
                  classNames={inventoryLoader && `pointer-events-none`}
                />
                <PublishButton
                  name="publish"
                  btnType="success"
                  eventLength={!eventLength || tableAlert}
                  handleOnClick={(data: any) => TempIsPublish(data)}
                  isLoading={publishLoader}
                />
              </div>
            )}

          {/* <div className="flex flex-wrap gap-2 my-2">
          <button
            type="button"
            className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
          >
            <KTSVG
              className="fill-violet-500 group-hover:fill-white transition"
              path="standard_icons/cross.svg"
            />
          </button>
        </div> */}
        </div>
        {inventoryLoader && <ProgressBardLoader secondCounter={5} />}
      </footer>
    </>
  );
});

export default Footer;
