import React, { useEffect } from "react";

type ListingShimmerProps = {
  id?: string;
  height?: string;
  NoOfShimmerRow?: number;
  paginateData: {
    current_page?: number;
    last_page?: number;
  };
};

const ListingShimmer: React.FC<ListingShimmerProps> = ({
  id = "custom-loader",
  height = "30px",
  NoOfShimmerRow = 2,
  paginateData,
}) => {
  // TOGGLE SHIMMER EFFECT
  useEffect(() => {
    const parentDiv = document.getElementById(id);
    if (!parentDiv) return;

    const { current_page, last_page } = paginateData;
    const isLastPage = current_page === last_page && current_page !== undefined;

    parentDiv.classList.toggle("hidden", isLastPage);
    parentDiv.classList.toggle("visible", !isLastPage);
  }, [paginateData, id]);

  return (
    <div id={id} className={`accordion bg-white font-medium pt-2.5 border-t `}>
      {[...Array(NoOfShimmerRow)].map((_, index) => (
        <div
          key={index}
          className="accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect"
          style={{ height: height }}
        ></div>
      ))}
    </div>
  );
};

export default ListingShimmer;
