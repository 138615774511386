import { LayoutContext } from "components/core/LayoutProvider";
import { IKTSVG } from "components/ui/IKTSVG";
import { useContext, useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import NavigationButtons from "../component/NavigationButtons";
import { display } from "../core/_functions";
import { SalesContext } from "../core/SalesProvider";
import PopUpShimmer from "./PopUpShimmer";

import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { QUERIES } from "helpers/const";
import {
  decimalNumber,
  getCurrencySymbol,
  ticketTypeRenameForSalesReport,
} from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import moment from "moment";
import { orderAction } from "pages/dashboard/core/requests";
import { useQuery } from "react-query";
import { default as crossArrow } from "../../../assets/media/icons/standard_icons/cross.svg";
import TeamOfferDetails from "../component/outToOfferDetails/TeamOfferDetails";
import { salesDetailsView } from "../core/_requests";

const LableInput = ({
  label,
  value,
  halfWidth = false,
  highlight = false,
}: any) => {
  return (
    <div
      className={`singleFOrmBLock ${
        halfWidth ? "md:w-1/4" : "md:w-1/2"
      } w-full px-[.4375rem] `}
    >
      <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-[.9375rem]">
        {label}
      </label>
      <div
        className={`!h-6 px-2.5 py-1 text-xs border-0 truncate ${
          highlight
            ? " outline-1 outline outline-indigo-500 bg-white"
            : "bg-gray-100/50"
        } rounded ${!value && "text-gray-400"}`}
        title={value ? value : "—"}
      >
        {value ? value : "—"}
      </div>
    </div>
  );
};

const OutToOfferDetails = ({
  show,
  tabValue,
  handleShow,
  handleOrder,
  canvasRef,
  showOrder,
  isGodAdmin,
  optionsData,
  setShowOrder = () => {},
  teamsOptions,
  refetchListing,
  index,
}: any) => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const [orderDetails, setOrderDetails] = useState<any>();
  const [actionLoader, setActionLoader] = useState(false);
  // const isLoading = false;
  const teams = coreLayout?.allowedAccess?.teams;

  const updateLoader = false;
  // const orderDetails = show?.data;
  let orders = layout.orders;

  const unLockedOrders = useMemo(
    () => layout.orders?.filter((order: any) => !order?.isLock),
    [layout.orders]
  );

  function findNextUnlockedIndex(startIndex: any, nav: any) {
    let currentIndex = startIndex;

    do {
      if (nav === "back") {
        currentIndex =
          currentIndex === 0 ? orders.length - 1 : currentIndex - 1;
      } else {
        currentIndex =
          currentIndex === orders.length - 1 ? 0 : currentIndex + 1;
      }

      if (currentIndex === startIndex) {
        break;
      }
    } while (orders[currentIndex].isLock);

    return currentIndex;
  }

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = show?.index;
    if (
      isGodAdmin &&
      (tabValue === "to_be_fulfilled" || tabValue === "out_to_offer")
    ) {
      newIndex = findNextUnlockedIndex(newIndex, nav);
    } else {
      if (nav === "back") {
        newIndex = newIndex === 0 ? orders.length - 1 : newIndex - 1;
      } else {
        newIndex = newIndex === orders.length - 1 ? 0 : newIndex + 1;
      }
    }

    const preRecord = orders[newIndex];

    handleShow({
      status: true,
      data: preRecord,
      index: newIndex,
    });
  };

  useEffect(() => {
    display(show?.status);
    // setOrderNotes(show?.data?.history);
  }, [show]);

  const {
    isLoading: popupLoading,
    isFetching,
    data: viewOrder,
    refetch: refetchDetails,
  } = useQuery(
    [QUERIES.SALES_OUT_TO_OFFER_DETAILS, show?.data?.order_id],
    () => salesDetailsView({ order_id: show?.data?.order_id, tabValue }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: show?.data?.order_id ? true : false,
      onSettled(data, error) {
        // const orderData = data?.data?.orders?.[0];
        // const options = data?.data?.options;
        // const category = options?.categories?.find(
        //   (item: any) => item?.id === orderData?.category_id
        // );
        // let section;
        // if (category) {
        //   setSectionsOptions(category?.sections);
        //   section = category?.sections?.find(
        //     (section: any) => section.name === orderData?.seating_location
        //   );
        // }
        // console.log(category, section, "sdfews");
        // const ticket_details = {
        //   ticket_type:
        //     options?.ticket_types?.find(
        //       (ticket_type: any) => ticket_type?.id === orderData?.ticket_type
        //     ) || {},
        //   row: orderData?.items?.[0]?.row,
        //   first_seat: orderData?.items?.[0]?.seat,
        //   restrictions: orderData?.restrictions || [],
        //   benefits: orderData?.restrictions || [],
        //   ticket_price: orderData?.ticket_price,
        //   deliver_by_date: orderData?.deliver_by_date,
        //   sub_ticket_type: orderData?.sub_ticket_type,
        //   order_total:
        //     Number(orderData?.ticket_price) * orderData?.ticket_quantity,
        //   category: category,
        //   section: section,
        // };
        // console.log(orderData?.items?.[0]?.row, "orderData?.items?.[0]?.row");
        // setInitialTicketState((prev) => {
        //   return {
        //     ...prev,
        //     ...ticket_details,
        //   };
        // });
        // setOfferTicket((prev) => {
        //   return {
        //     ...prev,
        //     ...ticket_details,
        //   };
        // });
        // setOptions(options);

        setOrderDetails(data?.data?.[0]);
      },
    }
  );

  const handleAction = (
    status: boolean,
    team_offer_id: string,
    order_id: string
  ) => {
    setActionLoader(true);
    orderAction({
      status: status,
      team_offer_id: team_offer_id,
      order_id: order_id,
    })
      .then((res: any) => {
        if (res?.data?.status) {
          if (status) {
            layout?.setOrders((prev: any) => {
              return prev?.filter((item: any) => item?.order_id != order_id);
            });
            layout?.setOutToOfferViewData({
              status: false,
              data: "",
              index: "",
            });
            display(false);
          } else {
            layout?.setOrders((prev: any) => {
              return prev?.map((item: any) => {
                if (item?.order_id === order_id) {
                  return {
                    ...item,
                    offers_count: item?.offers_count - 1,
                  };
                } else {
                  return item;
                }
              });
            });
          }
          showAlert(res?.message, false);
          refetchDetails();
        } else {
          showAlert(res?.message, true);
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setActionLoader(false);
      });
  };

  return (
    <>
      <div
        data-te-modal-init
        className={`${
          layout?.outToOfferViewData?.status ? "show" : " hidden"
        } popupinput-style2 upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-1000 ease-in-out font-medium z-40 md:max-w-[43.125rem] w-full`}
        tabIndex={-1}
        id="singleTicket"
        aria-labelledby="singleTicketLabel"
      >
        <div className="  bg-white border-t w-full h-full ml-auto flex flex-col">
          <div className="flex justify-between pl-5 items-center border-b">
            <div>
              <span className="md:text-sm15 text-sm12 block font-semibold">
                {" "}
                {`${
                  // popupLoading || isFetching
                  //   ? ""
                  //   : orderDetails?.team_offer_details?.length
                  isFetching
                    ? show?.data?.offers_count
                    : orderDetails?.team_offer_details?.length
                } ${
                  orderDetails?.team_offer_details?.length > 1
                    ? "offers"
                    : "offer"
                }: ${show?.data?.order_id} `}
              </span>
            </div>
            <div className="flex flex-wrap">
              <button
                type="button"
                className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
                data-bs-dismiss="offcanvas"
                ref={canvasRef}
                aria-label="Close"
                id="close"
                onClick={() => {
                  handleShow({
                    show: false,
                    data: "",
                  });
                  display(false);
                }}
              >
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                  path={crossArrow}
                  svgClassName="w-2.5 h-2.5"
                />

                <Tooltip
                  anchorId={`close`}
                  content={`Close`}
                  place={"left"}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                />
              </button>
            </div>
          </div>

          {/* VIEW ORDER DETAIL */}
          {popupLoading || isFetching ? (
            <PopUpShimmer />
          ) : (
            <>
              {" "}
              <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <div className="singleDetail mb-5">
                  <div className="firstRow p-3.5 border mb-0 rounded rounded-b-none border-b-0">
                    <div className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                      Open order
                    </div>
                    <div className="formBlock flex flex-wrap -mx-[.4375rem] gap-y-[.9375rem]">
                      <LableInput
                        label={`Event description`}
                        value={orderDetails?.event_description}
                      />
                      <LableInput
                        label={`Venue`}
                        value={`${orderDetails?.address}`}
                      />
                      <LableInput
                        label={`Event date`}
                        value={`${moment(orderDetails?.event_date).format(
                          "ddd, DD MMM YYYY, HH:mm"
                        )}`}
                      />
                      <LableInput
                        label={`Seat details`}
                        value={`${orderDetails?.seating_location}${
                          orderDetails?.seating_location && orderDetails?.row
                            ? " - "
                            : ""
                        }${orderDetails?.row}${
                          orderDetails?.first_seat && orderDetails?.row
                            ? " - "
                            : ""
                        }${orderDetails?.first_seat}`}
                      />

                      <LableInput
                        label={`Ticket type`}
                        halfWidth={true}
                        value={ticketTypeRenameForSalesReport(
                          orderDetails?.ticket_type,
                          orderDetails?.sub_ticket_type
                        )}
                        // change for ticket_types array
                        // value={ticketTypeRenameForSalesReport(
                        //   orderDetails?.ticket_types?.[0]?.id,
                        //   orderDetails?.sub_ticket_type
                        // )}
                      />
                      <LableInput
                        label={`Quantity`}
                        halfWidth={true}
                        value={orderDetails?.ticket_quantity}
                      />
                      <LableInput
                        label={`Ticket price`}
                        value={`${getCurrencySymbol(
                          orderDetails?.ticket_currency
                        )}${decimalNumber(orderDetails?.ticket_price)}`}
                        halfWidth={true}
                      />
                      <LableInput
                        label={`Order total`}
                        value={`${getCurrencySymbol(
                          orderDetails?.ticket_currency
                        )}${decimalNumber(
                          Number(orderDetails?.ticket_price) *
                            Number(orderDetails?.ticket_quantity)
                        )}`}
                        halfWidth={true}
                      />
                    </div>
                  </div>
                  <div className="firstRow p-3.5 border mb-5 rounded rounded-t-none">
                    <div className="text-sm13 font-semibold leading-4 mb-[.9375rem]">
                      Benefits & restrictions
                    </div>
                    <div className="benifitList text-sm flex flex-wrap">
                      <div className="text-sm12 text-gray-500 leading-[.9375rem]">
                        N/A
                      </div>
                    </div>
                  </div>
                </div>
                {orderDetails?.team_offer_details?.map(
                  (item: any, index: any) => {
                    const team = teams?.find((t: any) => {
                      return t.id === item?.team_id;
                    });

                    return (
                      <TeamOfferDetails
                        teamOffer={item}
                        index={index}
                        orderDetails={orderDetails}
                        team={team}
                        handleAction={handleAction}
                        actionLoader={actionLoader}
                      />
                    );
                  }
                )}
              </div>{" "}
            </>
          )}

          {/* NAVIGATION BUTTONS */}
          <div
            className={`w-full flex items-center ${
              isGodAdmin ? "justify-between" : "justify-start"
            }  px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]`}
          >
            <div className="arrow_buttons flex items-center bg-gray-100 rounded ">
              {/* {/ BACK BUTTON /} */}
              <NavigationButtons
                id="back"
                imgPath={angleLeftSVG}
                className={
                  show?.index === 0
                    ? "pointer-events-none"
                    : orders.length - 1 === show?.index
                }
                svgClassName={
                  show?.index === 0
                    ? "!fill-gray-400"
                    : orders.length - 1 === show?.index
                }
                handleNavigation={(data: any) => handleNavigation(data)}
                disabled={unLockedOrders?.length <= 1}
              />
              {/* {/ NEXT BUTTON /} */}
              <NavigationButtons
                id="next"
                imgPath={angleLeftSVG}
                className={
                  orders.length - 1 === show?.index ? "pointer-events-none" : ""
                }
                svgClassName={
                  orders.length - 1 === show?.index ? "!fill-gray-400" : ""
                }
                handleNavigation={(data: any) => handleNavigation(data)}
                disabled={unLockedOrders?.length <= 1}
              />
            </div>
          </div>
        </div>
      </div>
      {actionLoader && <ProgressBardLoader secondCounter={2} />}
    </>
  );
};

export default OutToOfferDetails;
