import { DeleteIcon } from "assets/media/icons/other_icons/DeleteIcon";
import DropDownField from "components/formComponent/DropDownField";
import { IKTSVG } from "components/ui/IKTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { isMobile, QUERIES } from "helpers/const";
import _, { size } from "lodash";
import {
  initialOptions,
  initialStateForHomeDesign,
} from "pages/customize/core/_.model";
import { getHomeDesign } from "pages/customize/core/_.request";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useQuery } from "react-query";
import AddSVG from "../../../../assets/media/icons/other_icons/add_icon.svg";
import AddGreySVG from "../../../../assets/media/icons/other_icons/gray-add.svg";
import Calendar from "../../../../assets/media/icons/standard_icons/calendar.svg";
import Category from "../../../../assets/media/icons/standard_icons/category.svg";
import Cross from "../../../../assets/media/icons/standard_icons/cross.svg";
import Venue from "../../../../assets/media/icons/standard_icons/geo_icon.svg";
import Performer from "../../../../assets/media/icons/standard_icons/performer.svg";
import AlgoliaAll from "../UI/AlgoliaAll";
export const DesignHomePage = () => {
  const { state, setState } = useCustomize();

  const { isLoading } = useQuery(
    [`${QUERIES.HOME_DESIGN_LIST}`],
    () => getHomeDesign(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data, error) {
        if (
          data?.data?.home_page_items &&
          size(data?.data?.home_page_items) > 0 &&
          data?.data?.status
        ) {
          let payload = data?.data?.home_page_items?.map((item: any) => {
            return {
              id: item?.id,
              types: initialOptions?.find(
                (options: any) => item?.type === options?.id
              ),
              type_components: item?.type_comp_ids?.map((component: any) => {
                return {
                  compoType: _.capitalize(component?.type),
                  compoId: component?.type_id,
                  compoName: component?.title,
                };
              }),
            };
          });
          setState(_.uniqBy(payload, "id"));
        }
      },
    }
  );

  // HANDLE ALGOLIA
  const handleHitSelect = (data: any) => {
    const { componentType } = data;

    data?.id &&
      setState((currentState: any) => {
        const indexToUpdate = componentType.index;

        const updatedState = currentState.map((item: any, idx: number) => {
          if (idx === indexToUpdate) {
            const isTypeComponentsEmpty =
              item.type_components.length === 1 &&
              !item.type_components[0].compoId;

            if (isTypeComponentsEmpty) {
              // Overwrite if both types and type_components are considered empty
              return {
                ...item,
                type_components: [
                  {
                    compoType: componentType?.name,
                    compoId: data.id,
                    compoName: data.name,
                  },
                ],
              };
            }

            // Otherwise, append to type_components array

            const newComponents = _.uniqBy(
              [
                ...item.type_components,
                {
                  compoType: componentType?.name,
                  compoId: data.id,
                  compoName: data.name,
                },
              ],
              "compoId"
            );

            return {
              ...item,
              type_components: newComponents,
            };
          }

          return item; // Keep other items unchanged
        });

        return updatedState;
      });
  };

  // HANDLE TYPE DROPDOWN
  const handleTypeChange = (data: any, index: number) => {
    const { name, value } = data.target;

    setState((currentState: any) => {
      const updatedState = currentState.map((item: any, idx: number) => {
        if (idx === index) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });

      return updatedState;
    });
  };

  let isAnyFieldEmpty = false;

  // ADD HEADER
  const handleAddHeader = () => {
    setState((currentState: any) => {
      return [...currentState, initialStateForHomeDesign];
    });
  };

  // REMOVE HEADER
  const handleRemoveHeader = (index: number) => {
    setState((current: any) => {
      return current?.map((item: any, filterIndex: number) => {
        if (index === filterIndex) {
          return {
            ...item,
            is_deleted: true,
          };
        } else return item;
      });
    });
  };

  // HANDLE REMOVE CHIPS
  const removeChip = (index: any, componentID: any) => {
    setState((current: any) => {
      return current?.map((currentItem: any, currentIndex: number) => {
        if (index === currentIndex) {
          return {
            ...currentItem,
            type_components: currentItem?.type_components?.filter(
              (components: any) => components?.compoId !== componentID
            ),
          };
        } else return currentItem;
      });
    });
  };

  // const renderComponents = (mainItem: any, index: number) => {
  //   return (
  //     <div className="w-full flex flex-wrap flex-1 items-center gap-2 overflow-y-hidden overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
  //       {mainItem?.type_components.map((item: any, itemIndex: any) => {
  //         if (item?.compoType) {
  //           return (
  //             <div
  //               key={itemIndex}
  //               className="group flex py-1 px-2 text-xs border rounded-full my-0.5 event-Type-comp max-w-full"
  //             >
  //               <IKTSVG
  //                 className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
  //                 path={`${
  //                   item?.compoType === "Venue"
  //                     ? Venue
  //                     : item?.compoType === "Event"
  //                     ? Calendar
  //                     : item?.compoType === "Category"
  //                     ? Category
  //                     : item?.compoType === "Performer"
  //                     ? Performer
  //                     : null
  //                 }`}
  //                 svgClassName="w-3.5 h-3.5 mr-1"
  //                 dataTooltipID={`menu-tooltip${index}${itemIndex}`}
  //               />
  //               <TooltipPortal
  //                 id={`menu-tooltip${index}${itemIndex}`}
  //                 content={`${
  //                   item?.compoType === "Venue"
  //                     ? "Venue"
  //                     : item?.compoType === "Event"
  //                     ? "Event"
  //                     : item?.compoType === "Category"
  //                     ? "Category"
  //                     : item?.compoType === "Performer"
  //                     ? "Performer"
  //                     : null
  //                 }`}
  //                 place={"top"}
  //                 variant="light"
  //                 className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
  //                   itemIndex === 0 ? "mt-0" : "mt-1.5"
  //                 }`}
  //               />
  //               <div
  //                 className="max-w-full whitespace-nowrap text-ellipsis overflow-hidden"
  //                 title={`${isMobile ? item?.compoName : ""}`}
  //               >
  //                 {item?.compoName}
  //               </div>
  //               <button
  //                 type="button"
  //                 className="ml-2"
  //                 onClick={() => removeChip(index, item?.compoId)}
  //                 id={`clear-${index}${itemIndex}`}
  //               >
  //                 <IKTSVG
  //                   path={Cross}
  //                   svgClassName="h-2 w-2 hover:fill-indigo-500"
  //                   dataTooltipID={`clear-${index}${itemIndex}`}
  //                 />
  //                 <TooltipPortal
  //                   id={`clear-${index}${itemIndex}`}
  //                   content={`Clear`}
  //                   place={"top"}
  //                   variant="light"
  //                   className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
  //                     itemIndex === 0 ? "mt-0" : "mt-1.5"
  //                   }`}
  //                 />
  //               </button>
  //             </div>
  //           );
  //         } else return null;
  //       })}
  //     </div>
  //   );
  // };

  const onDragEnd = (result: any, itemIndex: any) => {
    const { source, destination } = result;

    if (!destination) return;

    setState((prevState: any) => {
      const updatedState = [...prevState];

      // Retrieve the specific item being reordered
      const itemToReorder = { ...updatedState[itemIndex] };
      const components = Array.from(itemToReorder?.type_components);

      // Reorder the components
      const [movedComponent] = components.splice(source?.index, 1);
      components?.splice(destination?.index, 0, movedComponent);

      // Update the reordered components in the main item
      itemToReorder.type_components = components;
      updatedState[itemIndex] = itemToReorder;

      return updatedState;
    });
  };

  const renderComponents = (mainItem: any, index: any) => {
    // const onDragEnd = (result: any) => {
    //   if (!result.destination) return;

    //   const reorderedItems = Array.from(mainItem.type_components);
    //   const [removed] = reorderedItems.splice(result.source.index, 1);
    //   reorderedItems.splice(result.destination.index, 0, removed);

    //   // Update the mainItem with reordered items here, e.g.:
    //   // setMainItem({ ...mainItem, type_components: reorderedItems });
    // };

    return (
      <DragDropContext onDragEnd={(result) => onDragEnd(result, index)}>
        <Droppable droppableId={`droppable-${index}`} direction="horizontal">
          {(provided) => (
            <div
              className="w-full flex flex-wrap flex-1 items-center gap-2 overflow-y-hidden overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {mainItem?.type_components.map((item: any, itemIndex: any) => {
                if (item?.compoType) {
                  return (
                    <Draggable
                      key={itemIndex}
                      draggableId={`draggable-${index}-${itemIndex}`}
                      index={itemIndex}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="group flex py-1 px-2 text-xs border rounded-full my-0.5 event-Type-comp max-w-full"
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                            path={`${
                              item?.compoType === "Venue"
                                ? Venue
                                : item?.compoType === "Event"
                                ? Calendar
                                : item?.compoType === "Category"
                                ? Category
                                : item?.compoType === "Performer"
                                ? Performer
                                : null
                            }`}
                            svgClassName="w-3.5 h-3.5 mr-1"
                            dataTooltipID={`menu-tooltip${index}${itemIndex}`}
                          />
                          <TooltipPortal
                            id={`menu-tooltip${index}${itemIndex}`}
                            content={`${
                              item?.compoType === "Venue"
                                ? "Venue"
                                : item?.compoType === "Event"
                                ? "Event"
                                : item?.compoType === "Category"
                                ? "Category"
                                : item?.compoType === "Performer"
                                ? "Performer"
                                : null
                            }`}
                            place={"top"}
                            variant="light"
                            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                              itemIndex === 0 ? "mt-0" : "mt-1.5"
                            }`}
                          />
                          <div
                            className="max-w-full whitespace-nowrap text-ellipsis overflow-hidden"
                            title={`${isMobile ? item?.compoName : ""}`}
                          >
                            {item?.compoName}
                          </div>
                          <button
                            type="button"
                            className="ml-2"
                            onClick={() => removeChip(index, item?.compoId)}
                            id={`clear-${index}${itemIndex}`}
                          >
                            <IKTSVG
                              path={Cross}
                              svgClassName="h-2 w-2 hover:fill-indigo-500"
                              dataTooltipID={`clear-${index}${itemIndex}`}
                            />
                            <TooltipPortal
                              id={`clear-${index}${itemIndex}`}
                              content={`Clear`}
                              place={"top"}
                              variant="light"
                              className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                itemIndex === 0 ? "mt-0" : "mt-1.5"
                              }`}
                            />
                          </button>
                        </div>
                      )}
                    </Draggable>
                  );
                } else return null;
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return (
    <div className="inventory-list w-full h-full">
      <div className="tab_vontent opacity-100 transition-opacity duration-150 ease-linear block">
        <div className="flex flex-col">
          {state?.map((item: any, index: number) => {
            if (!item?.is_deleted) {
              return (
                <React.Fragment key={index}>
                  <div className="w-full flex flex-wrap gap-4 mb-5 p-2.5 md:p-5 border rounded">
                    {/* DELETE   */}
                    <button
                      type="button"
                      data-tooltip-id={`delete-${index}`}
                      className={`
                        ${
                          size(state) === 1
                            ? "pointer-events-none bg-gray-100 text-violet-800"
                            : ""
                        }
                        border group inline-flex group items-center font-medium rounded text-sm13 px-2.5 py-0.5 max-h-[1.875rem]`}
                      onClick={() => handleRemoveHeader(index)}
                    >
                      <TooltipPortal
                        id={`delete-${index}`}
                        content="Delete"
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium text-xs z-[9999]"
                      />

                      <div className="w-3 h-[.9375rem] flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer">
                        <DeleteIcon className="w-3 h-[.9375rem]" />
                      </div>
                    </button>
                    <div className="w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%]">
                      <div className={`rounded ${false && "shimmer-effect"}`}>
                        <DropDownField
                          options={initialOptions}
                          placeholder="Select type"
                          name="types"
                          value={item?.types}
                          handleOnChange={(data: any) =>
                            handleTypeChange(data, index)
                          }
                          menuPortalTarget={true}
                        />
                      </div>
                    </div>
                    <AlgoliaAll
                      item={item}
                      value={null}
                      isValuesNeeded={false}
                      hideCategoryButtons={item?.types?.hideCategoryButtons}
                      index={index}
                      handleHitSelect={handleHitSelect}
                    />
                    {/* FILTER CHIP */}
                    <div className="w-full flex flex-col flex-wrap gap-1">
                      {renderComponents(item, index)}
                    </div>
                  </div>
                </React.Fragment>
              );
            } else return null;
          })}
          <div className="flex gap-2">
            <button
              type="button"
              className={`
          ${
            isAnyFieldEmpty
              ? "text-gray-400 bg-gray-100"
              : "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
          }
            group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
              onClick={() => handleAddHeader()}
              disabled={isAnyFieldEmpty}
            >
              <IKTSVG
                className={`flex items-center justify-center  transition mr-2 ${
                  isAnyFieldEmpty ? "fill-black" : "fill-white"
                }`}
                path={isAnyFieldEmpty ? AddGreySVG : AddSVG}
                svgClassName="w-3.5 h-3"
              />
              Add
            </button>
          </div>
        </div>
      </div>
      {isLoading && <ProgressBardLoader secondCounter={0.5} />}
    </div>
  );
};
