/* eslint-disable array-callback-return */
import { getAuthDataFromStorage } from "helpers/AssetHelpers";
import _, { find, uniq } from "lodash";
import moment from "moment";
import {
  priceCalculationWithNumber,
  removeCommaFromNumber,
  resetPPEFields,
} from "../../../helpers/Functions";
import { INVENTORY_VALIDATE_COLUMN } from "../../../helpers/const";
import { bulkUpdateAction } from "./_request";

const getTikcetFiles = (ticketFiles: any) => {
  return ticketFiles.map((ticketFile: any) => {
    if (ticketFile?.awsFile) {
      return ticketFile?.awsFile;
    }
  });
};

function visiblityCountForPayload(visibility: any) {
  if (visibility === 0 || visibility === 1 || visibility === 2) {
    return 0;
  }
  if (visibility === 3) {
    return 1;
  }
  if (visibility === 4) {
    return 2;
  }
  if (visibility === 5) {
    return 3;
  }
  if (visibility === 6) {
    return 4;
  }
}

const setEventRecordPayload = (event: any, eventRecord: any) => {
  const ticketTypeId = eventRecord.ticket_type?.id;
  const ticket_type =
    ticketTypeId === "pdf" || ticketTypeId === "mobile-qr"
      ? "eticket"
      : ticketTypeId;

  const ticketType = ticketTypeId ? ticket_type : eventRecord.ticket_type;
  let listing_quality = eventRecord?.ticketUploaded
    ? 4
    : visiblityCountForPayload(eventRecord?.visibility);

  const price_quality = priceCalculationWithNumber(
    typeof eventRecord?.sell_price === "object"
      ? eventRecord?.sell_price?.value
      : eventRecord?.sell_price,
    eventRecord?.avg_price,
    eventRecord?.priceFactor
  );

  const mobile_links = _.map(eventRecord?.ticketFiles, "mobileLink")?.filter(
    (link) => link !== undefined
  );
  const uploadedTicketsUpdate = eventRecord?.ticketFiles?.filter(
    (obj: any) => !obj?.mobileLink
  );

  const updatedTicketExchangePrice = eventRecord?.ticket_exchange_prices?.map(
    (item: any) => {
      return {
        ...item,
        sell_price:
          typeof item?.sell_price === "string"
            ? item.sell_price.replace(",", "")
            : item?.sell_price,
      };
    }
  );

  return {
    delivery_options: eventRecord?.delivery_options
      ?.map((delivery: any) => {
        return { id: delivery?.id, price: eventRecord?.[delivery?.id] };
      })
      .filter((delivery: any) => delivery?.price),
    listing_id: eventRecord.id,
    name: event.name,
    event_id: event.id,
    exchanges: eventRecord?.exchanges[0]?.exchange_id
      ? eventRecord.exchanges.map((id: any) => id.exchange_id)
      : eventRecord.exchanges.filter(Number),

    ticket_type: ticketType,
    ...(ticketType === "eticket" && {
      sub_ticket_type: ticketTypeId
        ? ticketTypeId
        : eventRecord?.sub_ticket_type,
    }),

    sell_price_currency: eventRecord.sell_price_currency,
    sell_price: eventRecord.sell_price?.value
      ? removeCommaFromNumber(eventRecord.sell_price?.value)
      : removeCommaFromNumber(eventRecord.sell_price),
    price_per_exchange: eventRecord.price_per_exchange,
    ticket_exchange_prices: updatedTicketExchangePrice,
    face_value_currency: eventRecord.face_value_currency,
    face_value: eventRecord.face_value?.value
      ? removeCommaFromNumber(eventRecord.face_value?.value)
      : removeCommaFromNumber(eventRecord.face_value),
    quantity_available: eventRecord.quantity_available,
    quantity_display: eventRecord.quantity_display,
    category: eventRecord?.category?.id
      ? eventRecord?.category?.id
      : eventRecord?.category,
    section: eventRecord.section?.id
      ? eventRecord.section?.id
      : eventRecord.section,
    row: eventRecord.row,
    first_seat: eventRecord.first_seat,
    split_type: eventRecord.split_type?.id
      ? eventRecord.split_type?.id
      : eventRecord.split_type,
    split_quantity:
      (eventRecord?.split_type?.id &&
        eventRecord?.split_type?.id === "MULTIPLES") ||
      eventRecord.split_type === "MULTIPLES"
        ? parseInt(eventRecord?.split_quantity)
        : 0,
    date_to_ship: eventRecord.date_to_ship
      ? moment(eventRecord.date_to_ship).format("DD.MM.YYYY")
      : "",
    ...(eventRecord?.restrictions?.length <= 0
      ? { restrictions: [] }
      : {
          restrictions: eventRecord.restrictions[0]?.id
            ? eventRecord.restrictions.map((id: any) => id.id)
            : eventRecord.restrictions.filter(Number),
        }),
    ...(eventRecord?.benefits?.length <= 0
      ? { benefits: [] }
      : {
          benefits: eventRecord?.benefits[0]?.id
            ? eventRecord.benefits.map((id: any) => id.id)
            : eventRecord.benefits.filter(Number),
        }),
    status: eventRecord?.status,
    // ticket_files:  eventRecord?.ticketFiles
    //   ? getTikcetFiles(eventRecord?.ticketFiles)
    //   : undefined,
    ticket_files: _.map(uploadedTicketsUpdate, "awsFile"),
    in_hand: eventRecord?.ticketsInHands,
    listing_quality: listing_quality,
    price_quality: price_quality,
    mobile_links: mobile_links,
    deleteExistingFiles: eventRecord?.deleteExistingFiles,

    ...(eventRecord?.additional_file_html && {
      additional_file_html: eventRecord?.additional_file_html,
      is_additional_file_edited: true,
    }),
    ...(eventRecord?.additional_File?.length > 0 && {
      additional_file: eventRecord?.additional_File,
      is_additional_file_edited: true,
    }),
    ...(eventRecord?.is_additional_file_edited &&
      !eventRecord?.additional_file_html &&
      eventRecord?.additional_File?.length === 0 && {
        is_additional_file_edited: true,
        additional_file_html: null,
        additional_file: [],
      }),

    // ...(eventRecord?.additional_File?.length === 0 &&
    //   eventRecord?.is_additional_file_edited && {
    //     is_additional_file_edited: true,
    //     additional_file: [],
    //   }),
    // ...(!eventRecord?.additional_File &&
    //   !eventRecord?.is_additional_file_edited && {
    //     is_additional_file_edited: false,
    //   }),
    // ...(eventRecord?.additional_file_html && {
    //   is_additional_file_edited: true,
    // }),
    // ...(!eventRecord?.additional_file_html &&
    //   eventRecord?.is_additional_file_edited && {
    //     is_additional_file_edited: true,
    //     additional_file: [],
    //     additional_file_html: null,
    //   }),
    // ...(!eventRecord?.additional_file_html &&
    //   !eventRecord?.is_additional_file_edited && {
    //     is_additional_file_edited: false,
    //   }),
  };
};

const changedSelectedCount = (data: any) => {
  let changedSelectedCount = data?.reduce((count: any, event: any) => {
    const selectedCount = event?.eventRecords?.filter(
      (record: any) => record?.selected
    );
    return count + (selectedCount ? selectedCount.length : 0);
  }, 0);

  return changedSelectedCount;
};

const changedInputValues = (data: any) => {
  let changedDataCount = data?.reduce((count: any, event: any) => {
    const changedRecords = event?.eventRecords?.filter(
      (record: any) => record?.isInputChange
    );
    return count + (changedRecords ? changedRecords.length : 0);
  }, 0);

  return changedDataCount;
};
const bulkUploadCount = (data: any) => {
  let changedDataCount = data?.reduce((count: any, event: any) => {
    const changedRecords = event?.eventRecords?.filter(
      (record: any) => record?.bulkUpdate
    );
    return count + (changedRecords ? changedRecords.length : 0);
  }, 0);

  return changedDataCount;
};

const bulkPayloadCreate = (list: any, keyToFind: any) => {
  const result: any = [];
  list?.forEach((event: any) => {
    const eventName = event.name;
    const eventId = event.id;
    const eventCategory = event.categories;
    const isInputChange = event.eventRecords?.filter(
      (record: any) => record?.[`${keyToFind}`]
    );
    if (isInputChange && isInputChange.length > 0) {
      const recordsWithEventInfo = isInputChange.map((record: any) => {
        let final = {
          ...record,
          listing_id: record?.id,
          name: eventName,
          event_id: eventId,
          eventCategory: {
            categories: eventCategory,
          },

          // price_per_exchange: event?.price_per_exchange,
          ...((record?.price_per_exchange === 0 ||
            record?.price_per_exchange === false) && {
            ppePartners: resetPPEFields(
              record?.["ppePartners"],
              record?.sell_price,
              "ppePartners"
            ),
            ticket_exchange_prices: resetPPEFields(
              record?.["ppePartners"],
              record?.sell_price,
              "ticket_exchange_prices"
            ),
          }),
        };
        delete final.id;
        delete final.replacible;
        // delete final.visibility;
        delete final.user_name;
        delete final.oldVisibility;
        delete final.isLock;
        delete final.general_admission;
        return final;
      });
      result.push(...recordsWithEventInfo);
    }
  });

  return result;
};

async function bulkUpdate(batch: any) {
  return bulkUpdateAction(batch);
}

async function processInBatches(validPassedData: any, batchSize: any) {
  const length = validPassedData.length;
  const batchCount = Math.ceil(length / batchSize); // Calculate the number of batches
  const promises: any = [];

  for (let i = 0; i < batchCount; i++) {
    const start = i * batchSize;
    const end = (i + 1) * batchSize;
    const batch = validPassedData.slice(start, end);
    const promise = bulkUpdate(batch);
    promises.push(promise);
  }

  const results = await Promise.all(promises);
  const valuesArray = results.flatMap(
    (item: any) => item.data && item.data?.status && item.data?.payload
  );

  const ticketTypeChangeArray = results.flatMap(
    (item: any) => item.data?.status && item.data?.is_ticket_type_change
  );

  const mergedObject = valuesArray.reduce((acc, currentValue) => {
    return { ...acc, ...currentValue };
  }, {});

  const is_ticket_type_change = ticketTypeChangeArray.reduce(
    (acc, currentValue) => {
      return { ...acc, ...currentValue };
    },
    {}
  );

  return {
    mergedObject: mergedObject,
    is_ticket_type_change: is_ticket_type_change,
  };
}

const spellCorrect = (data: any) => {
  return data.map((value: any) => {
    switch (value) {
      case "quantity_available":
        return "Quantity";
      case "sell_price":
        return "Proceed price";
      case "face_value":
        return "Face value";
      case "category":
        return "Category";
      case "PPP":
        return "PPP";
      default:
        return value;
    }
  });
};

const getFieldErrorColumns = (data: any, allDeliveryTypes: any) => {
  // let errorsArrays = data?.errors?.map((item: any) => item.errors).flat();
  let errorsArrays = Object.keys(data.fieldErrors);
  let deliveryIds = allDeliveryTypes?.map((item: any) => item.id);
  // Finding matched values using ES6 features
  let matchedValues: any = errorsArrays.reduce(
    (acc: any, errorMessage: any) => {
      let matchingColumns = [
        ...INVENTORY_VALIDATE_COLUMN,
        ...deliveryIds,
        ...data.ppePartners?.map((partner: any) => partner.name),
      ].filter((columnValue: any) => errorMessage.includes(columnValue));
      let columnNames = matchingColumns.map((column: any) => {
        if (deliveryIds?.includes(column)) {
          return allDeliveryTypes.find((item: any) => item.id === column)?.type;
        } else {
          return column;
        }
      });
      return [...acc, ...columnNames];
    },
    []
  );

  matchedValues = spellCorrect(matchedValues);

  // Removing duplicates from matchedValues using Set
  matchedValues = [...new Set(matchedValues)];
  return matchedValues;
};

function getKeysWithErrors(list: any[]): string[] {
  const keysWithErrors: string[] = [];

  (list || []).forEach((event) => {
    (event.eventRecords || []).forEach((record: any) => {
      const fieldArry = record?.fieldErrors && Object.keys(record?.fieldErrors);

      if (fieldArry && record?.selected) {
        keysWithErrors.push(...fieldArry);
      }
    });
  });

  return uniq(keysWithErrors);
}

function checkIsFullfillUploadExist(list: any) {
  const selectedEventRecords = list
    ?.flatMap((item: any) =>
      item?.eventRecords?.filter((record: any) => record?.selected)
    )
    .filter((obj: any) => obj !== undefined);

  const flag =
    selectedEventRecords?.length > 0
      ? selectedEventRecords?.filter(
          (obj: any) =>
            (obj?.listing_ticket_files?.length > 0 ||
              obj?.mobile_links?.length > 0) &&
            Number(obj?.quantity_available) !==
              Number(
                obj?.listing_ticket_files?.length || obj?.mobile_links?.length
              )
        )
      : [];

  return flag || "";
}

function checkIsFullfillUploadExist2(list: any) {
  const selectedEventRecords = list
    ?.flatMap((item: any) =>
      item?.eventRecords?.filter((record: any) => record?.selected)
    )
    .filter((obj: any) => obj !== undefined);

  const flag = selectedEventRecords?.filter(
    (obj: any) =>
      Number(obj?.quantity_available) ===
        Number(
          obj?.listing_ticket_files?.length || obj?.mobile_links?.length
        ) && obj.status === "yes"
  );

  return flag || "";
}

const checkDifferentTeamIdAcrossEvents = (list: any) => {
  // Create a set to store selected team_ids
  const selectedTeamIds = new Set();

  // Iterate through each event
  list.forEach((event: any) => {
    // Filter records where selected is true
    const selectedRecords =
      event?.eventRecords?.filter((record: any) => record.selected) || [];

    // Add selected team_ids to the set
    selectedRecords.forEach((record: any) => {
      selectedTeamIds.add(record.team_id);
    });
  });

  // Check if there are different team_id values
  const hasDifferentTeamIds = selectedTeamIds.size > 1;

  return hasDifferentTeamIds;
};

function checkValidation(
  actionTitle: any,
  list: any,
  isGodAdmin: any,
  deliveryTypes: any
) {
  let message = "";
  const isQtyNotAvailable = checkIsFullfillUploadExist(list);
  const isFullWithPublish = checkIsFullfillUploadExist2(list);
  const isSelectedEvent = checkDifferentTeamIdAcrossEvents(list);

  let arr: any = getKeysWithErrors(list);

  //replace ids for paper fields
  arr = arr?.map((item: any) => {
    let delivery: any = find(deliveryTypes, { id: item });
    if (delivery?.id) {
      return delivery?.type;
    } else {
      return item;
    }
  });
  switch (actionTitle) {
    case "publish":
      if (isQtyNotAvailable?.length > 0) {
        message =
          "Listing quantity must match with the number of tickets uploaded to proceed";
      } else if (_.size(arr) > 0) {
        message = `There are some errors in ${spellCorrect(arr)?.join(", ")} ${
          _.size(arr) > 1 ? " fields" : " field"
        }`;
      }
      break;

    case "unpublish":
      if (_.size(arr) > 0) {
        message = `There are some errors in ${spellCorrect(arr)?.join(", ")} ${
          _.size(arr) > 1 ? " fields" : " field"
        }`;
      }
      break;

    case "Update pricing":
      if (_.size(arr) > 0) {
        message = `There are some errors in ${spellCorrect(arr)?.join(", ")} ${
          _.size(arr) > 1 ? " fields" : " field"
        }`;
      }
      break;

    case "Transfer ownership":
      if (isSelectedEvent && isGodAdmin) {
        message = "Please select listings from the same team only";
      } else if (_.size(arr) > 0) {
        message = `There are some errors in ${spellCorrect(arr)?.join(", ")} ${
          _.size(arr) > 1 ? " fields" : " field"
        }`;
      }
      break;

    default:
    // Handle other cases if needed
  }

  return message;
}

function getCategoryValue(item: any, event: any) {
  const category = item?.category?.id
    ? item?.category
    : event?.categories &&
      event?.categories.find(
        (category: any) => Number(category.id) === Number(item?.category)
      );
  return category;
}

function getSectionValue(eventRecord: any, event: any) {
  const sectionValue =
    eventRecord?.section && eventRecord?.section?.id
      ? eventRecord?.section
      : eventRecord?.section &&
        event?.categories &&
        Array.isArray(event?.categories) &&
        event?.categories
          .find(
            (category: any) =>
              Number(category.id) === Number(eventRecord?.category)
          )
          ?.sections?.find(
            (item: any) => Number(item?.id) === Number(eventRecord?.section)
          );

  return sectionValue;
}

function getTicketTypeValue(eventRecord: any, ticketTypeArray: any) {
  // let ticketTypeValue =
  //   typeof eventRecord?.ticket_type === "object"
  //     ? eventRecord?.ticket_type === "eticket" &&
  //       eventRecord?.sub_ticket_type === "pdf"
  //       ? {
  //           id: "pdf",
  //           name: "PDF E-Ticket",
  //         }
  //       : eventRecord?.ticket_type === "eticket" &&
  //         eventRecord?.sub_ticket_type === "mobile-qr"
  //       ? {
  //           id: "mobile-qr",
  //           name: "Mobile QR",
  //         }
  //       : eventRecord?.ticket_type
  //     : Array.isArray(ticketTypeArray) &&
  //       ticketTypeArray?.find((ticket: any) =>
  //         eventRecord?.ticket_type === "eticket"
  //           ? ticket?.id === eventRecord?.sub_ticket_type
  //           : ticket?.id === eventRecord?.ticket_type
  //       );
  // return ticketTypeValue;
  let ticketTypeValue: any;

  if (typeof eventRecord?.ticket_type === "object") {
    if (
      eventRecord?.ticket_type === "eticket" &&
      eventRecord?.sub_ticket_type === "pdf"
    ) {
      ticketTypeValue = {
        id: "pdf",
        name: "PDF E-Ticket",
      };
    } else if (
      eventRecord?.ticket_type === "eticket" &&
      eventRecord?.sub_ticket_type === "mobile-qr"
    ) {
      ticketTypeValue = {
        id: "mobile-qr",
        name: "Mobile QR",
      };
    } else {
      ticketTypeValue = eventRecord?.ticket_type;
    }
  } else if (Array.isArray(ticketTypeArray)) {
    let subTicket = !eventRecord?.sub_ticket_type?.trim()
      ? "pdf"
      : eventRecord?.sub_ticket_type;
    const foundTicket = ticketTypeArray?.find((ticket: any) => {
      if (eventRecord?.ticket_type === "eticket") {
        return ticket?.id === subTicket;
      } else {
        return ticket?.id === eventRecord?.ticket_type;
      }
    });

    ticketTypeValue = foundTicket;
  }
  return ticketTypeValue;
}

function visibilityMeterCalculationForInventory(
  event: any,
  item: any,
  ticketTypeArray: any
) {
  // let final = event?.eventRecords?.map((item: any) => {
  const category = getCategoryValue(item, event);
  const section = getSectionValue(item, event);
  const ticketType = getTicketTypeValue(item, ticketTypeArray);

  const processValue =
    typeof item?.sell_price === "object"
      ? item?.sell_price?.value
      : item?.sell_price;
  const faceValue =
    typeof item?.face_value === "object"
      ? item?.face_value?.value
      : item?.face_value;

  const ticketInHand = item?.isInputChange
    ? item?.ticketsInHands
    : item?.in_hand === "yes";

  const calculateVisibility = () => {
    let visibility = 0;

    // const isStep1Filled =
    //   event &&
    //   _.size(ticketType) > 0 &&
    //   item?.quantity_available &&
    //   faceValue !== "" &&
    //   faceValue !== "0.00" &&
    //   processValue !== "" &&
    //   processValue !== "0.00" &&
    //   _.size(category) > 0;

    const isStep1Filled =
      (_.size(ticketType) > 0 ? 1 : 0) +
        (item?.quantity_available ? 1 : 0) +
        (_.size(item?.faceValue) > 0 && faceValue !== "" && faceValue !== "0.00"
          ? 1
          : 0) +
        (_.size(item?.proceedValue) > 0 &&
        processValue !== "" &&
        processValue !== "0.00"
          ? 1
          : 0) >=
      2;

    const isCategoriesEmpty =
      event &&
      _.size(ticketType) > 0 &&
      item?.quantity_available &&
      faceValue !== "" &&
      faceValue !== "0.00" &&
      processValue !== "" &&
      processValue !== "0.00" &&
      event?.categories?.length === 0;

    const isStep2Filled =
      _.size(category) > 0 &&
      _.size(section) > 0 &&
      category?.name === section?.name;

    const isStep2Inner =
      _.size(category) > 0 &&
      _.size(category) > 0 &&
      category?.sections?.length === 1;

    const isStep3Filled =
      _.size(category) > 0 &&
      _.size(section) > 0 &&
      section?.name &&
      category?.name !== section?.name;

    // const isStep4Filled =
    //   item?.row || (item?.row && item?.firstSeat) || ticketInHand;

    const isStep4Filled = ticketInHand;

    const isStep5Filled =
      ticketType?.id !== "" &&
      (ticketType?.id === "mobile-qr" ||
      ticketType?.id === "pdf" ||
      ticketType?.id === "eticket"
        ? item?.quantity_available &&
          (item?.ticketFiles?.filter((obj: any) => JSON.stringify(obj) !== "{}")
            ?.length > 0 ||
            item?.listing_ticket_files?.length > 0 ||
            item?.mobile_links?.length > 0) &&
          Number(item?.quantity_available) ===
            (item?.ticketFiles?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            )?.length ||
              item?.listing_ticket_files?.length ||
              item?.mobile_links?.length)
        : "1");

    // FOR INTIAL LOW FILL SOME PERCENT WHEN VISIBILITY IS 1
    if (event) {
      visibility += 1;
    }
    //FOR LOW FILL AND LEVEL 1 AND LEVEL 2 COMPLETE WHEN VISIBILITY IS 5 AND CATEGORY AND SECTION EMPTY
    if (isCategoriesEmpty && isStep4Filled) {
      visibility += 4;
    }

    // FOR LOW FILL COMPLETE WHEN VISIBILITY IS 2
    if (isStep1Filled) {
      visibility += 1;
    }
    // FOR LEVEL 1 FILL COMPLETE WHEN VISIBILITY IS 3
    if (isStep1Filled && isStep2Filled) {
      // visibility += 1;
      visibility += isStep2Inner ? 2 : 1;
    }
    // FOR LEVEL 2 FILL COMPLETE WHEN VISIBILITY IS 4
    if (isStep1Filled && isStep3Filled) {
      visibility += 2;
    }

    // FOR LEVEL 3 FILL COMPLETE WHEN VISIBILITY IS 5
    if (isStep1Filled && (isStep2Filled || isStep3Filled) && isStep4Filled) {
      visibility += isStep3Filled ? 1 : isStep2Inner ? 1 : 2;
    }
    // FOR LEVEL 4 FILL COMPLETE WHEN VISIBILITY IS 6
    if (
      isStep1Filled &&
      (isStep2Filled || isStep3Filled) &&
      isStep4Filled &&
      isStep5Filled
    ) {
      visibility += 1;
    }

    return visibility;
  };

  const visibility = calculateVisibility();

  return visibility;
}

function visiblityMeterForBelowListing(Oldvisiblity: any, visiblity: any) {
  if (visiblity === 3) {
    return 1;
  }
  if (visiblity === 4) {
    return 2;
  }
  if (visiblity === 5) {
    return 3;
  }
  if (visiblity === 6) {
    return 4;
  }
  return Oldvisiblity > 2 && 0;
}
const priceFieldInputKeyDown = (e: any) => {
  // Allow numeric keys, backspace, delete, arrow keys, tab, and copy (Ctrl+C)
  if (
    !(
      (
        (e.key >= "0" && e.key <= "9") ||
        e.key === "Backspace" ||
        e.key === "Delete" ||
        e.key === "ArrowLeft" ||
        e.key === "ArrowRight" ||
        e.key === "Tab" ||
        (e.ctrlKey && e.key === "c") ||
        (e.ctrlKey && e.key === "a") ||
        (e.ctrlKey && e.key === "v") ||
        e.key === "."
      )
      // ALLOW BELOW CODE WHICH ALLOW ONLY ONE DOT FOR ENTER
      // (e.key === "." && e.target.value.indexOf(".") === -1)
    )
  ) {
    e.preventDefault();
  }
};

export function refreshEventsWhileActionPerform(list: any) {
  //send response to eventField component
  return list.map((event: any) => {
    return {
      ...event,
      eventRecords:
        Array.isArray(event["eventRecords"]) && event["eventRecords"].length > 0
          ? event["eventRecords"].map((eventRecord: any) => {
              return {
                ...eventRecord,
              };
            })
          : undefined,
    };
  });
}

const secureStateWhileTabbing = (e: any, tabTiming: number = 500) => {
  let timer = null;
  if (e.key === "Tab") {
    e.preventDefault();
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const focusableElements =
        "input, button, select, textarea, a[href], area[href], iframe, object, embed, [tabindex], [contenteditable]";
      const inputs = Array.prototype.slice.call(
        document.querySelectorAll(focusableElements)
      );
      const currentIndex = inputs.indexOf(document.activeElement);
      const nextElement = inputs[currentIndex + 1] || inputs[0];
      nextElement.focus();
    }, tabTiming);
  }
};

function categoryExist(event: any, record: any, type: any) {
  const categoryValue = record?.category?.id
    ? record?.category
    : event?.categories &&
      event?.categories.find(
        (category: any) => Number(category.id) === Number(record?.category)
      );

  const sectionValue =
    record?.section && record?.section?.id
      ? record?.section
      : record?.section &&
        event?.categories &&
        Array.isArray(event?.categories) &&
        event?.categories
          .find(
            (category: any) => Number(category.id) === Number(record?.category)
          )
          ?.sections?.find(
            (item: any) => Number(item?.id) === Number(record?.section)
          );

  const priceField =
    typeof record?.sell_price === "object"
      ? record?.sell_price?.value
      : record?.sell_price;

  const hasProceedValue = priceField !== "0.00" && priceField !== "";
  const hasNoCategory = _.size(categoryValue) === 0;
  const hasNoSection = _.size(sectionValue) === 0;

  if (type === "withoutcategory") {
    return _.size(record?.markinsight) > 0
      ? hasProceedValue && (hasNoCategory || hasNoSection)
      : false;
  }
  if (type === "withcategory") {
    return _.size(record?.markinsight) > 0
      ? hasProceedValue && _.size(categoryValue) > 0 && _.size(sectionValue) > 0
      : false;
  }
}

export const convertIntoDateTimeFormat = (date: any) => {
  if (!date) return;
  const parsedDate = moment(date, "DD/MM/YYYY HH:mm:ss", true);
  return parsedDate.format("DD/MM/YYYY HH:mm:ss");
};

export const convertIntoDateFormat = (
  date: any,
  format: any = "DD/MM/YYYY"
) => {
  if (!date) return;
  return moment(date).format(format);
};

export const stringifyComplexValue = (item: any) => {
  if (_.isArray(item) || _.isObject(item)) {
    return JSON.stringify(item);
  }
  return item;
};

export const objectHasKey = (obj: any, objKey: string) => {
  return Boolean(obj && _.isObject(obj) && objKey in obj);
};

function calculateAvgPriceForProceedValue(
  sectionname: any,
  markplaceArry: any,
  record: any,
  event: any,
  conversation_rate: any,
  isGodAdmin: any
) {
  const section =
    record?.section && record?.section?.id
      ? record?.section
      : record?.section &&
        event?.categories &&
        Array.isArray(event?.categories) &&
        event?.categories
          .find(
            (category: any) => Number(category.id) === Number(record?.category)
          )
          ?.sections?.find(
            (item: any) => Number(item?.id) === Number(record?.section)
          );

  let sectionValue = section?.originalName
    ?.trim()
    ?.toLowerCase()
    .split(" ")
    .join("");

  let markeplaceValueWithAvg: any =
    markplaceArry &&
    Object.entries(markplaceArry)?.map((item: any) => {
      return {
        id: item?.[0],
        name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
        avgPrice: item?.[1]?.total?.price_avg,
      };
    });

  let avgPrice = markeplaceValueWithAvg?.find(
    (obj: any) => obj.name === sectionValue
  )?.avgPrice;

  const conversationRate = isGodAdmin
    ? conversation_rate?.[record?.sell_price_currency]
    : conversation_rate;

  let finalAvgRate = avgPrice ? avgPrice * conversationRate : null;

  return finalAvgRate;
}
export const getDefaultFilters = (
  initialFilters: any,
  isGodAdmin: any,
  userSpecific: any
) => {
  if (isGodAdmin) return initialFilters;

  const excludedItems = userSpecific
    ? ["All teams", "Team members", "Hospitality tickets"]
    : ["All teams", "Hospitality tickets"];

  return initialFilters?.filter(
    (item: any) => !excludedItems.includes(item.name)
  );
};

export const getSalesEventDateRange = (performer: any = {}) => {
  const format = "DD MMM YYYY";
  const startDate = convertIntoDateFormat(performer?.start_event_date, format);
  const endDate = convertIntoDateFormat(performer?.end_event_date, format);

  if (startDate === endDate) return startDate;

  return `${convertIntoDateFormat(
    performer?.start_event_date,
    "DD MMM"
  )} - ${endDate}`;
};

export const getSelectedTeamMember = (data: any, isTeamId = false) => {
  const authData: any = getAuthDataFromStorage();
  const userData = authData ? JSON.parse(authData) : null;

  const fullName = data?.account_info
    ? `${data?.account_info?.firstname} ${data?.account_info?.surname}`
    : userData?.name;

  if (isTeamId) return userData?.id;

  return [{ id: userData?.id, name: fullName }];
};

export {
  bulkPayloadCreate,
  bulkUpdate,
  bulkUploadCount,
  calculateAvgPriceForProceedValue,
  categoryExist,
  changedInputValues,
  changedSelectedCount,
  checkValidation,
  getFieldErrorColumns,
  getKeysWithErrors,
  priceFieldInputKeyDown,
  processInBatches,
  secureStateWhileTabbing,
  setEventRecordPayload,
  spellCorrect,
  visibilityMeterCalculationForInventory,
  visiblityMeterForBelowListing,
};
