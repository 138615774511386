import { EditSquareIcon } from "assets/media/icons/other_icons/EditSquareIcon";
import { EyeIcon } from "assets/media/icons/other_icons/EyeIcon";
import clsx from "clsx";
import { capitalize } from "lodash";
import { Tooltip } from "react-tooltip";

const ListingActionButton = ({
  matchedView,
  sIndex,
  disabled = false,
  list,
  handleViewForm,
  buttonType = "view",
}: any) => {
  return (
    <td className="relative md:sticky right-0 ml-auto p-0 icon_td w-10">
      <div
        className="flex left-shad relative w-full h-full border-l left-shad"
        id="left-shad-list"
      >
        <button
          className={clsx(
            "group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 md:flex-auto flex-1",
            disabled && "pointer-events-none"
          )}
          type="button"
          disabled={disabled}
          data-tooltip-id={`record-view-tooltip${sIndex}`}
          onClick={() => handleViewForm(list, sIndex)}
        >
          <Tooltip
            id={`record-view-tooltip${sIndex}`}
            content={capitalize(buttonType)}
            place={`${sIndex === 0 ? "left" : "top"}`}
            variant="light"
            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
              sIndex === 0 ? "mt-0" : "mt-1.5"
            }`}
          />

          {buttonType === "view" ? (
            <EyeIcon
              className={clsx(
                "flex items-center justify-center w-3.5 h-3.5 transition",
                matchedView
                  ? "fill-indigo-500"
                  : "group-hover:fill-indigo-500 fill-violet-500"
              )}
            />
          ) : buttonType === "edit" ? (
            <EditSquareIcon
              width="14"
              height="14"
              className={clsx(
                "flex items-center justify-center w-3.5 h-3.5 transition",
                matchedView
                  ? "fill-indigo-500"
                  : "group-hover:fill-indigo-500 fill-violet-500"
              )}
            />
          ) : null}
        </button>
      </div>
    </td>
  );
};

export default ListingActionButton;
