const TableShimmer = ({ loading, height = "40px", length = 10 }: any) => {
  return (
    <>
      {Array.from({ length: length }, (v, i) => (
        <div
          className={`accordion font-medium  mb-2.5 mx-5 ${i === 0 && "mt-0"} `}
          key={i}
        >
          <div
            key={i}
            className={`accordion-item bg-white !rounded overflow-hidden ${
              loading && "shimmer-effect"
            } `}
            style={{ height: height }}
          ></div>
        </div>
      ))}
    </>
  );
};

export default TableShimmer;
