import PageTitle from "components/core/PageTitle";
import { LISTINGS_UTILS } from "helpers/const";
import { VenueDetailsProvider } from "./core/VenueDetailsProvider";
import VenueDetails from "./VenueDetails";

const VenueDetailsWrapper = () => {
  return (
    <VenueDetailsProvider>
      <PageTitle title={LISTINGS_UTILS.venueDetails.name} />
      <VenueDetails />
    </VenueDetailsProvider>
  );
};

export default VenueDetailsWrapper;
