import { PAYOUT_TAB } from "helpers/const";
import { size } from "lodash";

// @ts-nocheck
export const tabConditions = (
  isTabActive: string,
  filterType: string = "filter"
) => {
  const tabMapping: any = {
    Orders: filterType === "filter" ? "Orders" : "OrdersColumn",
    Invoices: filterType === "filter" ? "Invoices" : "InvoicesColumn",
    [PAYOUT_TAB]: filterType === "filter" ? PAYOUT_TAB : "payoutColumn",
    "Seller wallets":
      filterType === "filter" ? "Seller wallets" : "sellerwalletColumn",
    "Bank accounts":
      filterType === "filter" ? "Bank accounts" : "bankAccountsColumn",
    "Withdraw requests":
      filterType === "filter" ? "Withdraw requests" : "withdrawRequestColumn",
  };

  return tabMapping[isTabActive] || "";
};

export const downloadFile = ({ data, fileName, fileType }: any) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
  return false;
};

export const display = (isOpen: boolean) => {
  var pop_width =
    document.getElementsByClassName("s_ticket")?.[0]?.clientWidth - 20;
  var body = document.querySelector("body");
  var html = document.querySelector("html");
  var EventAccordion: any = document.getElementById("EventAccordion");
  if (isOpen) {
    (EventAccordion?.style).marginRight = `${pop_width}px`;
    // document.body.style.overflow = 'hidden';
    // body?.classList.add("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
  } else if (!isOpen) {
    (EventAccordion?.style).marginRight = `0px`;
    // document.body.style.overflow = 'unset';
    // body?.classList?.remove("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
    const singleTicket = document.getElementById("singleTicket");
    if (singleTicket) {
      singleTicket.style.visibility = "hidden";
    }
  }
};

export const countActiveFilters = (
  sellerWalletsFilter: any = {},
  bankAccountFilter: any = {},
  withdrawRequestFilter: any = {},
  tab = ""
) => {
  let count = 0;

  // SELLER WALLET
  if (size(sellerWalletsFilter?.teams) > 0) count++;
  if (size(sellerWalletsFilter?.statuses) > 0) count++;

  // BANK ACCOUNT FILTER
  if (size(bankAccountFilter?.teams) > 0) count++;
  if (bankAccountFilter?.txpay_change_request) count++;
  if (bankAccountFilter?.is_withdrawal_acc_configured?.name) count++;

  // WITHDRAW REQUEST
  if (size(withdrawRequestFilter?.teams) > 0) count++;
  if (tab === "Withdraw requests" && size(withdrawRequestFilter?.statuses) > 0)
    count++;
  if (withdrawRequestFilter?.txpay_change_request) count++;
  if (
    withdrawRequestFilter?.requestedDate?.startDate !== undefined &&
    withdrawRequestFilter?.requestedDate?.endDate !== undefined
  ) {
    count++;
  }

  return count;
};

export default countActiveFilters;
