/* eslint-disable react-hooks/exhaustive-deps */
import _, { isArray, isObject, size } from "lodash";
import moment from "moment";
import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import Collapse from "../../assets/media/icons/other_icons/Collapse.svg";
import Plus from "../../assets/media/icons/other_icons/add_icon.svg";
import OutlineCheckSVG from "../../assets/media/icons/other_icons/outline_check.svg";
import settingIcon from "../../assets/media/icons/other_icons/settingIcon.svg";
import Topup from "../../assets/media/icons/other_icons/upload-outline.svg";
import ClockOutlinkSVG from "../../assets/media/icons/standard_icons/clock-outline.svg";
import { LayoutContext } from "../../components/core/LayoutProvider";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../components/ui/IKTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import { HexColorCodeForVirtualCard } from "../../helpers/AssetHelpers";

import {
  addCommaInNumber,
  checkCompleteKYC,
  decimalNumber,
  getCurrencySymbol,
  handleSetCopyFilters,
  isMenuAllowed,
  priceFormat,
} from "../../helpers/Functions";
import { showAlert } from "../../helpers/ShowAlert";
import { QUERIES, SYMBOLS } from "../../helpers/const";
import useDebounce from "../add_inventory/core/useDebounce";
import BankDetailPopup from "../tx_trade/widget/Sidebar/BankDetailPopup";
import AddVirtualCardPopup from "./component/AddVirtualCardPopup";
import Header from "./component/Header";
import KycOverlay from "./component/KycOverlay";
import SettingPopup from "./component/SettingPopup";
import TXFilters from "./component/TXFilters";
import TXHeader from "./component/TXHeader";
import TransferFundPopup from "./component/TransferFundPopup";
import TransferPopup from "./component/TransferPopup";
import TxPayTab from "./component/TxPayTab";
import TxPayVirtualCard from "./component/TxPayVirtualCard";
import { VirtualCardPolicy } from "./component/VirtualCardPolicy";
import WithdrawFundsOffcanvas from "./component/WithdrawFundsOffcanvas";
import { TXContext } from "./core/TXProvider";
import { isCardPending, isVirtualCardTab } from "./core/_functions";
import { TX_PAY_TAB_ROUTES, TX_TAB } from "./core/const";
import {
  cancelWithdrawlRequest,
  getBalances,
  getOptionsDataForVirtualCards,
  getTxPayListing,
  getTxPayOverviewData,
  getVCListForGodadmin,
  getVcTransactionsData,
  getVirtualCardBalance,
  getVirtualCards,
  updateVirtualCards,
  viewSettingVirtualCards,
} from "./core/requests";
import DataTable from "./table/DataTable";
import VirtualCardTabTable from "./table/components/VirtualCardTabTable";

const cardBalanceOnSettled = (setVirtualCardList: any) => (data: any) => {
  const cards = data?.data?.cards ?? [];

  if (isArray(cards) && size(cards) > 0)
    setVirtualCardList((cardsData: any) =>
      cardsData.map((card: any) => {
        const existCard: any = cards.find(
          (item: any) => item[card?.id] && item[card?.id]
        )?.[card?.id];

        if (existCard) {
          return {
            ...card,
            totalBalance: decimalNumber(existCard?.balance),
            currency: SYMBOLS[existCard?.currency],
          };
        }

        return card;
      })
    );
};

const cardBalanceOnSettledGodadmin =
  (setVirtualCardList: any, setIsBalanceLoading: any) => (data: any) => {
    if (!data?.data?.cards || typeof data?.data?.cards !== "object") {
      console.error("Invalid card data:", data?.data?.cards);
      return;
    }

    setVirtualCardList((cardList: any) => {
      const mergedCards = cardList.map((card: any) => {
        // Ensure card ID is a string to match object keys
        const cardId = String(card.id);

        // Find matching balance info using currency
        const match = data?.data?.cards?.[cardId];

        if (match) {
          return {
            ...card,
            available_balance: match?.balance ?? 0, // Default to 0
            total_amount: match?.total_amount ?? 0, // Default to 0
            balanceLoading: false,
          };
        } else {
          return card;
        }
      });

      return mergedCards;
    });
    setIsBalanceLoading(false);
  };

const updateCardCount =
  (layout: any, setHeaderOptions: any, data: number) => () => {
    setHeaderOptions((prev: any) => ({
      ...prev,
      virtual_cards_count: Number(prev?.virtual_cards_count) + data,
    }));

    layout?.setGlobalCount((prev: any) => ({
      ...prev,
      tx_pay_count: {
        ...prev?.tx_pay_count,
        virtual_cards_count:
          Number(prev?.tx_pay_count?.virtual_cards_count) + data,
      },
    }));
  };

const TXPay = ({ list, ledgerData }: any) => {
  const virtualTablePerPage = 20;
  const [loader, setLoader] = useState(false);
  const [updateVirtualCardLoader, setUpdateVirtualCardLoader] =
    useState<any>(false);

  const [settingViewData, setSettingViewData] = useState<any>();
  const [transferViewData, setTransferViewData] = useState<any>();

  const globalLayout: any = useContext(LayoutContext);
  const layout = useContext(TXContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const [query, setQuery] = useState<any>("");
  const [virtualCardQuery, setVirtualCardQuery] = useState("");

  const [virtualCardOptionsKeys, setVirtualCardOptionsKeys] = useState<any>([
    "filter_options",
    "country_codes",
    "wallet",
  ]);
  const [virtualCardOptions, setVirtualCardOptions] = useState<any>();

  const [teamsOptions, setTeamsOptions] = useState<any>();
  const [headerOptions, setHeaderOptions] = useState<any>();
  const debouncedSearch = useDebounce(query, 1200);
  const debouncedSearchForVirtualCard = useDebounce(virtualCardQuery, 0);
  const [transferPopUpOpen, setTransferPopUpOpen] = useState<any>(false);

  const [isVirtualCardpolicyOpen, setIsVirtualCardpolicyOpen] = useState(true);

  let filters = layout.TXPayFilterHandle;

  const appliedFilters = useMemo(() => {
    return isGodAdmin ? layout.TXPayFilterHandleCopy : layout.TXPayFilterHandle;
  }, [layout.TXPayFilterHandle, layout.TXPayFilterHandleCopy, isGodAdmin]);

  let virtualAppliedFilters: any = useMemo(() => {
    return isGodAdmin
      ? {
          ...layout.virtualFilterHandleCopy,
          query: debouncedSearchForVirtualCard,
        }
      : { ...layout.virtualFilterHandle };
  }, [
    isGodAdmin,
    layout.virtualFilterHandleCopy,
    layout.virtualFilterHandle,
    debouncedSearchForVirtualCard,
  ]);
  // for not call api multiple times
  delete virtualAppliedFilters?.activeId;

  let coutFilters = useMemo(() => {
    return {
      ...appliedFilters,
      ...virtualAppliedFilters,
      page: undefined,
      per_page: undefined,
    };
  }, [appliedFilters, virtualAppliedFilters]);

  const access = globalLayout.allowedAccess?.allowed_access;
  const teamOptionsData = globalLayout?.allowedAccess?.teams;

  const isSettinsAvailable = access?.some(
    (item: any) => item.id === "settings" && item.status
  );

  const isWalletAvailabel = access?.some(
    (item: any) => (item.id === "txpay" && item.status === true) || false
  );

  const isVirtualCardAvailabel = access?.some(
    (item: any) =>
      (item.id === "virtual-cards" && item.status === true) || false
  );

  const [customReportLoading, setReportLoading] = useState(true);
  const [secondCounter, setSecondCounter] = useState(3);
  const heightRef1: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [overViewHeight, setOverViewHeight] = useState<any>();

  const [isWalletLoading, setIsWalletLoading] = useState(false);
  const [isbalanceLoading, setIsBalanceLoading] = useState(false);

  const [virtualCardList, setVirtualCardList] = useState([
    // {
    //   cardName: "Scott Welch no1",
    //   cardNumber: "0000",
    //   totalBalance: "99999",
    //   currency: "$",
    //   id: 1,
    //   active: false,
    //   status: "Active",
    //   exp_date: "12/24",
    //   cvv: "123",
    //   color: "Dark purple",
    // },
    // {
    //   cardName: "Scott Welch no2",
    //   cardNumber: "0000",
    //   totalBalance: "99999",
    //   currency: "$",
    //   id: 2,
    //   active: false,
    //   status: "Active",
    //   exp_date: "12/24",
    //   cvv: "123",
    //   color: "Dark purple",
    // },
  ]);

  const { pathname } = useLocation();

  const isVirtualCardTermsAccepted = useMemo(
    () =>
      Number(
        globalLayout?.allowedAccess?.ui_field_settings
          ?.saveTxPayVirtualCardTerms
      ) || 0,
    [globalLayout?.allowedAccess?.ui_field_settings]
  );

  const availableCards = useMemo(() => {
    if (
      !headerOptions?.virtual_cards_count ||
      virtualCardList?.length > headerOptions?.virtual_cards_count
    )
      return virtualCardList?.length;

    return headerOptions?.virtual_cards_count ?? 0;
  }, [virtualCardList, headerOptions?.virtual_cards_count]);

  const tx_cards_limit = useMemo(() => {
    if (isArray(globalLayout?.sellerLevelData?.config))
      return (
        globalLayout?.sellerLevelData?.config.find(
          (card: any) => card.key === "tx_cards_limit"
        )?.value ?? 0
      );
    return globalLayout?.sellerLevelData?.config?.tx_cards_limit ?? 0;
  }, [globalLayout?.sellerLevelData?.config]);

  const isCardLimitOver = useMemo(
    () => Number(availableCards) >= Number(tx_cards_limit),
    [availableCards, tx_cards_limit]
  );

  const remainingCardsNumber = useMemo(() => {
    const txCardsLimit = Number(tx_cards_limit);
    const availableCardsNumber = Number(availableCards);
    return Math.max(txCardsLimit - availableCardsNumber, 0);
  }, [availableCards, tx_cards_limit]);

  const isShowVirtualCardPolicy = useMemo(
    () =>
      !customReportLoading &&
      !globalLayout?.isFetching &&
      layout.activeTab === TX_TAB.virtualCard &&
      isVirtualCardpolicyOpen &&
      isVirtualCardTermsAccepted !== 1 &&
      !isGodAdmin,
    [
      globalLayout?.isFetching,
      layout.activeTab,
      isVirtualCardpolicyOpen,
      isVirtualCardTermsAccepted,
      isGodAdmin,
      customReportLoading,
    ]
  );

  // PAGINATION SET TO FIRST PAGE ON FILTER CHANGE
  useEffect(() => {
    layout.setTXPayFilterHandle((prev: any) => {
      const final = {
        ...prev,
        page: 1,
        per_page: 50,
      };
      handleSetCopyFilters(
        layout.setTXPayFilterHandleCopy,
        { page: 1, per_page: 50 },
        isGodAdmin
      );
      return final;
    });
    setReportLoading(true);
  }, [
    appliedFilters?.transaction_date,
    appliedFilters?.transaction_type,
    appliedFilters?.team_id,
    appliedFilters?.order_by,
    appliedFilters?.sort_order,
    // debouncedSearch,   // Comented because calling api when we search in query
  ]);

  const activeVirtualCard: any = useMemo(
    () => virtualCardList?.find((obj: any) => obj?.active) ?? {},
    [virtualCardList]
  );

  useEffect(() => {
    layout.setVirtualFilterHandle((prev: any) => {
      const final = {
        ...prev,
        page: 1,
        activeId: activeVirtualCard?.id ?? "",
      };
      handleSetCopyFilters(
        layout?.setVirtualFilterHandleCopy,
        { page: 1, activeId: activeVirtualCard?.id ?? "" },
        isGodAdmin
      );
      return final;
    });
  }, [
    activeVirtualCard?.id,
    virtualAppliedFilters?.transaction_date,
    virtualAppliedFilters?.transaction_type,
    virtualAppliedFilters?.team_id,
    // debouncedSearchForVirtualCard,
  ]);

  const {
    isLoading: balanceLoading,
    data: balanceData,
    refetch: balanceRefetch,
    isFetching: balanceIsFetching,
  } = useQuery(
    [`${QUERIES.GET_FAVOURITE}`],
    getBalances(layout.virtualFilterHandle, debouncedSearchForVirtualCard),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      // enabled: target_currency && trackedListingData && true,
      onError: (err) => {},
      onSettled: cardBalanceOnSettledGodadmin(
        layout.setVirtualCardData,
        setIsBalanceLoading
      ),
    }
  );

  const {
    isLoading: virtualTableLoading,
    isFetching: virtualTableFetching,
    data: virtualTableList,
    refetch: virtualDataeRefetch,
  }: {
    data: any;
    isLoading: boolean;
    isFetching: boolean;
    refetch: Function;
  } = useQuery(
    [
      "vc-transactions",
      activeVirtualCard?.id,
      debouncedSearchForVirtualCard,
      virtualAppliedFilters,
    ],
    isGodAdmin
      ? getVCListForGodadmin(
          activeVirtualCard?.id,
          virtualAppliedFilters,
          debouncedSearchForVirtualCard
        )
      : getVcTransactionsData(
          activeVirtualCard?.id,
          virtualAppliedFilters,
          debouncedSearchForVirtualCard
        ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled:
        isGodAdmin && layout.activeTab === TX_TAB.virtualCard
          ? true
          : Boolean(activeVirtualCard?.id),
      retry: false,
      onError: (err) => {},
      onSettled: (res) => {
        if (res?.data) {
          if (isGodAdmin) {
            const data = res?.data?.map((item: any) => ({
              ...item,
              balanceLoading: true,
            }));

            layout.setVirtualCardData((prev: any) =>
              [undefined, 1].includes(layout.virtualFilterHandle?.page)
                ? data
                : prev.concat(data)
            );

            setIsBalanceLoading(true);

            // balanceRefetch();

            getBalances(
              layout.virtualFilterHandle,
              debouncedSearchForVirtualCard
            )().then((resData: any) => {
              if (
                !resData?.data?.cards ||
                typeof resData?.data?.cards !== "object"
              ) {
                console.error("Invalid card data:", resData?.data?.cards);
                return;
              }

              layout?.setVirtualCardData((cardList: any) => {
                const mergedCards = cardList.map((card: any) => {
                  // Ensure card ID is a string to match object keys
                  const cardId = String(card.id);

                  // Find matching balance info using currency
                  const match = resData?.data?.cards?.[cardId];

                  if (match) {
                    return {
                      ...card,
                      available_balance: match?.balance ?? 0, // Default to 0
                      total_amount: match?.total_amount ?? 0, // Default to 0
                      balanceLoading: false,
                    };
                  } else {
                    return card;
                  }
                });
                return mergedCards;
              });
              setIsBalanceLoading(false);
            });
          } else {
            layout.setVirtualCardData((prev: any) =>
              [undefined, 1].includes(layout.virtualFilterHandle?.page)
                ? res?.data
                : prev.concat(res?.data)
            );
          }
        }
      },
    }
  );

  let txPayDataMemo: any = useMemo(() => {
    return [debouncedSearch, appliedFilters];
  }, [debouncedSearch, appliedFilters]);

  let txPayDataDebounce: any = useDebounce(JSON.stringify(txPayDataMemo), 500);

  // GET TX PAY DATA API
  const {
    isLoading: txPayLoading,
    isFetching: txPayFetching,
    data: txPayData,
    refetch: listingRefetch,
  } = useQuery(
    [`${QUERIES.TX_PAY_LISTING}`, ...JSON.parse(txPayDataDebounce)],
    () =>
      getTxPayListing({
        debouncedSearch,
        filters: appliedFilters,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: layout.activeTab === TX_TAB.wallet,
      onError: (err) => {},
    }
  );

  // GET VIRTUAL CARDS
  const {
    isLoading: virtualCardLoader,
    refetch: virtualCardListRefech,
    isFetching: virtualCardFetching,
    data: virtualCards,
  } = useQuery(`get-Virtual-cards`, () => getVirtualCards(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: layout.activeTab === TX_TAB.virtualCard,
    retry: false,
    onError: (err) => {},
    onSettled: (data) => {
      const cardsData =
        data?.data?.cards?.map((obj: any, index: number) => {
          return {
            cardName: obj?.card_alias,
            cardNumber: obj?.last_four,
            totalBalance: "",
            currency: "",
            id: obj?.id,
            active: obj?.id === settingViewData?.data?.id ? true : false,
            status: obj?.status,
            exp_date: "",
            cvv: "",
            color: HexColorCodeForVirtualCard(obj?.card_color),
            index: index + 1,
            cardBrand: obj?.card_brand,
          };
        }) || [];

      // Default select 1st card
      // if (
      //   cardsData.length > 0 &&
      //   !cardsData?.some((item: any) => item.active === true)
      // ) {
      //   cardsData[0].active = true;
      // }
      const sortedCardData = cardsData?.sort((a: any, b: any) =>
        a.status === "Inactive" ? 1 : b.status === "Inactive" ? -1 : 0
      );

      setVirtualCardList(sortedCardData);
    },
  });

  const { isLoading: isBalanceLoading, isFetching: isBalanceFetching } =
    useQuery(
      [`get-Virtual-cards-balance`, virtualCards?.data?.cards],
      getVirtualCardBalance,
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled:
          layout.activeTab === TX_TAB.virtualCard && size(virtualCardList) > 0,
        retry: false,
        onSettled: cardBalanceOnSettled(setVirtualCardList),
      }
    );

  const {
    isLoading: virtualCardSettingLoader,
    isFetching,
    //refetch,
  } = useQuery(
    [`view-Virtual-cards`, settingViewData?.data?.id],
    () => viewSettingVirtualCards(settingViewData?.data?.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: settingViewData?.data?.id ? true : false,
      retry: false,
      onError: (err) => {},
      onSettled: (data) => {
        const cards: any = data?.data?.card || {};
        const transformedCard = {
          cardName: cards.card_alias,
          cardNumber: cards?.last_four,
          fullCardNumber: cards?.card_number,
          totalBalance: decimalNumber(cards.available_balance || 0),
          currency: SYMBOLS[cards.currency],
          id: cards.id,
          active: false,
          status: cards.status,
          exp_date: cards.expiry_date,
          cvv: cards.cvv,
          color: HexColorCodeForVirtualCard(cards.card_color),
          index: settingViewData?.data?.index,
          cardBrand: cards?.card_brand,
        };
        setSettingViewData((pre: any) => {
          return {
            ...pre,
            data: transformedCard,
          };
        });
      },
    }
  );

  const handleOnUpdateVirtualCardData = () => {
    const closeBtn = document.getElementById("close-setting");

    if (updateVirtualCardLoader) return;

    if (!settingViewData?.data?.isUpdated) {
      showAlert("Card updated successfully.", false);
      setSettingViewData({});
      closeBtn && closeBtn?.click();
      return;
    }

    setUpdateVirtualCardLoader(true);
    updateVirtualCards(settingViewData?.data)
      ?.then((res) => {
        showAlert(res?.data?.message || res?.errors[0], !res.data.status);
        if (res.data.status) {
          // updateCardCount(globalLayout, setHeaderOptions, -1)();
          virtualCardListRefech();
          setVirtualCardList((pre: any) => {
            return pre?.map((obj: any) => {
              if (obj?.id === settingViewData?.data?.id) {
                return {
                  ...settingViewData?.data,
                  ...obj,
                };
              }
              return obj;
            });
          });
          if (closeBtn) {
            setTimeout(() => setSettingViewData({}), 100);
            closeBtn?.click();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setUpdateVirtualCardLoader(false);
      });
  };

  // GET TX PAY OVERVIEW DATA API
  const {
    isLoading: txPayOverViewLoading,
    isFetching: txPayOverviewFetching,
    data: txPayOverviewData,
    refetch,
  } = useQuery(
    [`tx-pay-overview`, debouncedSearch, coutFilters],
    () =>
      getTxPayOverviewData({
        debouncedSearch:
          layout.activeTab === TX_TAB.virtualCard
            ? layout?.virtualFilterHandle?.query
            : debouncedSearch,
        filters: coutFilters,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  //  OPTIONS API

  useQuery(
    [`tx-pay-options`, debouncedSearch, coutFilters],
    () => getOptionsDataForVirtualCards(virtualCardOptionsKeys),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: layout.activeTab === TX_TAB.virtualCard,
      retry: false,
      onError: (err) => {},
      onSettled: (data) => {
        const { status, ...rest } = data?.data;
        setVirtualCardOptions((pre: any) => {
          return {
            ...pre,
            ...rest,
          };
        });
      },
    }
  );

  // ON ENTER - SEARCH QUERY (ALGOLIA)
  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0 || size(teamArr) == 0) {
      return globalLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.base_currency);
    }
  }

  const handleKeyDown = (event: any) => {
    if (event?.target?.value && event.key === "Enter") {
      event.preventDefault();
      if (layout.activeTab === TX_TAB.wallet) {
        setQuery(event?.target?.value);
        layout?.setTXPayFilterHandle((currentValue: any) => {
          return {
            ...currentValue,
            query: event?.target?.value,
            page: 1,
            per_page: 20,
          };
        });
        handleSetCopyFilters(
          layout.setTXPayFilterHandleCopy,
          { query: event?.target?.value, page: 1, per_page: 20 },
          isGodAdmin
        );
        setIsWalletLoading(true);
      } else if (layout.activeTab === TX_TAB.virtualCard) {
        setVirtualCardQuery(event?.target?.value);
        layout?.setVirtualFilterHandle((currentValue: any) => {
          return {
            ...currentValue,
            query: event?.target?.value,
            page: 1,
            per_page: 20,
          };
        });
        handleSetCopyFilters(
          layout.setVirtualFilterHandleCopy,
          { query: event?.target?.value, page: 1, per_page: 20 },
          isGodAdmin
        );
      }
    }
  };

  useEffect(() => {
    if (txPayData && txPayData?.data?.ledger) {
      // layout.setItems(txPayData?.data?.ledger);
      const existingMonths = layout?.items?.map(
        (outerObj: any) => outerObj?.month
      );
      const mergedItems = layout?.items?.map((outerObj: any) => {
        const foundIndex = txPayData?.data?.ledger?.findIndex(
          (txObj: any) => txObj?.month === outerObj?.month
        );

        if (foundIndex !== -1) {
          const updatedTransactions = [
            ...outerObj?.transactions,
            ...(txPayData?.data?.ledger[foundIndex]?.transactions || []),
          ];

          return {
            ...outerObj,
            transactions: _.uniqBy(updatedTransactions, "id"),
          };
        }

        return outerObj;
      });

      const missingMonths = txPayData?.data?.ledger?.filter(
        (txObj: any) => !existingMonths?.includes(txObj.month)
      );

      const updatedLayoutItems =
        layout?.TXPayFilterHandle?.page > 1
          ? [...mergedItems, ...missingMonths]
          : txPayData?.data?.ledger;

      const newUpdateData = updatedLayoutItems?.map((obj: any) => {
        return {
          ...obj,
          isOpen: true,
        };
      });
      layout.setItems(newUpdateData);
    }
    if (
      txPayOverviewData?.data &&
      ((txPayData && txPayData?.data?.ledger) || isVirtualCardTab(pathname))
    )
      setHeaderOptions(txPayOverviewData?.data);
  }, [txPayData, txPayOverviewData, appliedFilters, pathname]);

  // get team otpions
  useEffect(() => {
    if (teamOptionsData?.length > 0) {
      setTeamsOptions(teamOptionsData);
    }
  }, [teamOptionsData?.length > 0]);

  useEffect(() => {
    if (txPayLoading === false && appliedFilters?.page === 1) {
      // setTimeout(() => {
      setReportLoading(false);
      // }, 500);
    }
  }, [txPayLoading, appliedFilters]);

  useEffect(() => {
    if (isWalletLoading) {
      if (layout.activeTab === TX_TAB.virtualCard) setIsWalletLoading(false);
      else setIsWalletLoading(txPayLoading || txPayFetching);
    }
  }, [txPayLoading, txPayFetching, layout.activeTab]);

  useEffect(() => {
    if (customReportLoading) {
      setSecondCounter((pre: any) => pre + 1);
    }
  }, [customReportLoading]);

  useEffect(() => {
    let height3: any;
    const element: any = document.querySelector("#inventoryaccordion");
    setTimeout(() => {
      height3 = IsToggle === true ? element?.offsetHeight : 0;
      setOverViewHeight(height3);
    }, 400);
  }, [IsToggle]);

  // useEffect(() => {
  //   const stickyElement = document.getElementById("topFilters") as HTMLElement;
  //   if (stickyElement) {
  //     const heightheightRef1 = heightRef1?.current?.offsetHeight;
  //     const HeaderHeight: any = document.querySelector("header")?.clientHeight;
  //     const MarginTopTotle =
  //       IsToggle === true
  //         ? heightheightRef1 + overViewHeight - HeaderHeight
  //         : heightheightRef1 - HeaderHeight;

  //     const initialPosition = stickyElement.getBoundingClientRect().top;

  //     const makeSticky = function () {
  //       if (window.scrollY + 60 >= initialPosition) {
  //         stickyElement.classList.add("sticky");
  //         stickyElement.style.top = `-${MarginTopTotle}px`;
  //       } else {
  //         stickyElement.classList.remove("sticky");
  //         stickyElement.style.top = `0rem`;
  //       }
  //     };

  //     if (window.scrollY > 200) {
  //       makeSticky();
  //     }

  //     window.addEventListener("scroll", makeSticky);
  //     window.addEventListener("change", makeSticky);
  //     window.addEventListener("blur", makeSticky);
  //     return () => {
  //       window.removeEventListener("scroll", makeSticky);
  //     };
  //   }
  //   // });
  // }, [toggleRef, IsToggle, overViewHeight]);
  /* end sticky event */

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;

    if (stickyElement) {
      const initialPosition = stickyElement.getBoundingClientRect().top;

      let MarginTopTotle = 0;
      // let topPosition = 0;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        const filter: any = document.getElementById(
          "filters_block"
        ) as HTMLElement;
        const heightheightRef2 = filter?.offsetHeight;
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1 = heightRef1?.current?.offsetHeight;
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const salesFilter: any =
          document.getElementById("txFilter")?.clientHeight;

        // topPosition = heightheightRef2 + salesFilter + HeaderHeight + -1;

        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;

        const eventHeaders = document.querySelectorAll(".event__header");
        // const tableHead = document.querySelectorAll(".tableHead");

        // const filterPadding = stickyElement.style.paddingBottom;
        const computedStyle = window.getComputedStyle(stickyElement);

        // Get the padding-bottom value
        const filterPadding = computedStyle.paddingBottom;
        const filterValue: any = parseInt(filterPadding);

        const eventHeaderHeight =
          document.querySelector(".event__header")?.clientHeight;
        const evtHeadTop =
          window.innerWidth > 767
            ? heightheightRef2 + HeaderHeight + filterValue + salesFilter
            : HeaderHeight;
        // Iterate through each element and apply the style
        eventHeaders.forEach((eventHeader: any) => {
          setTimeout(() => {
            eventHeader.style.top = evtHeadTop - 1 + "px";
          }, 10);
        });

        const tableHeads = document.querySelectorAll(".tableHead");
        tableHeads.forEach((tableHead: any) => {
          setTimeout(() => {
            tableHead.style.top = evtHeadTop + eventHeaderHeight - 1 + "px";
          }, 10);
        });
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        // const sticky_head: any = document.getElementById("sticky-head");
        // const stickyShadow: any = document.getElementById("sticky-shadow");
        // const sticky_headHeight: any = sticky_head?.clientHeight;

        recalculateMarginTopTotle();
        if (window.scrollY + HeaderHeight >= initialPosition) {
          stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
          // sticky_head && (sticky_head.style.top = topPosition + "px");
          // stickyShadow &&
          //   (stickyShadow.style.top = topPosition + sticky_headHeight + "px");
        } else {
          stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0rem`;
          // sticky_head && (sticky_head.style.top = "0rem");
          // stickyShadow && (stickyShadow.style.top = "0rem");
        }
      };
      const handleWindowResize = () => {
        // Handle logic when the window is resized
        makeSticky(); // Update sticky behavior based on new values
        recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
      };

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);

  const handleCancelWithdrawRequest = () => {
    if (!isGodAdmin) {
      setLoader(true);
      cancelWithdrawlRequest([])
        .then((response: any) => {
          if (response?.data?.status) {
            refetch &&
              refetch().then(() => {
                setLoader(false);
                showAlert(response?.message, false);
              });
          } else {
            setLoader(false);
            showAlert(response?.message, true);
          }
        })
        .catch((error: any) => {
          setLoader(false);
        });
    }
  };

  const handleOnVirtualCard = (action: any, data: any) => {
    if (isCardPending(data)) return;
    if (action === "view-transactions-click") {
      setVirtualCardList((prevCards: any) =>
        prevCards.map((card: any) =>
          card?.id === data?.id
            ? { ...card, active: true }
            : { ...card, active: false }
        )
      );

      // reset page while we set card
      layout.setVirtualFilterHandle((current: any) => ({
        ...current,
        page: 1,
        per_page: virtualTablePerPage,
      }));
    } else if (action === "transfer") {
      setTransferViewData({ data: data, show: true });
    } else if (action === "setting") {
      setSettingViewData({ data: { ...data, time: Date.now() }, show: true });
    }
  };

  const filterEnabled =
    layout.activeTab === TX_TAB.wallet ||
    (layout.activeTab === TX_TAB.virtualCard &&
      !(!activeVirtualCard?.id && virtualCardList.length >= 0 && !isGodAdmin));

  const handleOnCommonFilter = (data: any, action: string, type: string) => {
    const clearFilter = (
      setFilter: (currentValue: any) => void,
      fields: string[],
      handleCopyFilter: (currentValue: any) => void
    ) => {
      setFilter((currentValue: any) => {
        const updatedValue = { ...currentValue };
        console.log(updatedValue, fields, "asdasdq2wqdsax");
        fields?.forEach((field) => (updatedValue[field] = ""));
        // handleCopyFilter(updatedValue);
        handleSetCopyFilters(
          handleCopyFilter,
          {
            ...updatedValue,
          },
          isGodAdmin,
          true
        );
        return updatedValue;
      });
    };

    // const updateFilter = (setFilter: (currentValue: any) => void) => {
    //   setFilter((currentValue: any) => ({
    //     ...currentValue,
    //     [data?.target?.name]: data?.target?.value,
    //   }));
    // };

    const updateFilter = (setFilter: (currentValue: any) => void) => {
      setFilter((currentValue: any) => {
        const prevValue = currentValue[data?.target?.name];
        const newValue = data?.target?.value;

        let isWalletTab = layout.activeTab === TX_TAB.wallet;

        // Trigger loading only if value changed
        if (
          !_.isEqual(_.cloneDeep(prevValue), _.cloneDeep(newValue)) &&
          isWalletTab &&
          !isGodAdmin
        ) {
          setIsWalletLoading(true);
        }

        return {
          ...currentValue,
          [data?.target?.name]: newValue,
        };
      });
    };

    const filters = {
      clear: type ? [type] : [],
      "clear-all": [
        "transaction_date",
        "transaction_type",
        "team_id",
        "event_id",
        "query",
      ],
    };

    const handleFilter =
      layout.activeTab === TX_TAB.wallet
        ? layout.setTXPayFilterHandle
        : layout.setVirtualFilterHandle;

    const handleCopyFilter =
      layout.activeTab === TX_TAB.wallet
        ? layout.setTXPayFilterHandleCopy
        : layout.setVirtualFilterHandleCopy;

    if (action === "clear" || action === "clear-all") {
      layout.activeTab === TX_TAB.wallet && setIsWalletLoading(true);
      clearFilter(handleFilter, filters[action], handleCopyFilter);
    } else {
      updateFilter(handleFilter);
    }
  };

  //  Functions to handle Tab Switching
  const handleOnTabChange = (tabKey: any) => {
    if (tabKey === layout?.activeTab) {
      return false;
    } else {
      layout.setActiveTab(tabKey);
      // reset filters and virtual card data when we click wallet tab
      handleOnCommonFilter("", "clear-all", "clear-all");
      if (layout.activeTab === TX_TAB.wallet) {
        setVirtualCardList([]);
        setSettingViewData({});
        // layout.setVirtualCardData([]);
        setVirtualCardQuery("");
        return;
      } else {
        virtualCardListRefech();
      }

      // reset wallet tab query when we click virtual cards tab
      setQuery("");
    }
  };
  const pendingOrders =
    typeof headerOptions?.pending_order_count === "number"
      ? addCommaInNumber(headerOptions?.pending_order_count)
      : headerOptions?.pending_order_count;
  const orderText = pendingOrders
    ? `${pendingOrders} order${pendingOrders > 1 ? "s" : ""} pending`
    : "";

  const handleScrollToTop = () => {
    const scrollableDiv = document.getElementById("AddCardPopup");
    if (scrollableDiv) {
      scrollableDiv.scrollTop = 0; // Set the scroll position to 0
    }
  };
  return (
    <>
      <div className="main-content-wrap tx-pay-module" id="tx-pay-module">
        <div
          className={`topFilters sticky-element top-[60px] z-[12] pb-5 bg-gray-200 sticky max-md:!static `}
          id="topFilters"
        >
          <div className="top-accor shadow-op2">
            <div className="accordion font-medium" ref={heightRef1}>
              <div className="accordion-item !rounded-b-none !rounded-t bg-white max-md:border-b border-gray-200 shadow-op2">
                <div
                  className="flex  justify-between accordion-header mb-0"
                  id="headingOne"
                >
                  {/* HEADER FILTES & COLUMN FILTER */}
                  <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2 bg-white">
                    <div className="flex flex-wrap" id="filters">
                      <Header
                        title="Filters"
                        parent="filters"
                        tab={layout.activeTab}
                      />
                    </div>
                  </div>

                  {/* HEADER COLLAPSE BUTTON */}
                  <div>
                    {/* <HeaderCollapseButton /> */}
                    <button
                      className="group relative flex items-center py-4 px-[15px] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                      type="button"
                      data-te-collapse-init
                      data-te-toggle="collapse"
                      data-te-target="#inventoryaccordion"
                      aria-expanded="true"
                      aria-controls="inventoryaccordion"
                      onClick={() =>
                        setIsToggle(IsToggle === false ? true : false)
                      }
                    >
                      <IKTSVG
                        className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                        path={Collapse}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white border-t accordion-collapse font-medium collapse show"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              {/* Wallet Tab */}
              <div className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 z-[12] bg-opacity-[15%] px-5 pt-2.5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
                {isWalletAvailabel && (
                  <TxPayTab
                    tab={layout.activeTab}
                    onClick={handleOnTabChange}
                    labelText={TX_TAB.wallet}
                    currency={
                      isGodAdmin
                        ? isGodadminBasedCurrency(
                            layout?.TXPayFilterHandle?.team_id
                          )
                        : globalLayout.currencySymbol
                    }
                    amount={
                      headerOptions?.available_amount
                        ? priceFormat(headerOptions?.available_amount)
                        : "0"
                    }
                    route={TX_PAY_TAB_ROUTES.wallet}
                    shimmer={txPayLoading || txPayFetching}
                  />
                )}

                {/* Virtual cards */}
                {isVirtualCardAvailabel && (
                  <TxPayTab
                    tab={layout.activeTab}
                    onClick={handleOnTabChange}
                    labelText={TX_TAB.virtualCard}
                    // currency={"£"}
                    amount={
                      virtualCardList?.length >
                      headerOptions?.virtual_cards_count
                        ? virtualCardList?.length
                        : headerOptions?.virtual_cards_count
                    }
                    route={TX_PAY_TAB_ROUTES.virtualCard}
                    shimmer={
                      virtualCardLoader ||
                      virtualCardFetching ||
                      txPayOverviewFetching
                    }
                  />
                )}
              </div>

              {/*FIRST LAYER*/}

              <div
                className={`px-5 ${
                  layout.activeTab === TX_TAB.virtualCard ? "pt-5" : "pt-3"
                } max-md:border-b `}
              >
                {layout.activeTab === TX_TAB.virtualCard &&
                isVirtualCardAvailabel &&
                !isGodAdmin ? (
                  <div className="flex justify-between pb-[10px]">
                    <p className="font-semibold text-sm15">
                      {virtualCardList?.length > 0
                        ? `${virtualCardList?.length} virtual card${
                            virtualCardList?.length > 1 ? "s" : ""
                          }`
                        : "Add a virtual card"}{" "}
                      {!virtualCardLoader &&
                        `(${remainingCardsNumber} remaining)`}
                    </p>
                    <button
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasAddcard"
                      aria-controls="offcanvasAddcard"
                      disabled={isCardLimitOver}
                      onClick={handleScrollToTop}
                      className="w-24 h-6 flex items-center justify-center gap-2 bg-violet-500 hover:bg-indigo-500 transition-all rounded   text-sm13 text-white disabled:bg-gray-400 disabled:pointer-events-none"
                    >
                      <IKTSVG path={Plus} svgClassName="w-3 h-3 fill-white" />{" "}
                      Add card
                    </button>
                  </div>
                ) : null}

                <div className="divide-gray-200 divide-y-2 pb-1">
                  <div className="flex flex-nowrap pt-2 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                    {layout.activeTab === TX_TAB.wallet &&
                      isWalletAvailabel && (
                        <>
                          {/* AVAILABLE FUNDS */}
                          <TXHeader
                            title="Available funds"
                            amount={`${
                              headerOptions?.available_amount
                                ? priceFormat(headerOptions?.available_amount)
                                : "0"
                            }`}
                            svgPath={OutlineCheckSVG}
                            svgFillColor="fill-green-600"
                            // bggradient={true}
                            txPayLoading={txPayOverViewLoading}
                          >
                            {isObject(headerOptions?.open_withdrawal_request) &&
                            _.size(headerOptions?.open_withdrawal_request) >
                              0 ? (
                              <p className="text-xs text-gray-500 mb-2">
                                Pending withdraw request for{" "}
                                {getCurrencySymbol(
                                  headerOptions?.open_withdrawal_request
                                    ?.currency
                                )}
                                {addCommaInNumber(
                                  headerOptions?.open_withdrawal_request?.amount
                                )}{" "}
                                created on{" "}
                                {moment(
                                  headerOptions?.open_withdrawal_request
                                    ?.process_date
                                ).format("DD MMM YYYY")}
                              </p>
                            ) : (
                              ""
                            )}

                            <div
                              className={`flex flex-nowrap w-full ${
                                isGodAdmin ? "w-full" : "w-auto"
                              }`}
                            >
                              <button
                                type="button"
                                data-te-toggle="modal"
                                data-te-target="#topup"
                                className={`${
                                  (isGodAdmin || loader) &&
                                  "pointer-events-none !text-gray-400"
                                } 
                                false group min-w-[33.33%] bg-gray-100 py-2.5 group-hover:fill-indigo-500  flex flex-wrap flex-col group items-center text-violet-800 hover:text-indigo-500 outline-none first:rounded-l last:rounded-r text-sm13  leading-4  px-2.5`}
                              >
                                <IKTSVG
                                  path={Topup}
                                  className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 mb-1 rotate-180"
                                  svgClassName={`w-4 h-4 ${
                                    (isGodAdmin || loader) && "!fill-gray-400"
                                  } group-hover:fill-indigo-500 `}
                                />
                                {/* <IKTSVG
                                className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 mb-1"
                                path={Topup}
                                svgClassName={`w-4 h-4 ${
                                  isGodAdmin ||
                                  headerOptions?.available_amount <= 0
                                    ? "!fill-gray-400"
                                    : "group-hover:fill-indigo-500"
                                } `}
                              /> */}
                                Deposit
                              </button>
                              {isObject(
                                headerOptions?.open_withdrawal_request
                              ) &&
                              _.size(headerOptions?.open_withdrawal_request) >
                                0 ? (
                                <>
                                  <button
                                    type="button"
                                    className={`${
                                      (isGodAdmin || loader) &&
                                      "pointer-events-none !text-gray-400"
                                    } 
                                  
                                   group min-w-[33.33%] bg-gray-100/50 inline-flex group items-center text-violet-800 hover:text-indigo-500 transition font-medium first:rounded-l last:rounded-r text-sm13 leading-4 px-2.5`}
                                    onClick={() =>
                                      handleCancelWithdrawRequest()
                                    }
                                  >
                                    Cancel withdraw request
                                  </button>
                                </>
                              ) : (
                                <button
                                  type="button"
                                  className={`${
                                    (isGodAdmin ||
                                      headerOptions?.available_amount <= 0) &&
                                    "pointer-events-none !text-gray-400"
                                  }  group min-w-[33.33%] bg-gray-100 py-2.5 group-hover:fill-indigo-500  flex flex-wrap flex-col group items-center text-violet-800 hover:text-indigo-500 outline-none first:rounded-l last:rounded-r text-sm13  leading-4  px-2.5`}
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="#withdrowFunds"
                                  aria-controls="withdrowFundsLabel"
                                >
                                  <IKTSVG
                                    className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 mb-1"
                                    path={Topup}
                                    svgClassName={`w-4 h-4 ${
                                      isGodAdmin ||
                                      headerOptions?.available_amount <= 0
                                        ? "!fill-gray-400"
                                        : "group-hover:fill-indigo-500"
                                    } `}
                                  />
                                  Withdraw
                                </button>
                              )}
                              {isGodAdmin &&
                                isMenuAllowed(
                                  access,
                                  "wallet_to_wallet_transfer"
                                ) && (
                                  <button
                                    type="button"
                                    className={` false group min-w-[33.33%] bg-gray-100 py-2.5 group-hover:fill-indigo-500  flex flex-wrap flex-col group items-center text-violet-800 hover:text-indigo-500 outline-none first:rounded-l last:rounded-r text-sm13  leading-4  px-2.5`}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#withdrowFunds1"
                                    aria-controls="withdrowFundsLabel1"
                                    onClick={() => setTransferPopUpOpen(true)}
                                  >
                                    <IKTSVG
                                      path={Topup}
                                      className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 mb-1 rotate-90 `}
                                      svgClassName="w-4 h-4"
                                    />
                                    Transfer
                                  </button>
                                )}
                            </div>
                          </TXHeader>

                          {/* PENDING FUNDS */}
                          <TXHeader
                            title="Pending funds"
                            amount={`${
                              headerOptions?.pending_amount
                                ? headerOptions?.pending_amount
                                : "0"
                            }`}
                            svgPath={ClockOutlinkSVG}
                            svgFillColor="fill-violet-800"
                            txPayLoading={txPayOverViewLoading}
                          >
                            <div className="text-xs text-gray-500 mt-auto flex items-center flex-wrap">
                              {orderText}
                            </div>
                          </TXHeader>

                          {/* TOTAL REVENUE */}
                          <TXHeader
                            title="Total revenue"
                            amount={`${
                              headerOptions?.total_revenue
                                ? headerOptions?.total_revenue
                                : "0"
                            }`}
                            txPayLoading={txPayOverViewLoading}
                          >
                            <div className="text-xs mt-auto flex items-center flex-wrap">
                              <p className="w-full mb-1">
                                YTD:&nbsp;
                                {isGodAdmin
                                  ? isGodadminBasedCurrency(
                                      appliedFilters?.team_id
                                    )
                                  : globalLayout.currencySymbol}
                                {headerOptions?.ytd ? headerOptions?.ytd : "0"}
                              </p>
                              <p className="text-gray-500 w-full">
                                Last year:{" "}
                                {isGodAdmin
                                  ? isGodadminBasedCurrency(
                                      appliedFilters?.team_id
                                    )
                                  : globalLayout.currencySymbol}
                                {headerOptions?.last_year
                                  ? headerOptions?.last_year
                                  : "0"}
                              </p>
                            </div>
                          </TXHeader>

                          {/* Account details */}
                          <div
                            className={`w-full max-sm:min-w-full max-lg:min-w-[50%] max-lg2:min-w-[40%] md:w-1/2 xl:w-1/4 mb-5 px-2.5 min-h-[174px] `}
                          >
                            <div
                              className={`border rounded-xl h-full ${
                                txPayOverViewLoading && "shimmer-effect"
                              } `}
                            >
                              <div className="top-row flex flex-wrap items-center justify-between w-full px-[15px] py-5">
                                <h3 className="text-sm14 leading-4">
                                  Wallet details
                                </h3>

                                {isSettinsAvailable && !isGodAdmin && (
                                  <div className="det_row text-sm12 text-right flex flex-wrap">
                                    <Link
                                      to={"/settings"}
                                      state={{ data: "TX Pay" }}
                                      className={`group inline-flex group items-center transition`}
                                    >
                                      <IKTSVG
                                        path={settingIcon}
                                        className="stroke-violet-500 group-hover:stroke-indigo-500"
                                      />
                                    </Link>
                                  </div>
                                )}
                              </div>
                              <div className="account_det_list px-[15px] overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-y-auto max-h-[115px]">
                                <div className="det_row text-sm12 mb-[15px]">
                                  <span className="block leading-[15px] mb-1 text-gray-500">
                                    Account holder
                                  </span>
                                  <span className="block leading-4">
                                    {headerOptions?.wallet_details?.name
                                      ? headerOptions?.wallet_details?.name
                                      : ""}
                                  </span>
                                </div>

                                <div className="det_row text-sm12 mb-[15px] flex items-center justify-between">
                                  <div>
                                    <span className="block leading-[15px] mb-1 text-gray-500">
                                      Base currency
                                    </span>
                                    <span
                                      className="block leading-[15px]"
                                      dangerouslySetInnerHTML={{
                                        __html: headerOptions?.wallet_details
                                          ?.base_currency
                                          ? headerOptions?.wallet_details
                                              ?.base_currency
                                          : "",
                                      }}
                                    ></span>
                                  </div>
                                </div>
                                {headerOptions?.withdrawal_account?.iban && (
                                  <div className="det_row text-sm12 mb-[15px] flex items-center justify-between">
                                    <div>
                                      <span className="block leading-[15px] mb-1 text-gray-500">
                                        IBAN
                                      </span>
                                      <span className="block leading-[15px]">
                                        {
                                          headerOptions?.withdrawal_account
                                            ?.iban
                                        }
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {headerOptions?.deposit_account?.bank_name &&
                                  isGodAdmin && (
                                    <div className="det_row text-sm12 mb-[15px] flex items-center justify-between">
                                      <div>
                                        <span className="block leading-4 mb-1 text-gray-500">
                                          Deposit account
                                        </span>
                                        {headerOptions?.deposit_account
                                          ?.bank_name && (
                                          <span className="block leading-4">
                                            Bank name :{" "}
                                            {
                                              headerOptions?.deposit_account
                                                ?.bank_name
                                            }{" "}
                                          </span>
                                        )}
                                        {headerOptions?.deposit_account
                                          ?.account_holder_name && (
                                          <span className="block leading-4">
                                            Account holder name :{" "}
                                            {
                                              headerOptions?.deposit_account
                                                ?.account_holder_name
                                            }{" "}
                                          </span>
                                        )}
                                        {headerOptions?.deposit_account
                                          ?.ac_number && (
                                          <span className="block leading-4">
                                            Account number :{" "}
                                            {
                                              headerOptions?.deposit_account
                                                ?.ac_number
                                            }{" "}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                {headerOptions?.withdrawal_account?.bank_name &&
                                  isGodAdmin && (
                                    <div className="det_row text-sm12 mb-[15px] flex items-center justify-between">
                                      <div>
                                        <span className="block leading-4 mb-1 text-gray-500">
                                          Withdraw account
                                        </span>
                                        {headerOptions?.withdrawal_account
                                          ?.bank_name && (
                                          <span className="block leading-4">
                                            Bank name :{" "}
                                            {
                                              headerOptions?.withdrawal_account
                                                ?.bank_name
                                            }{" "}
                                          </span>
                                        )}
                                        {headerOptions?.withdrawal_account
                                          ?.account_holder_name && (
                                          <span className="block leading-4">
                                            Account holder name :{" "}
                                            {
                                              headerOptions?.withdrawal_account
                                                ?.account_holder_name
                                            }{" "}
                                          </span>
                                        )}
                                        {headerOptions?.withdrawal_account
                                          ?.ac_number && (
                                          <span className="block leading-4">
                                            Account number :{" "}
                                            {
                                              headerOptions?.withdrawal_account
                                                ?.ac_number
                                            }{" "}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    {layout.activeTab === TX_TAB.virtualCard &&
                      !isGodAdmin &&
                      isVirtualCardAvailabel && (
                        <>
                          <div className="card_list flex flex-no-wrap overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                            <TxPayVirtualCard
                              cardData={virtualCardList}
                              onClick={(action: any, data: any) =>
                                handleOnVirtualCard(action, data)
                              }
                              virtualCardLoader={
                                virtualCardLoader || virtualCardFetching
                              }
                              isCardLimitOver={isCardLimitOver}
                              isBalanceLoading={
                                isBalanceLoading || isBalanceFetching
                              }
                              handleScrollToTop={handleScrollToTop}
                            />
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* FILTERS */}
            {filterEnabled && (
              <TXFilters
                options={
                  layout.activeTab === TX_TAB.wallet
                    ? txPayData?.data?.transaction_types
                    : layout.activeTab === TX_TAB.virtualCard
                    ? virtualCardOptions?.transaction_types
                    : []
                }
                query={
                  layout.activeTab === TX_TAB.wallet
                    ? query
                    : layout.activeTab === TX_TAB.virtualCard
                    ? virtualCardQuery
                    : ""
                }
                debouncedSearch={
                  layout.activeTab === TX_TAB.wallet
                    ? debouncedSearch
                    : layout.activeTab === TX_TAB.virtualCard
                    ? debouncedSearchForVirtualCard
                    : ""
                }
                handleQuery={(data: any) => {
                  if (layout.activeTab === TX_TAB.wallet) {
                    setQuery(data);
                  } else if (layout.activeTab === TX_TAB.virtualCard) {
                    setVirtualCardQuery(data);
                  }
                }}
                paginateData={
                  layout.activeTab === TX_TAB.wallet
                    ? txPayData?.meta
                    : layout.activeTab === TX_TAB.virtualCard
                    ? virtualTableList?.meta
                    : {}
                }
                handleKeyDown={(event: any) => handleKeyDown(event)}
                teamsOptions={teamsOptions}
                isGodAdmin={isGodAdmin}
                totalCount={
                  layout.activeTab === TX_TAB.wallet
                    ? txPayData?.meta?.total
                    : isGodAdmin
                    ? virtualTableList?.meta?.total
                    : virtualTableList?.data?.length
                }
                txPayLoading={
                  layout?.virtualFilterHandle?.page === 1 &&
                  (customReportLoading ||
                    isWalletLoading ||
                    virtualTableLoading ||
                    virtualTableFetching)
                }
                commonValue={
                  layout.activeTab === TX_TAB.wallet
                    ? layout.TXPayFilterHandle
                    : layout.activeTab === TX_TAB.virtualCard
                    ? { ...layout.virtualFilterHandle, query: virtualCardQuery }
                    : ""
                }
                appliedFilters={
                  layout.activeTab === TX_TAB.wallet
                    ? appliedFilters
                    : layout.activeTab === TX_TAB.virtualCard
                    ? { ...virtualAppliedFilters }
                    : ""
                }
                isVirtualCardTab={layout.activeTab === TX_TAB.virtualCard}
                commonHandleOnChange={(data: any, action: any, type: any) =>
                  handleOnCommonFilter(data, action, type)
                }
                activeCard={activeVirtualCard}
                setIsWalletLoading={setIsWalletLoading}
              />
            )}
          </div>
        </div>

        {layout.activeTab === TX_TAB.wallet && isWalletAvailabel && (
          <>
            {customReportLoading || isWalletLoading
              ? Array.from({ length: 10 }).map((_, i) => (
                  <div
                    key={i}
                    className={`accordion font-medium mb-2.5 mx-5 ${
                      i === 0 ? "mt-2.5" : ""
                    }`}
                  >
                    <div
                      className={`accordion-item !rounded overflow-hidden ${
                        txPayLoading ? "shimmer-effect" : ""
                      }`}
                      style={{ height: "2.5rem" }}
                    ></div>
                  </div>
                ))
              : layout.items.length > 0 && (
                  <DataTable
                    ledgerData={layout.items}
                    refetch={refetch}
                    paginateData={txPayData?.meta}
                    txPayLoading={txPayLoading}
                  />
                )}
          </>
        )}
        {layout.activeTab === TX_TAB.virtualCard && isVirtualCardAvailabel && (
          <Fragment>
            {(virtualTableLoading || virtualTableFetching) &&
            [undefined, 1].includes(layout.virtualFilterHandle?.page)
              ? Array.from({ length: 10 }).map((_, i) => (
                  <div
                    key={i}
                    className={`accordion font-medium mb-2.5 mx-5 ${
                      i === 0 ? "mt-2.5" : ""
                    }`}
                  >
                    <div
                      className={`accordion-item !rounded overflow-hidden ${
                        virtualTableLoading || virtualTableFetching
                          ? "shimmer-effect"
                          : ""
                      }`}
                      style={{ height: "2.5rem" }}
                    ></div>
                  </div>
                ))
              : (isGodAdmin || activeVirtualCard?.id) &&
                layout.virtualCardData?.length > 0 && (
                  // <VirtualCardTransactionTable
                  //   isGodAdmin={isGodAdmin}
                  //   data={layout.virtualCardData}
                  //   paginateData={virtualTableList?.meta}
                  //   perPage={virtualTablePerPage}
                  // />
                  <VirtualCardTabTable
                    data={layout.virtualCardData}
                    paginateData={virtualTableList?.meta}
                    balanceLoading={isbalanceLoading}
                  />
                )}

            {layout.virtualCardData?.length === 0 &&
              !virtualTableLoading &&
              !virtualTableFetching &&
              (activeVirtualCard?.id || isGodAdmin) && <NoDataComponent />}

            {!activeVirtualCard?.id &&
              virtualCardList?.filter((card: any) => card?.status !== "Pending")
                .length > 0 &&
              !isGodAdmin && (
                <span className="text-sm14 w-full text-center block text-gray-500 my-2.5">
                  Select a card to view transactions
                </span>
              )}
          </Fragment>
        )}

        {layout?.items?.length === 0 &&
          !customReportLoading &&
          !isWalletLoading &&
          layout.activeTab === TX_TAB.wallet && (
            <>
              <NoDataComponent />
            </>
          )}

        {customReportLoading && (
          <ProgressBardLoader
            LoadingText="Loading your TX Pay data"
            secondCounter={secondCounter}
          />
        )}
        <WithdrawFundsOffcanvas txPayData={headerOptions} refetch={refetch} />

        {/* KYC */}
        {checkCompleteKYC(globalLayout?.userDetailShow)?.status && (
          <KycOverlay
            isFixed={true}
            kycStatus={
              checkCompleteKYC(globalLayout?.userDetailShow)?.proofSize > 0
                ? true
                : false
            }
          />
        )}
      </div>
      <BankDetailPopup />

      {/* Setting Popup start */}
      <TransferPopup
        data={transferViewData?.data}
        options={virtualCardOptions}
        virtualCardList={virtualCardList}
        setVirtualCardList={setVirtualCardList}
        activeVirtualCard={activeVirtualCard}
        refetch={virtualDataeRefetch}
      />

      <SettingPopup
        data={settingViewData?.data}
        loader={virtualCardSettingLoader || isFetching}
        setData={(e: any) => {
          setSettingViewData((pre: any) => {
            return {
              ...pre,
              data: e,
            };
          });
        }}
        handleOnConfirm={handleOnUpdateVirtualCardData}
        submitLoader={updateVirtualCardLoader}
      />
      <AddVirtualCardPopup
        setVirtualCardList={setVirtualCardList}
        virtualCardListRefech={virtualCardListRefech}
        options={virtualCardOptions}
        headerOptions={headerOptions}
        isCardLimitOver={isCardLimitOver}
        remainingCardsNumber={remainingCardsNumber}
        // refetch={updateCardCount(globalLayout, setHeaderOptions, 1)}
      />

      {isShowVirtualCardPolicy && (
        <VirtualCardPolicy
          setIsVirtualCardpolicyOpen={setIsVirtualCardpolicyOpen}
        />
      )}

      {/* Setting Popup End */}
      {layout.activeTab === TX_TAB.wallet && isWalletAvailabel && (
        <>
          {updateVirtualCardLoader && <ProgressBardLoader secondCounter={2} />}

          <TransferFundPopup
            popupOpen={transferPopUpOpen}
            refetch={listingRefetch}
          />
        </>
      )}
    </>
  );
};

export default TXPay;
