import TableBody from "components/fullPopup/TableBody";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import { SYMBOLS } from "helpers/const";
import { size } from "lodash";
import moment from "moment";
import MinusOutline from "../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../assets/media/icons/other_icons/plus-outline.svg";
import UploadOutOutline from "../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../assets/media/icons/other_icons/upload-outline.svg";
import TableHeader from "../table/components/TableHeader";

const NavigationButtons = ({
  handleNavigation,
  imgPath,
  className,
  id,
  disabled,
}: any) => {
  return (
    <button
      className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
        id === "next" && "rotate-180"
      } ${disabled ? "pointer-events-none " : ""}`}
      type="button"
      id={id}
      onClick={() => handleNavigation(id)}
    >
      <KTSVG
        className={`flex items-center justify-center ${
          disabled ? `fill-gray-400` : `fill-violet-500`
        } transition group-hover:fill-indigo-500`}
        path={imgPath}
        svgClassName="w-1.5 h-[.5625rem]"
      />
    </button>
  );
};

const SubtransactionPopup = ({
  showItemDetail,
  setSubTransactionPopupData,
  subTransactionPopupData,
  list,
}: any) => {
  const handleNavigation = (data: any) => {
    let newIndex = subTransactionPopupData?.index;

    if (data === "back") {
      newIndex = newIndex === 0 ? list.length - 1 : newIndex - 1;
    } else {
      newIndex = newIndex === list.length - 1 ? 0 : newIndex + 1;
    }

    const preRecord = list[newIndex];

    setSubTransactionPopupData({
      show: true,
      data: preRecord,
      index: newIndex,
    });
  };

  return (
    <div
      className={` bg-white shadow-3xl w-full lg:h-full h-auto ml-auto fixed max-lg:h-full md:w-[calc(100%_-_3.75rem)] lg:relative flex flex-col z-10 transition lg:w-1/2 pointer-events-auto`}
    >
      <div className="offcanvas-header flex justify-between border-t border-b">
        <h5
          className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5 12121"
          id="offcanvasRightLabel"
        >
          {subTransactionPopupData?.data?.description}
        </h5>
        <div className="buttons flex flex-wrap">
          <button
            className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-10 h-full border-l"
            aria-label="Close"
            onClick={() => {
              setSubTransactionPopupData({
                show: false,
                data: {},
              });
            }}
          >
            <KTSVG
              className="flex items-center justify-center  group-hover:hidden transition"
              path="other_icons/arrow-r.svg"
              svgClassName="w-[.9375rem] h-[.6875rem]"
            />
            <KTSVG
              className="flex items-center justify-center hidden group-hover:block transition"
              path="other_icons/arrow-r-indigo.svg"
              svgClassName="w-[.9375rem] h-[.6875rem]"
            />
          </button>
        </div>
      </div>
      <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full">
        <div className="card_options">
          <TableBody
            border="border"
            renderArray={subTransactionPopupData?.data?.subTransaction}
          >
            <thead className="text-xs text-gray-400">
              <tr className="border-b bg-white">
                {/* Team name */}

                <TableHeader
                  title="Team name"
                  cellWidth="min-w-[11.25rem]"
                  isArrow={false}
                />
                <TableHeader
                  title="Card number"
                  cellWidth="min-w-[11.25rem]"
                  isArrow={false}
                />

                {/* Date */}
                <TableHeader
                  title="Date"
                  cellWidth="min-w-[9.375rem]"
                  isArrow={false}
                  postKey="date"
                />

                {/* Type */}
                <TableHeader
                  title="Type"
                  cellWidth="min-w-[6.25rem]"
                  isArrow={false}
                  postKey="type"
                />

                {/* In */}
                <TableHeader
                  title="In"
                  cellWidth="min-w-[9.375rem]"
                  isArrow={false}
                  postKey="in"
                />

                {/* Out */}
                <TableHeader
                  title="Out"
                  cellWidth="min-w-[9.375rem]"
                  isArrow={false}
                  postKey="out"
                />

                {/* Status */}
                <TableHeader
                  title="Status"
                  cellWidth="min-w-[9.375rem]"
                  isArrow={true}
                  postKey="status"
                />

                {/* Description */}
                <TableHeader
                  title="Description"
                  cellWidth="lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]"
                />
                {/* <th className="sticky right-0 ml-auto  p-0 w-[2.5625rem]">
                    <div className="flex justify-end h-[2.5rem]  bg-white w-[2.5625rem]"></div>
                  </th> */}
              </tr>
            </thead>
            <tbody>
              {subTransactionPopupData?.data?.subTransaction &&
              size(subTransactionPopupData?.data?.subTransaction) > 0 ? (
                subTransactionPopupData?.data?.subTransaction?.map(
                  (list: any, index: number) => {
                    const date = moment(list?.date, "DD/MM/YYYY HH:mm:ss");
                    const formatedDate = date?.format("DD MMM YYYY HH:mm:ss");
                    return (
                      <tr className="border-b">
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                        >
                          <p className="w-[9.0625rem]">
                            {list?.team_name ? `${list?.team_name}` : "-"}
                          </p>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                        >
                          <p className="w-[9.0625rem]">
                            {list?.card_number ? `${list?.card_number}` : "-"}
                          </p>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                        >
                          <p className="w-[8.125rem]">{formatedDate}</p>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                        >
                          <div className="credit flex items-center">
                            <IKTSVG
                              className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
                              path={
                                list?.type === "Credit"
                                  ? PlusOutLine
                                  : list?.type === "Debit"
                                  ? MinusOutline
                                  : list?.in
                                  ? UploadOutline
                                  : UploadOutOutline
                              }
                              svgClassName="w-3 h-3"
                            />
                            {list?.type}
                          </div>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap text-sm"
                        >
                          {list.in
                            ? `${SYMBOLS?.[list?.currency]}${list.in}`
                            : "-"}
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap text-sm"
                        >
                          {list.out
                            ? `${SYMBOLS?.[list?.currency]}${list.out}`
                            : "-"}
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                        >
                          <p className="w-[8.125rem]">{list?.status ?? "-"}</p>
                        </td>
                        <td
                          style={{ height: "2.5625rem" }}
                          className="py-1.5 px-2.5 font-medium ellips-text"
                        >
                          <p
                            className=" whitespace-nowrap text-ellipsis md:max-w-[31.25rem] max-w-[18.75rem]"
                            title={list.description}
                          >
                            {list.description ? `${list.description}` : "-"}
                          </p>
                        </td>
                      </tr>
                    );
                  }
                )
              ) : (
                <tr className="border-b bg-white">
                  <td colSpan={8} align="center" className="h-[2.8125rem]">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </TableBody>
        </div>
      </div>

      <div className="offcanvas-footer border-t py-3 px-5 false text-right">
        {/* BACK BUTTON */}
        <div className="r_btn gap-2.5 flex flex-wrap items-center">
          <div className="pop_nav flex items-center bg-gray-100 rounded">
            <NavigationButtons
              id="back"
              imgPath="standard_icons/angle_left.svg"
              handleNavigation={(data: any) => handleNavigation(data)}
              disabled={setSubTransactionPopupData?.index === 0}
              svgClassName={
                setSubTransactionPopupData?.index === 0
                  ? "!fill-gray-400"
                  : "enn"
              }
            />
            <NavigationButtons
              id="next"
              imgPath="standard_icons/angle_left.svg"
              handleNavigation={(data: any) => handleNavigation(data)}
              disabled={list.length - 1 === setSubTransactionPopupData?.index}
              svgClassName={
                list.length - 1 === setSubTransactionPopupData?.index
                  ? "!fill-gray-400"
                  : "enn"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubtransactionPopup;
