import clsx from "clsx";
import { memo, useContext, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import CrosHandIcon from "../../../../assets/media/icons/other_icons/CrosHandIcon";
import HandSVG from "../../../../assets/media/icons/other_icons/HandSVG";
import PPPIcon from "../../../../assets/media/icons/other_icons/PPPIconn";
import EditSquareSVG from "../../../../assets/media/icons/other_icons/edit-square.svg";
import logIcon from "../../../../assets/media/icons/other_icons/logSvg.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import NoJSXComponent from "../../../../components/noJSX/NoJSXComponent";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import Button from "../../../../components/ui/buttons/Button";
import ComponentSVG from "../../../../components/ui/typography/ComponentSVG";
import {
  colorForMeter,
  decimalNumber,
  isDateBeforeValidLogDate,
  priceCalculation,
} from "../../../../helpers/Functions";
import { pngImagePath, SYMBOLS } from "../../../../helpers/const";
import { TicketError } from "../../../add_inventory/core/_functions";
import MobileViewListingBlock from "../../../add_inventory/table/components/MobileViewListingBlock";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  categoryExist,
  visiblityMeterForBelowListing,
} from "../../core/_functions";
import TicketTypeExist from "../nonJSXComponent/TicketTypeExist";
import Image from "./mobileViewComponent/Image";
import RenderList from "./mobileViewComponent/RenderList";
import EventRecordStatus from "./viewableComponent/EventRecordStatus";
import PPPWrapper from "./viewableComponent/PPPWrapper";

const EventFieldView = memo(
  ({
    eventRecord,
    eIndex,
    rIndex,
    event,
    disabledAll,
    forRowDisabled,
    setCurrentRecordId,
    isTableEditable,
    firstSelectedIndex,
    getColumnItems,
    Messages,
  }: any) => {
    const globalLayout = useContext(LayoutContext);
    const layout = useContext(InventoryContext);
    const { paperTypeExist } = TicketTypeExist(event);
    const { isGodAdmin, price_suggestion_factor } = NoJSXComponent();
    const priceFactor = Number(price_suggestion_factor);

    let isMobile = globalLayout?.isMobile;
    const calculateRowClasses = () => {
      let rowDisabledClass = "";
      let forRowDisabledClass = "";

      if (
        (eventRecord?.selected === false ||
          eventRecord?.selected === undefined) &&
        isTableEditable.length > 0
      ) {
        rowDisabledClass = "row-disabled";
      } else if (
        (eventRecord?.selected === false ||
          eventRecord?.selected === undefined) &&
        isTableEditable?.length === 0
      ) {
        rowDisabledClass = "";
      } else if (
        eventRecord?.selected === true &&
        isTableEditable?.length === 0
      ) {
        rowDisabledClass = "";
      } else if (
        eventRecord?.selected === true &&
        isTableEditable?.length > 0
      ) {
        rowDisabledClass = "row-indigo-light row-indigo-light -selected";
        forRowDisabledClass = "pointer-events-none !border-gray-300";
      }

      return { rowDisabledClass, forRowDisabledClass };
    };

    const { rowDisabledClass, forRowDisabledClass } = useMemo(
      calculateRowClasses,
      [eventRecord?.selected, isTableEditable?.length]
    );

    const isEventRecordHoldTickets =
      eventRecord?.hold_tickets &&
      Object.keys(eventRecord?.hold_tickets)?.length > 0;

    const isEventRecordError =
      eventRecord?.fieldErrors &&
      Object.keys(eventRecord?.fieldErrors)?.length > 0;
    const isEventRecordBEError =
      eventRecord?.errors && Object.keys(eventRecord?.errors)?.length > 0;
    const proccedValue =
      typeof eventRecord?.sell_price === "object"
        ? decimalNumber(eventRecord?.sell_price?.value)
        : decimalNumber(eventRecord?.sell_price);

    let totalHold = 0;
    eventRecord?.hold_tickets &&
      Object.keys(eventRecord?.hold_tickets).length > 0 &&
      Object.values(eventRecord?.hold_tickets)?.map((holdData: any) => {
        totalHold = totalHold + holdData?.hold_quantity;
      });

    const rowClasses = [
      "view_row",
      "first_row",
      "flex",
      "columns-1",
      "h-[2.5625rem]",
      "border-b",
      "whitespace-nowrap",
      "column-view",
      "items-center",

      eventRecord?.bulkUpdate ? "row-disabled !bg-gray-300/40" : "",
      layout?.isBulkActionLoader ? "row-disabled !bg-gray-300/40" : "",
      eventRecord?.deleteLoader ? "fade-effect" : "",
      eventRecord?.bulkLoader ? "row-disabled" : "",
      paperTypeExist ? "delivery-type-exist" : "",
      layout.tableAlert?.confirm ? "pointer-events-none" : "",
      eventRecord?.id === firstSelectedIndex?.id && eventRecord?.selected
        ? "first"
        : "",
      event.value === "" ? "row-disabled" : "",
      eventRecord?.processing ? "row-processing" : "",
      rowDisabledClass,
      // eventRecord?.newRow ? "row-indigo-light ease-out duration-500" : "",
      isEventRecordHoldTickets ? "" : "", // Add your class here
      isEventRecordError || eventRecord?.status === "failed" ? "" : "", // Add your class here
      eventRecord?.status === "partPublished" ? "" : "", // Add your class here
      layout.isDeleteActive ? "pointer-events-none" : "",
      layout.isEditPopUpActive ? "pointer-events-none" : "",
      eventRecord?.selected && layout.confirmRowDelete ? "fade" : "",
      eventRecord?.deleteLoader ? "row-disabled" : "",
      eventRecord?.loader ||
      (eventRecord?.loader &&
        (eventRecord?.bulkLoader || layout?.updateAllLoader))
        ? "row-disabled"
        : "",
      layout.selectAllLoader
        ? "!row-disabled !pointer-events-none !bg-gray-300/40"
        : "",
      isGodAdmin && eventRecord?.isLock === true ? "bg-gray-300/40" : "",
    ];

    const isALLTicketUploadedWithPublish =
      (Number(eventRecord?.listing_ticket_files.length) > 0 ||
        Number(eventRecord?.mobile_links?.length) > 0) &&
      Number(eventRecord?.quantity_available) > 0 &&
      Number(eventRecord?.quantity_available) ===
        (Number(eventRecord?.listing_ticket_files?.length) ||
          Number(eventRecord?.mobile_links?.length)) &&
      eventRecord?.status === "yes";

    const isPublishedWithGod =
      (isALLTicketUploadedWithPublish && !isGodAdmin) ||
      (isGodAdmin &&
        isALLTicketUploadedWithPublish &&
        eventRecord?.isLock === false);

    return (
      <div
        className={clsx(
          "view_row",
          (isEventRecordError ||
            eventRecord?.status === "failed" ||
            isEventRecordBEError) &&
            "row-error-new group-hover:bg-rose-550",
          isEventRecordHoldTickets &&
            "group-hover:bg-sky-blue-300 row-hold-new",
          eventRecord?.processing && "row-processing-new",
          isPublishedWithGod && "bg-white"
        )}
        onMouseEnter={() => {
          if (layout.isTableEditable?.length === 0 && !isMobile) {
            setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
          }
        }}
        onMouseLeave={(e: any) => {
          if (layout.isTableEditable?.length > 0 && !isMobile) {
            setCurrentRecordId(undefined);
          }
        }}
        onTouchStart={() => {
          if (
            !(layout.isTableEditable?.length > 0 && !eventRecord?.selected) &&
            !isMobile
          ) {
            setCurrentRecordId({ recordIndex: rIndex, eventIndex: eIndex });
          }
        }}
      >
        <div className={`${rowClasses.join(" ")}`}>
          {isGodAdmin && (
            <div
              className={`lock-img-view td_block checkbox_td td_block zero-col z-[3] ${
                isPublishedWithGod && "bg-white"
              }`}
              id="checkbox_shad_lock"
            >
              <div className="lock-icon relative w-full h-full border-r  ">
                <label
                  className={`cursor-pointer w-full h-full flex items-center justify-center ${
                    eventRecord?.isLock === true ? " lock" : "unlock"
                  }`}
                >
                  <Image
                    className="w-[.8125rem] h-[.9375rem]"
                    path={
                      eventRecord?.isLock === true
                        ? `${pngImagePath}lock.png`
                        : `${pngImagePath}unlock.png`
                    }
                  />
                </label>
              </div>
            </div>
          )}
          <div
            className={`td_block checkbox_td first-col bg-inherit z-[3] overflow-hidden ${
              isGodAdmin ? "left-10" : "left-0"
            }
              ${isPublishedWithGod && "bg-white"}
              `}
          >
            <label
              className={clsx(
                "bg-inherit select-none w-full h-full top-0 left-0 absolute cursor-pointer p-1 border-r group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]",
                forRowDisabled,
                disabledAll && "pointer-events-none",
                eventRecord?.selected && "bg-indigo-500 bg-opacity-[7%]",
                ((eventRecord?.errors &&
                  Object.keys(eventRecord?.errors || {}).length > 0) ||
                  eventRecord?.status === "failed") &&
                  "row-error-new bg-opacity-[5%] !group-hover:bg-rose-550 group-hover:bg-opacity-[5%]",
                (isEventRecordError ||
                  eventRecord?.status === "failed" ||
                  isEventRecordBEError) &&
                  "row-error-new !group-hover:bg-rose-550"
              )}
              htmlFor={`checkbox-all-${eIndex}${rIndex}`}
            >
              {eventRecord?.cancelled_order_id && (
                <span className="triangle-up"></span>
              )}
            </label>
            <input
              name="selected"
              id={`checkbox-all-${eIndex}${rIndex}`}
              type="checkbox"
              checked={eventRecord?.selected ? true : false}
              readOnly
              className={`${forRowDisabledClass} ${
                disabledAll && "pointer-events-none "
              }`}
            />
          </div>

          {/* BLOCKS */}
          <RenderList
            columns={getColumnItems}
            event={event}
            eventRecord={eventRecord}
          />

          <div
            className={`twentythree-col ${
              layout.proceedPriceToggle &&
              "fixed_price_proceed !min-w-[22.125rem]"
            }`}
          >
            {layout.isTableEditable?.length === 0 &&
            eventRecord?.isInputChange ? (
              <div
                className={`accordion-body 
                    ${eventRecord?.loader && "list-loading"} 
                    ${layout?.isBulkActionLoader && "list-loading"} 
                    left-shad ${
                      layout.proceedPriceToggle && "!justify-between !p-0"
                    }
                `}
                id="left-shad-list"
              >
                {layout.proceedPriceToggle && (
                  <MobileViewListingBlock
                    key={`${eIndex}-${rIndex}-${eventRecord?.id}-mobile-sell-price`}
                    className={
                      "eighteen-col flex items-center flex-1 !max-w-[8.1819rem] border-r !px-2.5 !py-0 h-full"
                    }
                    data-sybmol-attribute={
                      eventRecord?.sell_price_currency
                        ? SYMBOLS[eventRecord?.sell_price_currency]
                        : SYMBOLS[eventRecord?.sell_price?.symbol]
                    }
                    value={proccedValue}
                    placeholder="Proceed Price"
                  />
                )}
                <div className="btn-wrap flex items-center flex-1 justify-center">
                  {/* CANCEL RECORD BUTTON */}
                  <button type="button" className="cancel-view">
                    <Image
                      path={`${pngImagePath}cross.png`}
                      className="w-[.5966rem] h-[.5966rem]"
                    />
                  </button>

                  {/* UPDATE RECORD BUTTON */}
                  <button type="button" className="upload-view">
                    <Image
                      path={`${pngImagePath}Check.png`}
                      className="w-[.7585rem] h-[.6452rem]"
                    />
                  </button>
                </div>
              </div>
            ) : (
              <div
                className={`1232 st_icons left-shad ${
                  isPublishedWithGod && "bg-white"
                } ${
                  isEventRecordHoldTickets &&
                  "row-hold-new group-hover:bg-sky-blue-300"
                } ${
                  isEventRecordError ||
                  (isEventRecordBEError &&
                    "row-error-new group-hover:bg-rose-550")
                } ${layout.proceedPriceToggle && "flex justify-between"}`}
                id="left-shad-list"
              >
                {layout.proceedPriceToggle && (
                  <MobileViewListingBlock
                    key={`${eIndex}-${rIndex}-${eventRecord?.id}-mobile-sell-price`}
                    className={
                      "eighteen-col flex items-center flex-1 !max-w-[8.1819rem] border-r !px-2.5 !py-0"
                    }
                    data-sybmol-attribute={
                      eventRecord?.sell_price_currency
                        ? SYMBOLS[eventRecord?.sell_price_currency]
                        : SYMBOLS[eventRecord?.sell_price?.symbol]
                    }
                    value={proccedValue}
                    placeholder="Proceed Price"
                  />
                )}
                <div className={`icon-wrap`}>
                  <div className="icon-group flex justify-between items-center min-w-[6.375rem] p-3 border-r">
                    <button
                      className={`group flex flex-auto items-center justify-center `}
                      type="button"
                    >
                      <ComponentSVG
                        className={
                          eventRecord?.price_per_exchange
                            ? "fill-green-500"
                            : "fill-violet-400"
                        }
                      >
                        <PPPIcon
                          className={`w-3.5 h-4 group-hover:fill-indigo-500 `}
                        />
                      </ComponentSVG>
                    </button>

                    {/* TICKETS IN HAND */}
                    <Button className="group flex flex-auto items-center justify-center ">
                      <ComponentSVG>
                        {eventRecord?.ticketsInHands ? (
                          <HandSVG
                            className={`w-3.5 h-4 group-hover:fill-indigo-500 ${
                              Number(eventRecord?.quantity_available) > 0 &&
                              Number(eventRecord?.quantity_available) ===
                                (Number(
                                  eventRecord?.listing_ticket_files?.length
                                ) || Number(eventRecord?.mobile_links?.length))
                                ? "fill-gray-400"
                                : "fill-green-600"
                            } `}
                          />
                        ) : (
                          <CrosHandIcon
                            className={
                              "fill-violet-500 w-3.5 h-4 group-hover:fill-indigo-500"
                            }
                          />
                        )}
                      </ComponentSVG>
                    </Button>
                    <button
                      className={`group flex flex-auto items-center justify-center `}
                      type="button"
                    >
                      <Image
                        path={`${
                          eventRecord?.ticket_type?.id !== "mobile-qr" &&
                          eventRecord?.ticket_type?.id !== "pdf" &&
                          eventRecord?.ticket_type !== "eticket" &&
                          eventRecord?.ticket_type !== "mobile-link" &&
                          eventRecord?.ticket_type?.id !== "mobile-link"
                            ? `${pngImagePath}upload-disable.png`
                            : Number(eventRecord?.quantity_available) > 0 &&
                              Number(eventRecord?.quantity_available) ===
                                (Number(
                                  eventRecord?.listing_ticket_files?.length
                                ) || Number(eventRecord?.mobile_links?.length))
                            ? `${pngImagePath}upload-success.png`
                            : eventRecord?.fieldErrors?.[
                                "quantity_available"
                              ] === TicketError
                            ? `${pngImagePath}upload-error.png`
                            : `${pngImagePath}upload.png`
                        }`}
                        className="w-[.8125rem] h-[.6875rem]"
                        alt="upload"
                      />
                    </button>
                  </div>

                  <button
                    className={`flex flex-auto items-center justify-center p-1 w-10 md:max-w-[2.5rem] h-[2.5rem] border-r`}
                    type="button"
                    id="visibility_addInventory"
                  >
                    <div
                      id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                      className={` table-meter flex gap-[.0875rem] svg-wrap
                        
                        ${
                          categoryExist(event, eventRecord, "withcategory")
                            ? colorForMeter(
                                eventRecord?.avg_price
                                  ? priceCalculation(
                                      typeof eventRecord?.sell_price ===
                                        "object"
                                        ? eventRecord?.sell_price?.value
                                        : eventRecord?.sell_price,
                                      eventRecord?.avg_price
                                        ? Number(eventRecord?.avg_price)
                                        : null,
                                      priceFactor
                                    )
                                  : false
                              )
                            : ""
                        } 
                        
                        ${
                          layout.animationForVisibility?.eIndex === eIndex &&
                          layout.animationForVisibility?.rIndex === rIndex &&
                          layout.animationForVisibility?.flag
                            ? ` visibility-case${
                                eventRecord?.oldVisibility > 2
                                  ? eventRecord?.oldVisibility - 2
                                  : eventRecord?.oldVisibility
                              }-${visiblityMeterForBelowListing(
                                eventRecord?.oldVisibility,
                                eventRecord?.visibility
                              )}`
                            : `visibility-case${visiblityMeterForBelowListing(
                                eventRecord?.oldVisibility,
                                eventRecord?.visibility
                              )}`
                        }
                        `}
                    >
                      <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-l-[1px]"></span>
                      <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                      <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                      <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-r-[1px]"></span>
                    </div>
                  </button>

                  {/* PUBLISH & UNPUBLISHED */}
                  <button
                    className={`status-view collapsed `}
                    type="button"
                    id={`status-${event?.id}-${eventRecord?.id}`}
                  >
                    {eventRecord?.status === "yes" ? (
                      <Image
                        path={`${pngImagePath}HaxPublish.png`}
                        className="w-[1rem] h-[1.155rem]"
                      />
                    ) : (
                      <Image
                        path={`${pngImagePath}unpublish.png`}
                        className="w-[1rem] h-[1.155rem]"
                      />
                    )}
                  </button>

                  {/* LOGGING */}
                  <button
                    className={`status-view collapsed border-l ${
                      isDateBeforeValidLogDate(eventRecord?.created_at) &&
                      !isGodAdmin
                        ? "pointer-events-none"
                        : ""
                    }`}
                    type="button"
                    disabled={
                      isDateBeforeValidLogDate(eventRecord?.created_at) &&
                      !isGodAdmin
                    }
                    id={`logs-${event?.id}-${eventRecord?.id}`}
                  >
                    <Tooltip
                      anchorId={`logs-${event?.id}-${eventRecord?.id}`}
                      content={`Log`}
                      variant="light"
                      className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10`}
                    />
                    <IKTSVG
                      className={`flex items-center justify-center ${
                        isDateBeforeValidLogDate(eventRecord?.created_at) &&
                        !isGodAdmin
                          ? "fill-gray-500"
                          : "fill-violet-500"
                      } group-hover:fill-indigo-500 transition`}
                      path={logIcon}
                      svgClassName="w-4 h-4"
                    />
                  </button>
                </div>
              </div>
            )}
          </div>

          <div
            className={`sticky md:hidden right-0 p-0  bg-white ml-auto min-w-[1.875rem] cursor-pointer h-full z-10`}
          >
            <IKTSVG
              className={`fill-violet-500 border-l flex items-center justify-center transition bg-inherit h-full`}
              svgClassName="w-3 h-3"
              path={EditSquareSVG}
            />
          </div>
        </div>

        {/* PPE BLOCK */}
        <PPPWrapper eventRecord={eventRecord} eIndex={eIndex} />
        {/* ALL ERRORS, PROCESSING, TICKET HOLD, RESTRICTIONS */}
        <EventRecordStatus
          event={event}
          eventRecord={eventRecord}
          eIndex={eIndex}
          rIndex={rIndex}
        />
      </div>
    );
  }
);

export default EventFieldView;
