import { AddIcon } from "assets/media/icons/other_icons/AddIcon";
import { DeleteIcon } from "assets/media/icons/other_icons/DeleteIcon";
import clsx from "clsx";
import { LayoutContext } from "components/core/LayoutProvider";
import TextInput from "components/formComponent/TextInput";
import HeaderDetail from "components/sidePopup/HeaderDetail";
import { useFormik } from "formik";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import AlgoliaAll from "pages/customize/component/UI/AlgoliaAll";
import { venueDetailsButtonTypes } from "pages/customize/core/_.model";
import { useContext, useRef } from "react";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import { ErrorMessage } from "../core/_functions";
import { updateVariation } from "../core/requests";
import SectionSearch from "./SectionSearch";

interface viewForm {
  section: any;
  venue: any;
  sectionType: string;
  variation: string;
}

interface FormValues {
  variationDetails: viewForm[];
  isDelete: number | undefined;
  updatedRecords: Set<number>;
}

// Define validation schema with Yup
const validationSchema = Yup.object({
  variationDetails: Yup.array().of(
    Yup.object({
      section: Yup.object()
        .shape({
          name: Yup.string().required("Category/section is required"),
        })
        .required("Category/section is required"),
      variation: Yup.string()
        .matches(/^[^\s].*$/, "Variation cannot start with a space")
        .required("Variation is required"),
    })
  ),
});

const AddNewVariation = ({
  referralID,
  isShowForm,
  setState,
  listRefetch,
}: any) => {
  const coreLayout = useContext(LayoutContext);
  const venueRef: any = useRef<any>();
  const sectionRef: any = useRef<any>();

  // FORMIK
  const formik = useFormik<FormValues>({
    initialValues: {
      variationDetails: [
        {
          section: { reseller_id: referralID, id: "", name: "" },
          sectionType: "Category",
          venue: { id: "", name: "" },
          variation: "",
        },
      ],
      isDelete: undefined,
      updatedRecords: new Set(),
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      const localPayload = values?.variationDetails?.filter(
        (_: any, idx: number) => values?.updatedRecords.has(idx)
      );

      // UPDATE VARIATION
      const response = await updateVariation(localPayload);

      // ON SUCCESS
      if (response?.data && size(response?.data) > 0) {
        showAlert(response?.message, false);
        formik.setFieldValue("updatedRecords", new Set());
        listRefetch();
        setState(false);
        formik.resetForm();
      } else {
        // ON FAILED
        showAlert(
          response?.errors?.title
            ? response?.errors?.title
            : Array.isArray(response?.errors)
            ? response?.errors?.join("<br/>")
            : "Something went wrong!",
          true
        );
      }
    },
  });

  // CLOSE FORM
  const handleClosePopup = () => {
    formik.resetForm();
    setState(false);
    venueRef?.current.hide();
    sectionRef?.current.hide();
  };

  // HANDLE SECTION HIT SELECT
  const handleSectionHit = (data: any, index: number) => {
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
    formik.setFieldValue(
      `variationDetails.${index}.sectionType`,
      data?.componentType?.name
    );
    formik.setFieldValue(`variationDetails.${index}.section`, {
      ...formik.values.variationDetails[index].section,
      name: data?.name,
    });
  };

  // HANDLE VENUE SELECTION
  const handleHitVenue = (data: any, index: number, currentRecord: any) => {
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );

    formik.setFieldValue(`variationDetails.${index}.venue`, {
      id: data?.id,
      name: data?.name,
    });

    const params = {
      name: "",
      componentType: {
        name: currentRecord?.sectionType,
      },
    };
    handleSectionHit(params, index);
  };

  // HANDLE CLEAR VALUE
  const handleClearValue = (name: string, index: number) => {
    const updatedVariation = formik.values.variationDetails?.map(
      (item: any, sIndex: number) =>
        sIndex === index ? { ...item, [name]: "" } : item
    );
    formik.setFieldValue("variationDetails", updatedVariation);
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
  };

  // HANDLE ONCHANGE
  const handleOnChange = (event: any, index: any) => {
    // ONLY UPDATED RECORDS
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
    formik.handleChange(event);
  };

  // ADD NEW REFERRAL VALUE
  const handleAddReferral = () => {
    const resellerId: string | number = referralID;
    formik.setFieldValue("variationDetails", [
      ...formik.values.variationDetails,
      { section: { reseller_id: resellerId, name: "" }, variation: "" },
    ]);
  };

  // HANDLE DROPDOWN RESET
  const handleResetValueOfDropdown = (fieldName: string, index: number) => {
    formik.setFieldValue(
      "updatedRecords",
      new Set(formik.values.updatedRecords).add(index)
    );
    formik.setFieldValue(`variationDetails.${index}.${fieldName}`, {
      ...(fieldName === "section" ? { reseller_id: referralID } : {}),
      id: "",
      name: "",
    });
  };

  // HANDLE DELETE
  const handleDeleteRow = (index: number) => {
    const filteredRows = formik.values.variationDetails?.filter(
      (_, idx: number) => idx !== index
    );
    formik.setFieldValue("variationDetails", filteredRows);
    if (filteredRows?.length === 0) formik.setTouched({}, false);
    else {
      const newTouched = { ...formik.touched };
      if (newTouched.variationDetails) {
        newTouched.variationDetails = [...newTouched.variationDetails];
        newTouched.variationDetails.splice(index, 1);
      }

      formik.setTouched(newTouched);
    }
  };

  return (
    <div
      className={clsx(
        `offcanvas buy_canvas s_ticket popupinput-style2 offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  md:max-w-[calc(100%_-_3.75rem)] max-w-full w-full`,
        isShowForm && "show",
        coreLayout.asideMenuOpen && "lg2:max-w-[calc(100%_-_13.875rem)]"
      )}
      tabIndex={-1}
      id="singleTicket"
      aria-labelledby="singleTicketLabel"
    >
      <div className="bg-white border-t w-full h-full ml-auto flex flex-col">
        <HeaderDetail
          label={"Add new variation"}
          labelClass={`capitalize`}
          handleClosePopup={handleClosePopup}
          tooltipId="add-new-close"
        />

        {/* BODY */}
        <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
          {formik.values.variationDetails?.map((list: any, index: number) => {
            return (
              <form className="pb-5" onSubmit={formik.handleSubmit}>
                <div
                  className={clsx(
                    "w-full flex flex-wrap md:gap-y-4 gap-y-2.5 p-2.5 md:p-5 border rounded transition",
                    formik.values.updatedRecords?.has(index) &&
                      "bg-indigo-500 bg-opacity-[7%]"
                  )}
                >
                  {/* DELETE */}
                  <div className="delButon pr-2.5">
                    <button
                      type="button"
                      data-tooltip-id={`delete-${index}-new`}
                      className={clsx(
                        `border group inline-flex group items-center font-medium rounded text-sm13 px-2.5 md:py-0.5 py-2.5 max-h-[1.875rem] h-full`
                      )}
                      onClick={() => {
                        handleDeleteRow(index);
                      }}
                    >
                      <div
                        className={clsx(
                          "w-3 h-[.9375rem] flex items-center justify-center  rounded-full cursor-pointer fill-violet-500 hover:fill-indigo-500",
                          // formik.values.updatedRecords?.size > 0
                          false
                            ? "!fill-gray-500"
                            : "fill-violet-500 hover:fill-indigo-500"
                        )}
                      >
                        <Tooltip
                          id={`delete-${index}-new`}
                          content="Delete"
                          place="top"
                          variant="light"
                          className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-[99999] `}
                        />
                        <DeleteIcon className="w-3 h-[.9375rem]" />
                      </div>
                    </button>
                  </div>

                  <div className="flex flex-wrap md:flex-1 md:gap-y-4 gap-y-2.5">
                    {/* VENUE DROPDOWN */}
                    <div
                      className={`w-full  "lg:w-1/2  lg2:w-[40%] md:pl-2.5 lg2:pr-2.5`}
                    >
                      <div
                        className={`relative rounded flex-1 max-w-full md:mb-0 border-rose-500 `}
                      >
                        {isShowForm ? (
                          <div className="w-full">
                            <AlgoliaAll
                              reff={venueRef}
                              buttonTypes={["Venue"]}
                              width={"full"}
                              id={`venue-new-variation-${index}`}
                              index={`venue-new-variation-${index}`}
                              inputValue={list?.venue?.name}
                              value={list?.venue}
                              isVenueFullNameRequired={true}
                              handleHitSelect={(data: any) =>
                                handleHitVenue(data, index, list)
                              }
                              handlerResetValue={() =>
                                handleResetValueOfDropdown("venue", index)
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* SECTION SEARCH  */}

                    {isShowForm ? (
                      <SectionSearch
                        reff={sectionRef}
                        buttonTypes={venueDetailsButtonTypes}
                        index={`new-variation-${index}`}
                        inputValue={list?.section?.name}
                        value={list?.section}
                        handleHitSelect={(data: any) =>
                          handleSectionHit(data, index)
                        }
                        isUpdatedRecord={formik.values.updatedRecords.has(
                          index
                        )}
                        error={
                          (formik.errors.variationDetails?.[index] as any)
                            ?.section &&
                          (formik.touched.variationDetails?.[index] as any)
                            ?.section
                        }
                        errorLabel={
                          (formik.errors.variationDetails?.[index] as any)
                            ?.section?.name
                        }
                        type={
                          formik?.values?.variationDetails?.[index]?.sectionType
                        }
                        venueId={
                          formik?.values?.variationDetails?.[index]?.venue?.id
                        }
                        handlerResetValue={() =>
                          handleResetValueOfDropdown("section", index)
                        }
                      />
                    ) : null}

                    {/* NEW VARIATION */}
                    <div className="relative w-full md:w-1/2 rounded lg2:w-[20%] md:pl-2.5">
                      <div className={`rounded`}>
                        <TextInput
                          name={`variationDetails.${index}.variation`}
                          type="text"
                          value={list?.variation}
                          required={false}
                          placeholder={"Enter variation"}
                          inputClassName="appearance-none border border-gray-300 rounded w-full h-[1.875rem] px-2.5 pt-[0.4375rem] pb-2 focus:ring-0 focus:border-indigo-500 focus:bg-indigo-500 focus:bg-opacity-[7%] placeholder:truncate placeholder-shown:text-gray-400 block font-medium text-xs transition bg-white truncate"
                          handleOnChange={(e: any) => handleOnChange(e, index)}
                          handleClearValue={() =>
                            handleClearValue("variation", index)
                          }
                          errorClass={ErrorMessage(
                            formik,
                            index,
                            "variation",
                            "className"
                          )}
                          labelErrorClass={ErrorMessage(
                            formik,
                            index,
                            "variation",
                            "className"
                          )}
                        />
                        {ErrorMessage(formik, index, "variation", "") && (
                          <div className="text-xs text-rose-500 error-msg left-5">
                            {ErrorMessage(formik, index, "variation", "")}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          })}

          <div className="flex gap-2">
            <button
              type="button"
              className={clsx(
                "text-white hover:text-white bg-violet-500 hover:bg-indigo-500 group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5",
                formik.values.isDelete !== undefined &&
                  "hidden pointer-events-none"
              )}
              onClick={handleAddReferral}
              disabled={formik.values.isDelete !== undefined ? true : false}
            >
              <AddIcon
                className={clsx(
                  "w-3.5 h-3 flex items-center justify-center  transition mr-2 fill-white"
                )}
              />
              Add
            </button>
          </div>
        </div>

        {/* FOOTER */}
        {formik.values.variationDetails?.length > 0 ? (
          <div className=" offcanvas-footer bottom-6 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                data-bs-dismiss="offcanvas"
                onClick={handleClosePopup}
              >
                Cancel
              </button>
              <button
                type="submit"
                onClick={(e: any) => {
                  formik.handleSubmit();
                }}
                className={`relative z-10 border border-green-600 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 `}
              >
                Submit
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AddNewVariation;
