import { LayoutContext } from "components/core/LayoutProvider";
import { IKTSVG } from "components/ui/IKTSVG";
import { SYMBOLS } from "helpers/const";
import { decimalNumber, handleSetCopyFilters } from "helpers/Functions";
import moment from "moment";
import CardTransactionPopup from "pages/tx_pay/component/CardTransactionPopup";
import VirtualCardTranscationViewPopup from "pages/tx_pay/component/VirtualCardTranscationViewPopup";
import { TXContext } from "pages/tx_pay/core/TXProvider";
import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import EyeSVG from "../../../../assets/media/icons/other_icons/Eye.svg";
import MinusOutline from "../../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../../assets/media/icons/other_icons/plus-outline.svg";
import UploadOutOutline from "../../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../../assets/media/icons/other_icons/upload-outline.svg";
import angleLeftSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import DynamicDataTable from "./DynamicDataTable";

const getColumns: any = (setVirtualCardTransPopup: any) => [
  {
    title: "Team name",
    cellWidth: "min-w-[11.25rem]",
    isArrow: false,
    postKey: "team_name",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) => (list?.team_name ? `${list?.team_name}` : "-"),
  },
  {
    title: "Card number",
    cellWidth: "min-w-[11.25rem]",
    isArrow: false,
    postKey: "card_number",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) =>
      list?.card_number ? `${list?.card_number}` : "-",
  },
  {
    title: "Date",
    cellWidth: "min-w-[9.375rem]",
    isArrow: false,
    postKey: "date",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) => {
      const date = moment(list?.date, "DD/MM/YYYY HH:mm:ss");

      // Format the date as DD MMM YY
      const formatedDate = date.format("DD MMM YYYY HH:mm:ss");
      return formatedDate;
    },
  },
  {
    title: "Type",
    cellWidth: "min-w-[6.25rem]",
    isArrow: false,
    postKey: "type",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) => (
      <div className="credit flex items-center">
        <IKTSVG
          className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
          path={
            list?.type === "Credit"
              ? PlusOutLine
              : list?.type === "Debit"
              ? MinusOutline
              : list?.in
              ? UploadOutline
              : UploadOutOutline
          }
          svgClassName="w-3 h-3"
        />
        {list?.type}
      </div>
    ),
  },
  {
    title: "In",
    cellWidth: "min-w-[9.375rem]",
    isArrow: false,
    postKey: "in",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) =>
      list.in ? `${SYMBOLS?.[list?.currency]}${list.in}` : "-",
  },
  {
    title: "Out",
    cellWidth: "min-w-[9.375rem]",
    isArrow: false,
    postKey: "out",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) =>
      list.out ? `${SYMBOLS?.[list?.currency]}${list.out}` : "-",
  },
  {
    title: "Status",
    cellWidth: "min-w-[9.375rem]",
    isArrow: false,
    postKey: "status",
    height: "2.5625rem",
    className: `py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text  `,
    // getValue: ({ list }: any) => list?.status,
    getValue: ({ list }: any) => (
      <span
        className={`px-1.5 py-1 rounded-md text-white ${
          list.status?.toLowerCase() === "pending"
            ? "bg-yellow-100"
            : list.status?.toLowerCase() === "cleared" ||
              list.status?.toLowerCase() === "completion" ||
              list.status?.toLowerCase() === "success" ||
              list.status?.toLowerCase() === "refunded"
            ? "bg-green-600"
            : list.status?.toLowerCase() === "declined" ||
              list.status?.toLowerCase() === "voided"
            ? "bg-rose-500"
            : "bg-green-600"
        }`}
      >
        {list?.status}
      </span>
    ),
  },
  {
    title: "Description",
    cellWidth: "lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]",
    isArrow: false,
    postKey: "type",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) =>
      list.description ? `${list.description}` : "-",
  },
  {
    getTitle: ({ scroll }: any) => (
      <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] max-md:static">
        <div
          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
          id="left-shad-list"
        >
          <div className="arrow_buttons flex rounded ">
            <button
              className="group rounded-l  transition px-[.5625rem] py-2"
              type="button"
              id="leftArrow"
              onClick={(e: any) => scroll(-120)}
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                path={angleLeftSVG}
                svgClassName="w-1.5 h-[.5625rem]"
              />
            </button>
            <button
              className="group rounded-r  transition px-[.5625rem] py-2"
              type="button"
              onClick={(e: any) => scroll(120)}
              id="rightArrow"
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                path={angleRightSVG}
                svgClassName="w-1.5 h-[.5625rem]"
              />
            </button>
          </div>
        </div>
      </th>
    ),
    cellWidth: "min-w-[9.375rem]",
    isArrow: false,
    postKey: "type",
    height: "2.5625rem",
    className: "py-1.5 px-2 font-medium whitespace-nowrap ellips-text",
    direct: true,
    getValue: ({ list, tIndex }: any) => {
      const matchedView = false;
      return (
        <td className="group sticky right-0 ml-auto p-0 icon_td ">
          <React.Fragment key={tIndex}>
            <div
              className={`${
                matchedView ? `bg-indigo-500 bg-opacity-[7%]` : ""
              }`}
            >
              <div className={`flex  relative w-full h-full border-l `}>
                <button
                  className={`group flex items-center justify-center p-1 md:p-1 w-10 md:w-full h-[2.5625rem]`}
                  type="button"
                  id={`view-tx-pay-${tIndex}`}
                  onClick={() => {
                    setVirtualCardTransPopup((pre: any) => {
                      return {
                        ...pre,
                        status: true,
                        index: tIndex,
                        data: list,
                      };
                    });
                    // handleOrderView(
                    //   tIndex,
                    //   index,
                    //   transaction?.id,
                    //   transaction?.transfer_file
                    // );
                    // isMobileView &&
                    //   (document.body.style.overflow =
                    //     "hidden");
                  }}
                  disabled={!(list?.subTransaction.length > 0)}
                >
                  {list?.subTransaction.length > 0 && (
                    <Tooltip
                      anchorId={`view-tx-pay-${tIndex}`}
                      content={`${
                        // showLedger?.index === tIndex
                        //   ? "Close"
                        //   : "View"
                        "View"
                      }`}
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                    />
                  )}
                  <IKTSVG
                    className={`flex items-center justify-center ${
                      matchedView ? "fill-indigo-500" : "fill-violet-500"
                    } ${
                      matchedView
                        ? "group-hover:fill-indigo-500"
                        : "group-hover:fill-indigo-500"
                    } transition`}
                    path={EyeSVG}
                    svgClassName={`w-4 h-[.6875rem] ${
                      !(list?.subTransaction.length > 0) ? "opacity-0" : ""
                    }`}
                  />
                </button>
              </div>
            </div>
          </React.Fragment>
        </td>
      );
    },
  },
];

const getGodadminColumns: any = (
  setVirtualCardTransPopup: any,
  virtualCardTransPopup: any
) => [
  {
    title: "Team name",
    cellWidth: "min-w-[11.25rem]",
    isArrow: false,
    postKey: "team_name",
    height: "2.5625rem",
    className:
      "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text w-[9.0625rem]",
    getValue: ({ list }: any) => (
      <p className="w-[9.0625rem]">
        {list?.team_name ? `${list?.team_name}` : "-"}
      </p>
    ),
  },
  {
    title: "Team member",
    cellWidth: "min-w-[11.25rem]",
    isArrow: false,
    postKey: "team_member",
    height: "2.5625rem",
    className:
      "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text w-[9.0625rem]",
    getValue: ({ list }: any) => (
      <p className="w-[9.0625rem]">
        {list?.team_member ? `${list?.team_member}` : "-"}
      </p>
    ),
  },
  {
    title: "Card number",
    cellWidth: "min-w-[11.25rem]",
    isArrow: false,
    postKey: "card_number",
    height: "2.5625rem",
    className:
      "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text w-[9.0625rem]",
    getValue: ({ list }: any) => (
      <p className="w-[9.0625rem]">
        {list?.card_number ? `${list?.card_number}` : "-"}
      </p>
    ),
  },
  {
    title: "Date of issue",
    cellWidth: "min-w-[9.375rem]",
    isArrow: true,
    postKey: "date_of_issue",
    height: "2.5625rem",
    className:
      "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text w-[8.125rem]",
    getValue: ({ list }: any) => {
      const date = moment(list?.date_of_issue, "DD/MM/YYYY HH:mm:ss");

      // Format the date as DD MMM YY
      const formatedDate = date.format("DD MMM YYYY");
      return <p className="w-[8.125rem]">{formatedDate}</p>;
    },
  },
  {
    title: "Total amount",
    cellWidth: "min-w-[9.375rem]",
    isArrow: false,
    postKey: "type",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) => (
      <p
        className={`${list?.balanceLoading === true ? "shimmer-effect " : ""}`}
      >
        <span
          className={`${list?.balanceLoading === true ? "opacity-0 " : ""}`}
        >
          {list?.total_amount
            ? `${SYMBOLS[list?.currency]}${decimalNumber(list?.total_amount)}`
            : "-"}
        </span>
      </p>
    ),
  },
  {
    title: "Available balance",
    cellWidth: "lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]",
    isArrow: false,
    postKey: "in",
    height: "2.5625rem",
    className: "py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text",
    getValue: ({ list }: any) => (
      <p
        className={`whitespace-nowrap text-ellipsis ${
          list?.balanceLoading === true ? "shimmer-effect max-w-[7.25rem]" : ""
        }`}
        title={list.description}
      >
        <span
          className={`${list?.balanceLoading === true ? "opacity-0 " : ""}`}
        >
          {list.available_balance
            ? `${SYMBOLS[list?.currency]}${decimalNumber(
                list?.available_balance
              )}`
            : "-"}{" "}
        </span>
      </p>
    ),
  },

  {
    getTitle: ({ scroll }: any) => (
      <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem] max-md:static">
        <div
          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
          id="left-shad-list"
        >
          <div className="arrow_buttons flex rounded ">
            <button
              className="group rounded-l  transition px-[.5625rem] py-2"
              type="button"
              id="leftArrow"
              onClick={(e: any) => scroll(-120)}
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                path={angleLeftSVG}
                svgClassName="w-1.5 h-[.5625rem]"
              />
            </button>
            <button
              className="group rounded-r  transition px-[.5625rem] py-2"
              type="button"
              onClick={(e: any) => scroll(120)}
              id="rightArrow"
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                path={angleRightSVG}
                svgClassName="w-1.5 h-[.5625rem]"
              />
            </button>
          </div>
        </div>
      </th>
    ),
    cellWidth: "min-w-[9.375rem]",
    isArrow: true,
    postKey: "type",
    height: "2.5625rem",
    className: "py-1.5 px-2 font-medium whitespace-nowrap ellips-text",
    direct: true,
    getValue: ({ list, tIndex }: any) => {
      const matchedView = virtualCardTransPopup?.index === tIndex;
      return (
        <td className="group sticky right-0 ml-auto p-0 icon_td  border-b ">
          <React.Fragment key={tIndex}>
            <div
              className={`${
                matchedView ? `bg-indigo-500 bg-opacity-[7%]` : ""
              }`}
            >
              <div className={`flex  relative w-full h-full border-l `}>
                <button
                  className={`group flex items-center justify-center p-1 md:p-1 w-full  `}
                  type="button"
                  id={`view-tx-pay-${tIndex}`}
                  onClick={() => {
                    setVirtualCardTransPopup((pre: any) => {
                      return {
                        ...pre,
                        status: true,
                        index: tIndex,
                        data: list,
                      };
                    });
                    // handleOrderView(
                    //   tIndex,
                    //   index,
                    //   transaction?.id,
                    //   transaction?.transfer_file
                    // );
                    // isMobileView &&
                    //   (document.body.style.overflow =
                    //     "hidden");
                  }}
                >
                  <Tooltip
                    anchorId={`view-tx-pay-${tIndex}`}
                    content={`${
                      // showLedger?.index === tIndex
                      //   ? "Close"
                      //   : "View"
                      "View"
                    }`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    className={`flex items-center justify-center ${
                      matchedView ? "fill-indigo-500" : "fill-violet-500"
                    } ${
                      matchedView
                        ? "group-hover:fill-indigo-500"
                        : "group-hover:fill-indigo-500"
                    } transition`}
                    path={EyeSVG}
                    svgClassName={`w-4 h-[.6875rem]`}
                  />
                </button>
              </div>
            </div>
          </React.Fragment>
        </td>
      );
    },
  },
];
const VirtualCardTabTable = ({ data, paginateData, balanceLoading }: any) => {
  const coreLayout = useContext(LayoutContext);
  const layout = useContext(TXContext);
  const isGodAdmin = coreLayout.allowedAccess?.account_info?.is_god_admin;
  const [virtualCardTransPopup, setVirtualCardTransPopup] = useState({
    status: false,
    index: null,
    data: null,
  });

  const [virtualCardTransactionPopup, setVirtualCardTransactionPopup] =
    useState({
      status: false,
      index: null,
      data: null,
    });

  const [paginateData1, setPaginateData1] = useState(paginateData);

  useEffect(() => {
    if (paginateData) {
      setPaginateData1(paginateData);
    }
  }, [paginateData]);

  return (
    <>
      <DynamicDataTable
        columns={
          isGodAdmin
            ? getGodadminColumns(
                setVirtualCardTransactionPopup,
                virtualCardTransactionPopup
              ).filter(Boolean)
            : getColumns(setVirtualCardTransPopup).filter(Boolean)
        }
        data={data}
        paginateData={paginateData1}
        lazyLoading={isGodAdmin ? true : false}
        handleEndReached={() => {
          if (
            isGodAdmin &&
            // !balanceLoading &&
            paginateData?.current_page < paginateData?.last_page
          ) {
            layout.setVirtualFilterHandle((current: any) => {
              let nextPage = paginateData?.current_page + 1;

              const final = {
                ...current,
                page: nextPage,
                per_page: 20,
              };

              handleSetCopyFilters(
                layout.setVirtualFilterHandleCopy,
                { page: nextPage, per_page: 20 },
                isGodAdmin
              );
              return final;
            });
          }
        }}
        activePopup={
          isGodAdmin ? virtualCardTransactionPopup : virtualCardTransPopup
        }
        setVirtualCardTransPopup={setVirtualCardTransPopup}
      />

      <VirtualCardTranscationViewPopup
        mainData={virtualCardTransPopup}
        setMainData={setVirtualCardTransPopup}
        isGodAdmin={isGodAdmin}
        list={data}
      />

      <CardTransactionPopup
        mainData={virtualCardTransactionPopup}
        setMainData={setVirtualCardTransactionPopup}
        isGodAdmin={isGodAdmin}
        list={data}
      />
    </>
  );
};

export default VirtualCardTabTable;
