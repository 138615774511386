export const ErrorMessage = (
  formik: any,
  index: number,
  name: string,
  className: string
) => {
  if (
    (formik.touched.variationDetails?.[index] as any)?.[name] &&
    (formik.errors.variationDetails?.[index] as any)?.[name]
  ) {
    return className
      ? "border-rose-500"
      : (formik.errors.variationDetails[index] as any)?.[name];
  }
  return null;
};
