import { getDefaultPaginationFilters } from "helpers/Functions";
import _ from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import { INITIAL_HEADERS, QUERIES } from "../../helpers/const";
import useDebounce from "../add_inventory/core/useDebounce";
import ConnectorFilters from "./component/ConnectorFilters";
import ConnectorHeaderFilters from "./component/ConnectorHeaderFilters";
import { ConnectorContext } from "./core/ConnectorProvider";
import { getClientsListing, getClientsOptions } from "./core/requests";
import DataTable from "./table/DataTable";
import ConnectorView from "./widget/ConnectorView";

const handleClosePopup = (layout: any) => () => {
  // close connector view pop up
  layout.setConnectorViewState({
    status: false,
    data: undefined,
  });
  // reset headers, sideOption tab and main tab
  layout.setHeaders([INITIAL_HEADERS]);
  layout.setSideOption("Variables");
  layout.setIsTabActive("Configuration");
  layout.setListingFilters(getDefaultPaginationFilters());
  layout.setOrderFilters(getDefaultPaginationFilters());
  layout.setMappedEvents([]);
  layout.setEventsMappingState(false);
};

const Connector = () => {
  const layout = useContext(ConnectorContext);
  const [customClientLoading, setCustomClientLoading] = useState(true);
  const heightRef1: any = useRef();

  //  OPTIONS VIA API
  useQuery(QUERIES.API_CONNECTOR_CLIENTS, () => getClientsOptions(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onError: (err) => {},
    onSettled(data, error) {
      layout.setClientsOptionsData(data?.data);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let filters = { ...layout.connectorFilter };
  let sorting = layout.orderState;

  let clientsDataMemo: any = useMemo(() => {
    return [filters, sorting];
  }, [filters, sorting]);

  let clientFilterData: any = useDebounce(JSON.stringify(clientsDataMemo), 200);

  // GET clientslist
  const {
    isLoading: clientsLoading,
    data: clientData,
    refetch: refetchClients,
  } = useQuery(
    [`${QUERIES.API_CONNECTOR_CLIENTS}`, ...JSON.parse(clientFilterData)],
    () =>
      getClientsListing({
        filters: filters,
        sorting: sorting,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      // enabled: !optionsLoading && startListing ? true : false,
      onError: (err) => {},
      onSettled: (err) => {
        // setReportTableLoading(false)
        setCustomClientLoading(false);
      },
    }
  );

  useEffect(() => {
    setCustomClientLoading(true);
  }, [
    layout?.connectorFilter?.status,
    layout?.connectorFilter?.query,
    layout.orderState,
  ]);

  // GET ORDERS FOR LISTING
  useEffect(() => {
    // AFTER LAZY LOAD
    const updateList = async () => {
      if (!clientsLoading) {
        if (clientData?.data.length > 0) {
          layout.setClients((pre: any) => {
            if (clientData?.meta?.current_page > 1) {
              return _.uniqBy([...pre, ...clientData.data], "id");
            } else {
              return [...clientData.data];
            }
          });
        } else {
          layout.setClients([]);
        }
      }
    };
    updateList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, clientsLoading]);

  if (layout?.connectorViewState?.status)
    return (
      <ConnectorView
        data={layout?.connectorViewState}
        handleClosePopup={handleClosePopup(layout)}
      />
    );

  return (
    <div className="main-content-wrap">
      <div
        className={`topFilters sticky-element top-[3.75rem] z-[11] pb-5 bg-gray-200`}
        id="topFilters"
      >
        <div className="accordion font-medium shadow-op2">
          <div
            className="accordion-item !rounded-none bg-white border-b border-gray-200"
            ref={heightRef1}
          >
            <div
              className="flex  justify-between accordion-header mb-0"
              id="headingOne"
            >
              <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
                <ConnectorHeaderFilters title={"Filters"} />
              </div>
            </div>
          </div>

          <ConnectorFilters
            connectorLoading={customClientLoading}
            refetchClients={refetchClients}
            paginateData={clientData?.meta}
          />
        </div>
      </div>

      {customClientLoading ? (
        <>
          {Array.from({ length: 10 }, (v: any, i: any) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-5"
              } `}
              key={i}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  clientsLoading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))}
        </>
      ) : (
        <DataTable
          refetch={refetchClients}
          paginateData={clientData?.meta}
          data={layout?.clients}
          clientsLoading={clientsLoading}
        />
      )}

      {layout?.clients?.length === 0 && !clientsLoading && (
        <>
          <NoDataComponent />
        </>
      )}
    </div>
  );
};

export default Connector;
