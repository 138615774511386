import { getVenuePerformerEventConfig } from "helpers/Functions";
import { size } from "lodash";
import moment from "moment";
import axios from "../../../axois/SetupAxios";
import { colorVirtualCard } from "../../../helpers/AssetHelpers";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";
import {
  IVcTransactionParams,
  mapCardBalanceListing,
  mapVcTransactionsData,
} from "./mapper";
const algoliasearch = require("algoliasearch");

// TX PAY LISTING
const getTxPayListing = (data: any): Promise<any> => {
  let transaction_date = data?.filters?.transaction_date;
  let transaction_type = data?.filters?.transaction_type;
  let query = data?.filters?.query;
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let sort_order = data?.filters?.sort_order;
  let order_by = data?.filters?.order_by;
  let export_to_csv = data?.export_to_csv;
  let id = data?.id;
  let team_id = data?.filters?.team_id?.id;

  let payload = {
    ...data.pagination,
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(transaction_date && {
      from_transaction_date: moment(transaction_date?.startDate).format(
        "YYYY-MM-DD"
      ),
      to_transaction_date: moment(transaction_date?.endDate).format(
        "YYYY-MM-DD"
      ),
    }),
    ...(transaction_type && { transaction_type: transaction_type.id }),
    ...(data?.debouncedSearch && { query: data?.debouncedSearch }),
    ...(export_to_csv && {
      export_to_csv: 1,
      page: undefined,
      per_page: undefined,
    }),
    ...(id && { id: id }),
    ...(team_id && { team_id }),
    ...(query && { query }),
    ...(sort_order && { sort_order }),
    ...(order_by && { order_by }),
  };
  return axios
    .post(`tx-pay/transactions`, payload)
    .then((response) => response.data);
};

// TX PAY Overview
const getTxPayOverviewData = (data: any): Promise<any> => {
  let transaction_date = data?.filters?.transaction_date;
  let transaction_type = data?.filters?.transaction_type;
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let export_to_csv = data?.export_to_csv;
  let id = data?.id;
  let team_id = data?.filters?.team_id?.id;

  let payload = {
    ...data.pagination,
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(transaction_date && {
      from_transaction_date: moment(transaction_date?.startDate).format(
        "YYYY-MM-DD"
      ),
      to_transaction_date: moment(transaction_date?.endDate).format(
        "YYYY-MM-DD"
      ),
    }),
    ...(transaction_type && { transaction_type: transaction_type.id }),
    ...(data?.debouncedSearch && { query: data?.debouncedSearch }),
    ...(export_to_csv && { export_to_csv: 1 }),
    ...(id && { id: id }),
    ...(team_id && { team_id }),
  };
  return axios.post(`tx-pay/counts`, payload).then((response) => response.data);
};

// WITHDRAW FUNDS API
const withdrawFunds = (data: any): Promise<any> => {
  let payload = {
    amount: data?.amount,
    date: moment(data?.date).format("YYYY-MM-DD"),
  };
  return axios
    .post(`tx-pay/withdraw-funds`, payload)
    .then((response) => response.data);
};

// ALGOLIA
const algoliaSearch = (data: any): Promise<any> => {
  const venuePerformerConfig: any = getVenuePerformerEventConfig(
    data?.coreLayout
  );

  let query = data?.inputChange;
  let payload = {
    ...(data?.salesFilter?.algoliaPage && {
      page: data?.salesFilter?.algoliaPage,
    }),
  };

  let isVenueConfig = "";
  let isPerformerNamesConfig = "";

  // VENUE CONFIG SETUP
  if (venuePerformerConfig?.venue_id) {
    isVenueConfig = `venue_id:${venuePerformerConfig?.venue_id} `;
  }

  // Performers CONFIG SETUP
  if (venuePerformerConfig?.performers) {
    isPerformerNamesConfig = `AND performer_names:"${venuePerformerConfig?.performers}"`;
  }

  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );

  // SEARCH WITH EVENTS
  const index = client.initIndex("events");
  const filters = `${isVenueConfig} ${isPerformerNamesConfig}`;
  // EXECUTE THE API WITH PAYLOAD
  return index
    .search(query, { ...payload, ...(filters.trim() && { filters: filters }) })
    .then((response: any) => {
      return response;
    });
};

// CANCEL WITHDRAWL REQUEST
const cancelWithdrawlRequest = async (data: any): Promise<any> => {
  const response = await axios.get(`tx-pay/cancel-withdraw-request`, {
    ...(size(data) > 0 && { params: { withdraw_request_id: data } }),
  });
  return response.data;
};

// VIEW DETAIL
const txPayDetailsView = (data: any): Promise<any> => {
  let payload = {
    ...(data?.id && { id: data?.id }),
  };
  return axios
    .get(`tx-pay/view`, { params: payload })
    .then((response) => response.data);
};

const addVirtualCard = (data: any): Promise<any> => {
  const payload = data;

  return axios
    .post(`tx-pay/add-card`, payload)
    .then((response) => response.data);
};

const getVirtualCards = (): Promise<any> => {
  return axios.get(`tx-pay/listing-card`).then((response) => response.data);
};

const updateVirtualCards = (data: any): Promise<any> => {
  const newData = {
    ...(data?.cardName && { card_alias: data?.cardName }),
    ...(data?.status && {
      status:
        typeof data?.status == "object"
          ? data?.status?.id
            ? data?.status?.id
            : data?.status?.name
          : data.status,
    }),
    ...(data?.id && { id: data?.id }),
    ...(colorVirtualCard(
      typeof data?.color === "object" ? data?.color?.name : data?.color
    ) && {
      card_color: colorVirtualCard(
        typeof data?.color === "object" ? data?.color?.name : data?.color
      ),
    }),
  };

  const payload = newData;
  return axios
    .post(`tx-pay/update-card`, payload)
    .then((response) => response.data);
};

const viewSettingVirtualCards = (data: any): Promise<any> => {
  const newData = {
    ...(data && { id: data }),
  };
  return axios
    .get(`tx-pay/view-card`, { params: newData })
    .then((response) => response.data);
};
const getTrasnferFundsData = (data: any): Promise<any> => {
  let params = {
    ...(data && { account_id: data }),
  };
  return axios
    .get(`tx-pay/transfer`, {
      params: params,
    })
    .then((response) => response.data);
};

const postTrasnferFundsData = (data: any): Promise<any> => {
  return axios.post(`tx-pay/transfer`, data).then((response) => response.data);
};

interface ITransferFormData {
  card_id: number;
  amount: number;
  currency: string;
}

const postVcMoneyTrasnferData = (data: ITransferFormData): Promise<any> => {
  return axios
    .post(`tx-pay/vc-money-transfer`, data)
    .then((response) => response.data);
};

const getVcTransactionsData =
  (id: number, filters: IVcTransactionParams, debouncedSearch?: any) =>
  (): Promise<any> => {
    const convertedData = mapVcTransactionsData(id, filters, debouncedSearch);

    delete convertedData?.page
    delete convertedData?.per_page

    return axios
      .get(`tx-pay/vc-transactions`, {
        params: convertedData,
        ...(filters?.export_to_pdf && { responseType: "blob" }),
      })
      .then((response) => response.data);
  };

const getVcTransactionsDataForPopup = (id: number) => (): Promise<any> => {
  const params = {
    id,
  };

  return axios
    .get(`tx-pay/vc-transactions`, {
      params: params,
    })
    .then((response) => response.data);
};

const getVCListForGodadmin =
  (id: number, filters: IVcTransactionParams, debouncedSearch?: any) =>
  (): Promise<any> => {
    const convertedData = mapVcTransactionsData(id, filters, debouncedSearch);
    return axios
      .get(`tx-pay/listing-card`, {
        params: convertedData,
        ...(filters?.export_to_pdf && { responseType: "blob" }),
      })
      .then((response) => response.data);
  };

const getBalances =
  (filters: IVcTransactionParams, debouncedSearch?: any) =>
  (): Promise<any> => {
    const convertedData = mapCardBalanceListing(filters, debouncedSearch);

    return axios
      .get(`tx-pay/listing-card/balances`, {
        params: convertedData,
        ...(filters?.export_to_pdf && { responseType: "blob" }),
      })
      .then((response) => response.data);
  };
const getOptionsDataForVirtualCards = (data: any): Promise<any> => {
  const payload = {
    ...(data?.length > 0 && { fields: data }),
  };

  return axios
    .post(`tx-pay/vc-options`, payload)
    .then((response) => response.data);
};

export const getVirtualCardBalance = (): Promise<any> => {
  return axios
    .get(`tx-pay/listing-card/balances`)
    .then((response) => response.data);
};
export const getVirtualCardBalanceWithPagination = (
  id: number,
  filters: IVcTransactionParams,
  debouncedSearch?: any
): Promise<any> => {
  const convertedData = mapVcTransactionsData(id, filters, debouncedSearch);
  return axios
    .get(`tx-pay/listing-card/balances`, {
      params: convertedData,
      ...(filters?.export_to_pdf && { responseType: "blob" }),
    })
    .then((response) => response.data);
};

export {
  addVirtualCard,
  algoliaSearch,
  cancelWithdrawlRequest,
  getBalances,
  getOptionsDataForVirtualCards,
  getTrasnferFundsData,
  getTxPayListing,
  getTxPayOverviewData,
  getVCListForGodadmin,
  getVcTransactionsData,
  getVcTransactionsDataForPopup,
  getVirtualCards,
  postTrasnferFundsData,
  postVcMoneyTrasnferData,
  txPayDetailsView,
  updateVirtualCards,
  viewSettingVirtualCards,
  withdrawFunds,
};
