const NoDataComponent = ({ isPadding = false, isNoMargin = false }: any) => {
  return (
    <div className={`${isPadding ? "p-0" : "px-5"}`}>
      <div
        className={`text-sm13 font-medium text-center p-2.5 bg-white rounded ${
          isNoMargin ? "mb-0" : "mb-[12.5rem]"
        }`}
      >
        No data available
      </div>
    </div>
  );
};

export default NoDataComponent;
