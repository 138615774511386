import { size } from "lodash";
import React from "react";
import NoData from "./NoData";
interface TablyBodyInterface {
  border?: string;
  children?: any;
  renderArray: any;
  popupTableRef?: any;
}

const TableBody: React.FC<TablyBodyInterface> = ({
  border = "",
  children,
  renderArray,
  popupTableRef,
}: any) => {
  return (
    <div className="accordion-body relative w-full">
      <div
        className={`${border} inventory-table sales-table overflow-x-auto overflow-y-hidden scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
        ref={popupTableRef}
      >
        <table className="w-full text-xs text-left">{children}</table>
        {/* NO DATA AVAILABLE */}
        {renderArray && size(renderArray) === 0 && <NoData />}
      </div>
    </div>
  );
};

export default TableBody;
