import { getDefaultPaginationFilters } from "helpers/Functions";
import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import { TX_PAY_FILTER_ITEMS } from "../../../helpers/const";
import { isVirtualCardTab } from "./_functions";
import { TX_TAB } from "./const";

export interface LayoutContextModel {
  activeTab: any;
  setActiveTab: (data: any) => void;

  filter: any;
  setFilter: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  ledgers: Array<any>;
  setLedgers: (data: any) => void;

  items: Array<any>;
  setItems: (data: any) => void;

  TXPayFilterHandle: any;
  setTXPayFilterHandle: (data: any) => void;

  TXPayFilterHandleCopy: any;
  setTXPayFilterHandleCopy: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  virtualCardData: Array<any>;
  setVirtualCardData: (data: any) => void;

  virtualFilterHandle: any;
  setVirtualFilterHandle: (data: any) => void;

  virtualFilterHandleCopy: any;
  setVirtualFilterHandleCopy: (data: any) => void;
}

const TXContext = createContext<LayoutContextModel>({
  activeTab: "",
  setActiveTab: () => {},

  filter: [],
  setFilter: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  ledgers: [],
  setLedgers: (data: any) => {},

  items: [],
  setItems: (data: any) => {},

  TXPayFilterHandle: {},
  setTXPayFilterHandle: (data: any) => {},

  TXPayFilterHandleCopy: {},
  setTXPayFilterHandleCopy: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  virtualCardData: [],
  setVirtualCardData: (data: any) => {},

  virtualFilterHandle: {},
  setVirtualFilterHandle: (data: any) => {},

  virtualFilterHandleCopy: {},
  setVirtualFilterHandleCopy: (data: any) => {},
});

const TXProvider = ({ children }: PropsWithChildren) => {
  let { pathname } = useLocation();

  const [filter, setFilter] = useState<any>("new");
  const [activeTab, setActiveTab] = useState<any>(() =>
    isVirtualCardTab(pathname) ? TX_TAB.virtualCard : TX_TAB.wallet
  );
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveTxPayFilters;

  let txpayItems = getHeaderFilter(
    "saveTxPayFilters",
    isFilterExistInDB,
    TX_PAY_FILTER_ITEMS
  );

  if (isGodAdmin) {
    // NOTHING CHANGE
    // if (activeTab === TX_TAB.virtualCard) {
    //   txpayItems = txpayItems
    //     ?.map((item: any) => {
    //       if (item.name === "Transaction date") {
    //         return {
    //           ...item,
    //           name: "Date of issue",
    //         };
    //       } else {
    //         return item;
    //       }
    //     })
    //     .filter((item: any) => item?.name !== "Transaction type");
    // }
    // console.log(txpayItems, "txpayItems");
  } else {
    txpayItems = txpayItems?.filter((item: any) => item.name !== "All teams");
  }

  const [filterItems, setFilterItems] = useState(() => {
    // GET DRAGGABLE FILTERS DATA FROM LOCAL STORAGE
    // const saved: any = localStorage.getItem("saveTxPayFilters");

    // let items: any = isGodAdmin
    //   ? JSON.parse(saved) || TX_PAY_FILTER_ITEMS
    //   : JSON.parse(saved) || TX_PAY_FILTER_ITEMS;
    // return isGodAdmin
    //   ? items
    //   : items?.filter((item: any) => item.name !== "All teams");
    // return JSON.parse(saved) || TX_PAY_FILTER_ITEMS;
    return txpayItems;
  });

  const [draggedItems, setDraggedItems] = useState(filterItems);

  const [ledgers, setLedgers] = useState([]);
  const [items, setItems] = useState([]);
  const [TXPayFilterHandle, setTXPayFilterHandle] = useState<any>(
    getDefaultPaginationFilters(50)
  );
  const [TXPayFilterHandleCopy, setTXPayFilterHandleCopy] = useState<any>(
    getDefaultPaginationFilters(50)
  );
  const [virtualCardData, setVirtualCardData] = useState([]);
  const [virtualFilterHandle, setVirtualFilterHandle] = useState({});
  const [virtualFilterHandleCopy, setVirtualFilterHandleCopy] = useState({});

  const value: LayoutContextModel = {
    activeTab,
    setActiveTab,

    filter,
    setFilter,

    filterItems,
    setFilterItems,

    ledgers,
    setLedgers,

    items,
    setItems,

    TXPayFilterHandle,
    setTXPayFilterHandle,

    TXPayFilterHandleCopy,
    setTXPayFilterHandleCopy,

    draggedItems,
    setDraggedItems,

    virtualCardData,
    setVirtualCardData,

    virtualFilterHandle,
    setVirtualFilterHandle,

    virtualFilterHandleCopy,
    setVirtualFilterHandleCopy,
  };

  return <TXContext.Provider value={value}>{children}</TXContext.Provider>;
};

export { TXContext, TXProvider };
