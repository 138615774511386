import _, { isArray, map, size } from "lodash";
import moment from "moment";
import axios from "../../../../axois/SetupAxios";
import { makePayloadForReferral } from "./functions";

// OPTIONS
const getFilterOptions = async (): Promise<any> => {
  const response = await axios.post(`subscribers/options`);
  return response.data;
};

// ACTION
const userStutusAction = async (data: any): Promise<any> => {
  const payload = {
    ...(data?.listData?.id && { team_id: data?.listData?.id }),
    ...(data?.action && { action: data?.action?.id }),
  };
  const response = await axios.post(`subscribers/action`, payload);
  return response.data;
};

// GET LISTING
const getListings = async (data: any): Promise<any> => {
  let teams = size(data?.teams) > 0 && _.map(data?.teams, "id");
  let risk_levels =
    size(data?.risk_levels) > 0 && _.map(data?.risk_levels, "id");
  let seller_levels =
    size(data?.seller_levels) > 0 && _.map(data?.seller_levels, "id");
  let user_type = size(data?.user_type) > 0 && _.map(data?.user_type, "id");
  let statuses = size(data?.statuses) > 0 && _.map(data?.statuses, "id");
  let query = data?.query;
  let per_page = data?.per_page;
  let page = data?.page;
  let from_register_date = data?.register_date
    ? moment(data?.register_date?.startDate).format("YYYY-MM-DD")
    : undefined;
  let to_register_date = data?.register_date
    ? moment(data?.register_date?.endDate).format("YYYY-MM-DD")
    : undefined;
  let order_by = data?.order_by;
  let sort_order = data?.sort_order;
  let demo_statuses =
    data?.demo_statuses &&
    isArray(data?.demo_statuses) &&
    size(data?.demo_statuses) > 0;
  const export_to_csv = data?.export_to_csv;

  let payload = {
    page,
    per_page,
    ...(teams && { teams: teams }),
    ...(risk_levels && { risk_levels: risk_levels }),
    ...(seller_levels && { seller_levels: seller_levels }),
    ...(user_type && { user_type: user_type }),
    ...(statuses && { statuses: statuses }),
    ...(query && { query }),
    ...(from_register_date &&
      to_register_date && { from_register_date, to_register_date }),
    ...(order_by && sort_order && { order_by, sort_order }),
    ...(demo_statuses && { demo_statuses: map(data?.demo_statuses, "id") }),
    ...(export_to_csv && { export_to_csv: 1 }),
  };
  const [response1, response2] = await Promise.all([
    axios.post("subscribers/listing", payload),
    axios.post("subscribers/listing", { ...payload, counts: true }),
  ]);

  return {
    ...response1.data,
    counts: response2.data?.data,
  };
};

// GET FORM DATA
const getUserFormData = async (data: any): Promise<any> => {
  const response = await axios.get(`subscribers/edit/${data}`);
  return response.data;
};

// UPDATE FORM DATA
const updateUserFormData = async (id: any, data: any): Promise<any> => {
  const response = await axios.post(`subscribers/edit/${id}`, data);
  return response.data;
};

const sellerLevelUpdate = async (data: any): Promise<any> => {
  const response = await axios.post(
    "subscribers/team/seller-level-update",
    data
  );
  return response.data;
};

// TEAM LISTING
const getTeamDataPersonal = (data: any): Promise<any> => {
  let order_by = data?.order_by;
  let sort_order = data?.sort_order;
  let payload = {
    ...(data?.team_id && { team_id: data?.team_id }),
    ...(data?.type && { type: data?.type }),
    ...(data?.page && { page: data?.page }),
    ...(data?.per_page && { per_page: data?.per_page }),
    ...(order_by && sort_order && { order_by, sort_order }),
  };

  return axios
    .post(`settings/account/team-members`, payload)
    .then((response) => response.data);
};

const getMarketPlacePersonalData = (data: any): Promise<any> => {
  let payload = {
    ...(data?.user_id && { user_id: data?.user_id }),
    ...(data?.team_id && { team_id: data?.team_id }),
  };

  return axios
    .post(`subscribers/team-user/connected-marketplace-list`, payload)
    .then((response) => response.data);
};

const saveUpdatedMarketplace = (payload: any): Promise<any> => {
  return axios
    .post(`subscribers/team-user/update-marketplace-list`, payload)
    .then((response) => response.data);
};

const getSubscribeReferralsListings = async (data: any): Promise<any> => {
  const payload = makePayloadForReferral(data);

  const [response1, response2] = await Promise.all([
    axios.post("subscribers/referrals", payload),
    axios.post("settings/referral-counts", payload),
  ]);

  return {
    ...response1.data,
    counts: response2.data?.data,
  };
};

const updateReferralAction = async (payload: any): Promise<any> => {
  const response = await axios.post(`subscribers/referrals/action`, payload);
  return response.data;
};

const referralViewData = async (payload: any): Promise<any> => {
  const response = await axios.post(`subscribers/referrals/details`, payload);
  return response.data;
};

export {
  getFilterOptions,
  getListings,
  getMarketPlacePersonalData,
  getSubscribeReferralsListings,
  getTeamDataPersonal,
  getUserFormData,
  referralViewData,
  saveUpdatedMarketplace,
  sellerLevelUpdate,
  updateReferralAction,
  updateUserFormData,
  userStutusAction,
};
