import axios from "../../../axois/SetupAxios";

export const getVenueDetails = async (data: any): Promise<any> => {
  const payload = {
    ...(data?.query && { keyword: data?.query }),
    per_page: data?.per_page,
    page: data?.page,
  };
  const response = await axios.get(`events/variations`, {
    params: payload,
  });
  return response.data;
};

// GET VARIATIONS
export const getVariationDetails = async (data: any): Promise<any> => {
  const reseller_id = data?.data?.id;
  const payload = {
    ...(reseller_id && { reseller_id }),
    per_page: data?.per_page,
    page: data?.page,
  };
  const response = await axios.get(`events/variations-action`, {
    params: payload,
  });
  return response.data;
};

// DELETE VARIATIONS
export const deleteVariation = async (data: any): Promise<any> => {
  const id = data?.section?.id;
  const payload = {
    ...(id && { id }),
  };
  const response = await axios.delete(`events/variations-action`, {
    params: payload,
  });
  return response.data;
};

// UPDATE VARIATIONS
export const updateVariation = async (data: any): Promise<any> => {
  const payload = {
    reseller_variations: data?.map((item: any) => {
      return {
        id: item?.section?.id,
        reseller_id: item?.section?.reseller_id,
        type: item?.sectionType === "Category" ? 1 : 2,
        name: item?.section?.name,
        variation: item?.variation,
        ...(item?.venue?.id && { venue_id: item?.venue?.id }),
      };
    }),
  };
  const response = await axios.post(`events/variations-action`, payload);
  return response.data;
};

// GET SECTION OPTIONS
export const getSectionOptions = async (data: any): Promise<any> => {
  const payload = {
    keyword: data?.keyword,
    exchange_id: data?.exchangeID,
    type: data?.type === "Category" ? 1 : 2,
    ...(data?.venueId && { venue_id: data?.venueId }),
  };
  const response = await axios.post(`events/search-venue-detail`, payload);
  return response.data;
};
