import { Tooltip } from "react-tooltip";
import Check from "../../../../../assets/media/icons/other_icons/check.svg";
import Cross from "../../../../../assets/media/icons/standard_icons/cross.svg";
import Button from "../../../../../components/ui/buttons/Button";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";

type TCancelUpdateButton = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  eIndex: string | number;
  rIndex: string | number;
  cancelRecord: (data: any) => void;
  updateRecord: (...args: any[]) => void;
};

const CancelUpdateButton = ({
  event,
  eventRecord,
  eIndex,
  rIndex,
  cancelRecord,
  updateRecord,
}: TCancelUpdateButton) => {
  return (
    <div className="flex items-center justify-center min-w-[14rem]">
      {/* CANCEL RECORD BUTTON */}
      <Button
        className="group flex mx-2 items-center justify-center h-6 w-6 text-white bg-white border border-gray-300 hover:bg-indigo-500 hover:border-indigo-500 transition rounded"
        id={`record-cancel-tooltip-${event?.id}-${eventRecord?.id}`}
        onClick={() => cancelRecord(eventRecord)}
      >
        <Tooltip
          anchorId={`record-cancel-tooltip-${event?.id}-${eventRecord?.id}`}
          content="Cancel"
          place={rIndex === 0 ? "left" : "top"}
          variant="light"
          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
        />
        <IKTSVG
          className="fill-violet-500 group-hover:fill-white"
          path={Cross}
          svgClassName="w-[.5966rem] h-[.5966rem]"
        />
      </Button>

      {/* UPDATE RECORD BUTTON */}
      <Button
        className="flex mx-2 items-center justify-center h-6 w-6 text-white bg-violet-500 hover:bg-indigo-500 transition rounded"
        onClick={(data: any) => {
          eventRecord.loader = true;
          updateRecord(data, eventRecord, true, eIndex, rIndex);
        }}
        id={`record-update-tooltip-${event?.id}-${eventRecord?.id}`}
      >
        <Tooltip
          anchorId={`record-update-tooltip-${event?.id}-${eventRecord?.id}`}
          content="Confirm"
          place={rIndex === 0 ? "left" : "top"}
          variant="light"
          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 text-violet-800"
        />
        <IKTSVG
          path={Check}
          className="fill-white"
          svgClassName="w-[.7585rem] h-[.6452rem]"
        />
      </Button>
    </div>
  );
};

export default CancelUpdateButton;
