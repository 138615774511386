import { IKTSVG } from "components/ui/IKTSVG";

import TableBody from "components/fullPopup/TableBody";
import { KTSVG } from "components/ui/KTSVG";
import { SYMBOLS } from "helpers/const";
import { size } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import EyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import MinusOutline from "../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../assets/media/icons/other_icons/plus-outline.svg";
import UploadOutOutline from "../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../assets/media/icons/other_icons/upload-outline.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import Cross from "../../../assets/media/icons/standard_icons/cross.svg";
// import { display } from "../core/_functions";
import { LayoutContext } from "components/core/LayoutProvider";
import { getVcTransactionsDataForPopup } from "../core/requests";
import TableHeader from "../table/components/TableHeader";
import SubtransactionPopup from "./SubtransactionPopup";

const CardTransactionPopup = ({
  mainData,
  setMainData,
  isGodAdmin,
  list,
}: any) => {
  const coreLayout = useContext(LayoutContext);
  const [popupData, setPopupData] = useState([]);
  const [subTransactionPopupData, setSubTransactionPopupData] = useState({
    show: false,
    data: {},
    index: -1,
  });
  const handleOnClosePop = () => {
    display(false);
    setMainData({
      status: false,
      index: null,
      data: null,
    });
  };

  const display = (isOpen: any) => {
    var body = document.querySelector("body");
    var html = document.querySelector("html");
    var pop_width =
      document.getElementById("cardtransactionpopup")?.clientWidth || 0;

    if (isOpen) {
      // @ts-ignore
      document.getElementById("TXAccordion").style.marginRight = `${
        pop_width - 20
      }px`;
      // document.body.style.overflow = 'hidden';
      body?.classList.add("scrollbar-none");
      html?.classList.add("scrollbar-none");
    } else if (!isOpen) {
      // @ts-ignore
      document.getElementById("TXAccordion").style.marginRight = "0px";
      // document.body.style.overflow = 'unset';
      body?.classList.add("scrollbar-none");
      html?.classList.add("scrollbar-none");
    }
  };
  const NavigationButtons = ({
    handleNavigation,
    imgPath,
    className,
    id,
    disabled,
  }: any) => {
    return (
      <button
        className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
          id === "next" && "rotate-180"
        } ${disabled ? "pointer-events-none " : ""}`}
        type="button"
        id={id}
        onClick={() => handleNavigation(id)}
      >
        <KTSVG
          className={`flex items-center justify-center ${
            disabled ? `fill-gray-400` : `fill-violet-500`
          } transition group-hover:fill-indigo-500`}
          path={imgPath}
          svgClassName="w-1.5 h-[.5625rem]"
        />
      </button>
    );
  };

  const handleNavigation = (data: any) => {
    let newIndex = mainData?.index;

    if (data === "back") {
      newIndex = newIndex === 0 ? list.length - 1 : newIndex - 1;
    } else {
      newIndex = newIndex === list.length - 1 ? 0 : newIndex + 1;
    }

    const preRecord = list[newIndex];

    setMainData({
      status: true,
      data: preRecord,
      index: newIndex,
    });
  };

  useEffect(() => {
    if (mainData?.status) {
      display(mainData?.status);
    }
  }, [mainData]);

  const {
    isLoading: vcTransactionsLoading,
    isFetching: vcTransactionsFetching,
    data: vcTransactionsList,
    refetch: virtualDataeRefetch,
  }: {
    data: any;
    isLoading: boolean;
    isFetching: boolean;
    refetch: Function;
  } = useQuery(
    ["vc-transactions", mainData?.data?.id],
    getVcTransactionsDataForPopup(mainData?.data?.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      enabled: Boolean(mainData?.data?.id),
      retry: false,
      onError: (err) => {},
      onSettled: (res) => {
        setPopupData(res.data);
      },
    }
  );
  //md:w-[calc(100%-3.75rem)]

  const popupTableRef: any = useRef();

  const scroll = (scrollOffset: any) => {
    popupTableRef.current.scrollLeft += scrollOffset;
  };

  return (
    <>
      <div
        className={` ${
          mainData?.status && "show"
        }  offcanvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0  bg-clip-padding outline-none transition duration-300 ease-in-out font-medium upload-ticket-popup w-full md:pointer-events-none  ${
          coreLayout?.asideMenuOpen
            ? "lg2:max-w-[calc(100%_-_13.875rem)] max-w-[calc(100%_-_3.75rem)] md:w-[86.25rem] "
            : "md:max-w-[calc(100%_-_3.75rem)] lg2:w-[86.25rem]"
        }  w-full  `}
        tabIndex={-1}
        //   aria-labelledby="offcanvasTransferLabel"
        // data-bs-backdrop="false"
      >
        <div className="pop_wrap flex flex-wrap h-full md:overflow-hidden overflow-auto pointer-events-none">
          {subTransactionPopupData.show && (
            <SubtransactionPopup
              subTransactionPopupData={subTransactionPopupData}
              setSubTransactionPopupData={setSubTransactionPopupData}
              list={vcTransactionsList?.data}
            />
          )}
          <div
            className={`pay-detail bg-white w-full lg:h-full h-auto ml-auto relative flex flex-col lg:w-1/2 pointer-events-auto shadow-3xl`}
            id="cardtransactionpopup"
          >
            {" "}
            <div className="border offcanvas-header flex justify-between border-t border-b">
              <h5 className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15">
                {`${popupData?.length ? popupData?.length : ""} ${
                  popupData?.length === 1 ? "transaction" : "transactions"
                } - ${mainData?.data?.team_name} - ${
                  mainData?.data?.card_number
                }`}
              </h5>
              {!subTransactionPopupData.show && (
                <button
                  type="button"
                  className="flex items-center justify-center h-10 w-10 border-l box-content group"
                  onClick={handleOnClosePop}
                >
                  <IKTSVG
                    className="fill-violet-500   group-hover:fill-indigo-500 transition"
                    path={Cross}
                    svgClassName="w-[.6875rem] h-[.6875rem]"
                  />
                </button>
              )}
            </div>
            <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full">
              {vcTransactionsLoading || vcTransactionsFetching ? (
                <div
                  className={`accordion bg-white font-medium pt-2.5 px-2.5 border relative z-[1] `}
                >
                  <div
                    className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
                  ></div>
                  <div
                    className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
                  ></div>
                </div>
              ) : (
                <div className="card_options">
                  <TableBody
                    border="border"
                    renderArray={popupData}
                    popupTableRef={popupTableRef}
                  >
                    <thead className="text-xs text-gray-400">
                      <tr className="border-b bg-white">
                        {/* Team name */}

                        {/* <TableHeader
                          title="Team name"
                          cellWidth="min-w-[11.25rem]"
                          isArrow={false}
                        />
                        <TableHeader
                          title="Card number"
                          cellWidth="min-w-[11.25rem]"
                          isArrow={false}
                        /> */}

                        {/* Date */}
                        <TableHeader
                          title="Date"
                          cellWidth="min-w-[9.375rem]"
                          isArrow={false}
                          postKey="date"
                        />

                        {/* Type */}
                        <TableHeader
                          title="Type"
                          cellWidth="min-w-[6.25rem]"
                          isArrow={false}
                          postKey="type"
                        />

                        {/* In */}
                        <TableHeader
                          title="In"
                          cellWidth="min-w-[9.375rem]"
                          isArrow={false}
                          postKey="in"
                        />

                        {/* Out */}
                        <TableHeader
                          title="Out"
                          cellWidth="min-w-[9.375rem]"
                          isArrow={false}
                          postKey="out"
                        />

                        {/* Status */}
                        <TableHeader
                          title="Status"
                          cellWidth="min-w-[9.375rem]"
                          isArrow={false}
                          postKey="status"
                        />

                        {/* Description */}
                        <TableHeader
                          title="Description"
                          cellWidth="lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]"
                        />
                        <th className="sticky right-0 ml-auto  p-0 min-w-[2.5rem] md:w-[5.0625rem]">
                          <div
                            className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
                            id="left-shad-list"
                          >
                            <div className="arrow_buttons flex rounded ">
                              <button
                                className="group rounded-l  transition px-[.5625rem] py-2"
                                type="button"
                                id="leftArrow"
                                onClick={(e: any) => scroll(-120)}
                              >
                                <IKTSVG
                                  className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                  path={angleLeftSVG}
                                  svgClassName="w-1.5 h-[.5625rem]"
                                />
                              </button>
                              <button
                                className="group rounded-r  transition px-[.5625rem] py-2"
                                type="button"
                                onClick={(e: any) => scroll(120)}
                                id="rightArrow"
                              >
                                <IKTSVG
                                  className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                  path={angleRightSVG}
                                  svgClassName="w-1.5 h-[.5625rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {size(popupData) > 0 &&
                        popupData?.map((list: any, index: number) => {
                          const date = moment(
                            list?.date,
                            "DD/MM/YYYY HH:mm:ss"
                          );
                          const formatedDate = date?.format(
                            "DD MMM YYYY HH:mm:ss"
                          );
                          const matchedView =
                            subTransactionPopupData.index === index;
                          return (
                            <tr
                              className={`border-b ${
                                matchedView
                                  ? "bg-indigo-500 bg-opacity-[7%]"
                                  : ""
                              }`}
                              id="tx-pay-row"
                            >
                              {/* <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                              >
                                <p className="w-[9.0625rem]">
                                  {list?.team_name ? `${list?.team_name}` : "-"}
                                </p>
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                              >
                                <p className="w-[9.0625rem]">
                                  {list?.card_number
                                    ? `${list?.card_number}`
                                    : "-"}
                                </p>
                              </td> */}
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                              >
                                <p className="w-[8.125rem]">{formatedDate}</p>
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                              >
                                <div className="credit flex items-center">
                                  <IKTSVG
                                    className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
                                    path={
                                      list?.type === "Credit"
                                        ? PlusOutLine
                                        : list?.type === "Debit"
                                        ? MinusOutline
                                        : list?.in
                                        ? UploadOutline
                                        : UploadOutOutline
                                    }
                                    svgClassName="w-3 h-3"
                                  />
                                  {list?.type}
                                </div>
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                              >
                                {list.in
                                  ? `${SYMBOLS?.[list?.currency]}${list.in}`
                                  : "-"}
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                              >
                                {list.out
                                  ? `${SYMBOLS?.[list?.currency]}${list.out}`
                                  : "-"}
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                              >
                                {/* {list.status ? list.status : "-"} */}
                                {list.status ? (
                                  <span
                                    className={`px-1.5 py-1 rounded-md text-white ${
                                      list.status?.toLowerCase() === "pending"
                                        ? "bg-yellow-100"
                                        : list.status?.toLowerCase() ===
                                            "cleared" ||
                                          list.status?.toLowerCase() ===
                                            "completion" ||
                                          list.status?.toLowerCase() ===
                                            "success" ||
                                          list.status?.toLowerCase() ===
                                            "refunded"
                                        ? "bg-green-600"
                                        : list.status?.toLowerCase() ===
                                            "declined" ||
                                          list.status?.toLowerCase() ===
                                            "voided"
                                        ? "bg-rose-500"
                                        : "bg-green-600"
                                    }`}
                                  >
                                    {list?.status}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium ellips-text"
                              >
                                <p
                                  className=" whitespace-nowrap text-ellipsis md:max-w-[31.25rem] max-w-[18.75rem]"
                                  title={list.description}
                                >
                                  {list.description
                                    ? `${list.description}`
                                    : "-"}
                                </p>
                              </td>

                              <td className="group sticky right-0 ml-auto p-0 icon_td ">
                                <React.Fragment key={index}>
                                  <div
                                    className={`${
                                      matchedView
                                        ? `bg-indigo-500 bg-opacity-[7%]`
                                        : ""
                                    }`}
                                  >
                                    <div
                                      className={`flex  relative w-full h-full border-l `}
                                    >
                                      <button
                                        className={`group flex items-center justify-center p-1 md:p-1 w-10 md:w-full h-[2.5625rem]  `}
                                        type="button"
                                        id={`view-tx-pay-${index}`}
                                        onClick={() => {
                                          setSubTransactionPopupData({
                                            show: true,
                                            data: list,
                                            index: index,
                                          });
                                        }}
                                        disabled={
                                          !(list?.subTransaction.length > 0)
                                        }
                                      >
                                        {list?.subTransaction.length > 0 && (
                                          <Tooltip
                                            anchorId={`view-tx-pay-${index}`}
                                            content={`${
                                              // showLedger?.index === index
                                              //   ? "Close"
                                              //   : "View"
                                              "View"
                                            }`}
                                            place="top"
                                            variant="light"
                                            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                          />
                                        )}
                                        <IKTSVG
                                          className={`flex items-center justify-center ${
                                            matchedView
                                              ? "fill-indigo-500"
                                              : "fill-violet-500"
                                          } ${
                                            matchedView
                                              ? "group-hover:fill-indigo-500"
                                              : "group-hover:fill-indigo-500"
                                          } transition`}
                                          path={EyeSVG}
                                          svgClassName={`w-4 h-[.6875rem] ${
                                            !(list?.subTransaction.length > 0)
                                              ? "opacity-0"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </React.Fragment>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </TableBody>
                </div>
              )}
            </div>
            {!subTransactionPopupData?.show && (
              <div className="offcanvas-footer border-t py-3 px-5 false text-right">
                <div className="r_btn gap-2.5 flex flex-wrap items-center">
                  <div className="pop_nav flex items-center bg-gray-100 rounded">
                    {/* BACK BUTTON */}
                    <NavigationButtons
                      id="back"
                      imgPath="standard_icons/angle_left.svg"
                      handleNavigation={(data: any) => handleNavigation(data)}
                      disabled={mainData?.index === 0}
                      svgClassName={
                        mainData?.index === 0 ? "!fill-gray-400" : "enn"
                      }
                    />
                    {/* NEXT BUTTON */}
                    <NavigationButtons
                      id="next"
                      imgPath="standard_icons/angle_left.svg"
                      handleNavigation={(data: any) => handleNavigation(data)}
                      disabled={list.length - 1 === mainData?.index}
                      svgClassName={
                        list.length - 1 === mainData?.index
                          ? "!fill-gray-400"
                          : "enn"
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {subTransactionPopupData.show && (
        <div className="offcanvas-backdrop fade show"></div>
      )}
    </>
  );
};

export default CardTransactionPopup;
