import { useContext } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import CompleteSignUp from "./CompleteSignUpPopup";

const KycOverlay = ({ isFixed = false, radius = false, kycStatus }: any) => {
  const coreLayout = useContext(LayoutContext);

  return (
    <>
      <div
        className={`${
          isFixed
            ? "fixed left-[3.75rem] w-[calc(100%_-_3.75rem)] pt-[5.25rem]"
            : "absolute left-0 w-full"
        } ${
          radius ? "rounded" : ""
        } top-0 h-full z-30 text-center bg-black/70 p-2.5 md:p-5`}
      >
        {!coreLayout?.KYCPopup && (
          <div className="flex flex-col justify-center items-center h-full text-white">
            <p className="text-sm15 font-semibold leading-5 mb-2.5">
              {kycStatus
                ? "Thank you for providing your KYC documents"
                : "Provide your KYC documents to complete sign up and add/withdraw funds"}
            </p>
            {!kycStatus ? (
              <button
                type="button"
                className="text-sm15 font-semibold bg-green-600 hover:bg-indigo-500 transition text-white rounded-lg px-2.5 py-1.5"
                data-bs-toggle="offcanvas"
                data-bs-target="#completeSignUp"
                onClick={() => coreLayout?.setKYCPopup(true)}
              >
                Complete sign up
              </button>
            ) : (
              <p className="text-sm13">
                One of our experts will notify you when your TX Pay wallet is
                available
              </p>
            )}
          </div>
        )}
      </div>
      <CompleteSignUp />
    </>
  );
};

export default KycOverlay;
