import { QUERIES } from "helpers/const";
import { size } from "lodash";
import useDebounce from "pages/add_inventory/core/useDebounce";
import MultiSelectWithButtons from "pages/customize/component/UI/MultiSelectWithButtons";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import { useEffect, useImperativeHandle, useState } from "react";
import { useQuery } from "react-query";
import { getSectionOptions } from "../core/requests";

const SectionSearch = ({
  reff,
  value,
  index,
  handleHitSelect,
  width = "",
  inputValue = "",
  type = "",
  handlerResetValue = () => {},
  isValuesNeeded = true,
  setAlogliaTye = () => {},
  hideCategoryButtons,
  buttonTypes,
  error,
  errorLabel,
  exchangeID,
  isUpdatedRecord,
  venueId,
}: any) => {
  const [inputChange, setInputChange] = useState<any>("");
  const [typeComponent, setTypeComponent] = useState({
    name: buttonTypes?.[0],
  });
  const [algoliaOptions, setAlgoliaOptions] = useState<any>();
  const { setContentFilter, isTabActive } = useCustomize();
  const algoliaDebounce = useDebounce(inputChange, 400);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  useImperativeHandle(reff, () => ({
    hide: () => setAlgoliaOptions([]),
  }));

  // SECTION API
  const { isLoading, isFetching } = useQuery(
    [
      `${QUERIES.CUSTOMIZE_ALGOLIA}`,
      size(algoliaDebounce) >= 3,
      typeComponent,
      venueId,
    ],
    () => {
      if (!venueId && (!inputChange || inputChange.trim() === "")) {
        return Promise.resolve();
      }

      return getSectionOptions({
        keyword: inputChange,
        type: typeComponent?.name,
        exchangeID,
        venueId,
      });
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      // enabled: isFocused && (algoliaDebounce || venueId) ? true : false,
      enabled:
        isFocused && (size(algoliaDebounce) >= 3 || venueId) ? true : false,
      onSettled(data, error) {
        if (data) {
          setAlgoliaOptions(data?.data?.result);
        }
      },
    }
  );

  const handleInputChange =
    (algoliaDebounce: any, setInputChange: any, setAlgoliaOptions: any) =>
    (data: any, e: any) => {
      if (["menu-close", "set-value", "input-blur"].includes(e.action)) {
        if (!data) {
          setInputChange((current: any) => {
            return current;
          });
        }
      } else {
        if (data === "")
          handleHitSelect({
            componentType: {
              ...typeComponent,
              id: `type_components`,
              index: index,
            },
          });

        setInputChange(() => {
          if (algoliaDebounce !== data) {
            setAlgoliaOptions([]);
          }
          return data;
        });
      }
    };

  const handleAlgoliaButtons = (data: any) => {
    const isCrossBtn = data?.target?.closest("#cross");
    if (isCrossBtn) {
      setInputChange(undefined);
      setAlgoliaOptions([]);
      handlerResetValue();
    } else if (data?.id) {
      setTypeComponent(data);
    }
  };

  useEffect(() => {
    if (inputValue) setInputChange(inputValue);
    else setInputChange("");
  }, [inputValue]);

  useEffect(() => {
    if (type)
      setTypeComponent({
        name: buttonTypes?.includes(type) ? type : "Event",
      });
  }, [buttonTypes, type]);

  const handleKeyDown = (event: any) => {
    if (
      event?.target?.value &&
      event.key === "Enter" &&
      isTabActive === "content_page"
    ) {
      event.preventDefault();
      setContentFilter((pre: any) => {
        return {
          ...pre,
          title: event?.target?.value,
          eventName: [],
        };
      });
    }
  };

  useEffect(() => {
    setAlogliaTye(typeComponent);
    setAlgoliaOptions([]);
  }, [typeComponent]);

  return (
    <div
      className={`w-full ${
        width || "md:w-1/2  lg2:w-[40%] md:px-2.5 max-md:pr-0"
      }`}
    >
      <div
        className={`relative rounded flex-1 max-w-full md:mb-0 border-rose-500 ${
          false && "shimmer-effect"
        }`}
      >
        <div className="w-full">
          <MultiSelectWithButtons
            hideCategoryButtons={hideCategoryButtons}
            name={`type_components_${index}`}
            options={algoliaOptions}
            isLoading={isLoading || isFetching}
            handleIsMenuFocused={(data: any) => setIsFocused(data)}
            handleOnChange={(e: any) => {
              let value = e.target.value;

              if (value?.name && isValuesNeeded)
                setInputChange(value?.name ?? "");

              handleHitSelect({
                ...value,
                componentType: {
                  ...typeComponent,
                  id: `type_components`,
                  index: index,
                },
              });
            }}
            value={value || null}
            inputValue={inputChange}
            handleInputChange={handleInputChange(
              algoliaDebounce,
              setInputChange,
              setAlgoliaOptions
            )}
            handleKeyDown={handleKeyDown}
            onSearch={(data: any) => {
              handleAlgoliaButtons(data);
            }}
            isMulti={false}
            classNamePrefix="inventory_select"
            className={`searchbar-wrap rounded ${
              error?.name ? "section-error" : ""
            }`}
            placeholder={`Search ${(
              typeComponent?.name as string
            ).toLowerCase()}`}
            menuPortalTarget={document.body}
            typeComponent={typeComponent}
            buttonTypes={buttonTypes}
          />

          {/* ERROR */}
          {error?.name ? (
            <div className="text-xs text-rose-500 error-msg left-[.75rem]">
              {errorLabel}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SectionSearch;
