import FilterChip from "./FilterChip";
import Filters from "./Filters";

const FilterWrapper = ({ paginateData, loading }: any) => {
  return (
    <>
      <Filters loading={loading} />
      <FilterChip paginateData={paginateData} loading={loading} />
    </>
  );
};

export default FilterWrapper;
