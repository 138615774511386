/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "../../../components/ui/Card";
import { KTSVG } from "../../../components/ui/KTSVG";
import { DashboardContext } from "../core/DashboardProvider";

// IS CHILD IN VIEW - FUNCTION
function isChildInViewport(parentId: string, childId: string): boolean {
  const parentElement = document.getElementById(parentId);
  const childElement = document.getElementById(childId);
  if (!parentElement || !childElement) {
    return false;
  }
  const parentRect = parentElement.getBoundingClientRect();
  const childRect = childElement.getBoundingClientRect();
  return (
    childRect.top >= parentRect.top &&
    childRect.left >= parentRect.left &&
    childRect.bottom <= parentRect.bottom &&
    childRect.right <= parentRect.right
  );
}

// SCROLL VIA OFFCANVAS
const scrollToVisible = (parentId: string, childId: string) => {
  const parent = document.getElementById(parentId);
  const child = document.getElementById(childId);
  const parentRect = parent && parent.getBoundingClientRect();
  const childReact = child && child.getBoundingClientRect();
  if (parentRect && childReact)
    if (
      childReact.top < parentRect.top ||
      childReact.bottom > parentRect.bottom
    ) {
      parent.scrollTo({
        top: child.offsetTop - parent.offsetTop,
        behavior: "smooth",
      });
    }
};

const options = [
  { id: "industry", name: "Industry" },
  { id: "legal", name: "Legal" },
  { id: "tours", name: "Tours" },
  { id: "sports", name: "Sports" },
  { id: "theater", name: "Theater" },
  { id: "onsales", name: "Onsales" },
  { id: "guest-commentary", name: "Guest Commentary" },
  { id: "world", name: "World" },
  { id: "video", name: "Video" },
  { id: "conferences", name: "Conferences" },
];

const NewsCard = ({ loader }: any) => {
  const [offCanvasData, setOffCanvasData] = useState({
    heading: "",
  });
  const layout = useContext(DashboardContext);
  const newsData = layout?.industryNewsData;
  // const offCanvasHandle = (item: any) => {
  //   setOffCanvasData({
  //     heading: layout.description,
  //   });
  // };
  const { newsOffCanvas } = layout;

  // SMOOTH SCROLL IN NEWS CARD
  useEffect(() => {
    const isInViewport = isChildInViewport(
      "newsCard",
      `newscard-item-${newsOffCanvas?.index}`
    );
    if (!isInViewport) {
      scrollToVisible("newsCard", `newscard-item-${newsOffCanvas?.index}`);
    }
  }, [newsOffCanvas.index]);

  let name = "category";
  let placeholder = "Category";

  // let value = options?.find((item: any) => layout?.newsOptions === item.id);
  const handleOnChange = (event: any) => {
    layout?.setNewsOptions({
      id: event?.target?.value?.id,
      page: 1,
    });
    // value = layout?.newsOptions?.id
    //   ? layout?.newsOptions?.id
    //   : options?.find((item: any) => layout?.newsOptions?.id === item.id);
  };

  // FETCH API INFINITE
  const fetchData = () => {
    layout?.setNewsOptions((current: any) => {
      let nextPage: number = current?.page + 1;
      return {
        ...current,
        page: nextPage,
      };
    });
  };

  const handleOffCanvas = (data: any, index: number) => {
    layout.setNewsOffCanvas({
      data: data,
      index: index,
    });
  };

  return (
    <div className="lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2 relative">
      <Card
        title="Industry News"
        name={name}
        placeholder=""
        options={options}
        //data={optionsdata}
        handleOnChange={handleOnChange}
        value={options?.find(
          (item: any) => layout?.newsOptions?.id === item.id
        )}
        borderBottom="true"
      >
        {/* Card sub Heading */}
        <div
          id="newsCard"
          className="no-scroll flow-root max-h-72 outline-black px-58 overflow-x-auto mt-2 pt-2 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
        >
          {loader && layout?.industryNewsData?.length === 0 ? (
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 transition animate-spin"
                path="other_icons/process.svg"
              />
            </span>
          ) : (
            newsData?.length > 0 ? (
              <InfiniteScroll
                dataLength={newsData?.length ? newsData?.length : 0}
                next={fetchData}
                hasMore={true}
                loader={Array.from({ length: 3 }, (v, i) => (
                  <div
                    className={`accordion font-medium px-5 mb-2 ${
                      i === 0 && "mt-2"
                    } `}
                    key={i}
                  >
                    <div
                      className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                      style={{ height: "30px" }}
                    ></div>
                  </div>
                ))}
                scrollableTarget="newsCard"
              >
                <ul className="max-h-50 overflow-y-auto ">
                  {newsData &&
                    newsData?.length > 0 &&
                    newsData.map((item: any, index: number) => {
                      let tempDate = moment(item?.pubDate).format("DD MMM");
                      let matched = layout.newsOffCanvas?.index === index;

                      return (
                        <React.Fragment key={index}>
                          <li
                            id={`newscard-item-${index}`}
                            className={`border border-transparent ${
                              matched && "!border-indigo-500 !bg-indigo-500/10"
                            } hover:border-indigo-500 cursor-pointer transition hover:bg-indigo-500/10 bg-gray-100/50 mb-2 mx-5 rounded`}
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            onClick={() => {
                              handleOffCanvas(item, index);
                              //layout.setDescription(item?.contents);
                              // layout.setTitle(item.title);
                              // offCanvasHandle(item);
                            }}
                            key={index}
                          >
                            <div className="flex items-center space-x-4">
                              <div className="flex items-center w-full">
                                {/* <div className="pl-2 sm:pl-0">
                              <img
                                className="w-10 h-10 rounded object-cover"
                                src="https://dynaimage.cdn.cnn.com/cnn/c_fill,g_auto,w_1200,h_675,ar_16:9/https%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F221208164147-argentina-lionel-messi.jpg"
                                alt="Default avatar"
                              />
                            </div> */}
                                <div className="flex flex-wrap justify-between w-full py-1">
                                  <p className="w-full sm:w-auto text-xs flex-1 my-0.5 px-3 sm:px-4 font-medium">
                                    {item.title}
                                  </p>
                                  <p className="sm:w-auto text-xs my-0.5 px-3 sm:px-4 font-medium text-gray-500">
                                    {tempDate ? tempDate : ""}
                                  </p>
                                  {/* <div className="inline-flex items-center w-full sm:w-auto my-0.5 px-3 sm:px-4 text-xs font-medium text-gray-500">
                        $320
                      </div> */}
                                </div>
                              </div>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                </ul>
              </InfiniteScroll>
            ) : (
              <div className="text-center text-sm13 py-4">
                No records could be found at the moment.
              </div>
            )
          )}
        </div>
      </Card>
    </div>
  );
};

export default NewsCard;
