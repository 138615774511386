import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import downAngleSVG from "../../../../../assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { PersonalDetailsContext } from "../../core/PersonalDetailsProvider";
import { SUBSCRIBE_TAB } from "../../core/const";

const HeaderCell = ({
  title,
  width,
  arrow,
  postKey,
  padding,
  uniqueKey,
  sortFor = "personalDetails",
  onClick = () => {},
  orderForSort,
}: any) => {
  const {
    filterData,
    referralFilterData,
    setFilterData,
    activeTab,
    setReferralFilterData,
    setCommonFilter,
  } = useContext(PersonalDetailsContext);
  let order =
    activeTab === SUBSCRIBE_TAB?.subscribeDetails
      ? filterData
      : SUBSCRIBE_TAB?.referral
      ? referralFilterData
      : orderForSort;

  const handleSort = (sortedKey: any) => {
    const stateSetters: Record<string, (data: any) => void> = {
      [SUBSCRIBE_TAB.subscribeDetails]: setFilterData,
      [SUBSCRIBE_TAB.referral]: setReferralFilterData,
    };

    const setState = stateSetters[activeTab];

    if (!setState) return;

    setState((prev: any) => {
      const isNewKey = prev?.order_by !== sortedKey;
      const nextSortOrder = isNewKey
        ? "desc"
        : prev?.sort_order === "desc"
        ? "asc"
        : prev?.sort_order === "asc"
        ? undefined
        : "desc";

      const updatedData = {
        ...prev,
        order_by: sortedKey,
        sort_order: nextSortOrder,
        page: 1,
        per_page: 20,
      };
      setCommonFilter((prevFilter: any) => ({
        ...prevFilter,
        ...(activeTab === SUBSCRIBE_TAB.subscribeDetails
          ? {
              subscriberFilter: {
                ...prevFilter.subscriberFilter,
                order_by: sortedKey,
                sort_order: nextSortOrder,
                page: 1,
                per_page: 20,
              },
            }
          : {
              referralFilter: {
                ...prevFilter.referralFilter,
                order_by: sortedKey,
                sort_order: nextSortOrder,
                page: 1,
                per_page: 20,
              },
            }),
      }));

      return updatedData;
    });
  };

  return (
    <th
      className={`px-1.5 py-3 font-medium whitespace-nowrap ${width} ${padding}`}
      key={uniqueKey}
    >
      {arrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button
            type="button"
            onClick={() => {
              if (sortFor !== "personalDetails") {
                onClick(postKey);
              } else {
                handleSort(postKey);
              }
            }}
          >
            <IKTSVG
              path={downAngleSVG}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                order?.order_by === postKey && order?.sort_order === "desc"
                  ? "rotate-180 !fill-violet-500"
                  : order?.order_by === postKey && order?.sort_order === "asc"
                  ? " !fill-violet-500"
                  : "fill-gray-400"
              }
                  `}
            />
          </button>
        </div>
      ) : (
        title
      )}
      <Tooltip
        anchorId={`pricePerExchange-tooltip${title}`}
        content="Price per exchange"
        place="top"
        variant="light"
        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
      />
    </th>
  );
};
export default HeaderCell;
