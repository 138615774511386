import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import { SearchIcon } from "assets/media/icons/standard_icons/SearchIcon";
import { LayoutContext } from "components/core/LayoutProvider";
import DatePickerField from "components/formComponent/DatePickerField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import { IKTSVG } from "components/ui/IKTSVG";
import { INITIAL_REFERRAL_DETAILS, isMobile } from "helpers/const";
import { handleSelectDeselectAll, removeEmptyValues } from "helpers/Functions";
import _, { size } from "lodash";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import close from "../../../../assets/media/icons/other_icons/Clear.svg";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";

function ToggleElemClose() {
  const submenuWrapper: any = document.querySelector("#filtersMob");
  submenuWrapper.classList.remove("activeFIlter");
  document.body.classList.remove("overflow-hidden");
}

const ReferralFilters = ({
  loading,
  searchValue,
  setSearchValue,
  options,
  handleOnApplyButtonClick = () => {},
}: any) => {
  const coreLayout = useContext(LayoutContext);
  const {
    referralFilterData,
    setReferralFilterData,
    filterItems,
    activeTab,
    commonFilter,
  } = useContext(PersonalDetailsContext);

  let newFilterData = commonFilter?.referralFilter;

  const refferalTeamRef: any = useRef(null);
  const refferedTeamRef: any = useRef(null);
  const refferedstatus: any = useRef(null);

  useEffect(() => {
    setSearchValue("");
  }, [activeTab]);

  // HEADER FILTER
  const getFilterItems = () =>
    filterItems?.filter((obj: any) => obj?.checked === true);

  //   console.log(getFilterItems());

  // HANDLE CHANGE
  const handleOnChange = (data: any) => {
    setReferralFilterData((current: any) => {
      return {
        ...current,
        [data?.target?.name]: data?.target?.value,
        page: 1,
        per_page: 20,
      };
    });
  };

  // BULK SELECT / DESELECT
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any
  ) => {
    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      setReferralFilterData
    );

    if (refferalTeamRef && refferalTeamRef?.current) {
      refferalTeamRef?.current?.blur();
    }
    if (refferedTeamRef && refferedTeamRef?.current) {
      refferedTeamRef?.current?.blur();
    }
    if (refferedstatus && refferedstatus?.current) {
      refferedstatus?.current?.blur();
    }
  };

  // RESET ALL
  const handleRefresh = () => {
    setReferralFilterData(INITIAL_REFERRAL_DETAILS);
    handleOnApplyButtonClick("apply", activeTab, INITIAL_REFERRAL_DETAILS);
    setSearchValue("");
  };

  const clearDisable = false;

  const buttonDisabled =
    size(referralFilterData?.teams) > 0 ||
    size(referralFilterData?.referralTeam) > 0 ||
    size(referralFilterData?.referralStatus) > 0 ||
    referralFilterData?.startDate ||
    referralFilterData?.endDate ||
    referralFilterData?.query;

  const applyButtonDisabled =
    size(newFilterData?.teams) > 0 ||
    size(newFilterData?.referralTeam) > 0 ||
    size(newFilterData?.referralStatus) > 0 ||
    newFilterData?.startDate ||
    newFilterData?.endDate ||
    newFilterData?.query;

  const newApplyButtonDisabled = useMemo(() => {
    const obj1 = _.cloneDeep(referralFilterData);
    const obj2 = _.cloneDeep(newFilterData);
    delete obj1.page;
    delete obj1.per_page;
    delete obj2.page;
    delete obj2.per_page;

    const removeEmptyvalue1 = removeEmptyValues(obj1);
    const removeEmptyvalue2 = removeEmptyValues(obj2);

    return _.isEqual(removeEmptyvalue1, removeEmptyvalue2);
  }, [referralFilterData, newFilterData]);

  return (
    <div id="filtersMob" className="filtersMob max-md:flex max-md:flex-col">
      <div
        className="flex flex-wrap border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
        id={`filters_block`}
      >
        <div
          className="closePopup hidden max-md:flex"
          id="closePopup"
          onClick={ToggleElemClose}
        >
          <IKTSVG
            path={close}
            className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
            svgClassName="w-4 h-4"
          />
        </div>
        {/* SEARCH WITH NAME */}
        <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  px-[.3125rem]`}>
          <div
            className={`relative rounded flex-1 max-w-full md:mb-0 ${
              loading && "shimmer-effect"
            } `}
          >
            <div className="w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button
                  type="button"
                  onClick={() => searchValue && setSearchValue("")}
                >
                  <div className="fill-violet-500">
                    {searchValue ? (
                      <CrossIcon className="w-3.5" />
                    ) : (
                      <SearchIcon className="h-3.5 w-3.5" />
                    )}
                  </div>
                </button>
              </div>
              <input
                type="text"
                name="query"
                // defaultValue={""}
                id="simple-search-personal"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search name"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    let eventObj = {
                      target: {
                        name: "query",
                        value: event?.target?.value,
                      },
                    };
                    handleOnChange(eventObj);
                    handleOnApplyButtonClick("apply", activeTab, {
                      ...newFilterData,
                      page: 1,
                      query: event?.target?.value,
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>

        {getFilterItems()?.map((columnItem: any, index: number) => {
          switch (columnItem.id) {
            case 0:
              return (
                <React.Fragment key={index}>
                  {/* REFERRER TEAM NAME */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div className={`rounded ${loading && "shimmer-effect"}  `}>
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Referrer team name"
                        options={options?.data?.referrer_teams}
                        name="teams"
                        value={referralFilterData?.teams}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        selectedRef={refferalTeamRef}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "teams",
                            options?.data?.referrer_teams,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );

            case 1:
              return (
                <React.Fragment key={index}>
                  {/* REFERRED TEAM NAME */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Referred team name"
                        options={options?.data?.referred_teams}
                        name="referralTeam"
                        value={referralFilterData?.referralTeam}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        selectedRef={refferedTeamRef}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "referralTeam",
                            options?.data?.referred_teams,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            case 2:
              return (
                <React.Fragment key={index}>
                  {/* REFERRAL TEAM */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Referral status"
                        options={options?.data?.referral_statuses}
                        name="referralStatus"
                        value={referralFilterData?.referralStatus}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        selectedRef={refferedstatus}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "referralStatus",
                            options?.data?.referral_statuses,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );

            case 3:
              return (
                <React.Fragment key={index}>
                  {/* START DATE */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <DatePickerField
                        name="startDate"
                        placeholder="Start date"
                        disabledKeyboardNavigation={false}
                        value={referralFilterData?.startDate}
                        isDateRange={false}
                        handleOnChange={(data: any) => handleOnChange(data)}
                        format="dd/MM/yy"
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            case 4:
              return (
                <React.Fragment key={index}>
                  {/* END DATE */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <DatePickerField
                        name="endDate"
                        placeholder="End date"
                        disabledKeyboardNavigation={false}
                        value={referralFilterData?.endDate}
                        isDateRange={false}
                        handleOnChange={(data: any) => handleOnChange(data)}
                        format="dd/MM/yy"
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
          }
        })}

        {!isMobile && (
          <div className={`px-[.3125rem]  ${loading && "shimmer-effect"}`}>
            <button
              onClick={() =>
                handleOnApplyButtonClick("apply", activeTab, {
                  ...referralFilterData,
                  page: 1,
                })
              }
              className={`${
                newApplyButtonDisabled && "pointer-events-none !bg-gray-500"
              } apply-filter-common  group inline-flex group items-center bg-violet-500 hover:bg-indigo-500 text-white   transition font-medium rounded text-sm13 px-2.5 py-0.5 false h-7 `}
            >
              Apply
            </button>
          </div>
        )}
      </div>
      <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
        <button
          className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
            clearDisable
              ? "bg-white text-violet-800 border-gray-300"
              : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
          }`}
          onClick={handleRefresh}
        >
          Clear
        </button>

        <button
          className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
          onClick={() => {
            ToggleElemClose();
            handleOnApplyButtonClick("apply", activeTab, {
              ...referralFilterData,
              page: 1,
            });
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default ReferralFilters;
