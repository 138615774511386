import { IKTSVG } from "components/ui/IKTSVG";
import { useEffect, useState } from "react";
import backToTOp from "../../../../assets/media/icons/other_icons/backtotop.svg";
import CSVExport from "./CSVExport";

const Footer = ({ listingLength, fetchData, fileName }: any) => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;

    // Show the button when the user scrolls down 400px
    if (scrollPosition > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white shadow-2xl shadow-black z-20 min-h-[3.125rem]">
      <button
        type="button"
        className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed bottom-[4.0625rem] right-0 transition duration-300 ${
          isVisible ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        id="backtotop"
        onClick={scrollToTop}
      >
        <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
      </button>
      {listingLength > 0 && (
        <div className={`flex flex-wrap w-full`}>
          <div className="flex flex-wrap items-center gap-2 my-2">
            <CSVExport fetchData={fetchData} fileName={fileName} />
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
